import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    Avatar,
    AvatarGroup,
    Tooltip,
    Typography,
    Pagination,
    Stack,
    Box,
    useMediaQuery,
    useTheme,
    Skeleton
} from '@mui/material';
import { stringToColor } from '../../../utilities/commonUtils';
import '../../../index.css'
import { ticketStatusForSearch } from '../../../constants/commonConstants';
import { getSavedTicket, getTicketsByQuery } from '../../../app/Ticket/TicketManagement';
import { toast } from "react-toastify";

/**
  * generate a color code based on the due date for label background
  * @param {*} dueDate 
  * @returns hex colorCode 
  */
const calculateBackgroundColor = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const timeDifference = due - today;
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    if (daysDifference <= 2) {
        return '#ffd6cc'; // Due in the next 2 days or earlier
    } else if (daysDifference <= 5) {
        return '#ffe6cc'; // Due within 3 to 5 days
    } else {
        return '#d9f3d9'; // Due beyond 5 days
    }
};

function QuickSearchResultView({ calledFrom }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const sessionUser = useSelector((state) => state.userData.data);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [clickedTicket, setClickedTicket] = useState('');
    const quickSearchData = useSelector((state) => state.ticketManagement.ticketQuickSearch)
    function getMaxHeight() {
        const windowHeight = window.innerHeight;
        const divTop = document.querySelector('div').getBoundingClientRect().top;
        const divHeight = windowHeight - divTop;
        const maxHeight = Math.min(divHeight, windowHeight) - 200;
        return `${maxHeight}px`;
    }

    const openTicketEditModal = (customTicketId) => {
        dispatch({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'mainState',
                field: 'existingTicketModalOpen',
                value: true,
            },
        });
        // Now lets dispatch the api call fetch the ticket data for the modal
        dispatch(getSavedTicket(customTicketId))
    }

    const handlePageChange = (event, newPage) => {
        dispatch(getTicketsByQuery({ data: quickSearchData, page: newPage }))
    };

    const NoDataFound = () => {
        return (
            <div style={{ textAlign: "center", padding: "20px" }}>
                <h3>No data found</h3>
                <p>Please try a different search query</p>
            </div>
        );
    };

    const handleTicketClick = (customTicketId) => {
        setClickedTicket(customTicketId);
        openTicketEditModal(customTicketId);
    }


    useEffect(() => {
       
        if ((!quickSearchData?.data?.length > 0 && !quickSearchData?.loadingFlag) || sessionUser.franchise !== quickSearchData?.data[0]?.franchise) {
            dispatch(getTicketsByQuery({ data: {}, page: 1, last20: true }))
        }
        if (isMobile) {
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'ticketQuickSearch',
                    field: 'isFilterComponentCollapsed',
                    value: true,
                },
            });
        }
    }, [])

    useEffect(() => {
        if (quickSearchData.csvData) {
            const blob = new Blob([quickSearchData.csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'tickets.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('CSV Downloaded successfully.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'ticketQuickSearch',
                    field: 'csvData',
                    value: null,
                },
            });
        }
    }, [quickSearchData.csvData]);

    useEffect(() => {
        if (quickSearchData?.exportPdfSuccess) {
            toast.success('PDF Downloaded successfully.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'ticketQuickSearch',
                    field: 'exportPdfSuccess',
                    value: null,
                },
            });
        }
    }, [quickSearchData?.exportPdfSuccess])



    return (
        <div style={{}}>
            <TableContainer className='custom-scrollbar' sx={{ maxHeight: quickSearchData.isFullScreen ? calledFrom === 'reportsTab' ? "680px" : getMaxHeight() : '680px', overflowY: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead sx={{ backgroundColor: '#f2f2f2', position: 'sticky !important', top: 0, zIndex: 5 }}>
                        <TableRow>
                            <TableCell sx={{ width: '3%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>ID</TableCell>
                            <TableCell sx={{ width: '35%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Ticket Head</TableCell>
                            <TableCell sx={{ width: '7%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Status</TableCell>
                            <TableCell sx={{ width: '10%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Company</TableCell>
                            <TableCell sx={{ width: '7%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Location</TableCell>
                            <TableCell sx={{ width: '8%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Assigned Users</TableCell>
                            <TableCell sx={{ width: '8%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Reported Users</TableCell>
                            <TableCell sx={{ width: '7%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Priority</TableCell>
                            <TableCell sx={{ width: '7%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Due Date</TableCell>
                            <TableCell sx={{ width: '7%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Created At</TableCell>
                            <TableCell sx={{ width: '3%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Payment Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            quickSearchData.isLoading ?
                                (
                                    // Show Skeleton loaders for each cell when isLoading is true
                                    Array.from(Array(10).keys()).map((index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant="text" />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) :
                                quickSearchData?.data?.map((ticket) => (
                                    <TableRow
                                        key={ticket.customTicketId}
                                        sx={{ borderBottom: '1px solid #e0e0e0', backgroundColor: clickedTicket === ticket.customTicketId ? '#DEEBFF' : '' }}
                                        hover
                                    >
                                        <TableCell
                                            sx={{
                                                width: '5%',
                                                cursor: 'pointer',
                                                padding: '4px',
                                            }}
                                            onClick={() => { handleTicketClick(ticket.customTicketId) }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 600,
                                                    cursor: 'pointer',
                                                    color: '#1769aa', // Use the primary color for links (blue by default)
                                                    textDecoration: 'none',
                                                    transition: 'text-decoration 0.2s ease-in-out',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                            >
                                                {ticket.customTicketId}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ width: '30%', padding: '4px' }}>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 600,
                                                    cursor: 'pointer',
                                                    color: '#1769aa', // Use the primary color for links (blue by default)
                                                    textDecoration: 'none',
                                                    transition: 'text-decoration 0.2s ease-in-out',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                                onClick={() => { handleTicketClick(ticket.customTicketId) }}
                                            >
                                                {ticket.ticketHead}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ width: '10%', padding: '4px' }}>
                                            <Chip
                                                label={(() => {
                                                    const statusItem = ticketStatusForSearch.find(item => item.value === ticket.status);
                                                    return statusItem ? statusItem.label : ticket.status;
                                                })()}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '2px',
                                                    minWidth: "50px",
                                                    backgroundColor: (() => {
                                                        const statusItem = ticketStatusForSearch.find(item => item.value === ticket.status);
                                                        return statusItem ? statusItem.backgroundColor : '#f2f2f2';
                                                    })(),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '10%', padding: '4px' }}>
                                            <Chip
                                                label={ticket?.company?.companyName?.substring(0, 17)}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '1px',
                                                    backgroundColor: '#f2f2f2',
                                                    width: '100%',
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '10%', padding: '4px' }}>
                                            {ticket?.companyLocation?.locationName ?
                                                <Chip
                                                    label={ticket?.companyLocation?.locationName?.substring(0, 17) || ''}
                                                    sx={{
                                                        borderRadius: '5px',
                                                        padding: '1px',
                                                        backgroundColor: '#d3d3d3',
                                                        width: '100%',
                                                    }}
                                                /> : null
                                            }
                                        </TableCell>
                                        <TableCell sx={{ width: '5%', padding: '4px' }}>
                                            <Tooltip title={ticket?.assignedUsers?.map((user) => user.displayName || `${user.firstName} ${user.lastName}`).join(', ')} arrow>
                                                <AvatarGroup max={3}>
                                                    {ticket?.assignedUsers?.length && ticket?.assignedUsers?.map((user) => {
                                                        const { displayName, firstName, lastName, photoURL } = user;
                                                        const fullName = (firstName && lastName) ? `${firstName} ${lastName}` : displayName;
                                                        return (
                                                            <Avatar
                                                                key={user._id}
                                                                alt="User Avatar"
                                                                src={user.photoURL}
                                                                sx={{
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    backgroundColor: user.photoURL ? 'transparent' : stringToColor(fullName),
                                                                    color: 'white',
                                                                    cursor: 'pointer',
                                                                    zIndex: 1,
                                                                }}
                                                                onClick={() => console.log('Clicked on Assigned User')}
                                                            >
                                                                {(!user.photoURL && (user.displayName || user.firstName)) ? (
                                                                    user.displayName
                                                                        ? user.displayName[0]
                                                                        : user.firstName[0]
                                                                ) : null}
                                                            </Avatar>
                                                        )
                                                    })}
                                                </AvatarGroup>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ width: '5%', padding: '4px' }}>
                                            <Tooltip title={ticket?.reportedUsers?.map((user) => user.displayName || `${user.firstName} ${user.lastName}`).join(', ')} arrow>
                                                <AvatarGroup max={3}>
                                                    {ticket?.reportedUsers?.length && ticket?.reportedUsers?.map((user) => {
                                                        const { displayName, firstName, lastName, photoURL } = user;
                                                        const fullName = (firstName && lastName) ? `${firstName} ${lastName}` : displayName;
                                                        return (
                                                            <Avatar
                                                                key={user._id}
                                                                alt="User Avatar"
                                                                src={user.photoURL}
                                                                sx={{
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    backgroundColor: user.photoURL ? 'transparent' : stringToColor(fullName),
                                                                    color: 'white',
                                                                    cursor: 'pointer',
                                                                    zIndex: 1,
                                                                }}
                                                                onClick={() => console.log('Clicked on Reported User')}
                                                            >
                                                                {(!user.photoURL && (user.displayName || user.firstName)) ? (
                                                                    user.displayName
                                                                        ? user.displayName[0]
                                                                        : user.firstName[0]
                                                                ) : null}
                                                            </Avatar>
                                                        )
                                                    })}
                                                </AvatarGroup>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ width: '10%', padding: '4px' }}>
                                            <Chip
                                                label={ticket.priority.charAt(0).toUpperCase() + ticket.priority.slice(1)}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '3px',
                                                    minWidth: "70px",
                                                    backgroundColor: (() => {
                                                        switch (ticket.priority) {
                                                            case 'low':
                                                                return '#d9f3d9'; // Light green for low priority
                                                            case 'medium':
                                                                return '#ffe6cc'; // Light orange for medium priority
                                                            case 'high':
                                                                return '#ffd6cc'; // Light red for high priority
                                                            default:
                                                                return '#f2f2f2'; // Default light gray color
                                                        }
                                                    })(),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '5%', padding: '4px' }}>
                                            <Chip
                                                label={isNaN(Date.parse(ticket?.dueDate)) ? null : new Date(ticket?.dueDate).toLocaleDateString('en-GB')}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '0px',
                                                    backgroundColor: isNaN(Date.parse(ticket?.dueDate)) ? 'transparent' : calculateBackgroundColor(ticket?.dueDate),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '5%', padding: '4px' }}>
                                            <Chip
                                                label={new Date(ticket?.createdAt).toLocaleDateString('en-GB')}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '0px',
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '2%', padding: '4px' }}>
                                            <Chip
                                                label={ticket?.paymentStatus}
                                                sx={{
                                                    borderRadius: '20px',
                                                    padding: '4px 8px',
                                                    backgroundColor: !['required', 'paymentPending'].includes(ticket?.paymentStatus) ? '#b7e4c7' : '#f4b0b0',
                                                    color: '#333',
                                                    fontWeight: 600,
                                                    fontSize: '0.8rem',
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
                {
                    (quickSearchData?.data?.length === 0 && !quickSearchData?.isLoading) && (
                        <>
                            <NoDataFound />
                        </>
                    )

                }
                {(!quickSearchData.isFullScreen || calledFrom === 'reportsTab') &&
                    <Stack spacing={2} direction="row" sx={{ marginTop: '25px', marginBottom: '7px' }}>
                        <Box sx={{ flexGrow: 1 }} />
                        <Pagination
                            defaultPage={quickSearchData?.activePage}
                            page={quickSearchData?.activePage}
                            count={quickSearchData?.totalPages}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Stack>
                }
            </TableContainer>
            {(quickSearchData.isFullScreen) &&
                <Stack spacing={2} direction="row" sx={{ marginTop: '25px', marginBottom: '7px' }}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Pagination
                        defaultPage={1}
                        page={quickSearchData?.activePage}
                        count={quickSearchData?.totalPages}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Stack>
            }
        </div>
    )
}

export default QuickSearchResultView;