import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, Popover, Grid, Chip, IconButton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AssetFilter from './AssetFilter'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { getAssetsByQuery } from '../../app/Asset/AssetSlice';


const filterChips = [
    {
        id: 1,
        label: 'Date Modified',
        value: 'dateModified'
    },
    {
        id: 2,
        label: 'Action Due',
        value: 'actionDue'
    },
    {
        id: 3,
        label: 'Service Due',
        value: 'serviceDue'
    },
    {
        id: 4,
        label: 'Invoice Due',
        value: 'invoiceDue'
    },
    {
        id: 5,
        label: 'Ware House',
        value: 'warehouse'
    },

]

const AssetFilterChips = () => {
    const dispatch = useDispatch();
    const queryData = useSelector((state) => state.assetManagement.queryData);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeFilter, setActiveFilter] = useState('');


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        dispatch(getAssetsByQuery(queryData));
    }, [activeFilter])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>

            <Grid container spacing={1} pl={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid xs={10.5} style={{ display: 'flex', justifyContent: 'space-between', gap: 3 }}>
                    {
                        filterChips.map((item) => {
                            return (
                                <Chip
                                    key={item.id}
                                    label={item.label}
                                    variant={activeFilter === item.id ? 'filled' : 'outlined'}
                                    color={activeFilter === item.id ? 'primary' : undefined}
                                    sx={{ borderRadius: '8px', fontSize: '11px', cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch({
                                            type: 'assetManagement/updateFieldValues',
                                            payload: {
                                                state: 'queryData',
                                                field: 'exclusiveSortAndFilter',
                                                value: item.value,
                                            },
                                        });
                                        dispatch({
                                            type: 'assetManagement/updateFieldValues',
                                            payload: {
                                                state: 'queryData',
                                                field: 'page',
                                                value: 1,
                                            },
                                        });

                                        setActiveFilter(item.id)
                                    }}
                                />
                            )
                        })
                    }
                </Grid>
                <Grid xs={1.5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        isMobile && (
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="asset-filter"
                                onClick={handleClick}
                            >
                                <FilterAltRoundedIcon color={'secondary'} fontSize="large" />
                            </IconButton>
                        )
                    }
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        PaperProps={{
                            style: { maxHeight: 'calc(100% - 40px)', overflow: 'scroll' }, // Prevents the Popover from going beyond the screen's bottom
                        }}
                    >
                        <Box p={2} style={{ maxHeight: 'calc(100% - 40px)', overflow: 'auto' }}>
                            <Grid container>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={handleClose}
                                    >
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <AssetFilter handleClose={() => { handleClose() }} expandByDefault={true} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Popover>
                </Grid>
            </Grid >
        </>
    )
}

export default AssetFilterChips
