import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import axios from 'axios';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import './FileUploader.css'

import service from './Service'

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '@mui/material/Tooltip';


// PLUGINS
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';


import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';

// Register the plugins
registerPlugin(
    FilePondPluginFileValidateSize,
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginGetFile,
    FilePondPluginFileRename
);

const baseURL = process.env.REACT_APP_BASE_URL;

function FileUploader({ destinationParentFolder, destinationSubFolder, onFileUploadSuccess, onDeleteFile, savedFiles, disabled, setAdminOnly = false }) {
    const sessionUserRole = useSelector((state) => state.userData.data.role);
    const sessionUserType = useSelector((state) => state.userData.data.userType);
    const [files, setFiles] = useState([]);
    // console.log(savedFiles)
    const [fileDownloadProgress, setFileDownloadProgress] = useState({});
    // console.log(savedFiles)
    const acceptedFileTypes = [
        'image/jpeg',
        'image/png',
        'image/heif',
        'image/gif',
        'image/svg+xml',
        'image/*',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'text/plain',
        'text/csv',
        'video/*',
        'audio/*'
    ];
    const maxFileSize = '1000MB';

    const deleteFileFromServer = async (file) => {
        if (disabled) return;
        if (sessionUserRole !== 'superAdmin' && sessionUserRole !== 'coreAdmin') {
            toast.warning('You do not priviliage to delete files, Please connect with Admin.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            return;
        }
        try {
            const res = await service.deleteFile({
                _id: file._id,
                destinationParentFolder: destinationParentFolder,
                destinationSubFolder: destinationSubFolder,
                targetFilePath: file.destinationPath
            });
            if (res.success === true) {
                onDeleteFile(file.filename);
                toast.info('File has been removed.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    style: { width: '400px' },
                });
            } else if (res.error?.message) {
                toast.error(res.error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    style: { width: '400px' },
                });
            }
        } catch (error) {
            if (error?.message) {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    style: { width: '400px' },
                });
            }
        }
    };

    const handleFileDownload = async (_id, fileName) => {
        try {
            const response = await axios.get(`${baseURL}files/downloadFileProxyApi/${_id}`, {
                withCredentials: true,
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setFileDownloadProgress((prevProgress) => ({
                        ...prevProgress,
                        [_id]: percentage,
                    }));
                },
            });

            // Get the content type from the response headers
            const contentType = response.headers['content-type'];

            const blobData = new Blob([response.data], { type: contentType });

            // Create a URL for the blob data
            const blobURL = URL.createObjectURL(blobData);

            // Create a hidden <a> element for downloading the file
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = blobURL;
            a.download = fileName; // Use the provided file name
            document.body.appendChild(a);

            // Trigger a click event on the <a> element to start the download
            a.click();

            // Clean up by removing the <a> element and revoking the blob URL
            document.body.removeChild(a);
            URL.revokeObjectURL(blobURL);

            setFileDownloadProgress((prevState) => {
                const newState = prevState;
                delete prevState[_id];
                return newState
            });
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };



    return (
        <div style={{ backgroundColor: '#F1F0EF', paddingBottom: '8px' }}>
            <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                maxFiles={10}
                server={{
                    url: baseURL,
                    timeout: 900000,
                    process: {
                        url: 'files/upload',
                        method: 'POST',
                        withCredentials: true,
                        onerror: (response) => response.error,
                        onload: (response) => {
                            response = JSON.parse(response)
                            if (response.success) {
                                onFileUploadSuccess(response.data);
                                setFiles([]);
                            }
                        },
                        ondata: (formData) => {
                            formData.append('destinationParentFolder', destinationParentFolder);
                            formData.append('destinationSubFolder', destinationSubFolder);

                            if (['coreAdmin', 'superAdmin', 'technicalAdmin', 'technician'].includes(sessionUserRole) && !setAdminOnly) {
                                const isAdminOnlyFile = window.confirm('Is this file only for Admin to see?');
                                formData.append('isAdminOnlyFile', isAdminOnlyFile);
                            } else {
                                formData.append('isAdminOnlyFile', false);
                            }

                            return formData;
                        },
                    },
                }}
                name="files"
                checkValidity={true}
                credits={false}
                labelIdle='Drag & Drop attach files or <span  class="filepond--label-action">Browse</span>'
                // validation
                acceptedFileTypes={acceptedFileTypes}
                allowFileTypeValidation={true}
                maxFileSize={maxFileSize}
                // beforeAddFile={(item) => {}}
                fileRenameFunction={(file) =>
                    new Promise((resolve) => {
                        const newFileName = file.name;
                        resolve(newFileName);
                    })
                }
                maxParallelUploads={2}
                disabled={disabled}
                allowFileMetadata={true}
                fileMetadataObject={{ capture: 'environment' }}
            />

            {/* Render the uploaded files */}
            {
                <>
                    <div>
                        {savedFiles?.length > 0 && (
                            <div className="files-list-wrapper">
                                {savedFiles
                                    ?.filter((file) => !(sessionUserRole === 'customerUser' && file.type === 'proposal'))
                                    ?.filter((file) => !(file.isAdminOnlyFile === true && sessionUserType !== 'Admin'))
                                    ?.sort((file1, file2) => new Date(file2.createdAt) - new Date(file1.createdAt))
                                    ?.map((file, index) => (
                                        <div key={index} className="file-item">

                                            {/* File Download Button */}
                                            {fileDownloadProgress[file._id] ? (
                                                <Box sx={{ position: 'relative', display: 'inline-flex', color: '#fff', marginRight: '0.5em' }}>
                                                    <CircularProgress variant="determinate" sx={{ color: '#fff' }} value={fileDownloadProgress[file._id]} />
                                                    <Box
                                                        sx={{
                                                            top: 0,
                                                            left: 0,
                                                            bottom: 0,
                                                            right: 0,
                                                            position: 'absolute',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="caption" fontWeight={'medium'} component="div" sx={{ color: '#fff', fontSize: '12px', padding: '4px' }}>
                                                            {`${Math.round(fileDownloadProgress[file._id])}%`}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                // File Download Button
                                                <Tooltip title="Download File" arrow placement="top">
                                                    <FileDownloadRoundedIcon onClick={() => { handleFileDownload(file._id, file.filename) }} className="icon-button icon-hover" />
                                                </Tooltip>
                                            )}

                                            {/* File Name */}
                                            <span className="file-name">{file.filename}</span>

                                            {/* File Size Chip */}
                                            <Tooltip
                                                title={
                                                    <div className="tooltip-content">
                                                        <div>
                                                            <strong>ID:</strong> {file._id}
                                                        </div>
                                                        <div>
                                                            <strong>Uploaded by:</strong> {file.userName}
                                                        </div>
                                                        <div>
                                                            <strong>Uploaded At:</strong> {new Date(file.createdAt).toLocaleDateString()}
                                                        </div>
                                                        <div>
                                                            <strong>File Size:</strong> {file.fileSize}
                                                        </div>
                                                        <div>
                                                            <strong>File Type:</strong> {file.contentType}
                                                        </div>
                                                        <div>
                                                            <strong>Parent Folder:</strong> {file.destinationParentFolder}
                                                        </div>
                                                        <div>
                                                            <strong>Sub Folder:</strong> {file.destinationSubFolder}
                                                        </div>
                                                        <div>
                                                            <strong>Is Admin Only File:</strong> {file?.isAdminOnlyFile ? 'Yes' : 'No'}
                                                        </div>
                                                    </div>
                                                }
                                                arrow
                                                placement="top"
                                            >
                                                <InfoRoundedIcon className="file-size-chip icon-hover" />
                                            </Tooltip>

                                            {/* Cancel Button */}
                                            <Tooltip title="Remove File" arrow placement="top">
                                                <CancelRoundedIcon disabled={(sessionUserRole !== 'superAdmin' && sessionUserRole !== 'technicalAdmin') || (disabled)} onClick={() => { deleteFileFromServer(file) }} className="icon-button icon-hover" />
                                            </Tooltip>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                </>
            }
        </div>
    );
}

export default FileUploader;
