// Libraries and modules
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components from external libraries
import { Grid, InputAdornment, Chip, Box, Collapse, Avatar, IconButton, TextField, MenuItem, Skeleton, Divider, InputLabel, Typography, Menu, FormControlLabel, Alert, AlertTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import EngineeringIcon from '@mui/icons-material/Engineering';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import SettingsInputAntennaRoundedIcon from '@mui/icons-material/SettingsInputAntennaRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
// Your own components
import { CreateButton, ResetButton } from '../../components/commons/Buttons';
import CustomDialogueMedium from '../../components/commons/CustomDialogueMedium';
import SelectInput from '../../components/commons/SelectInput';
import TextInput from '../../components/commons/TextInput';
import Autocomplete from '../../components/commons/Autocomplete';
import { assetContractType, assetHealthStatus, assetTypes, partsRequiredOptions, printerTypes, tonerLevel } from '../../constants/commonConstants';
import MUIDatePicker from '../../components/commons/DatePicker/DatePicker';
import MultipleUserSelector from '../../components/commons/MultipleUserSelector/MultipleUserSelector';
import SingleUserSelector from '../../components/commons/SingleUserSelector/SingleUserSelector';
import { getCompaniesList, getCompanyLocations } from '../UserMangaement/Service';
import CustomFieldController from '../../components/CustomFieldController/CustomFieldController';
import { createAsset, editAsset, getAsset } from '../../app/Asset/AssetSlice';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { getOrdinalSuffix } from '../../utilities/commonUtils';
import AssetActionItems from './AssetActionItems';
import FileUploader from '../../components/FileUploader/FileUploader';
import AssetHistoryView from './AssetHistoryView';
import PrinterInvoiceGrid from './PrinterInvoiceBreakUp';
import CommentController from '../../components/commons/CommentController/CommentController';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ManageHistoryRounded } from '@mui/icons-material';
import SimpleTicketTable from './SimpleTicketTable';

function CreateOrUpdateAssetView() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const currentFranchise = useSelector((state) => state.franchiseMangement.currentFranchise);
    const data = useSelector((state) => state.assetManagement.createOrEditData);
    const isModalOpen = useSelector((state) => state.assetManagement.isCreateOrEditModalOpen);
    const modalMode = useSelector((state) => state.assetManagement.modalMode);
    const isError = useSelector((state) => state.assetManagement.isError);
    const isLoading = useSelector((state) => state.assetManagement.isLoading);
    const errorMessage = useSelector((state) => state.assetManagement.errorMessage);
    const isSuccess = useSelector((state) => state.assetManagement.isSuccess);
    const [currentTab, setCurrentTab] = useState('assetHistory');

    const [machineCodeEditable, setMachineCodeEditable] = useState(true);

    const [isPrinterDetailExpanded, setIsPrinterDetailsExpanded] = useState(false);

    const [originalCompaniesList, setOriginalCompaniesList] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [companyLocationsList, setCompanyLocationsList] = useState([]);

    const [collapseState, setCollapseState] = useState({
        basicDetails: isMobile ? false : true,
        maintenanceDetails: isMobile ? false : true,
        companyAndUsers: isMobile ? false : true,
        printerDetails: isMobile ? false : true,
        invoiceDetails: isMobile ? false : true,
        connectionDetails: isMobile ? false : true,
        additionalDetails: isMobile ? false : true,
    })
   

    useEffect(() => {
        if (isModalOpen) {
            getCompaniesList()
                .then((result) => {
                    if (result?.data?.length) {
                        let tempArr = [];
                        result.data.forEach(item => {
                            tempArr.push({ value: item._id, label: item.companyName, type: item.type });
                        });
                        setOriginalCompaniesList(tempArr)
                        setCompaniesList(tempArr)
                    } else if (result.data) {
                        setOriginalCompaniesList([{ value: result.data._id, label: result.data.companyName, type: result?.data?.type }]);
                        setCompaniesList([{ value: result.data._id, label: result.data.companyName, type: result?.data?.type }])
                    }
                });
        }
        if (modalMode === 'update') setMachineCodeEditable(false);
    }, [isModalOpen]);

    useEffect(() => {
        if (data?.company?.value) {
            getSelectedCompaniesLocations();
        }
    }, [data?.company?.value]);

    useEffect(() => {
        if (isSuccess) {
            toast.success(modalMode === 'create' ? 'Company created!' : 'Changes saved!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'isSuccess', value: false, } });
        }
        if (isError) {
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'isError', value: false, } });
        }
    }, [isSuccess, isError])


    const getSelectedCompaniesLocations = async () => {
        try {
            const locationsList = await getCompanyLocations(data?.company?.value);
            setCompanyLocationsList(locationsList?.data?.map((item) => ({ value: item.id, label: item.locationName })) || []);
        } catch (error) {
            setCompanyLocationsList([]);
            console.error(`Failed to get locations: ${error.message}`);
        }
    }


    const handleModalClose = () => {
        dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'mainState', field: 'isCreateOrEditModalOpen', value: false, } });
        if (modalMode === 'update') {
            // When user closes the modal of an edit view then reset the edit data
            dispatch({ type: 'assetManagement/resetStateField', payload: { state: 'mainState', field: 'createOrEditData' } });
            dispatch({ type: 'assetManagement/resetStateField', payload: { state: 'mainState', field: 'modalMode' } });
        }
    };

    const errorValidationOnSubmission = () => {
        const errors = [];
        if (!data) {
            errors.push('Data is missing');
        } else {
            if (!data.assetName) {
                errors.push('Asset Name');
            }
            if (!data.assetType) {
                errors.push('Asset Type');
            }
            if (!data.healthStatus) {
                errors.push('Health Status');
            }
        }
        return errors;
    }

    const handleSubmission = () => {
        const errors = errorValidationOnSubmission();
        if (errors.length) {
            toast.error(`Missing Mandatory Fields: ${errors.join(',')}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    style: { width: '400px' },
                }
            );
            return;
        }
        if (modalMode === 'create') {
            dispatch(createAsset(data));
        } else if (modalMode === 'update') {
            dispatch(editAsset(data));
        } else {
            toast.error('Invalid modal mode',
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    style: { width: '400px' },
                }
            );
        }
    }

    const getTonerLevelSeverityColor = (tonerLevel) => {
        switch (tonerLevel) {
            case 'full':
                return 'success';
            case 'medium':
                return 'info';
            case 'low':
                return 'warning';
            case 'criticallyLow':
            case 'nil':
                return 'error';
            default:
                return 'info';
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function saveUploadedFilesInRedux(fileData) {
        dispatch({
            type: 'assetManagement/updateFieldValues',
            payload: {
                state: 'createOrEditData',
                field: 'uploads',
                value: [...data.uploads, fileData],
            },
        });
    }

    function removeUploadedFileFromRedux(fileName) {
        if (fileName) {
            dispatch({
                type: 'assetManagement/updateFieldValues',
                payload: {
                    state: 'createOrEditData',
                    field: 'uploads',
                    value: data.uploads?.filter(item => item.filename !== fileName),
                },
            });
        }
    }


    const footerButtons = (
        <>
            <ResetButton
                style={{ margingRight: '5px' }}
                onClick={() => {
                    if (modalMode === 'create') dispatch({ type: 'assetManagement/resetStateField', payload: { state: 'mainState', field: 'createOrEditData' } });
                    else dispatch(getAsset(data._id));
                }}
            />
            <CreateButton text={modalMode === 'update' ? "Save" : "Create"} onClick={() => { handleSubmission() }} disabled={isLoading} />
        </>
    );

    return (
        <div>
            <CustomDialogueMedium
                open={isModalOpen}
                onClose={() => { handleModalClose() }}
                headerText={modalMode !== 'update' ? "Create Asset" : `Editing Asset - ${data.machineCode}`}
                footerButtons={footerButtons}
                isLarge={!isMobile}
            >
                {
                    !isLoading &&
                    <Grid container spacing={2}>
                        {data.actionRequiredItems?.length > 0 ?
                            <Grid item xs={12}>
                                <AssetActionItems />
                            </Grid>
                            : null
                        }

                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                    <Chip
                                        icon={<FormatListNumberedRtlIcon />}
                                        label={<Typography variant="h6">Basic Details</Typography>}
                                        clickable
                                        onClick={() => {
                                            setCollapseState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    basicDetails: !prevState.basicDetails
                                                }
                                            })
                                        }}
                                        deleteIcon={collapseState.basicDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        onDelete={() => { }}
                                        sx={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                    />
                                    <Typography variant="body2" sx={{marginLeft:"1rem"}}>Machine Code Allocated Range {currentFranchise?.minAssetRange} - {currentFranchise?.maxAssetRange}</Typography>
                            </Box>
                            <Divider sx={{ height: '2px' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={collapseState.basicDetails}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <TextInput
                                                label="Machine Code"
                                                required={true}
                                                name={'machineCode'}
                                                error={!data.machineCode}
                                                value={data.machineCode}
                                                disabled={!machineCodeEditable}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'machineCode', value: e.target.value, }, }) }}
                                                endAdornment={<EditIcon />}
                                                onEndAdornmentClick={() => { setMachineCodeEditable(!machineCodeEditable) }}
                                            />
                                           
                                        </Grid>

                                        {/* ID */}
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <TextInput
                                                label="Asset Id"
                                                name={'assetId'}
                                                value={data.customMachineId ?? ''}
                                                disabled={true}
                                            />
                                        </Grid>

                                        {/* Asset Type */}
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <SelectInput
                                                required={true}
                                                name={'assetType'}
                                                label="Asset Type"
                                                value={data.assetType}
                                                error={!data.assetType}
                                                options={assetTypes}
                                                onChange={(e) => {
                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'assetType', value: e.target.value, }, })
                                                    setCollapseState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            printerDetails: e.target.value === 'printer',
                                                        }
                                                    });
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <SelectInput
                                                required={true}
                                                name={'contractType'}
                                                label="Contract Type"
                                                value={data.contractType}
                                                error={!data.contractType}
                                                options={assetContractType}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'contractType', value: e.target.value, }, }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <TextInput
                                                label="Machine Name"
                                                name={'assetName'}
                                                required={true}
                                                value={data.assetName}
                                                error={!data.assetName}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'assetName', value: e.target.value, }, }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <TextInput
                                                label="Model"
                                                name={'model'}
                                                value={data.model}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'model', value: e.target.value, }, }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <TextInput
                                                label="Serial Number"
                                                name={'serialNumber'}
                                                value={data.serialNumber}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'serialNumber', value: e.target.value, }, }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <TextInput
                                                label="Series"
                                                name={'series'}
                                                value={data.series}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'series', value: e.target.value, }, }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Grid>
                        <Divider sx={{ height: '4px' }} />

                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                    <Chip
                                        icon={<EngineeringIcon />}
                                        label={<Typography variant="h6">Maintenance Details</Typography>}
                                        clickable
                                        onClick={() => {
                                            setCollapseState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    maintenanceDetails: !prevState.maintenanceDetails
                                                }
                                            })
                                        }}
                                        deleteIcon={collapseState.maintenanceDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        onDelete={() => { }}
                                        sx={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                    />
                            </Box>
                            <Divider sx={{ height: '2px' }} />
                        </Grid>
                            <Grid item xs={12}>
                                <Collapse in={collapseState.maintenanceDetails}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <SelectInput
                                                required={true}
                                                name={'healthStatus'}
                                                label="Health Status"
                                                value={data.healthStatus}
                                                options={assetHealthStatus}
                                                error={!data.healthStatus || ['repairNeeded', 'maintenancePending'].includes(data.healthStatus)}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'healthStatus', value: e.target.value, }, }) }}
                                            />
                                        </Grid>

                                        {/* lastService: '', */}
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <MUIDatePicker
                                                label="Last Service Date"
                                                value={isNaN(Date.parse(data.lastService)) ? null : new Date(data.lastService)}
                                                onChange={(e) => {
                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'lastService', value: e, }, });
                                                    if (data.serviceDurationInDays && !isNaN(Date.parse(e))) {
                                                        const lastServiceDate = new Date(e);
                                                        const nextServiceDate = new Date(lastServiceDate.setDate(lastServiceDate.getDate() + Number(data.serviceDurationInDays)));

                                                        dispatch({
                                                            type: 'assetManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'createOrEditData',
                                                                field: 'nextService',
                                                                value: nextServiceDate,
                                                            },
                                                        });
                                                    }
                                                }}
                                                onClear={() => {
                                                    dispatch({
                                                        type: 'assetManagement/updateFieldValues',
                                                        payload: {
                                                            state: 'createOrEditData',
                                                            field: 'lastService',
                                                            value: null,
                                                        },
                                                    });
                                                }}
                                                maxDate={Date.now()}
                                                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                            />
                                        </Grid>
                                        {/* serviceDurationInDays: '', */}
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <TextInput
                                                label={`Service in every ${data.serviceDurationInDays} days`}
                                                name={'serviceDurationInDays'}
                                                required={true}
                                                type={'number'}
                                                value={data.serviceDurationInDays}
                                                onChange={(e) => {
                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'serviceDurationInDays', value: e.target.value, }, })
                                                    if (e.target.value && !isNaN(Date.parse(data.lastService))) {
                                                        const lastServiceDate = data.lastService ? new Date(data.lastService) : new Date();
                                                        const nextServiceDate = new Date(lastServiceDate.setDate(lastServiceDate.getDate() + Number(e.target.value)));
                                                        dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'nextService', value: nextServiceDate, }, });
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        {/* nextServiceDate: '', */}
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <MUIDatePicker
                                                label="Next Service Date"
                                                value={isNaN(Date.parse(data.nextService)) ? null : new Date(data.nextService)}
                                                onChange={(e) => {
                                                    dispatch({
                                                        type: 'assetManagement/updateFieldValues',
                                                        payload: {
                                                            state: 'createOrEditData',
                                                            field: 'nextService',
                                                            value: e,
                                                        },
                                                    });
                                                }}
                                                onClear={() => {
                                                    dispatch({
                                                        type: 'assetManagement/updateFieldValues',
                                                        payload: {
                                                            state: 'createOrEditData',
                                                            field: 'nextService',
                                                            value: null,
                                                        },
                                                    });
                                                }}
                                                minDate={new Date()}
                                                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                            />
                                        </Grid>

                                        {/* LAST SERVICE BY, */}
                                        <Grid item xs={isMobile ? 12 : 6} >
                                            <SingleUserSelector
                                                label='Last Service By'
                                                value={data.lastServiceBy}
                                                onChange={(newValue) => {
                                                    dispatch({
                                                        type: 'assetManagement/updateFieldValues',
                                                        payload: {
                                                            state: 'createOrEditData',
                                                            field: 'lastServiceBy',
                                                            value: newValue,
                                                        },
                                                    });
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} lg={2}>
                                            <SelectInput
                                                required={true}
                                                name={'partsRequired'}
                                                label="Parts Required"
                                                value={data.partsRequired}
                                                // color={data.partsRequired === 'yes' ? 'warning' : 'info'}
                                                // error={['low', 'criticallyLow', 'nil'].includes(data.tonerLevelMagenta)}
                                                options={partsRequiredOptions}
                                                onChange={(e) => {
                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'partsRequired', value: e.target.value, }, })
                                                    if (data.partsRequired === 'no') dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'partsNotes', value: '', }, })
                                                }}
                                            />
                                        </Grid>

                                        {
                                            (data.partsRequired === 'yes' || data.partsRequired === 'partsReceived') ?
                                                <Grid item xs={12} mb={2} >
                                                    <TextInput
                                                        required={data.partsRequired === 'yes'}
                                                        name={'partsNotes'}
                                                        multiline
                                                        minRows={2}
                                                        label="Parts Required Notes"
                                                        value={data.partsNotes}
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'partsNotes', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                                : null
                                        }
                                    </Grid>
                                </Collapse>
                            </Grid>

                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                    <Chip
                                        icon={<BusinessRoundedIcon />}
                                        label={<Typography variant="h6">Company & Users</Typography>}
                                        clickable
                                        onClick={() => {
                                            setCollapseState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    companyAndUsers: !prevState.companyAndUsers
                                                }
                                            })
                                        }}
                                        deleteIcon={collapseState.companyAndUsers ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        onDelete={() => { }}
                                        sx={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                    />
                            </Box>
                            <Divider sx={{ height: '2px' }} />
                        </Grid>

                            <Grid item xs={12}>
                                <Collapse in={collapseState.companyAndUsers}>
                                    <Grid container spacing={2}>
                                        {/* company: '', */}
                                        <Grid item xs={isMobile ? 12 : 6} >
                                            <Autocomplete
                                                label="Company"
                                                name='company'
                                                value={data.company || null}
                                                onChange={(e) => {
                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'company', value: e.target.value, }, })
                                                    // Reset Location and company user data when company changes
                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'location', value: null, } });
                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'users', value: [], } });

                                                }}
                                                options={companiesList}
                                            />
                                        </Grid>

                                        {/* location: '', */}
                                        <Grid item xs={isMobile ? 12 : 6} >
                                            <Autocomplete
                                                label="Company Location"
                                                name='companyLocation'
                                                value={data.location || null}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'location', value: e.target.value, }, }) }}
                                                options={companyLocationsList}
                                                required={false}
                                            // error={(!data.company && !data.userType === 'Admin')}
                                            />
                                        </Grid>

                                        {/* users: [], */}
                                        <Grid item xs={isMobile ? 12 : 6} >
                                            <MultipleUserSelector
                                                label='Users'
                                                value={data.users}
                                                onChange={(newValue) => {
                                                    dispatch({
                                                        type: 'assetManagement/updateFieldValues',
                                                        payload: {
                                                            state: 'createOrEditData',
                                                            field: 'users',
                                                            value: newValue,
                                                        },
                                                    });
                                                }}
                                                companyId={data?.company?.value ?? ''}
                                                onlySelectedCompanyUser={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Grid>

                            {
                                data.assetType === 'printer' &&
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <Chip
                                            icon={<PrintRoundedIcon />}
                                            label={<Typography variant="h6">Printer Details</Typography>}
                                            clickable
                                            onClick={() => {
                                                setCollapseState((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        printerDetails: !prevState.printerDetails
                                                    }
                                                })
                                            }}
                                            deleteIcon={collapseState.printerDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            onDelete={() => { }}
                                            sx={{
                                                borderRadius: '8px',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Divider sx={{ height: '2px' }} />
                                </Grid>
                            }


                            {/* Printer Details */}
                            <Grid item xs={12}>
                                <Collapse in={collapseState.printerDetails}>
                                    <Grid container spacing={2}>
                                    <Grid item xs={isMobile ? 6 : data.printerType === 'black&White' ? 4 : 2}>
                                        <SelectInput
                                            required={true}
                                            name={'printerType'}
                                            label="Printer Type"
                                            value={data.printerType}
                                            options={printerTypes}
                                            onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'printerType', value: e.target.value, }, }) }}
                                        />
                                    </Grid>

                                    {
                                        data.printerType === 'black&White' ?
                                            <>
                                                <Grid item xs={isMobile ? 12 : 4}>
                                                    <TextInput
                                                        label="Counter Limit (Black)"
                                                        name={'counterLimitBlack'}
                                                        value={data.counterLimitBlack}
                                                        type='number'
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'counterLimitBlack', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={isMobile ? 12 : 4}>
                                                    <TextInput
                                                        label="Current Counter (Black)"
                                                        name={'currentCounterBlack'}
                                                        value={data.currentCounterBlack}
                                                        type='number'
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'currentCounterBlack', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                <Grid item xs={isMobile ? 12 : 2.5}>
                                                    <TextInput
                                                        label="Counter Limit (Black)"
                                                        name={'counterLimitBlack'}
                                                        value={data.counterLimitBlack}
                                                        type='number'
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'counterLimitBlack', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={isMobile ? 12 : 2.5}>
                                                    <TextInput
                                                        label="Current Counter (Black)"
                                                        name={'currentCounterBlack'}
                                                        value={data.currentCounterBlack}
                                                        type='number'
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'currentCounterBlack', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={isMobile ? 12 : 2.5}>
                                                    <TextInput
                                                        label="Counter Limit (Color)"
                                                        name={'counterLimitColor'}
                                                        value={data.counterLimitColor}
                                                        type='number'
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'counterLimitColor', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={isMobile ? 12 : 2.5}>
                                                    <TextInput
                                                        label="Current Counter (Color)"
                                                        name={'currentCounterColor'}
                                                        value={data.currentCounterColor}
                                                        type='number'
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'currentCounterColor', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                            </>
                                    }

                                    {
                                        data.printerType === 'black&White' ?
                                            <Grid item xs={6}>
                                                <SelectInput
                                                    required={true}
                                                    name={'tonerLevelBlack'}
                                                    label="Black Toner Level"
                                                    value={data.tonerLevelBlack}
                                                    color={getTonerLevelSeverityColor(data.tonerLevelBlack)}
                                                    error={['criticallyLow', 'low', 'nil'].includes(data.tonerLevelBlack)}
                                                    options={tonerLevel}
                                                    onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'tonerLevelBlack', value: e.target.value, }, }) }}
                                                />
                                            </Grid>
                                            :
                                            <>
                                                <Grid item xs={6}>
                                                    <SelectInput
                                                        required={true}
                                                        name={'wasteToner'}
                                                        label="Waste Toner"
                                                        value={data.wasteToner}
                                                        error={['full', 'medium'].includes(data.wasteToner)}
                                                        options={tonerLevel}
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'wasteToner', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SelectInput
                                                        required={true}
                                                        name={'tonerLevelYellow'}
                                                        label="Yellow Toner Level"
                                                        value={data.tonerLevelYellow}
                                                        color={getTonerLevelSeverityColor(data.tonerLevelYellow)}
                                                        options={tonerLevel}
                                                        error={['criticallyLow', 'low', 'nil'].includes(data.tonerLevelYellow)}
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'tonerLevelYellow', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SelectInput
                                                        required={true}
                                                        name={'tonerLevelBlack'}
                                                        label="Black Toner Level"
                                                        value={data.tonerLevelBlack}
                                                        color={getTonerLevelSeverityColor(data.tonerLevelBlack)}
                                                        error={['criticallyLow', 'low', 'nil'].includes(data.tonerLevelBlack)}
                                                        options={tonerLevel}
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'tonerLevelBlack', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SelectInput
                                                        required={true}
                                                        name={'tonerLevelCyan'}
                                                        label="Cyan Toner Level"
                                                        value={data.tonerLevelCyan}
                                                        color={getTonerLevelSeverityColor(data.tonerLevelCyan)}
                                                        error={['criticallyLow', 'low', 'nil'].includes(data.tonerLevelCyan)}
                                                        options={tonerLevel}
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'tonerLevelCyan', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SelectInput
                                                        required={true}
                                                        name={'tonerLevelMagenta'}
                                                        label="Magenta Toner Level"
                                                        value={data.tonerLevelMagenta}
                                                        color={getTonerLevelSeverityColor(data.tonerLevelMagenta)}
                                                        error={['criticallyLow', 'low', 'nil'].includes(data.tonerLevelMagenta)}
                                                        options={tonerLevel}
                                                        onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'tonerLevelMagenta', value: e.target.value, }, }) }}
                                                    />
                                                </Grid>
                                            </>
                                        }

                                        <Grid item xs={12}>
                                            <Typography sx={{ marginRight: '10px', fontWeight: 500 }} variant='subtitle1'>Consumer Stocks</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                {/* Yellow Toner */}
                                                {data.printerType !== 'black&White' && (
                                                    <Grid item xs={6} sm={6} md={3}>
                                                        <TextField
                                                            fullWidth
                                                            inputProps={{ inputMode: 'numeric' }}
                                                            label="Yellow Toner Level"
                                                            onChange={(e) => {
                                                                dispatch({
                                                                    type: 'assetManagement/updateFieldValues',
                                                                    payload: {
                                                                        state: 'createOrEditData',
                                                                        field: 'tonerStocksAtClient',
                                                                        value: { ...data.tonerStocksAtClient, yellowToner: e.target.value },
                                                                    }
                                                                })
                                                            }}
                                                            value={data.tonerStocksAtClient?.yellowToner}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <div style={{ height: '20px', width: '20px', borderRadius: '50%', backgroundColor: 'yellow' }} />
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={() => dispatch({
                                                                            type: 'assetManagement/updateFieldValues',
                                                                            payload: {
                                                                                state: 'createOrEditData',
                                                                                field: 'tonerStocksAtClient',
                                                                                value: { ...data.tonerStocksAtClient, yellowToner: '' },
                                                                            }
                                                                        })}>
                                                                            <ClearRoundedIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                )}

                                                {/* Magenta Toner */}
                                                {data.printerType !== 'black&White' && (
                                                    <Grid item xs={6} sm={6} md={3}>
                                                        <TextField
                                                            fullWidth
                                                            inputProps={{ inputMode: 'numeric' }}
                                                            label="Magenta Toner Level"
                                                            onChange={(e) => {
                                                                dispatch({
                                                                    type: 'assetManagement/updateFieldValues',
                                                                    payload: {
                                                                        state: 'createOrEditData',
                                                                        field: 'tonerStocksAtClient',
                                                                        value: { ...data.tonerStocksAtClient, magentaToner: e.target.value },
                                                                    }
                                                                })
                                                            }}
                                                            value={data.tonerStocksAtClient?.magentaToner}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <div style={{ height: '20px', width: '20px', borderRadius: '50%', backgroundColor: 'magenta' }} />
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={() => dispatch({
                                                                            type: 'assetManagement/updateFieldValues',
                                                                            payload: {
                                                                                state: 'createOrEditData',
                                                                                field: 'tonerStocksAtClient',
                                                                                value: { ...data.tonerStocksAtClient, magentaToner: '' },
                                                                            }
                                                                        })}>
                                                                            <ClearRoundedIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                )}

                                                {/* Cyan Toner */}
                                                {
                                                    data.printerType !== 'black&White' &&
                                                    <Grid item xs={6} sm={6} md={3}>
                                                        <TextField
                                                            fullWidth
                                                                label="Cyan Toner Level"
                                                                inputProps={{ inputMode: 'numeric' }}
                                                                onChange={(e) => {
                                                                    dispatch({
                                                                        type: 'assetManagement/updateFieldValues',
                                                                        payload: {
                                                                            state: 'createOrEditData',
                                                                            field: 'tonerStocksAtClient',
                                                                    value: { ...data.tonerStocksAtClient, cyanToner: e.target.value },
                                                                }
                                                            })
                                                        }}
                                                                value={data.tonerStocksAtClient?.cyanToner}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                    <div style={{ height: '20px', width: '20px', borderRadius: '50%', backgroundColor: 'cyan' }} />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={() => dispatch({
                                                                        type: 'assetManagement/updateFieldValues',
                                                                        payload: {
                                                                            state: 'createOrEditData',
                                                                            field: 'tonerStocksAtClient',
                                                                            value: { ...data.tonerStocksAtClient, cyanToner: '' },
                                                                        }
                                                                    })}>
                                                                                <ClearRoundedIcon />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                }

                                                {/* Black Toner */}
                                                <Grid item xs={6} sm={6} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        inputProps={{ inputMode: 'numeric' }}
                                                        label="Black Toner Level"
                                                        onChange={(e) => {
                                                            dispatch({
                                                                type: 'assetManagement/updateFieldValues',
                                                                payload: {
                                                                    state: 'createOrEditData',
                                                                    field: 'tonerStocksAtClient',
                                                                value: { ...data.tonerStocksAtClient, blackToner: e.target.value },
                                                            }
                                                        })
                                                    }}
                                                        value={data.tonerStocksAtClient?.blackToner}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                <div style={{ height: '20px', width: '20px', borderRadius: '50%', backgroundColor: 'black' }} />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={() => dispatch({
                                                                    type: 'assetManagement/updateFieldValues',
                                                                    payload: {
                                                                        state: 'createOrEditData',
                                                                        field: 'tonerStocksAtClient',
                                                                        value: { ...data.tonerStocksAtClient, blackToner: '' },
                                                                    }
                                                                })}>
                                                                        <ClearRoundedIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>


                            {/* Invoice Details */}

                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Chip
                                        icon={<FileCopyRoundedIcon />}
                                        label={<Typography variant="h6">Invoice Details</Typography>}
                                        clickable
                                        onClick={() => {
                                            setCollapseState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    invoiceDetails: !prevState.invoiceDetails
                                                }
                                            })
                                        }}
                                        deleteIcon={collapseState.invoiceDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        onDelete={() => { }}
                                        sx={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                    />
                            </Box>
                            <Divider sx={{ height: '2px' }} />
                        </Grid>

                            <Grid item xs={12}>
                                <Collapse in={collapseState.invoiceDetails}>
                                    <Grid container spacing={2}>
                                        {/* Last InvoiceDate: '', */}
                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <MUIDatePicker
                                                label="Last Invoice Date"
                                                value={isNaN(Date.parse(data.lastInvoicedDate)) ? null : new Date(data.lastInvoicedDate)}
                                                onChange={(e) => {
                                                    dispatch({
                                                        type: 'assetManagement/updateFieldValues',
                                                        payload: {
                                                            state: 'createOrEditData',
                                                            field: 'lastInvoicedDate',
                                                            value: e,
                                                        },
                                                    });
                                                    if (!isNaN(Date.parse(e))) {
                                                        if (data.invoiceDurationType === 'fixedDayOfMonth') {
                                                            // Get the invoice day
                                                            const invoiceDay = data.invoiceDayOrDuration;
                                                            // get the date in the coming month for that particular day of the month 
                                                            // If the day doesn't exist, the Date object will adjust to the last day of the month
                                                            const nextMonth = new Date(e.getFullYear(), e.getMonth() + 1, 1);
                                                            const lastDayOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0).getDate();
                                                            const nextInvoiceDate = new Date(e.getFullYear(), e.getMonth() + 1, Math.min(lastDayOfNextMonth, invoiceDay));

                                                            // and dispatch that date to the 
                                                            dispatch({
                                                                type: 'assetManagement/updateFieldValues',
                                                                payload: {
                                                                    state: 'createOrEditData',
                                                                    field: 'nextInvoiceDate',
                                                                    value: nextInvoiceDate,
                                                                },
                                                            });
                                                        } else if (data.invoiceDurationType === 'inGivenDuration') {
                                                            // add the given days to from the lastServiceDate and dispatch to nextServiceDate;
                                                            const lastInvoiceDate = new Date(e);
                                                            const nextInvoiceDate = new Date(lastInvoiceDate.setDate(lastInvoiceDate.getDate() + Number(data.invoiceDayOrDuration)));
                                                            dispatch({
                                                                type: 'assetManagement/updateFieldValues',
                                                                payload: {
                                                                    state: 'createOrEditData',
                                                                    field: 'nextInvoiceDate',
                                                                    value: nextInvoiceDate,
                                                                },
                                                            });
                                                        }
                                                    }
                                                }}
                                                onClear={() => {
                                                    dispatch({
                                                        type: 'assetManagement/updateFieldValues',
                                                        payload: {
                                                            state: 'createOrEditData',
                                                            field: 'lastInvoicedDate',
                                                            value: null,
                                                        },
                                                    });
                                                }}
                                                maxDate={Date.now()}
                                                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                                fullWidth
                                            />
                                        </Grid>

                                        {/* invoiceDuration: '', */}
                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <TextField
                                                label={
                                                    data.invoiceDurationType === 'fixedDayOfMonth' ?
                                                        `Invoice on the ${getOrdinalSuffix(data.invoiceDayOrDuration)} of the month`
                                                        : `Invoice on every ${data.invoiceDayOrDuration} days`}
                                                name={'invoiceDay'}
                                                required={true}
                                                type={'number'}
                                                value={data.invoiceDayOrDuration}
                                                onChange={(e) => {
                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'invoiceDayOrDuration', value: e.target.value, }, });
                                                    if (!isNaN(Date.parse(data.lastInvoicedDate)) && Number(e.target.value) > 0) {
                                                        if (data.invoiceDurationType === 'fixedDayOfMonth') {
                                                            // Get the invoice day
                                                            const invoiceDay = e.target.value;
                                                            // get the date in the coming month for that particular day of the month 
                                                            // If the day doesn't exist, the Date object will adjust to the last day of the month
                                                            const lastInvoicedDate = new Date(data.lastInvoicedDate);
                                                            const nextMonth = new Date(lastInvoicedDate.getFullYear(), lastInvoicedDate.getMonth() + 1, 1);
                                                            const lastDayOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0).getDate();
                                                            const nextInvoiceDate = new Date(lastInvoicedDate.getFullYear(), lastInvoicedDate.getMonth() + 1, Math.min(lastDayOfNextMonth, invoiceDay));

                                                            // and dispatch that date to the
                                                            dispatch({
                                                                type: 'assetManagement/updateFieldValues',
                                                                payload: {
                                                                    state: 'createOrEditData',
                                                                    field: 'nextInvoiceDate',
                                                                    value: nextInvoiceDate,
                                                                },
                                                            });
                                                        } else if (data.invoiceDurationType === 'inGivenDuration') {
                                                            // add the given days to from the lastServiceDate and dispatch to nextServiceDate;
                                                            const lastInvoicedDate = new Date(data.lastInvoicedDate);
                                                            const nextInvoiceDate = new Date(lastInvoicedDate.setDate(lastInvoicedDate.getDate() + Number(e.target.value)));
                                                            dispatch({
                                                                type: 'assetManagement/updateFieldValues',
                                                                payload: {
                                                                    state: 'createOrEditData',
                                                                    field: 'nextInvoiceDate',
                                                                    value: nextInvoiceDate,
                                                                },
                                                            });
                                                        }
                                                    }
                                                }}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="more"
                                                                aria-controls="long-menu"
                                                                aria-haspopup="true"
                                                                onClick={handleClick}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                id="long-menu"
                                                                anchorEl={anchorEl}
                                                                keepMounted
                                                                open={Boolean(anchorEl)}
                                                                onClose={handleClose}
                                                            >
                                                                <MenuItem onClick={() => {
                                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'invoiceDurationType', value: 'fixedDayOfMonth', }, })
                                                                    handleClose();
                                                                }}>Fixed Day of Month</MenuItem>
                                                                <MenuItem onClick={() => {
                                                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'invoiceDurationType', value: 'inGivenDuration', }, })
                                                                    handleClose();
                                                                }}>In Every Given Day (Rotational)</MenuItem>
                                                            </Menu>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={!data.invoiceDayOrDuration || (data.invoiceDurationType === 'fixedDayOfMonth' && data.invoiceDayOrDuration > 30)}
                                                helperText={data.invoiceDurationType === 'fixedDayOfMonth' ? "Choose a day b/w 1st to 30th of the month" : ""}
                                                inputProps={{ max: data.invoiceDurationType === 'fixedDayOfMonth' ? 30 : null }}
                                            />
                                        </Grid>

                                        {/* nextInvoiceDate: '', */}
                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <MUIDatePicker
                                                label="Next Invoice Date"
                                                value={isNaN(Date.parse(data.nextInvoiceDate)) ? null : new Date(data.nextInvoiceDate)}
                                                onChange={(e) => {
                                                    dispatch({
                                                        type: 'assetManagement/updateFieldValues',
                                                        payload: {
                                                            state: 'createOrEditData',
                                                            field: 'nextInvoiceDate',
                                                            value: e,
                                                        },
                                                    });
                                                }}
                                                onClear={() => {
                                                    dispatch({
                                                        type: 'assetManagement/updateFieldValues',
                                                        payload: {
                                                            state: 'createOrEditData',
                                                            field: 'nextInvoiceDate',
                                                            value: null,
                                                        },
                                                    });
                                                }}
                                                minDate={new Date()}
                                                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                            />
                                        </Grid>

                                        {/* LAST Invoiced BY, */}
                                        {/* <Grid item xs={isMobile ? 12 : 6} >
                            <SingleUserSelector
                                label='Last Invoice By'
                                value={data.lastInvoiceBy}
                                onChange={(newValue) => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'createOrEditData',
                                            field: 'lastInvoiceBy',
                                            value: newValue,
                                        },
                                    });
                                }}
                            />
                            </Grid> */}
                                        {
                                            modalMode === 'update' && (
                                                <Grid item xs={isMobile ? 12 : 10}>
                                                    <PrinterInvoiceGrid
                                                        id={data._id}
                                                        selectedCompanyId={data.company?.value}
                                                        printerType={data.printerType}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Collapse>
                            </Grid>

                        <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Chip
                                        icon={<SettingsInputAntennaRoundedIcon />}
                                        label={<Typography variant="h6">Connection Details</Typography>}
                                        clickable
                                        onClick={() => {
                                            setCollapseState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    connectionDetails: !prevState.connectionDetails
                                                }
                                            })
                                        }}
                                        deleteIcon={collapseState.connectionDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        onDelete={() => { }}
                                        sx={{
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                    />
                            </Box>
                            <Divider sx={{ height: '2px' }} />
                        </Grid>

                            <Grid item xs={12}>
                                <Collapse in={collapseState.connectionDetails}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <TextInput
                                                label="Remote Connection ID/IP"
                                                name={'remoteConnectionId'}
                                                value={data.remoteConnectionId}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'remoteConnectionId', value: e.target.value, }, }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <TextInput
                                                label="Remote Connection User Name"
                                                name={'remoteConnectionUserName'}
                                                value={data.remoteConnectionUserName}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'remoteConnectionUserName', value: e.target.value, }, }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <TextInput
                                                label="Remote Connection Password"
                                                name={'remoteConnectionPassword'}
                                                value={data.remoteConnectionPassword}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'remoteConnectionPassword', value: e.target.value, }, }) }}
                                            />
                                        </Grid>

                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <TextInput
                                                label="System IP"
                                                name={'systemIp'}
                                                value={data.systemIp}
                                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'systemIp', value: e.target.value, }, }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <EditNoteRoundedIcon sx={{ mr: 1 }} />
                                    <Typography variant="h6">Additional Details</Typography>
                                </Box>
                                <Divider sx={{ height: '2px' }} />
                            </Grid>

                            {/* remarks: '', */}
                        <Grid item xs={12}>
                            <TextInput
                                label="Remarks"
                                multiline={true}
                                minRows={2}
                                name={'remarks'}
                                value={data.remarks}
                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'remarks', value: e.target.value, }, }) }}
                            />
                            </Grid>

                            {/* FILE UPLOADS || ATTACHMENTS */}
                            <Grid item xs={12} mt={4} sx={{ marginBottom: '5px' }}>
                                <FileUploader
                                    onFileUploadSuccess={saveUploadedFilesInRedux}
                                    onDeleteFile={removeUploadedFileFromRedux}
                                    destinationParentFolder={modalMode === 'create' ? 'tempFiles' : 'assets'}
                                    destinationSubFolder={modalMode === 'create' ? 'assets' : data._id}
                                    savedFiles={data.uploads}
                                    disabled={false}
                                />
                            </Grid>

                        {/* customFieldValues: [], */}
                            <Grid item xs={12} sx={{ marginTop: '5px' }}>
                            <CustomFieldController
                                fieldValues={data.customFieldValues}
                                onChange={(newFieldValueSet) => {
                                    dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'customFieldValues', value: newFieldValueSet, }, })
                                }}
                            />
                        </Grid>
                            {
                                modalMode === 'update' && (
                                    <Grid item xs={12}>
                                        <CommentController label={'Add a comment'} module={'asset'} id={data.id} machineCode={data.machineCode} />
                                    </Grid>
                                )
                            }

                        {/* isActive: true, */}
                            {
                                modalMode === 'update' &&
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: isMobile ? 'nowrap' : 'wrap', // No wrap when isMobile is true
                                        justifyContent: { xs: 'flex-start', md: 'center' },
                                        overflowX: isMobile ? 'auto' : 'visible', // Scrollable when isMobile is true
                                    }}
                                >
                                    <Chip
                                        label={`Updated By: ${data.updatedBy?.displayName}`}
                                        avatar={<Avatar>{data.updatedBy?.displayName?.charAt(0)}</Avatar>}
                                        sx={{ marginBottom: '10px', borderRadius: isMobile ? '5px' : '16px' }} // Smaller borderRadius when isMobile is true
                                    />
                                    <Chip
                                        label={`Updated At: ${new Date(data?.updatedAt).toLocaleString()}`}
                                        color="secondary"
                                        sx={{ marginRight: '10px', marginBottom: '10px', borderRadius: isMobile ? '5px' : '16px' }} // Smaller borderRadius when isMobile is true
                                    />
                                    <Chip
                                        label={`Created At: ${new Date(data.createdAt).toLocaleString()}`}
                                        color="secondary"
                                        sx={{ marginRight: '10px', marginBottom: '10px', borderRadius: isMobile ? '5px' : '16px' }} // Smaller borderRadius when isMobile is true
                                    />
                                    <Chip
                                        label={`Created By: ${data.createdBy?.displayName}`}
                                        avatar={<Avatar>{data.createdBy?.displayName?.charAt(0)}</Avatar>}
                                        sx={{ marginRight: '10px', marginBottom: '10px', borderRadius: isMobile ? '5px' : '16px' }} // Smaller borderRadius when isMobile is true
                                    />
                                </Grid>
                            }

                            {
                                modalMode === 'update' ?
                                    <Grid item xs={12} sx={{ marginTop: '5px' }}>
                                        <Chip
                                            icon={<ManageHistoryRounded />}
                                            label="Action Logs"
                                            clickable
                                            color={currentTab === 'assetHistory' ? 'primary' : 'default'}
                                            onClick={() => setCurrentTab('assetHistory')}
                                            sx={{ marginRight: '10px' }}
                                        />
                                        <Chip
                                            icon={<ReportProblemRoundedIcon />}
                                            label="Tickets"
                                            clickable
                                            color={currentTab === 'tickets' ? 'primary' : 'default'}
                                            onClick={() => setCurrentTab('tickets')}
                                        />
                                        {currentTab === 'assetHistory' && <AssetHistoryView assetId={data._id} />}
                                        {currentTab === 'tickets' && <SimpleTicketTable id={data._id} />}
                                    </Grid>
                                    : null
                            }
                    </Grid>

                }
                {
                    isLoading &&
                    <Grid container spacing={2}>
                        {[...Array(20)].map((item, index) => (
                            <Grid item xs={6} key={index}>
                                <Skeleton variant="rect" height={56} sx={{ boarderRadius: '8px' }} />
                            </Grid>
                        ))}
                    </Grid>
                }

            </CustomDialogueMedium>
        </div>
    )
}

export default CreateOrUpdateAssetView;