import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Tab, Tooltip, Typography, useMediaQuery, useTheme, TextField } from '@mui/material';
import { getAllFranchiseData } from '../../app/Franchise/service';
import { useDispatch, useSelector } from 'react-redux';
import { saveAllFranchises, openEditModal } from '../../app/Franchise/FranchiseSlice';
import logo from '../../MediaContents/AFT Logo 2.png';
import EditFranchiseComoponent from './EditFranchise';
import { FilterList } from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const ListFranchise = () => {
    const franchiseData = useSelector(state => state.franchiseMangement.franchises);
    const [filteredFranchiseData, setFilteredFranchiseData] = useState(franchiseData);
    const [currentTab, setCurrentTab] = useState("activeFranchise");
    const [filterText, setFilterText] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    const handleEdit = (franchiseId) => {
        const selectedFranchise = franchiseData.find((item) => item._id === franchiseId);
        dispatch(openEditModal(selectedFranchise))
    }

    useEffect(() => {
        const activeFranchises = franchiseData.filter(franchise => {
            if (currentTab === 'activeFranchise') {
                return franchise.isActive
            } else {
                return !franchise.isActive
            }
        });
        setFilteredFranchiseData(activeFranchises);
    }, [franchiseData, currentTab]);

    const handleFilterChange = (e) => {
        const searchText = e.target.value.toLowerCase();
        setFilterText(searchText);
        const filteredData = franchiseData.filter(franchise =>
            franchise.country.toLowerCase().includes(searchText) ||
            franchise.area.toLowerCase().includes(searchText)
        );
        setFilteredFranchiseData(filteredData);
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <Box  sx={{paddingBottom:"1rem"}}>
                <TextField
                    label="Filter"
                    variant="outlined"
                    size="small"
                    value={filterText}
                    onChange={handleFilterChange}
                />
            </Box>
            <TabContext value={currentTab}>
                <Box sx={{ width: isMobile ? '100%' : '75%', typography: 'body1', display: 'grid', gridColumn: '2fr 1fr' }}>
                    <Paper
                        elevation={1}
                        sx={{
                            borderColor: 'divider',
                            zIndex: 99,
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            transition: 'box-shadow 0.3s',
                            '&:hover': {
                                boxShadow: '3px 15px 15px rgba(0, 0, 0, 0.4)',
                                cursor: 'pointer',
                                elevation: 5,
                            },
                        }}
                    >
                        <TabList
                            onChange={handleTabChange}
                            aria-label="CompanyManagement Tabs"
                            variant={isMobile ? 'scrollable' : 'fullWidth'}
                            allowScrollButtonsMobile={isMobile ? true : false}
                            orientation={'horizontal'}
                            sx={{
                                '& .MuiTab-root': {
                                    padding: '0 16px',
                                    minHeight: '48px',
                                    maxHeight: '48px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:not(:last-child)': {
                                        borderRight: '1px solid #ccc', // Apply border to all but the last child
                                    },
                                },
                                '& .Mui-selected': {
                                    borderBottom: 'solid 5px #97C3F0',
                                    backgroundColor: '#f5f5f5',
                                },
                            }}
                        >
                            <Tab value="activeFranchise" label="Active Franchise" />
                            <Tab value="inactiveFranchise" label="Inactive Franchise" />
                        </TabList>
                    </Paper>

                </Box>

                {/* Filter input */}


                {/* Table section */}
                <TabPanel value="activeFranchise">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={`header-cell `} sx={{ width: "160px" }}>
                                        <span className="header-text">Logo</span> </TableCell>
                                    <TableCell className={`header-cell `}>
                                        <span className="header-text">Country</span>
                                    </TableCell>
                                    <TableCell className={`header-cell `}>
                                        <span className="header-text">Area</span>
                                    </TableCell>
                                    <TableCell className={`header-cell `}>
                                        <span className="header-text">Asset Range</span>
                                    </TableCell>
                                    <TableCell className={`header-cell `}>
                                        <span className="header-text">Actions</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredFranchiseData.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ width: '160px', display: "flex", justifyContent: "center" }}>
                                            <div style={{ width: '140px', padding: "5px" }}>
                                                <img src={item.logoUrl ? item.logoUrl : logo} alt={logo} style={{ maxWidth: '100%', height: 'auto', border: "0.5px solid gray", backgroundColor: "#3f51b5" }} />
                                            </div>
                                        </TableCell>
                                        <TableCell>{item.country}</TableCell>
                                        <TableCell>{item.area}</TableCell>
                                        <TableCell>{item.minAssetRange} - {item.maxAssetRange}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={() => handleEdit(item._id, index)}>Edit</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>

                <TabPanel value="inactiveFranchise">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: "160px" }}>Logo</TableCell>
                                    <TableCell>Country</TableCell>
                                    <TableCell>Area</TableCell>
                                    <TableCell>Range</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredFranchiseData.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{ width: '160px', display: "flex", justifyContent: "center" }}>
                                            <div style={{ width: '140px', padding: "5px" }}>
                                                <img src={item.logoUrl ? item.logoUrl : logo} alt={logo} style={{ maxWidth: '100%', height: 'auto', border: "0.5px solid gray", backgroundColor: "#3f51b5" }} />
                                            </div>
                                        </TableCell>
                                        <TableCell>{item.country}</TableCell>
                                        <TableCell>{item.area}</TableCell>
                                        <TableCell>{item.minAssetRange} - {item.maxAssetRange}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={() => handleEdit(item._id, index)}>Edit</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </TabContext>
            <EditFranchiseComoponent />
        </>
    );
}

export default ListFranchise;
