import React, { useState } from "react";
import { Autocomplete as MuiAutocomplete, TextField } from "@mui/material";

const Autocomplete = ({ options, value, onChange, label, required, error, helperText, name, disabled, multiple }) => {
    const [nameFromParent] = useState(name)
    const handleChange = (_, newValue) => {
        onChange({ target: { name: nameFromParent, value: newValue } }); // Call the parent's onChange with the new name and value
    };

    return (
        <MuiAutocomplete
            required={required ?? false}
            disabled={disabled ?? false}
            name={name}
            multiple={multiple ?? false}
            options={options}
            getOptionLabel={(option) => option.label} // Add this line to specify the label property of each option object
            value={value}
            onChange={handleChange} // Use the handleChange function to pass the new name and value
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    required={required ?? false}
                    error={error}
                    helperText={helperText}
                    autoComplete="off"
                />
            )}
        />
    );
};

export default Autocomplete;