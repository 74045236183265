import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersWithQueryPagination } from "./Service";
import Table from "../../components/commons/Table/Table";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { toast } from "react-toastify";

const tableColumns = [
    // { field: "photoURL", header: "Avatar", type: "avatar", colSize: "small" },
    { field: "customUserId", header: "ID", type: "text", colSize: "extra-small" },
    { field: "displayName", header: "Full Name", type: "text", colSize: "medium" },
    { field: "createdAt", header: "Joined", type: "date", colSize: "medium" },
    { field: "email", header: "Email", type: "text", colSize: "large" },
    { field: "companyName", header: "Company", type: "text", colSize: "medium" },
    { field: "authProvider", header: "Login Method", type: "text", colSize: "medium" },
    { field: "role", header: "Role", type: "text", colSize: "medium" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
];

const mobileOnlyColumns = [
    // { field: "photoURL", header: "Avatar", type: "avatar", colSize: "small" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
    { field: "displayName", header: "Full Name", type: "text", colSize: "medium" },
    { field: "companyName", header: "Company", type: "text", colSize: "small" },
];

const ActiveUsersTable = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.userData.data.userType)
    const franchiseChange = useSelector((state) => state.userData.data.franchise)
    const filterValues = useSelector((state) => state.userManagement.activeUsersFilter);
    const filterResetFlag = useSelector((state) => state.userManagement.activeUsersFilterResetFlag);
    const [usersList, setUsersList] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 20;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllActiveUsersToList(1, pageSize, true, generateQueryString());
    }, [franchiseChange]);

    useEffect(() => {
        if (filterResetFlag) getAllActiveUsersToList(1, pageSize, true);
    }, [filterResetFlag])


    useImperativeHandle(ref, () => ({
        triggerSearchFromParent,
    }));

    const triggerSearchFromParent = () => {
        const hasAtLeastOneFilterValue = Object.values(filterValues).some(value => value !== "");
        dispatch({ type: 'userManagement/setActiveUsersFilterErrorFlag', payload: "Please choose at least one filter option." })
        if (!hasAtLeastOneFilterValue) return;

        const filterQuery = generateQueryString();
        getAllActiveUsersToList(activePage, pageSize, true, filterQuery);
    };

    const generateQueryString = () => {
        let queryString = "";
        for (let key in filterValues) {
            if (filterValues[key] !== "") {
                // Autosuggest component query
                if (key === 'role' || key === 'company' || key === 'authProvider') {
                    queryString += `&${key}=${filterValues[key]?.value ?? ""}`
                } else {
                    // other input queries that uses Selectbox or Textfield 
                    queryString += `&${key}=${filterValues[key]}`
                }
            }
        }
        return queryString;
    }


    const getAllActiveUsersToList = async (page, pageSize, isActive, filterQuery) => {
        setIsLoading(true);
        try {
            const response = await getUsersWithQueryPagination(page, pageSize, isActive, filterQuery);
            if (response?.data?.message === 'Ok') {
                setActivePage(response.data.page || 1);
                setTotalPages(response.data.totalPages || 0);
                setUsersList(response.data.users || []);
                // console.log(response.data.users)
            }
            setIsLoading(false);
            if (props.isFilterModalOpen) {
                if (response?.data?.users?.length) {
                    dispatch({ type: 'userManagement/setActiveUsersFilterErrorFlag', payload: "" })
                    setTimeout(() => {
                        props.closeFilterModal(false);
                    }, 400);
                } else {
                    dispatch({ type: 'userManagement/setActiveUsersFilterErrorFlag', payload: "No result for given query." })
                }
            }
        } catch (error) {
            toast.warning('Invalid query parameters!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                style: { width: '400px' },
            });
        }
    };

    const handlePageChange = (event, newPage) => {
        if (
            Object.values(filterValues).some(value => value !== "")
        ) getAllActiveUsersToList(newPage, pageSize, true, generateQueryString());
        else getAllActiveUsersToList(newPage, pageSize, true);
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: `${userType === 'Customer' ? 'flex-start' : 'flex-end'}` }}>
            <Table
                data={usersList}
                columns={tableColumns}
                mobileOnlyColumns={mobileOnlyColumns}
                onButtonClick={props.handleUserEditBtnClick}
                isLoading={isLoading}
                firstColumnSticky={true}
            />
            <Stack spacing={2} sx={{ marginTop: '25px' }}>
                <Pagination
                    defaultPage={1}
                    page={activePage}
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </div>
    );
});

export default ActiveUsersTable;


