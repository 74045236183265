import { privateApi } from "../../api"



export const createNewItAmcIssue = async (data) => {
    return privateApi.post('itAmc/create-it-amc-issue', data)
}


export const getAllListItAmcIssueList = async (params) => {
    return privateApi.get('itAmc/get-list-issue', params)
}


export const addNewComment = async (issueId, data) => {
    return privateApi.post(`itAmc/add-new-comment/${issueId}`, data)
}

export const updateItAmcIssue = async (issueId, data) => {
    return privateApi.post(`itAmc/update-it-amc-issue/${issueId}`, data)
}

export const updateIssueAttachments = async (issueId, data) => {
    return privateApi.post(`itAmc/add-new-file/${issueId}`, data)
}