import { useEffect } from "react";
import { db } from "../../../../utilities/auth-config/firebase-config";
import { collection, query, where, onSnapshot } from "@firebase/firestore";
import '../AdminChatComponent.css';
import longNotificationSound from '../../../../MediaContents/Sounds/ringtone-126505.mp3'
import notificationSound from '../../../../MediaContents/Sounds/notifications-sound-127856.mp3'
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const IncomingChatsRedux = () => {
    const audio = new Audio(notificationSound);
    const longAudio = new Audio(longNotificationSound); // Long notification sound
    const dispatch = useDispatch();
    const incomingChatsRef = collection(db, 'rooms');
  
    const sessionUser = useSelector((state) => state.userData.data);
    useEffect(() => {
        // Unlock the audio on the first user interaction
        const unlockAudio = () => {
            audio.play().then(() => audio.pause());
            longAudio.play().then(() => longAudio.pause());
            document.removeEventListener('click', unlockAudio);
        };
        document.addEventListener('click', unlockAudio);

        const q = query(incomingChatsRef, where("status", "==", "open"), where("franchiseArea", "==", sessionUser.franchise.area));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const updatedIncomingChats = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })).
            filter((rooms)=>{
                if(sessionUser.role !== 'coreAdmin' && sessionUser.role !== 'superAdmin' ){
                    return rooms.userType !== 'Admin'
                }
                return true
            });
            dispatch({ type: 'chats/updateUnattendedChats', payload: updatedIncomingChats });
            if (updatedIncomingChats?.length) {
                if (document.hidden) { // Check if the tab is active
                    longAudio.play(); // Play the long notification sound if the tab is not active
                    toast.info('New incoming messages...', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 20000,
                        style: { width: '400px' },
                    });
                } else {
                    toast.info('New incoming messages...', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        style: { width: '400px' },
                    });
                    audio.play(); // Play the normal notification sound if the tab is active
                }
            }
        });

        // Event listener for the visibilitychange event
        const handleVisibilityChange = () => {
            if (!document.hidden) { 
                audio.pause();
                longAudio.pause();
            }
        }

        // Add the event listener when the component mounts
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            unsubscribe(); // Unsubscribe when the component unmounts
            // Remove the event listener when the component unmounts
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [sessionUser.franchise]);

    return (
        <>
        </>
    )
}

export default IncomingChatsRedux