import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CreateNewExportAsset from './CreateNewExportAsset';
import { Box, Button, Pagination, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_FILTER_EXPORT_ASSET, OPEN_EXPORT_ASSET_MODAL, SET_SELECTED_EXPORT_ASSET, UPDATE_EXPORT_ASSET_LIST, UPDATE_FILTER_EXPORT_ASSET, UPDATE_SELECTED_EXPORT_ASSET, getExportAssetThunk, getFieldsOptionData } from '../../app/ExportAsset/ExportAssetSlice';
import Table from "../../components/commons/Table/Table";
import ViewExportAsset from './ViewExportAsset';
import FilterExportAsset from './FilterExportAsset';
import UpdateExportAsset from './UpdateExportAsset';
import ExportAssetCount from './ExportAssetCount';

const exportAssetTable = [
    { field: "machineCode", header: "Code", type: "text", colSize: "small" },
    { field: "machineName", header: "Brand Name", type: "text", colSize: "medium" },
    { field: "machineModel", header: "Model", type: "text", colSize: "medium" },
    { field: "assetType", header: "Asset Type", type: "text", colSize: "small" },
    { field: "origin", header: "Origin", type: "text", colSize: "small" },
    { field: "healthStatus", header: "health Status", type: "text", colSize: "small" },
    { field: "isPartsRequired", header: "Parts Required", type: "text", colSize: "small" },
    { field: "updatedBy", header: "updated By", type: "text", colSize: "medium" },
    { field: "updatedAt", header: "updated At", type: "date", colSize: "medium" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
]

const exportAssetToner = [
    { field: "machineName", header: "Brand Name", type: "text", colSize: "small" },
    { field: "machineModel", header: "Model", type: "text", colSize: "small" },
    { field: "tonerQuantity", header: "Quantity", type: "text", colSize: "small" },
    { field: "tonerColor", header: "Color", type: "text", colSize: "small" },
    // { field: "assetType", header: "Asset Type", type: "text", colSize: "small" },
    { field: "origin", header: "Origin", type: "text", colSize: "small" },
    { field: "updatedBy", header: "updated By", type: "text", colSize: "medium" },
    { field: "updatedAt", header: "updated At", type: "date", colSize: "medium" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
]
const mobileOnlyColumns = [
    { field: "machineCode", header: "Code", type: "text", colSize: "extra-small" },
    { field: "machineName", header: "Brand Name", type: "text", colSize: "small" },
    { field: "machineModel", header: "Model", type: "text", colSize: "small" },
    { field: "assetType", header: "Asset Type", type: "text", colSize: "small" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
]
const mobileOnlyColumnsToner = [
    { field: "machineName", header: "Brand Name", type: "text", colSize: "small" },
    { field: "machineModel", header: "Model", type: "text", colSize: "small" },
    { field: "tonerQuantity", header: "Quantity", type: "text", colSize: "small" },
    { field: "tonerColor", header: "Color", type: "text", colSize: "small" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
]
const ListExportAssets = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.exportAssetMangement.isLoading);
    const exportAsset = useSelector((state) => state.exportAssetMangement);
    const exportAssetViewController = useSelector(state => state.exportAssetMangement.viewController);

    const handleCreateDialogOpen = () => {
        dispatch(OPEN_EXPORT_ASSET_MODAL("create"))
        // setShowCreateDialog(true);
    };
    const handleExportAssetEdit = (selectedExportData) => {
        dispatch(SET_SELECTED_EXPORT_ASSET(selectedExportData))
        // dispatch(OPEN_EXPORT_ASSET_MODAL("view"))
        dispatch(OPEN_EXPORT_ASSET_MODAL("update"));
        // setShowCreateDialog(true);
    };
    const handlePageChange = (event, newPage) => {
        // dispatch(getCompaniesByQuery({ ...queryData, page: newPage }))
        dispatch(UPDATE_FILTER_EXPORT_ASSET({ key: "page", value: newPage }));
    };


    useEffect(() => {
        dispatch(getExportAssetThunk(exportAsset.filter))
    }, [exportAsset.filter]);

    useEffect(() => {
        dispatch(getFieldsOptionData())
    }, [])

    // useEffect(()=>{
    //     return ()=>{
    //         dispatch(CLEAR_FILTER_EXPORT_ASSET())
    //     }
    // })

    return (
        <>

            <FilterExportAsset />
            {exportAssetViewController.showTable && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Table
                    data={exportAsset.exportAssetList[exportAsset.filter.currentTab] || []}
                    columns={exportAsset.filter.currentTab === 'warehouseToner' ? exportAssetToner : exportAssetTable}
                    mobileOnlyColumns={exportAsset.filter.currentTab === 'warehouseToner' ? mobileOnlyColumnsToner : mobileOnlyColumns}
                    onButtonClick={handleExportAssetEdit}
                    isLoading={isLoading}
                    firstColumnSticky={true}
                />
                <Stack spacing={2} sx={{ marginTop: '25px' }}>
                    <Pagination
                        defaultPage={1}
                        page={exportAsset.filter.page}
                        count={exportAsset.filter.count}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Stack>
            </div>}
            {!exportAssetViewController.showTable &&
                <ExportAssetCount
                    countData={exportAsset.exportAssetList[exportAsset.filter.currentTab] || []}
                />}
            <CreateNewExportAsset />
            <ViewExportAsset />
            <UpdateExportAsset />
        </>
    );
}

export default ListExportAssets;
