import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, Collapse, Tooltip, Stack, useMediaQuery, useTheme } from '@mui/material'
import TextInput from '../../components/commons/TextInput';
import { assetContractType, assetHealthStatus, assetTypes, partsRequiredOptions } from '../../constants/commonConstants';
import MUIDatePicker from '../../components/commons/DatePicker/DatePicker';
import SelectInput from '../../components/commons/SelectInput';
import Autocomplete from '../../components/commons/Autocomplete';
import { getCompaniesList } from '../UserMangaement/Service';
import { ResetButton, SearchButton } from '../../components/commons/Buttons';
import { getAssetsByQuery } from '../../app/Asset/AssetSlice';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import GetAppIcon from '@mui/icons-material/GetApp';
import { styled } from "@mui/material/styles";
import { toast } from 'react-toastify';
import AssetFilterChips from './AssetFilterChips';


const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: '5px !important',
    borderRadius: '5px',
    transition: 'background 0.3s ease-in-out',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
}));


const AssetFilter = ({ expandByDefault, handleClose }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const queryData = useSelector((state) => state.assetManagement.queryData);
    const csvData = useSelector((state) => state.assetManagement.csvData);
    const [companiesList, setCompaniesList] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const isLoading = useSelector((state) => state.companyManagement.isLoading);
    const [isFilterComponentExpanded, setIsFilterComponentExpanded] = useState(expandByDefault ? expandByDefault : false);

    useEffect(() => {
        getCompaniesList()
            .then((result) => {
                if (result?.data?.length) {
                    let tempArr = [];
                    result.data.forEach(item => {
                        tempArr.push({ value: item._id, label: item.companyName, type: item.type });
                    });
                    setCompaniesList(tempArr)
                } else if (result.data) {
                    setCompaniesList([{ value: result.data._id, label: result.data.companyName, type: result?.data?.type }])
                }
            });
    }, []);

    useEffect(() => {
        if (csvData) {
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'assets.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('CSV Downloaded successfully.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({
                type: 'assetManagement/updateFieldValues',
                payload: {
                    state: 'mainState',
                    field: 'csvData',
                    value: null,
                },
            });
        }
    }, [csvData]);

    return (
        <Grid container spacing={2} key={resetKey} >
            <Grid item xs={isMobile ? 12 : 4}>
                <TextInput
                    label="Machine Code"
                    name={'machineCode'}
                    value={queryData.machineCode}
                    onChange={(e) => {
                        dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'queryData', field: 'machineCode', value: e.target.value, }, });
                        dispatch(getAssetsByQuery({ ...queryData, machineCode: e.target.value, page: 1 }))
                    }}
                />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
                <TextInput
                    label="Machine Name"
                    name={'assetName'}
                    value={queryData.assetName}
                    onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'queryData', field: 'assetName', value: e.target.value, }, }) }}
                />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
                <SelectInput
                    required={true}
                    name={'assetType'}
                    label="Asset Type"
                    value={queryData.assetType}
                    options={assetTypes}
                    onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'queryData', field: 'assetType', value: e.target.value, }, }) }}
                />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={isFilterComponentExpanded}>
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 12 : 4}>
                            <TextInput
                                label="Model"
                                name={'model'}
                                value={queryData.model}
                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'queryData', field: 'model', value: e.target.value, }, }) }}
                            />
                        </Grid>

                        <Grid item xs={isMobile ? 12 : 4}>
                            <SelectInput
                                required={true}
                                name={'healthStatus'}
                                label="Health Status"
                                value={queryData.healthStatus}
                                options={assetHealthStatus}
                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'queryData', field: 'healthStatus', value: e.target.value, }, }) }}
                            />
                        </Grid>
                        {/* COMPANY */}
                        <Grid item xs={isMobile ? 12 : 4} >
                            <Autocomplete
                                label="Company"
                                name='company'
                                fullWidth
                                value={queryData.company}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'queryData',
                                            field: 'company',
                                            value: e.target.value,
                                        },
                                    });
                                }}
                                options={companiesList}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 4} >
                            <SelectInput
                                required={true}
                                name={'contractType'}
                                label="Contract Type"
                              
                                value={queryData.contractType}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'queryData',
                                            field: 'contractType',
                                            value: e.target.value,
                                        },
                                    });
                                }}
                                options={assetContractType}
                            />
                        </Grid>
                       
                        {/* Parts Required */}
                        <Grid item xs={isMobile ? 12 : 4}>
                            <SelectInput
                                required={true}
                                name={'partsRequired'}
                                label="Parts Required"
                                value={queryData.healthStatus}
                                options={partsRequiredOptions}
                                onChange={(e) => { dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'queryData', field: 'partsRequired', value: e.target.value, }, }) }}
                            />
                        </Grid>

                        {/* CREATED DATE */}
                        <Grid item xs={isMobile ? 12 : 4} style={{ display: 'flex', alignItems: 'center' }}>
                            <MUIDatePicker
                                label="Next Invoice Date From"
                                value={queryData.nextInvoiceFrom}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'queryData',
                                            field: 'nextInvoiceFrom',
                                            value: e,
                                        },
                                    });
                                }}
                                onClear={() => {
                                    dispatch({
                                        type: 'ticketManagement/updateFieldValues',
                                        payload: {
                                            state: 'ticketQuickSearch',
                                            field: 'nextInvoiceFrom',
                                            value: null,
                                        },
                                    });
                                }}
                                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                            />
                        </Grid>

                        {/* CREATED TO DATE */}
                        <Grid item xs={isMobile ? 12 : 4} style={{ display: 'flex', alignItems: 'center' }}>
                            <MUIDatePicker
                                label="Next Invoice Date To"
                                value={queryData.nextInvoiceTo}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'queryData',
                                            field: 'nextInvoiceTo',
                                            value: e,
                                        },
                                    });
                                }}
                                onClear={() => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'queryData',
                                            field: 'nextInvoiceTo',
                                            value: null,
                                        },
                                    });
                                }}
                                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                            />
                        </Grid>
                        {/* SERVICE DATE BY */}
                        <Grid item xs={isMobile ? 12 : 4} style={{ display: 'flex', alignItems: 'center' }}>
                            <MUIDatePicker
                                label="Next Service From"
                                value={queryData.nextServiceFrom}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'queryData',
                                            field: 'nextServiceFrom',
                                            value: e,
                                        },
                                    });
                                }}
                                onClear={() => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'queryData',
                                            field: 'nextServiceFrom',
                                            value: null,
                                        },
                                    });
                                }}
                                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                            />
                        </Grid>

                        {/* SERVNEXT SERVICE TO */}
                        <Grid item xs={isMobile ? 12 : 4} style={{ display: 'flex', alignItems: 'center' }}>
                            <MUIDatePicker
                                label="Next Service To"
                                value={queryData.nextServiceTo}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'queryData',
                                            field: 'nextServiceTo',
                                            value: e,
                                        },
                                    });
                                }}
                                onClear={() => {
                                    dispatch({
                                        type: 'assetManagement/updateFieldValues',
                                        payload: {
                                            state: 'queryData',
                                            field: 'nextServiceTo',
                                            value: null,
                                        },
                                    });
                                }}
                                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                {
                    !isMobile && (
                        <div style={{ maxWidth: '850px' }}>
                            <AssetFilterChips />
                        </div>

                    )
                }
                <Stack direction="row" spacing={1}>
                    <Tooltip title="Export to CSV">
                        <StyledIconButton
                            onClick={() => { dispatch(getAssetsByQuery({ ...queryData, page: 0, exportCSV: true })) }}
                        >
                            <GetAppIcon />
                        </StyledIconButton>
                    </Tooltip>

                    {/* <Tooltip title="Export to PDF">
                        <StyledIconButton
                            // onClick={() => { dispatch(getTicketsByQuery({ data: { ...quickSearchData, exportPdf: true }, page: 1 })) }}
                        >
                            <PictureAsPdfIcon />
                        </StyledIconButton>
                    </Tooltip> */}
                </Stack>
                <div style={{ width: '5px' }} />
                <ResetButton
                    tex={'Search'}
                    disabled={isLoading}
                    onClick={() => {
                        dispatch({ type: 'assetManagement/resetStateField', payload: { state: 'mainState', field: 'queryData' } })
                        dispatch(getAssetsByQuery({ clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, page: 1 }))
                        setResetKey(prevKey => prevKey + 1);
                    }}
                />
                <div style={{ width: '5px' }} />
                <SearchButton
                    tex={'Search'}
                    disabled={isLoading}
                    onClick={() => {
                        dispatch(getAssetsByQuery({ ...queryData, page: 1 }));
                        isMobile && handleClose();
                    }} />
                <div style={{ width: '5px' }} />
                <IconButton
                    onClick={() => {
                        setIsFilterComponentExpanded(!isFilterComponentExpanded)
                    }}
                    sx={{
                        transition: 'background-color 0.3s, color 0.3s',
                        '&:hover': {
                            color: '#fff',
                            backgroundColor: '#81d4fa',
                        },
                    }}
                >
                    {
                        isFilterComponentExpanded ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />
                    }
                </IconButton>

            </Grid>
        </Grid>
    )
}

export default AssetFilter
