import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Typography, IconButton, Collapse, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { FiberManualRecord } from '@mui/icons-material';

const ExportAssetCount = ({countData}) => {
    const exportAssetCount = useSelector((state) => state.exportAssetMangement.assetCounts);
    const isLoading = useSelector((state) => state.exportAssetMangement.isLoading);
    const exportAssetViewController = useSelector(state => state.exportAssetMangement.viewController);
    const exportAssetFilter = useSelector(state => state.exportAssetMangement.filter);
    


    if (exportAssetViewController.showTable) {
        return null;
    }

    return (
        <div style={{ width: '100%', border: "solid 1px #000", borderRadius: "2px", overflow: "hidden", display: "grid" }}>

            {isLoading &&
                <Typography>Loading...</Typography>
            }
            {!isLoading && exportAssetFilter.currentTab === 'warehouseToner' && <Grid container >
                {exportAssetCount['warehouseToner']?.map((assetCount, index) => (
                    <Grid container key={index} sx={{ borderBottom: "1px solid #DAEEFB", padding: "1rem" }}>
                        <Grid item xs={10} md={9} sx={{ display: "flex", alignItems: "center" }}>
                            {/* {console.log(assetCount)} */}
                            <Typography variant="h6" sx={{ fontWeight: "500", color: "#333" }}> {assetCount.name} ({assetCount.count})</Typography>
                        </Grid>
                        {assetCount.items.map((tonerTypeCount, index) => (
                            <Grid container key={index} sx={{ borderBottom: "1px solid #DAEEFB", padding: "1rem" }}>
                                <Grid item xs={10} md={9} sx={{ display: "flex", alignItems: "center" }}>
                                    {/* {console.log(tonerTypeCount)} */}
                                    <Typography sx={{ fontWeight: "400", color: "#333", fontSize: "1.5 " }}> {tonerTypeCount.tonerType} ({tonerTypeCount.count})</Typography>
                                </Grid>
                                {tonerTypeCount.brands.map((brandCount, index) => (
                                    <Grid container key={index} sx={{ borderBottom: "1px solid #DAEEFB", padding: "1rem" }}>
                                        <Grid item xs={10} md={9} sx={{ display: "flex", alignItems: "center" }}>
                                            {/* {console.log(tonerTypeCount)} */}
                                            <Typography sx={{ fontWeight: "400", color: "#333", fontSize: "1.5 " }}> {brandCount.brandName} ({brandCount.quantity})</Typography>
                                        </Grid>
                                        {brandCount.models.map((assetModel, modelIndex) => (
                                            <Fragment key={modelIndex}>
                                                <Grid container spacing={2} xs={12} lg={7} sx={{ paddingTop: "1rem", gap: ".8rem" }}>

                                                    <Grid item xs={6} lg={3} sx={{ display: "flex", gap: "1rem", paddingX: "1rem" }} alignItems={'center'}><FiberManualRecord style={{ fontSize: ".8rem" }} /> {assetModel.model}</Grid>
                                                    <Grid item xs={1}>{assetModel.quantity}</Grid>
                                                </Grid>
                                            </Fragment>
                                        ))}
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                ))}
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', padding: '1rem' }}>
                    <Typography variant="body1" sx={{ marginRight: '1rem' }}>Total Count:</Typography>
                    <Box sx={{ backgroundColor: '#077', padding: '0.5rem 1rem', borderRadius: '1rem' }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#fff' }}>
                            {exportAssetCount['warehouseToner']?.reduce((sum, item) => sum + parseInt(item.count), 0)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>}
            {!isLoading && exportAssetFilter.currentTab !== 'warehouseToner' &&
                <Grid container>
                    {exportAssetCount[exportAssetFilter.currentTab]?.map((assetCount, index) => (
                        <Grid container key={index} sx={{ backgroundColor: index % 2 === 0 ? "#fff" : "#F6F4F6", borderBottom: "1px solid #DAEEFB", padding: "1rem" }}>
                            <Grid item xs={10} md={9} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="h6" sx={{ fontWeight: "500", color: "#333" }}> {assetCount._id.brandName} ({assetCount.total})</Typography>

                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", paddingX: "4rem" }}>

                                {assetCount.models.map((assetModel, modelIndex) => (
                                    <Fragment key={modelIndex}>
                                        <Grid container spacing={2} xs={12} lg={7} sx={{ paddingTop: "1rem", gap: ".8rem" }}>
                                            <Grid item xs={3} sx={{ display: "flex", gap: "1rem", paddingX: "1rem" }} alignItems={'center'}><FiberManualRecord style={{ fontSize: ".8rem" }} /> {assetModel.model}</Grid>
                                            <Grid item xs={3}>{assetModel.assetType}</Grid>
                                            <Grid item xs={1}>{assetModel.count}</Grid>
                                        </Grid>
                                    </Fragment>
                                ))}

                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', padding: '1rem' }}>
                        <Typography variant="body1" sx={{ marginRight: '1rem' }}>Total Count:</Typography>
                        <Box sx={{ backgroundColor: '#077', padding: '0.5rem 1rem', borderRadius: '1rem' }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#fff' }}>
                                {exportAssetCount[exportAssetFilter.currentTab] && exportAssetCount[exportAssetFilter.currentTab].reduce((sum, item) => sum + parseInt(item.total), 0)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
        </div >
    );
}

export default ExportAssetCount;
