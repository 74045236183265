import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import { Skeleton, Button, useMediaQuery, useTheme } from "@mui/material"
import "./ChatViewComponent.css"; // Import your custom CSS file for styling
import { convertFirestoreTimestampToDate, convertFirestoreTimestampToIST } from "../../utilities/commonUtils";
import { markMessagesAsSeen } from "../../utilities/auth-config/firebase-config";
import { stringToColor } from "../../utilities/commonUtils";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Fragment } from "react";

const ChatSeen = styled.div({
    position: "absolute",
    right: "10px",
    bottom: "4px",
    fontSize: "12px",
    opacity: "0.5",
});

const imageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp', 'image/svg+xml'];
const videoTypes = ['video/mp4', 'video/webm', 'video/ogg'];


const ChatViewComponent = ({
    chats,
    currentPage,
    totalPages,
    getMessagesWithPagination,
    newChatFlag,
    setNewChatFlag,
    scrollDivRef,
    isAdminChatView
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const user = useSelector((state) => state.userData.data);
    const isCustomerChatWindowOpen = useSelector((state) => state.userData.setting.showCustomerChatWindow);
    const isAdminsOnline = useSelector((state) => state.chats.isAdminsOnline);
    const isCustomerChatAttended = useSelector((state) => state.chats.isCustomerChatAttended);


    const lastChatRef = useRef(null);
   
    useEffect(() => {
        const scrollDiv = scrollDivRef?.current;
        if (scrollDiv) {
            scrollDiv.scrollTop = scrollDiv.scrollHeight;
        }
        if (setNewChatFlag) setNewChatFlag(false);
    }, [newChatFlag]);

    useEffect(() => {
        if (isCustomerChatWindowOpen && newChatFlag) {
            markMessagesAsSeen(user._id, user._id, 'customerChatWindow');
        }
    }, [isCustomerChatWindowOpen, newChatFlag])

    // for getting used chat date array
    let usedChatDate = []
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="chat-container">
                        {
                            currentPage < totalPages &&
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    sx={{ margin: '5px' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { getMessagesWithPagination(currentPage + 1) }}
                                >
                                    Load More Chats...
                                </Button>
                            </div>
                        }
                        {chats?.map((chat, index) => {
                            let showDate = true
                            const currentMessageDate = convertFirestoreTimestampToDate(chat.timestamp)
                            if (usedChatDate.includes(currentMessageDate)) {
                                showDate = false
                            } else {
                                usedChatDate.push(currentMessageDate)
                            }
                           return (<Fragment key={chat.id}>
                                {showDate && <div className="text-center w-full text-[1.3rem] mb-[2rem]">
                                    {currentMessageDate}
                                </div>}
                                <div
                                    key={index}
                                    className={`chat-message ${(chat.userId === user._id) || (chat.userType === 'Admin' && isAdminChatView) ? "current-user" : "other-user"
                                        }`}
                                >
                                
                                    {chat.userId !== user._id && chat.userName !== 'System Generated' && (
                                        <Avatar
                                            alt="User Avatar"
                                            src={chat.photoURL}
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                fontSize: "16px",
                                                marginRight: "10px",
                                                marginBottom: "10px",
                                                backgroundColor: chat.photoURL
                                                    ? "transparent"
                                                    : chat.userName
                                                        ? stringToColor(chat.userName)
                                                        : "gray",
                                            }}
                                        >
                                            {!chat.photoURL && chat.userName ? chat.userName[0] : null}
                                        </Avatar>
                                    )}
                                    <Paper
                                        elevation={0}
                                        className={`message-paper ${chat.userId === user._id
                                            ? "current-user-paper-custumer"
                                            : "other-user-paper-custumer"
                                            }`}
                                    >
                                        {chat.userId === user._id && (
                                            <ChatSeen>
                                                {chat.seen ? (
                                                    <FontAwesomeIcon icon={faCheckDouble} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faCheck} />
                                                )}
                                            </ChatSeen>
                                        )}
                                        <div className="message-row">
                                            <div className="message-content">
                                                {chat.type === "text" && (
                                                    <>
                                                        <div className="message-text">{chat.message}</div>
                                                        <div className={`${(chat.userId !== user._id ? "timestamp-row" : 'timestamp-row-same-user')} ${convertFirestoreTimestampToIST(chat.timestamp) === 'Just now' ? 'animate' : ''}`}>
                                                            {convertFirestoreTimestampToIST(chat.timestamp)}
                                                        </div>
                                                    </>
                                                )}
                                                {chat.type === "link" && (
                                                    <>
                                                        <a href={chat.linkURL} rel="noopener noreferrer" style={{ fontWeight: 'bold', color: 'blue', textDecoration: 'underline' }}>{chat.message}</a>
                                                        <div className={`${(chat.userId !== user._id ? "timestamp-row" : 'timestamp-row-same-user')} ${convertFirestoreTimestampToIST(chat.timestamp) === 'Just now' ? 'animate' : ''}`}>
                                                            {convertFirestoreTimestampToIST(chat.timestamp)}
                                                        </div>
                                                    </>
                                                )}

                                                {chat.type === "file" && (
                                                    <>
                                                        <div className="message-text">{chat.fileName}</div>
                                                        {(imageTypes.includes(chat.fileType) || videoTypes.includes(chat.fileType)) ? (
                                                            imageTypes.includes(chat.fileType) ? (
                                                                <img
                                                                    src={chat.fileURL}
                                                                    alt={chat.fileName}
                                                                    className="thumbnail"
                                                                    style={{
                                                                        maxWidth: isMobile ? '150px' : '300px',
                                                                        borderRadius: '8px',
                                                                        boxShadow: '0px 3px 6px rgba(0,0,0,0.16), 0px 3px 6px rgba(0,0,0,0.23)'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <video
                                                                    src={chat.fileURL}
                                                                    controls
                                                                    className="thumbnail"
                                                                    style={{
                                                                        maxWidth: isMobile ? '150px' : '300px',
                                                                        borderRadius: '8px',
                                                                        boxShadow: '0px 3px 6px rgba(0,0,0,0.16), 0px 3px 6px rgba(0,0,0,0.23)'
                                                                    }}
                                                                />
                                                            )
                                                        ) : null}
                                                        <br />
                                                        <Button
                                                            sx={{ margin: '5px' }}
                                                            variant="contained"
                                                            color="secondary"
                                                            href={chat.fileURL}
                                                            download={chat.fileName}
                                                            target="_blank"
                                                            startIcon={<DownloadRoundedIcon />}
                                                        >
                                                            Download
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Paper>
                                </div>
                            </Fragment>
                            )
                        })}

                        {!isAdminChatView && !isCustomerChatAttended && !isAdminsOnline && (
                            <div style={{
                                position: 'sticky',
                                bottom: '20',
                                width: '100%',
                                backgroundColor: '#f8d7da',
                                borderRadius: '8px',
                                color: '#721c24',
                                textAlign: 'center',
                                padding: '10px',
                            }}>
                                Our support specialists are offline now, please contact
                                <a href="tel:+9710507828055" style={{ color: '#721c24', textDecoration: 'none' }}>+971 0507828055 {' '}</a>
                                over the phone or Watsapp.
                            </div>
                        )}
                    </div>
                    <div ref={lastChatRef} />
                </Col>
            </Row>
        </Container>
    );
};

export default ChatViewComponent;