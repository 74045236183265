import { useEffect, useState } from 'react';
import '../AdminChatComponent.css';
import { Grid, Typography, Divider, Avatar, Paper, Chip, Badge } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useSelector, useDispatch } from "react-redux";
import { stringToColor } from "../../../../utilities/commonUtils";


const MyChats = () => {
    const dispatch = useDispatch();
    const admin = useSelector((state) => state.userData?.data);
    const acceptedIncomingChats = useSelector((state) => state.chats.attendedChats);
    const selectedChatUser = useSelector((state) => state.chats.openedChatId);
    const numberOfNewMessages = useSelector((state) => state.chats.unSeenMessages);

    const handleUserSelectForChat = (userId) => {
        dispatch({ type: 'chats/setOpenedChatId', payload: userId });
        dispatch({ type: 'chats/removeFromUnseenMessages', payload: userId, });
    }

    return (
        <div>
            {acceptedIncomingChats.map((user, index) => (
                    <Paper
                        key={user.id}
                        elevation={1}
                        sx={{
                            margin: '10px',
                            borderRadius: '8px',
                            transition: 'all 0.2s', 
                            boxShadow: user.id === selectedChatUser ? '0px 10px 15px rgba(0, 0, 0, 0.4)' : "",
                            borderLeft: user.id === selectedChatUser ? "solid 5px rgb(25, 118, 210)" : "",
                            borderRight: user.id === selectedChatUser ? "solid 5px rgb(25, 118, 210)" : "",
                            borderBottom: user.id === selectedChatUser ? "solid 5px rgb(25, 118, 210)" : "",
                            '&:hover': {
                                boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.4)',
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => handleUserSelectForChat(user.id)}
                    >
                        <Grid container alignItems="center" sx={{ padding: '2px', overFlow: 'hidden' }}>
                            <Grid item xs={12} container alignItems="center" spacing={2} direction="row">
                                <Grid item xs={3} container justifyContent="center" alignItems="center">
                                    <Avatar
                                        src={user.photoURL || undefined}
                                        alt={user.displayName ? user.displayName.charAt(0) : user.firstName.charAt(0)}
                                        sx={{
                                            bgcolor: user.displayName
                                                ? stringToColor(user.displayName)
                                                : stringToColor(`${user.firstName} ${user.lastName}`),
                                            width: 48,
                                            height: 48,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {user.displayName ? user.displayName.charAt(0) : user.firstName.charAt(0)}
                                    </Avatar>
                                </Grid>
                                <Grid item xs={9}>
                                <Badge badgeContent={numberOfNewMessages[user.id]} color="primary" sx={{ zIndex: 10 }}>    
                                <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {user.displayName ? user.displayName : `${user.firstName} ${user.lastName}`}
                                    </Typography>
                                    </Badge>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {user.company.companyName.length > 25
                                            ? `${user.company.companyName.slice(0, 25)}...`
                                            : user.company.companyName}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '25ch',
                                        }}
                                    >
                                        {user.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingLeft: '3px' }}>
                                <Typography variant="body2">
                                    {(() => {
                                        switch (user.status) {
                                            case 'open':
                                                return (
                                                    <Chip label={`Status: ${user.status}`} color="primary" size="small" />
                                                );
                                            case 'attended':
                                                return (
                                                    <Chip label={`Status: ${user.status}`} color="success" size="small" />
                                                );
                                            case 'closed':
                                                return (
                                                    <Chip label={`Status: ${user.status}`} color="secondary" size="small" />
                                                );
                                            default:
                                                return (
                                                    <Chip label={`Status: ${user.status}`} color="default" size="small" />
                                                );
                                        }
                                    })()}
                                </Typography>
                                {user.status === "attended" && (
                                    <Chip
                                        label={`Attended by: ${user.attendedByName}`}
                                        color="primary"
                                        size="small"
                                        icon={<CheckCircleOutlineRoundedIcon />}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Paper>
            ))}
        </div>
    )
}

export default MyChats
