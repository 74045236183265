import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CssBaseline } from "@mui/material";
import { styled } from "@mui/material/styles";



import AppHeader from "./components/Header";
import AppSidebar from "./components/Sidebar";
import ChatSupportButton from "./components/CustomerChatButton";

const isAuthenticated = (authUser) => {
    return authUser ? true : false;
};

const AppContainer = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "240px 1fr 1fr 1fr 1fr 1fr",
    gridTemplateRows: "90px 1fr",
    gridTemplateAreas: `
    'header header header header header header'
    'sidebar content content content content content'
  `,
    gap: "0 16px",
    [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr", // Hide sidebar and give full width to the content container on small screens
        gridTemplateAreas: `
      'header'
      'content'
    `,
        "& > :nth-child(2)": {
            display: "none", // Hide the SidebarContainer on small screens
        },
    },
}));

const MainContent = styled("div")({
    gridArea: "content",
    flexGrow: 1,
    padding: theme => theme.spacing(5),
    marginRight: '5px',
    // background: "#F0F1F3",
    overflowY: "auto",
});

const PrivateRoutes = ({ path }) => {
    const location = useLocation();
    const authUser = localStorage.getItem('_id');
    const user = useSelector((state) => state.userData.data);

    return isAuthenticated(authUser) ? (
        <>
            <CssBaseline />
            <AppContainer>
                <AppHeader />
                <AppSidebar />
                <MainContent>
                    <Outlet />
                </MainContent>
                {(user.userType === 'Customer' || user.role === 'supportAgent') &&
                    <ChatSupportButton />
                }
            </AppContainer>
        </>
    ) :
        (
            <Navigate to="/login" replace state={{ from: location.pathname }} />
        );
};

export default PrivateRoutes;





