import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme, Grid, Avatar, Stack, TextField, InputAdornment, IconButton, Card, CardHeader, CardContent, Typography, Pagination } from '@mui/material'
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { stringToColor } from "../../../utilities/commonUtils";
import { privateApi } from "../../../api";
import { toast } from "react-toastify";
import { format } from 'date-fns';

const CommentController = ({
    module,
    id
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const sessionUser = useSelector((state) => state.userData.data);
    const [input, setInput] = useState('');
    const [comments, setComments] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);


    const fetchComments = async () => {
        if (!id) return;
        try {
            const response = await privateApi.get(`comments/get?module=${module}&id=${id}&limit=5&pageNumber=${page}`);
            setComments(response.data.comments);
            setTotalPages(response.data.pages);
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
        }
    };

    useEffect(() => {
        fetchComments();
    }, [id, page]);

    const handleCommentSubmit = async () => {
        if (!id) {
            toast.error('A Module id is required, which is missing.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            return;
        }
        if (input.trim() !== '') {
            const data = {
                commentString: input,
                commentBy: sessionUser._id, // assuming sessionUser._id is the user's id
                module: module,
                ticketId: module === 'tickets' ? id : undefined,
                assetId: module === 'asset' ? id : undefined
            };
            try {
                const response = await privateApi.post('comments/add', data);
                if (response?.data) {
                    setInput('');
                    toast.success('Comment added.', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000,
                        style: { width: '400px' },
                    });
                    fetchComments(); // Fetch comments after a new one is added
                }
            } catch (error) {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    style: { width: '400px' },
                });
            }
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };


    function formatDate(dateTimeString) {
        return format(new Date(dateTimeString), 'dd/MM/yyyy hh:mm a'); // Format the date and time
    }

    return (
        <Grid container spacing={1} sx={{
            background: '#F4F4F4', // Neutral background
            borderRadius: '8px',
            padding: '3px'
        }}>
            <Grid item xs={12}>
                <Stack direction={'row'} spacing={1.5} sx={{ marginTop: '10px' }}>
                    <Avatar
                        alt="User Avatar"
                        src={sessionUser.photoURL}
                        style={{
                            marginRight: '8px',
                            marginTop: '4px',
                            backgroundColor: sessionUser.photoURL
                                ? 'transparent'
                                : sessionUser.displayName
                                    ? stringToColor(sessionUser.displayName)
                                    : stringToColor(`${sessionUser.firstName} ${sessionUser.lastName}`),
                        }}
                    >
                        {!sessionUser.photoURL && (sessionUser.displayName || sessionUser.firstName) ? (
                            sessionUser.displayName
                                ? sessionUser.displayName[0]
                                : sessionUser.firstName[0]
                        ) : null}
                    </Avatar>

                    <TextField
                        id="new-comment-add-textbox"
                        label="Add a comment"
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={10}
                        value={input}
                        onChange={(e) => {
                            setInput(e.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                >
                                    <IconButton onClick={handleCommentSubmit}>
                                        <SendRoundedIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </Grid>

            <Grid
                item
                xs={12}
            >
                {
                    comments
                        ?.slice()
                        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                        ?.map((comment, index) => (
                            <Card
                                key={`card-${index}`}
                                sx={{
                                    margin: isMobile ? 0.5 : 1, // Smaller margin when isMobile is true
                                    padding: isMobile ? 0.5 : 1, // Smaller padding when isMobile is true
                                    borderRadius: '10px',
                                    background: 'linear-gradient(315deg, #b7d1d4, #d9f8fd)',
                                    boxShadow: '0px 0px 5px #c1dce0', // Reduced box shadow spread completely
                                    transition: 'box-shadow 0.3s ease', // Transition for shadow
                                    '&:hover': {
                                        boxShadow: '0px 0px 3px #c1dce0', // Reduced box shadow spread on hover
                                    },
                                }}
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            alt="Commented User"
                                            src={comment?.commentBy?.photoURL}
                                            sx={{
                                                marginRight: '8px',
                                                marginTop: '4px',
                                                backgroundColor: comment?.commentBy?.photoURL
                                                    ? 'transparent'
                                                    : comment?.commentBy?.displayName
                                                        ? stringToColor(comment.commentBy.displayName)
                                                        : stringToColor(`${comment?.commentBy?.firstName} ${comment?.commentBy?.lastName}`),
                                            }}
                                        >
                                            {!comment?.commentBy?.photoURL && (comment?.commentBy?.displayName || comment?.commentBy?.firstName) ? (
                                                comment?.commentBy?.displayName
                                                    ? comment?.commentBy?.displayName[0]
                                                    : comment?.commentBy?.firstName[0]
                                            ) : null}
                                        </Avatar>
                                    }
                                    title={
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                            {comment?.commentBy?.displayName ?? `${comment?.commentBy?.firstName} ${comment?.commentBy?.lastName}`}
                                        </Typography>
                                    }
                                    subheader={formatDate(comment.createdAt)}
                                />
                                <CardContent sx={{ paddingTop: '0' }}>
                                    <Typography variant="body1"> {/* Reduce the font size */}
                                        {comment.commentString}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                }
            </Grid>
            {
                totalPages > 1 &&
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                </Grid>
            }
        </Grid>
    )
}

export default CommentController;