import React from 'react';
import Chip from '@mui/material/Chip';
import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

function AuthProviderChip({ authProvider }) {
    let label = '';
    let icon = null;
    let backgroundColor = ''; // Initialize with an empty background color
    let iconSize = 20; // Adjust the icon size as needed

    switch (authProvider) {
        case 'email':
            label = 'Email';
            icon = <EmailIcon sx={{ fontSize: iconSize }} />;
            backgroundColor = '#E1F5FE';
            break;
        case 'Microsoft':
            label = 'Microsoft';
            icon = <MicrosoftIcon sx={{ fontSize: iconSize }} />;
            backgroundColor = '#DCEDC8';// Light Green
            break;
        case 'Google':
            label = 'Google';
            icon = <GoogleIcon sx={{ fontSize: iconSize }} />;
            backgroundColor = '#FFCC80'; // Light Orange
            break;
        default:
            label = 'Unknown';
            break;
    }

    return (
        <Chip
            label={label}
            icon={icon}
            sx={{
                borderRadius: '10px',
                padding: '4px 8px',
                fontSize: '14px',
                textTransform: 'capitalize',
                backgroundColor: backgroundColor, // Set the background color based on authProvider
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                '& .MuiChip-icon': {
                    marginRight: '4px',
                },
            }}
        />
    );
}

export default AuthProviderChip;
