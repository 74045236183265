// DeclineDialog.jsx
import React, {useState} from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function DeclineDialog({ open, handleClose, handleDecline }) {
    const [declineReason, setDeclineReason] = useState('');

    const onDeclineButtonClick = () => {
        if (declineReason && declineReason?.trim() !== '') {
            handleDecline('decline-with-reason', declineReason)
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={'md'}
            fullWidth={true}
        >
            <DialogTitle
                className={'fixed-header'}
                sx={{
                    backgroundColor: '#244fac',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                Decline?
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {handleClose(false)}}
                    aria-label="close"
                    sx={{
                        transition: 'background-color 0.3s, color 0.3s',
                        '&:hover': {
                            color: 'grey',
                            backgroundColor: '#fff',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    id="name"
                    label="Decline Reason"
                    sx={{
                        marginTop: '25px'
                    }}
                    onChange={(e) => setDeclineReason(e.target.value)}
                    type="text"
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={10}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="error"
                    onClick={onDeclineButtonClick}
                >
                    Decline
                </Button>
            </DialogActions>
        </Dialog>
    );
}