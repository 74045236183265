import { Grid, TextField, Button, Checkbox, formControlLabel, FormControlLabel, Typography, Switch } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveButton } from '../../components/commons/Buttons';
import CustomDialogueSmall from '../../components/commons/CustomDialogueSmall';
import { closeModal, updateFranchiseDataSuccess, updateFranchiseFieldValues, updateIsActive } from '../../app/Franchise/FranchiseSlice';
import { updateFranchiseData } from '../../app/Franchise/service';
import { toast } from 'react-toastify';


const EditFranchiseComoponent = () => {

    const dispatch = useDispatch();
    const [assetRangeChangeNeeded, setAssetRangeChangeNeeded] = React.useState(false);
    const isEditModalOpen = useSelector(state => state.franchiseMangement.isCreateOrEditModalOpen);
    const selectedFranchise = useSelector(state => state.franchiseMangement.selectedFranchise);
    const modalMode = useSelector(state => state.franchiseMangement.modalMode);
    const imageData = useSelector(state => state.franchiseMangement.create.image);
    const isActive = useSelector(state => state.franchiseMangement.selectedFranchise?.isActive);
    const franchiseId = selectedFranchise?._id;


    if (!selectedFranchise) {
        return null;
    }

    const handleSave = async () => {
        try {
            const formData = new FormData();
            formData.append('image', imageData);
            formData.append('isAssetRangeChange', assetRangeChangeNeeded);
            formData.append('isActive', isActive);
            formData.append('country', selectedFranchise?.country);
            formData.append('area', selectedFranchise?.area);
            const result = await updateFranchiseData(formData, franchiseId);

            if (result.success) {
                // dispatching the updated data to redux state.
                dispatch(updateFranchiseDataSuccess(result.data.updatedFranchise))
            } else {
                throw new Error("Failed to update the Franchise data");

            }

            toast.success('Franchise data updated successfully!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch(closeModal());

        } catch (err) {
            toast.error(err.message || 'Failed to update Franchise data', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
        }
    }

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            // Update the state with the selected image
            dispatch(updateFranchiseFieldValues({ key: 'image', value: imageFile }));
        }
    };

    const handleChange = (event) => {
        setAssetRangeChangeNeeded(event.target.checked);
    };

    const handleActiveChange = (event) => {
        const isActive = event.target.checked;
        dispatch(updateIsActive({ isActive }))
    }

    const footerButtons = (
        <>
            <SaveButton onClick={handleSave}>Save</SaveButton>
        </>
    )


    return (
        <CustomDialogueSmall
            open={isEditModalOpen && modalMode === 'update'}
            onClose={() => { dispatch(closeModal()) }}
            headerText="Edit Franchise"
            footerButtons={footerButtons}
        >

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled
                        id="outlined-basic"
                        label="Area"
                        variant="outlined"
                        value={selectedFranchise.area}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled
                        id="outlined-basic"
                        label="Country"
                        variant="outlined"
                        value={selectedFranchise.country}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled
                        id="outlined-basic"
                        label="Asset ID Range"
                        variant="outlined"
                        value={`${selectedFranchise.minAssetRange} - ${selectedFranchise.maxAssetRange}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6} >
                    <FormControlLabel
                        control={<Checkbox checked={assetRangeChangeNeeded} onChange={handleChange} />}
                        label={<Typography variant="body1" style={{ color: 'blue' }}>Asset Range Update Needed ?</Typography>}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px', }}>
                        <Typography variant="body2" gutterBottom>Company Active Status</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={selectedFranchise.isActive}
                                    color={selectedFranchise.isActive ? "success" : "warning"}
                                    onChange={handleActiveChange}
                                />
                            }
                            label={
                                <Typography
                                    variant="body1"
                                    style={{ color: selectedFranchise.isActive ? 'inherit' : 'red' }}
                                >
                                    {selectedFranchise.isActive ? "Active" : "Inactive"}
                                </Typography>
                            }
                        />
                    </div>
                </Grid>

                {imageData && (
                    <Grid item xs={12} sm={12}>
                        <img src={URL.createObjectURL(imageData)} alt="Selected" style={{ maxWidth: '100%', maxHeight: '70px', border: '1px solid black', width: 'auto', objectFit: 'contain' }} />
                    </Grid>
                )}
                {selectedFranchise?.logoUrl && !imageData && (
                    <Grid item xs={12} sm={12}>
                        <img src={selectedFranchise?.logoUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '70px', border: '1px solid black', width: 'auto', objectFit: 'contain' }} />
                    </Grid>
                )}

                <Grid item xs={12} sm={12}>
                    <Button variant="contained" component="label" fullWidth>
                        Change Logo
                        <input
                            accept="image/*"
                            id="logo-upload"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                        />
                    </Button>
                </Grid>
            </Grid>
        </CustomDialogueSmall>
    );
}

export default EditFranchiseComoponent;
