import { privateApi } from "../../api";



export const getUsersWithQueryPagination = async (page, pageSize, isActive, filterQuery) => {
    let query = `users/getUsers/?page=${page}&limit=${pageSize}&isActive=${isActive}`
    if (filterQuery) query += filterQuery
    return await privateApi.get(query);
};

export const getPendingApprovalUsers = async (page, pageSize, filterQuery) => {
    let query = `users/getPendingApprovalUsers/?page=${page}&limit=${pageSize}`
    if (filterQuery) query += filterQuery
    return await privateApi.get(query);
}

export const updateUserProfile = async (userId, data) => {
    let path = `users/updateUserProfile/${userId}`;
    return await privateApi.put(path, data);
};



export const getCompaniesList = async () => {
    return await privateApi.get('company/getCompaniesList');
}

export const getCompanyLocations = async (id) => {
    return await privateApi.get(`company/getCompanyLocations/${id}`);
}

export const declineUserJoinRequest = async (tempUserId) => {
    let path = `users/declineUserJoinRequest/${tempUserId}`;
    return await privateApi.delete(path);
}


export const approveTempUserJoinRequest = async (data) => {
    let path = `users/approveTempUserJoinRequest`;
    return await privateApi.post(path, data);
}