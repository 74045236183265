import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/commons/Table/Table";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { toast } from "react-toastify";
import { getAsset, getAssetsByQuery } from "../../app/Asset/AssetSlice";
import { getCompany } from "../../app/Company/CompanySlice";
import CustomDialogueSmall from "../../components/commons/CustomDialogueSmall";
import { CloseButton } from "../../components/commons/Buttons";
import AssetActionItems from "./AssetActionItems";

const tableColumns = [
    { field: "machineCode", header: "Machine Code", type: "linkText", colSize: "small" },
    { field: "assetName", header: "Name", type: "linkText", colSize: "medium" },
    { field: "healthStatus", header: "Health", type: "text", colSize: "medium" },
    { field: "companyName", header: "Company", type: "linkText", colSize: "medium" },
    { field: "locationName", header: "Location", type: "text", colSize: "medium" },
    { field: "contractType", header: "Contract Type", type: "text", colSize: "medium" },
    { field: "updatedAt", header: "Updated At", type: "date", colSize: "medium" },
    { field: "nextService", header: "Next Service", type: "date", isWarningIndicationNeeded: true, warningDuration: 3, colSize: "medium" },
    { field: "nextInvoiceDate", header: "Next Invoice", type: "date", isWarningIndicationNeeded: true, warningDuration: 3, colSize: "medium" },
    { field: "actionRequiredItems", header: "Actions", type: "iconBtn", icon: 'ErrorOutlineRoundedIcon', colSize: "small", color:"error" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
];

const mobileOnlyColumns = [
    { field: "machineCode", header: "Machine Code", type: "linkText", colSize: "small" },
    { field: "actionRequiredItems", header: "Actions", type: "iconBtn", icon: 'ErrorOutlineRoundedIcon', colSize: "small", color: "error" },
    { field: "assetName", header: "Name", type: "linkText", colSize: "medium" },
    { field: "companyName", header: "Company", type: "text", colSize: "medium" },
    { field: "contractType", header: "Contract Type", type: "text", colSize: "medium" },
    { field: "updatedAt", header: "Updated At", type: "date", colSize: "medium" },
    { field: "nextService", header: "Next Service", type: "date", isWarningIndicationNeeded: true, warningDuration: 3, colSize: "medium" },
    { field: "nextInvoiceDate", header: "Next Invoice", type: "date", isWarningIndicationNeeded: true, warningDuration: 3, colSize: "medium" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
];

const CompaniesTable = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const assetsList = useSelector((state) => state.assetManagement.assetsList);
    const sessionUser = useSelector((state) => state.userData.data)
    const queryData = useSelector((state) => state.assetManagement.queryData);
    const isLoading = useSelector((state) => state.assetManagement.isLoading);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [warningOpenedAsset, setWarningOpenedAsset] = useState(null);

    useEffect(() => {
        dispatch(getAssetsByQuery(queryData));
    }, [sessionUser.franchise])

    const handlePageChange = (event, newPage) => {
        dispatch(getAssetsByQuery({ ...queryData, page: newPage }))
    };

    const handleAssetEditClick = (data, actionType, field) => {
        if (data.type === 'superAdmin') {
            toast.warning('Editing Super Admin Company Data is not allowed.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
        } else if (field === 'actionRequiredItems') {
            setWarningOpenedAsset(data);
            setIsWarningModalOpen(true)
        } else {
            dispatch(getAsset(data._id));
        }
    }

    const handleTableLinkClick = (row, field) => {
        if (field === 'companyName') {
            if (row?.company?._id) dispatch(getCompany(row.company._id));
        } else {
            handleAssetEditClick(row);
        }
    }

    return (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Table
                data={assetsList}
                columns={tableColumns}
                mobileOnlyColumns={mobileOnlyColumns}
                onButtonClick={handleAssetEditClick}
                onLinkClick={(row, field) => handleTableLinkClick(row, field)}
                isLoading={isLoading}
                autoTableHeight={true}
                firstColumnSticky={true}
            />
            <Stack spacing={2} sx={{ marginTop: '25px', marginBottom: '15px' }}>
                <Pagination
                    defaultPage={1}
                    page={queryData.page}
                    count={queryData.totalPages}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
            </div>

            <CustomDialogueSmall
                open={isWarningModalOpen}
                onClose={() => {
                    setIsWarningModalOpen(false)
                }}
                headerText={`Actionable Items on Machine ${warningOpenedAsset?.machineCode}` }
                footerButtons={
                    <>
                        <CloseButton
                            onClick={() => {
                                setIsWarningModalOpen(false)
                            }} />
                    </>
                }
            >
                <AssetActionItems propData={warningOpenedAsset}/>
            </CustomDialogueSmall>
        </>
    );
});

export default CompaniesTable;


