import React, { useEffect, useState } from 'react';
import CustomDialogueMedium from './../../components/commons/CustomDialogueMedium';
import { SaveButton } from '../../components/commons/Buttons';
import { Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import Autocomplete from '../../components/commons/Autocomplete';
import { getCompaniesList, getCompanyLocations } from '../UserMangaement/Service';
import ItamcIssueStatusSelector from '../../components/commons/ItamcIssueStatusSelector';
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_IT_AMC_CREATE_ISSUE, RESET_IT_AMC_UPDATE_DATA, UPDATE_IT_AMC_UPDATE_DATA, createNewIssue } from '../../app/ItAmc/ItAmcManagement';
import SelectInput from '../../components/commons/SelectInput';
import MultipleUserSelector from '../../components/commons/MultipleUserSelector/MultipleUserSelector';
import FileUploader from '../../components/FileUploader/FileUploader';
import { toast } from 'react-toastify';

const CreateItAmcIssue = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isModalOpen = useSelector(state => state.itAmcManagement.isModalOpen);
    const issueData = useSelector(state => state.itAmcManagement.data);

    const [companiesList, setCompaniesList] = useState([]);
    const [companyLocationsList, setCompanyLocationsList] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        if (isModalOpen === 'create') {
            getCompaniesList()
                .then((result) => {
                    if (result?.data?.length) {
                        let tempArr = [];
                        result.data.forEach(item => {
                            tempArr.push({ value: item._id, label: item.companyName, type: item.type });
                        });
                        setCompaniesList(tempArr);
                    } else if (result.data) {
                        setCompaniesList([{ value: result.data._id, label: result.data.companyName, type: result?.data?.type }]);
                    }
                });
        }
        return () => {
            dispatch(RESET_IT_AMC_UPDATE_DATA())
        }
    }, [isModalOpen]);

    const getSelectedCompaniesLocations = async () => {
        try {
            const locationsList = await getCompanyLocations(issueData?.company?.value);
            setCompanyLocationsList(locationsList?.data?.map((item) => ({ value: item.id, label: item.locationName })) || []);
        } catch (error) {
            setCompanyLocationsList([]);
            console.error(`Failed to get locations: ${error.message}`);
        }
    };

    useEffect(() => {
        if (issueData.company) {
            getSelectedCompaniesLocations();
        }
        dispatch(UPDATE_IT_AMC_UPDATE_DATA({ name: "companyLocation", value: "" }));
    }, [issueData.company, issueData.company?.value]);

    const handleModalClose = () => dispatch(OPEN_IT_AMC_CREATE_ISSUE(false));

    const handleChangeInput = (e) => {
        const { value, name } = e.target;
        dispatch(UPDATE_IT_AMC_UPDATE_DATA({ name, value }));
    };

    const saveUploadedFilesInRedux = (fileData) => {
        dispatch(UPDATE_IT_AMC_UPDATE_DATA({
            name: "uploads",
            value: [...issueData.uploads, fileData]
        }));
    };

    const removeUploadedFileFromRedux = (fileName) => {
        if (fileName) {
            dispatch(UPDATE_IT_AMC_UPDATE_DATA({
                name: "uploads",
                value: issueData.uploads?.filter(item => item.filename !== fileName)
            }));
        }
    };

    const validateForm = () => {
        let errors = {};
        let multInp = [];
        if (!issueData.issueHeading) {
            multInp.push("Issue Heading")
            errors.issueHeading = "Issue Heading is required."
        };
        if (!issueData.company) {
            multInp.push("Company")
            errors.company = "Company is required."
        };
        if (!issueData.reportedUsers?.length) {
            multInp.push("Reported Users")
            errors.reportedUsers = "At least one Reported User is required."
        };
        if (!issueData.status) {
            multInp.push("Status")
            errors.reportedUsers = "Please Select Status of the issue"
        };
        setValidationErrors(errors);
        const errorsOccured = Object.keys(errors).length === 0
        if (!errorsOccured) {
            toast.error("Please Enter " + multInp.join(", "))
        }
        return errorsOccured;
    };

    const handleSubmit = () => {
        const validation = validateForm()
        if (validation) {
          
            // Dispatch a submit action or any other logic here
            dispatch(createNewIssue(issueData))
        }
    };

    const footerButtons = (
        <>
            <SaveButton onClick={handleSubmit} />
        </>
    );

    return (
        <CustomDialogueMedium
            open={isModalOpen === 'create'}
            onClose={handleModalClose}
            headerText={"Create New Issue"}
            footerButtons={footerButtons}
            isLarge={!isMobile}
        >
            <Grid container sx={{ padding: "1rem" }} spacing={2}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            sx={{ width: "100%" }}
                            label="Issue Heading"
                            required
                            value={issueData.issueHeading}
                            name={'issueHeading'}
                            onChange={handleChangeInput}
                            error={!!validationErrors.issueHeading}
                            helperText={validationErrors.issueHeading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            sx={{ width: "100%" }}
                            label="Description"
                            name={'description'}
                            multiline
                            value={issueData.description}
                            minRows={3}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                </Grid>
                <Grid sx={{ marginTop: "1rem" }} container spacing={1}>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Autocomplete
                            default={null}
                            label="Company"
                            name='company'
                            onChange={handleChangeInput}
                            options={companiesList}
                            value={issueData.company ?? null}
                            error={!!validationErrors.company}
                            helperText={validationErrors.company}
                        />
                    </Grid>
                    {issueData.company && companyLocationsList.length > 0 && <Grid item xs={isMobile ? 12 : 6}>
                        <Autocomplete
                            default={null}
                            label="Company Location"
                            name='companyLocation'
                            onChange={handleChangeInput}
                            options={companyLocationsList}
                            required={false}
                            value={issueData.companyLocation ?? null}
                        />
                    </Grid>}
                </Grid>
                <Grid sx={{ marginTop: "1rem" }} container spacing={1}>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <ItamcIssueStatusSelector
                            name={'status'}
                            label="Status"
                            value={issueData.status}
                            onChange={handleChangeInput}
                            ticketState={'new'}
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <SelectInput
                            label={'Payment Status'}
                            name={'paymentStatus'}
                            value={issueData.paymentStatus}
                            required
                            default={'notRequired'}
                            onChange={handleChangeInput}
                            options={[{ key: 'required', name: 'Payment Required' }, { key: 'notRequired', name: 'Payment Not Required' }, { key: 'paid', name: 'Paid' }, { key: 'paymentPending', name: 'Payment Pending' }]}
                        />
                    </Grid>
                </Grid>
                <Grid sx={{ marginTop: "1rem" }} container spacing={2}>
                    <Grid item xs={12}>
                        <MultipleUserSelector
                            label={'Reported Users'}
                            value={issueData.reportedUsers}
                            required
                            error={!issueData.reportedUsers?.length || !!validationErrors.reportedUsers}
                            helperText={validationErrors.reportedUsers}
                            companyId={issueData.company?.value}
                            onChange={(newValue) => {
                                dispatch(UPDATE_IT_AMC_UPDATE_DATA({ name: "reportedUsers", value: newValue }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultipleUserSelector
                            label={'Attended By'}
                            value={issueData.attendedBy}
                            companyId={issueData.company?.value}
                            onChange={(newValue) => {
                                dispatch(UPDATE_IT_AMC_UPDATE_DATA({ name: "attendedBy", value: newValue }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                        <FileUploader
                            setAdminOnly={true}
                            onFileUploadSuccess={saveUploadedFilesInRedux}
                            onDeleteFile={removeUploadedFileFromRedux}
                            destinationParentFolder='tempFiles'
                            destinationSubFolder='itAmcIssueAttachments'
                            savedFiles={issueData.uploads}
                            disabled={false}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </CustomDialogueMedium>
    );
}

export default CreateItAmcIssue;
