import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import {
    getFirestore,
    collection,
    getDocs,
    getDoc,
    where,
    query,
    writeBatch,
    commitBatch,
    doc,
    updateDoc,
    arrayUnion,
} from "@firebase/firestore";
import { getStorage } from "firebase/storage"; // Import getStorage
import { deleteToken, getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";

    // Production API Config
const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: "fix-my-office.firebaseapp.com",
        projectId: "fix-my-office",
        storageBucket: "fix-my-office.appspot.com",
        messagingSenderId: "845142228933",
        appId: "1:845142228933:web:f97bd7900e1e76cf25d5ac",
        measurementId: "G-N5VRF6ED5P"
    };

    

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const storage = getStorage(app); // Create and export the storage instance
export const messaging = async () => await isSupported() && getMessaging(app);

export const signInWithGoogle = async () => {
    try {
        await signInWithPopup(auth, googleProvider);
        const user = auth.currentUser;
        if (user) {
            return user;
        } else {
            throw new Error("User not found.");
        }
    } catch (error) {
        return error;
    }
};

export const signInWithMicrosoft = async () => {
    try {
        await signInWithPopup(auth, new OAuthProvider("microsoft.com"));
        const user = auth.currentUser;
        if (user) {
            return user;
        } else {
            throw new Error("User not found.");
        }
    } catch (error) {
        return error;
    }
};

export const signOutUser = async () => {
    try {
        const auth = getAuth(); // Ensure auth is obtained correctly
        if (auth.currentUser) {
            await signOut(auth);
            return { success: true, message: "User signed out successfully." };
        } else {
            // Return an object indicating no user is signed in
            return { success: false, message: "No user is currently signed in." };
        }
    } catch (error) {
        // Return an object with the error information
        return { success: false, error: error };
    }
};


export const markMessagesAsSeen = async (roomId, userId) => {
    try {
        const messagesRef = collection(db, 'messages');
        const querySnapshot = await getDocs(
            query(messagesRef, where('roomId', '==', roomId), where('userId', '!=', userId), where('seen', '==', null))
        );

        const batch = writeBatch(db);

        querySnapshot.forEach((doc) => {
            const docRef = doc.ref;
            batch.update(docRef, { seen: true });
        });

        await batch.commit();

        return { success: true, message: 'Messages marked as seen.' };
    } catch (error) {

        return { success: false, error: error.message };
    }
};


async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
        // console.log('Notification permission granted.');
        return true;
    } else {
        // console.log('Notification permission denied.');
        return false;
    }
}

// export const messaging = getMessaging(app)