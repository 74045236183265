import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextInput from '../../components/commons/TextInput';
import { Grid, FormHelperText, Stack } from '@mui/material';
import { roleOptionsForCustomerAdmin, allUserRoles, roleOptionsForVendorAdmin, authProviders, authProvidersForAutoComplete } from '../../constants/commonConstants';
import { isValidEmail } from '../../utilities/commonUtils';
import { getCompaniesList } from './Service';
import Autocomplete from '../../components/commons/Autocomplete';

function ActiveUserFilter({ isActive }) {
    const dispatch = useDispatch();
    const sessionUserType = useSelector((state) => state.userData.data.userType);
    const sessionUserRole = useSelector((state) => state.userData.data.role);
    const filterValues = useSelector((state) => state.userManagement.activeUsersFilter);
    const filterErrorFlag = useSelector((state) => state.userManagement.activeUsersFilterErrorFlag);
    const [roleOptions, setRoleOptions] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [companiesList, setCompaniesList] = useState([]);

    const handleValueChange = (name, value) => {
        if (inputError(name, value)) return;
        dispatch({ type: 'userManagement/handleActiveUsersFilterValueChange', payload: { name, value } });
    }

    const inputError = (name, value) => {
        if (name === 'customUserId' && (isNaN(value))) return true;
        if (name === 'email' && !isValidEmail(value)) setIsEmailValid(true)
        else setIsEmailValid(false)
        return false;
    }

    useEffect(() => {
        if (isActive) dispatch({ type: 'userManagement/handleActiveUsersFilterValueChange', payload: { name: isActive, value: true } })
        getUserRoles();
        getCompaniesList()
            .then((result) => {
                if (result?.data?.length) {
                    let tempArr = [];
                    result.data.forEach(item => {
                        tempArr.push({ value: item._id, label: item.companyName });
                    });
                    setCompaniesList(tempArr)
                } else if (result.data) {
                    setCompaniesList([{ value: result.data._id, label: result.data.companyName }])
                }
            })
    }, [])

    const getUserRoles = () => {
        let options;


        if (sessionUserType === 'Admin') {
            options = allUserRoles; 
        } else if (sessionUserType === 'Customer') {
            options = roleOptionsForCustomerAdmin;
        } else if (sessionUserType === 'Vendor') {
            options = roleOptionsForVendorAdmin;
        } else {
            // Default options if none of the above conditions match
            options = [];
        }
        if (sessionUserRole === 'coreAdmin') {
            options = [{ key: 'coreAdmin', name: 'Core Admin' }, ...options ]
        }
        setRoleOptions(() => {
            const mapped = options.map(item => {
                return { label: item.name, value: item.key }
            });
            return mapped;
        })
    }

    return (
        <div>
            <Stack sx={{ margin: 0, marginBottom: '5px' }}>
                {filterErrorFlag ? (
                    <FormHelperText error>
                        {filterErrorFlag}
                    </FormHelperText>
                ) : <FormHelperText>
                </FormHelperText>}
            </Stack>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={4}>

                        <Grid item xs={isMobile ? 12 : 6}>
                            <TextInput
                                label="Full Name"
                                name={'displayName'}
                                max={100}
                                value={filterValues.displayName}
                                onChange={(e) => handleValueChange('displayName', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <TextInput
                                label="User Id"
                                name={'customUserId'}
                                value={filterValues.customUserId}
                                onChange={(e) => handleValueChange('customUserId', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <TextInput
                                label="Email"
                                name={'email'}
                                error={isEmailValid}
                                value={filterValues.email}
                                onChange={(e) => handleValueChange('email', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Autocomplete
                                label="Role"
                                name='role'
                                value={filterValues.role}
                                onChange={(e) => handleValueChange('role', e.target.value)}
                                options={roleOptions}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Autocomplete
                                label="Company"
                                name='company'
                                value={filterValues.company}
                                onChange={(e) => handleValueChange('company', e.target.value)}
                                options={companiesList}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Autocomplete
                                label="Login Method"
                                name='authProvider'
                                value={filterValues.authProvider}
                                onChange={(e) => handleValueChange('authProvider', e.target.value)}
                                options={authProvidersForAutoComplete}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default ActiveUserFilter;
