import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import {
    TextField,
    IconButton,
    InputAdornment,
    Box,
    Paper
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/UploadFile';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { styled } from '@mui/material/styles';

import { serverTimestamp, addDoc, collection, doc, updateDoc, getDoc } from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from '../../utilities/auth-config/firebase-config';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import MicIcon from "@mui/icons-material/Mic";

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: 'white',
    boxShadow: '0px -1px 5px 0px rgba(0, 0, 0, 0.2)',
    bottom: 0,
    minWidth: '200px !important',
    width: '100%',
    transition: 'box-shadow 0.2s',
    '&:hover': {
        boxShadow: '3px 15px 15px rgba(0, 0, 0, 0.4)',
        cursor: 'pointer',
        borderBottom: 'solid 5px #091B95',
    },
});


const ChatTextField = styled(TextField)(({ theme }) => ({
    flexGrow: 2,
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-root": {
        fieldset: {
            border: "none",
        },
        padding: 10,
        "&:hover fieldset": {
            border: "none",
        },
    },
    "& .MuiInputBase-input": {
        borderRadius: 0,
        width: "100%",
        border: 0,
        fontSize: 16,
        padding: 0,
    },
}));

const ChatInputController = () => {
    const [firstMessageFlag, setFirstMessageFlag] = useState(0);
    const dispatch = useDispatch();
    const endChatIconButtonRef = useRef(null);
    const isAdminsOnline = useSelector((state) => state.chats.isAdminsOnline);
    const isCustomerChatAttended = useSelector((state) => state.chats.isCustomerChatAttended);


    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const user = useSelector((state) => state.userData.data);
    const isChatAttended = useSelector((state) => state.chats.isCustomerChatAttended)
    const [message, setMessage] = useState('');

    const messageDbRef = collection(db, 'messages');
    const storageRef = ref(storage, user._id);

    const roomRef = doc(db, 'rooms', user._id);

    async function updateUserRoom() {
        try {
            const activeAdminsDoc = doc(db, 'activeAdmins', 'admins');
            const activeAdminsSnapshot = await getDoc(activeAdminsDoc);
            const activeAdminsData = activeAdminsSnapshot.data();

            if (activeAdminsData && activeAdminsData.admins && activeAdminsData.admins.length > 0) {
                const userRoomSnapshot = await getDoc(roomRef);
                const userRoomData = userRoomSnapshot.data()
                if (userRoomData !== 'open' || userRoomData !== 'attended') {

                    await updateDoc(doc(db, "rooms", user._id), {
                        status: "open",
                        attendedBy: "",
                        attendedByName: "",
                    });
                };
                dispatch({ type: 'chats/updateIsCustomerChatAttended', payload: true });
            } else {
                toast.warning('Sorry, All the admins are currently offline, Please reach out to us over the phone or Watsapp us at +971 97372865788', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 15000,
                    style: { width: '400px' },
                });
            }
        } catch (error) {
            // console.log(error);
        }
    };

    const handleSendMessage = async () => {
        if (!user._id) {
            toast.warning('Please select a chat box to start sending messages.');
            return
        }
        if ( !isAdminsOnline) {
            toast.warning('Sorry, All the admins are currently offline, Please reach out to us over the phone or Watsapp us at +971 0507828055', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 15000,
                style: { width: '400px' },
            });
            return;
        }
        const messageToSend = message;
        setMessage('');
        if (messageToSend.trim() !== '') {
            const messageObj = {
                message: messageToSend,
                photoURL: user.photoURL ?? "",
                roomId: user._id,
                timestamp: serverTimestamp(),
                type: 'text',
                userId: user._id,
                userName: user.displayName ?? `${user.firstName} ${user.lastName}`,
                userType: user.userType,
                seen: null,
                fileURL: '',
            };

            await addDoc(messageDbRef, messageObj);
            // change status to open
            if (!isChatAttended) {
                updateUserRoom();
            }

            
        }
    };

    const handleFileChange = async (event) => {
        await handleFileUpload(event.target.files[0]);
    };

    const handleFileUpload = async (file) => {
        if (!isAdminsOnline) {
            toast.warning('Sorry, All the admins are currently offline, Please reach out to us over the phone or watsapp us at +971 0507828055', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 15000,
                style: { width: '400px' },
            });
            return;
        }
        if (!file) {
            toast.warning('Please select a file to upload.');
            return;
        }

        try {
            const fileRef = ref(storageRef, file.name); // Storage reference
            await uploadBytes(fileRef, file);

            const downloadURL = await getDownloadURL(fileRef);

            // Save the download link in messages collection
            const messageObj = {
                message: '',
                photoURL: user.photoURL ?? "",
                roomId: user._id,
                timestamp: serverTimestamp(),
                type: 'file',
                fileType: file.type ?? "",
                fileName: file.name ?? "",
                userId: user._id,
                userName: user.displayName ?? `${user.firstName} ${user.lastName}`,
                seen: null,
                fileURL: downloadURL,
            };

            await addDoc(messageDbRef, messageObj)
        } catch (error) {
            toast.error('An error occured while trying to upload the file.')
        }
    };

    const handleChatSessionClose = async () => {
        await updateDoc(doc(db, "rooms", user._id), {
            status: "closed",
            attendedBy: '',
            attendedByName: '',
        });
        dispatch({ type: 'chats/setOpenedChatId', payload: null });
        endChatIconButtonRef.current.click();
    }

    return (
        <Paper
            component="form"
            elevation={0}
            sx={{
                p: "10px 15px",
                m: 0,
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                width: "100%",
            }}
        >

            <IconButton
                sx={{ p: "10px", marginRight: "5px" }}
                aria-label="file-attachment"
                onClick={() => document.getElementById('file-input').click()}
            >
                <FontAwesomeIcon icon={faPaperclip} />
            </IconButton>
            <input
                accept="*/*"
                style={{ display: 'none' }}
                id="file-input"
                type="file"
                onChange={handleFileChange}
                disabled={!user._id}
            />

            <IconButton disabled sx={{ p: "10px", marginRight: "10px" }} aria-label="record">
                <MicIcon />
            </IconButton>
            <ChatTextField
                placeholder="Type your message..."
                maxRows={5}
                fullWidth
                multiline
                ref={inputRef}
                value={message}
                onChange={(e) => {
                    setMessage(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                    }
                }}
            />
            <IconButton
                type="button"
                sx={{ p: "10px", marginLeft: "10px" }}
                aria-label="send message"
                onClick={handleSendMessage}
            >
                <SendIcon />
            </IconButton>
        </Paper>
    );
};

export default ChatInputController;
