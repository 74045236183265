import { Avatar, Box, Grid, Paper, Typography, Badge } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { db } from '../../utilities/auth-config/firebase-config';
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { stringToColor } from '../../utilities/commonUtils';

const ListEnquiryUsers = ({ dialogOpen, setDialogOpen }) => {
    const [users, setUsers] = useState([]);

    const handleDialogOpen = (user) => {
        setDialogOpen(user || true);
    };

    useEffect(() => {
        const q = query(collection(db, "enquiryUser"), orderBy("timestamp", "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const usersData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setUsers(usersData);
        }, (error) => {
            console.error("Error fetching documents: ", error);
        });

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, []);

    return (
        <Box sx={{ width: '100%', typography: 'body1', backgroundColor: '#f4f6f8', padding: '1rem', maxWidth: '23rem' }}>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                Enquiries Chat
            </Typography>
            <Box sx={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto', paddingTop: '1rem' }}>
                {users.length > 0 ? (
                    users.map((user) => (
                        <Fragment key={user.id}>
                            <Paper
                                elevation={3}
                                onClick={() => handleDialogOpen(user)}
                                sx={{
                                    mb: 1,
                                    borderRadius: '12px',
                                    position:"relative",
                                    transition: 'box-shadow 0.3s',
                                    '&:hover': {
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
                                        cursor: 'pointer',
                                    },
                                    ...(dialogOpen.id === user.id && {
                                        backgroundColor: "#e0e0e0"
                                    })
                                }}
                            >
                                <Grid container alignItems="center" spacing={2} sx={{ paddingX: '1rem', paddingBottom: '1rem' }}>
                                    <Grid item xs={3} container justifyContent="center">
                                        <Avatar
                                            alt={user.name || 'Guest'}
                                            sx={{
                                                bgcolor: stringToColor(user.name || 'Guest'),
                                                width: 56,
                                                height: 56,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {user.name ? user.name[0] : 'G'}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontWeight: 'bold',
                                                mb: 0.5,
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            {user.name || 'Guest User'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                maxWidth: 'calc(100% - 60px)',
                                            }}
                                        >
                                            {user.email || 'No email provided'}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                maxWidth: 'calc(100% - 60px)',
                                                mt: 1
                                            }}
                                        >
                                            {user.message ? `${user.message.substring(0, 40)}${user.message.length > 40 ? '...' : ''}` : 'No message'}
                                        </Typography>
                                    </Grid>
                                    {user.notSeenMessageCount > 0 && (
                                        <Grid item xs={1} container  justifyContent="center" position={"absolute"} top={"0"} right={".8rem"} alignItems="center">
                                            <Badge
                                                badgeContent={user.notSeenMessageCount}
                                                color="primary"
                                                sx={{ 
                                                    '.MuiBadge-dot': {
                                                        borderRadius: '50%',
                                                        width: '10px',
                                                        height: '10px',
                                                        backgroundColor: '#f44336',
                                                    },
                                                    '.MuiBadge-root': {
                                                        margin: '0 8px',
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        </Fragment>
                    ))
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        No Enquiry users found
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default ListEnquiryUsers;
