export const registerCustomerInputValidation = (registerData) => {
    const errors = [];

    // Check required fields
    if (!registerData.emailId) {
        errors.push('Email');
    }
    if (!registerData.preferredContact) {
        errors.push('Preferred contact');
    }
    if (!registerData.linkWithExistingCompany && !registerData.companyName) {
        errors.push('Company name');
    }
    if (registerData.linkWithExistingCompany && (!registerData.companyName && !registerData.companyCode)) {
        errors.push("Company name or code");
    }
   
    if (registerData.authProvider === 'email') {
        if (!registerData.password) {
            errors.push('Password');
        }
        if (!registerData.firstName) {
            errors.push('First Name');
        }
        if (!registerData.lastName) {
            errors.push('Last Name');
        }
    }
    if (registerData.companyAddress && !registerData.linkWithExistingCompany) {
        const { city, country, stateProvinceRegion, streetLine1 } = registerData.companyAddress;
        if (!city) {
            errors.push('City');
        }
        if (!country) {
            errors.push('Country');
        }
        if (!stateProvinceRegion) {
            errors.push('State/Province/Region');
        }
        if (!streetLine1) {
            errors.push('Street');
        }
    }


    // Check Google/Microsoft authentication fields
    if (registerData.authProvider === 'Google' || registerData.authProvider === 'Microsoft') {
        if (!registerData.authObj.accessToken || !registerData.authObj.email || !registerData.authObj.uid || !registerData.authObj.stsTokenManager.accessToken) {
            errors.push('Authentication Token');
        }
        if (!registerData.authObj.displayName) {
            errors.push('Full Name');
        }
    }

    if (!registerData.franchise.country || !registerData.franchise.area){
        errors.push('franchise')
    }
        // if (registerData.linkWithExistingCompany && (!registerData.companyCode || !registerData.companyName)) {
        //     errors.push('Company to link');
        // }

        return errors;
};