import React from 'react'
import { Skeleton, useMediaQuery, Grid, useTheme } from "@mui/material"

const TicketModalSkeleton = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            {/* Skeleton for Ticket Heading */}
            <Grid item xs={12}>
                <Skeleton variant="text" height={90} animation="wave" />
            </Grid>

            {/* Skeleton for Ticket Description */}
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <Skeleton variant="text" height={90} animation="wave" />
            </Grid>

            {/* Skeleton for Company */}
            <Grid item xs={isMobile ? 12 : 6}>
                <Skeleton variant="rectangular" height={40} animation="wave" />
            </Grid>


            <Grid item xs={isMobile ? 12 : 6}>
                <Skeleton variant="rectangular" height={40} animation="wave" />
            </Grid>

            <Grid item xs={12}>
                <Skeleton variant="text" height={80} animation="wave" />
            </Grid>

            <Grid item xs={12}>
                <Skeleton variant="text" height={40} animation="wave" />
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <Skeleton variant="text" height={80} animation="shimmer" />
            </Grid>


            <Grid item xs={6}>
                <Skeleton variant="rectangular" height={40} animation="shimmer" />
            </Grid>


            <Grid item xs={6}>
                <Skeleton variant="avatar" height={40} animation="shimmer" />
            </Grid>

            <Grid item xs={6}>
                <Skeleton variant="text" height={40} animation="shimmer" />
            </Grid>

            {/* Skeleton for Ticket Description */}
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <Skeleton variant="text" height={40} animation="wave" />
            </Grid>

            <Grid item xs={12}>
                <Skeleton variant="text" height={40} animation="wave" />
            </Grid>

            {/* Skeleton for Ticket Description */}
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <Skeleton variant="text" height={40} animation="wave" />
            </Grid>

            {/* Skeleton for Company */}
            <Grid item xs={isMobile ? 12 : 6}>
                <Skeleton variant="rectangular" height={40} animation="wave" />
            </Grid>
            {/* Skeleton for Company */}
            <Grid item xs={isMobile ? 12 : 6}>
                <Skeleton variant="rectangular" height={40} animation="wave" />
            </Grid>
        </>
    )
}

export default TicketModalSkeleton;
