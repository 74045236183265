import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/commons/Table/Table";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { toast } from "react-toastify";
import { getCompaniesByQuery, getCompany } from "../../app/Company/CompanySlice";






const tableColumns = [
    // { field: "photoURL", header: "Avatar", type: "avatar", colSize: "small" },
    { field: "_id", header: "ID", type: "text", colSize: "extra-small" },
    { field: "companyName", header: "Company Name", type: "text", colSize: "large" },
    { field: "companyCode", header: "Code", type: "text", colSize: "small" },
    { field: "type", header: "Type", type: "text", colSize: "medium" },
    { field: "companyPhone", header: "Phone", type: "text", colSize: "medium" },
    { field: "companyEmail", header: "Email", type: "text", colSize: "medium" },
    { field: "createdAt", header: "Created AT", type: "date", colSize: "medium" },
    { field: "updatedAt", header: "Updated At", type: "date", colSize: "medium" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
];

const mobileOnlyColumns = [
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
    { field: "companyName", header: "Company Name", type: "text", colSize: "medium" },
    { field: "companyCode", header: "Code", type: "text", colSize: "small" },
    { field: "type", header: "Type", type: "text", colSize: "medium" },
];

const CompaniesTable = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const companiesList = useSelector((state) => state.companyManagement.companiesList);
    const queryData = useSelector((state) => state.companyManagement.queryData);
    const isLoading = useSelector((state) => state.companyManagement.isLoading);
    const sessionUser = useSelector((state) => state.userData.data)

    useEffect(() => {
        dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'queryData', field: 'isActive', value: props.isActive, } });
        dispatch(getCompaniesByQuery({ ...queryData, isActive: props.isActive }));
    }, [sessionUser.franchise])
    
    const handlePageChange = (event, newPage) => {
        dispatch(getCompaniesByQuery({ ...queryData, page: newPage }))
    };

    const handleCompanyEditClick = (company) => {
        if (company.type === 'superAdmin' && sessionUser.role !== 'coreAdmin' ) {
            toast.warning('Editing Super Admin Company Data is not allowed.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            return;
        } else {
            dispatch(getCompany(company._id));
        } 

    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Table
                data={companiesList}
                columns={tableColumns}
                mobileOnlyColumns={mobileOnlyColumns}
                onButtonClick={handleCompanyEditClick}
                isLoading={isLoading}
                firstColumnSticky={true}
            />
            <Stack spacing={2} sx={{ marginTop: '25px' }}>
                <Pagination
                    defaultPage={1}
                    page={queryData.page}
                    count={queryData.totalPages}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </div>
    );
});

export default CompaniesTable;


