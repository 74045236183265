import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from './service';

const initialState = {
    createOrEditData: {
        id: '',
        companyCode: '',
        type: 'customer',
        companyName: '',
        trnNumber: '',
        companyPhone: '+971',
        companyEmail: '',
        address: {
            streetLine1: '',
            city: '',
            postalCode: '',
            stateProvinceRegion: '',
            country: '',
        },
        locationInput: {
                locationName: '',
                address: {
                    streetLine1: '',
                    city: '',
                    postalCode: '',
                    stateProvinceRegion: '',
                    country: '',
                }
        },
        locations: [
            // {
            //     locationName: '',
            //     address: {
            //         streetLine1: '',
            //         city: '',
            //         postalCode: '',
            //         stateProvinceRegion: '',
            //         country: '',
            //     }
            // }
        ],
        locationsDeleted: [],
        isActive: '',
        createdAt: '',
        updatedAt: '',
        createdBy: '',
        updatedBy: '',
    },
    queryData: {
        companyCode: '',
        companyName: '',
        companyEmail: '',
        isActive: true,
        trnNumber: '',
        page: '',
        limit: 10,
        totalCount: '',
        totalPages: ''
    },
    companiesList: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMessage: '',
    errorMessages: [],
    isCreateOrEditModalOpen: false,
    modalMode: 'create' // accepted enums  'create' || 'update'
};


export const createCompany = createAsyncThunk(
    'companyManagement/createCompany',
    async (newCompanyInput, { rejectWithValue }) => {
        try {
            const { id, createdAt, updatedAt, createdBy, updatedBy, ...companyData } = newCompanyInput;

            const response = await service.createCompany(companyData);

            if (response.status === 201) {
                return response.data;
            }

            return rejectWithValue('An un-expected error occurred while making the request.');
        } catch (error) {
            return rejectWithValue(error?.message ?? 'An un-expected error occurred while making the request.');
        }
    }
);

export const editCompany = createAsyncThunk(
    'companyManagement/editCompany',
    async (editCompanyInput, { rejectWithValue }) => {
        try {
            const { createdAt, updatedAt, createdBy, updatedBy, locationInput, isSuccess, ...companyData } = editCompanyInput;

            const response = await service.editCompany(companyData);

            if (response.status === 200) {
                return response.data;
            }

            return rejectWithValue('An un-expected error occurred while making the request.');
        } catch (error) {
            return rejectWithValue(error?.message ?? 'An un-expected error occurred while making the request.');
        }
    }
);


export const getCompaniesByQuery = createAsyncThunk(
    'companyManagement/getCompaniesByQuery',
    async (queryData, { rejectWithValue }) => {
        try {
            const response = await service.getCompaniesByQuery(queryData);

            if (response.status === 200) {
                return response.data;
            }

            return rejectWithValue('An un-expected error occurred while making the request.');
        } catch (error) {
            return rejectWithValue(error?.message ?? 'An un-expected error occurred while making the request.');
        }
    }
);

export const getAllCompaniesList = createAsyncThunk(
    'companyManagement/getAllCompaniesList',
    async (_, { rejectWithValue }) => {
        try {
            const response = await service.getAllCompaniesList();
            if (response.status === 200) {
                return response.data;
            }
            return rejectWithValue('An un-expected error occurred while making the request.');
        } catch (error) {
            return rejectWithValue(error?.message ?? 'An un-expected error occurred while making the request.');
        }
    }
)

export const getCompany = createAsyncThunk(
    'companyManagement/getCompany',
    async (id, { rejectWithValue }) => {
        try {
            const response = await service.getCompany(id);
            if (response.status === 200) {
                return response.data;
            }
            return rejectWithValue('An un-expected error occurred while making the request.');
        } catch (error) {
            return rejectWithValue(error?.message ?? 'An un-expected error occurred while making the request.');
        }
    }
)



const companySlice = createSlice({
    name: 'companyManagement',
    initialState,
    reducers: {
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },
        resetStateField: (state, actions) => {
            // reset a specific field like create or edit data to its initial state; this will set a given state field to initial state
            // Usage: dispatch(resetStateField({ state: 'createOrEditData', field: 'companyName' })); 
            // Usage: dispatch(resetStateField({ state: 'mainState', field: 'createOrEditData' }));
            const updateState = actions.payload.state;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: initialState[updateState][field],
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: initialState[field],
                }
            }
        },
        resetCompanyManagementState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            // CREATE COMPANY EXTRA REDUCERS
            .addCase(createCompany.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(createCompany.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.modalMode = 'create';
                state.createOrEditData = { ...action.payload, locationInput: initialState.createOrEditData.locationInput };
            })
            .addCase(createCompany.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
            })

            // EDIT COMPANY EXTRA REDUCERS
            .addCase(editCompany.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(editCompany.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.createOrEditData = { ...action.payload, locationInput: initialState.createOrEditData.locationInput };
            })
            .addCase(editCompany.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
            })

            // Get A COMPANY TO EDIT 
            .addCase(getCompany.pending, (state) => {
                state.modalMode = 'update';
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(getCompany.fulfilled, (state, action) => {
                state.isCreateOrEditModalOpen = true;
                state.isLoading = false;
                state.isError = false;
                state.createOrEditData = { ...action.payload, locationInput: initialState.createOrEditData.locationInput };
            })
            .addCase(getCompany.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
                state.modalMode = 'create';
                state.createOrEditData = initialState.createOrEditData;
            })

            // GET COMPANIES TABLE DATA 
            .addCase(getCompaniesByQuery.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(getCompaniesByQuery.fulfilled, (state, action) => {
                state.isLoading = false;
                state.companiesList = action.payload.companies;
                state.queryData.searchSuccess = action.payload?.companies?.length > 0;
                state.queryData.page = action.payload.page;
                state.queryData.totalCount = action.payload.totalCount;
                state.queryData.totalPages = action.payload.totalPages;

            })
            .addCase(getCompaniesByQuery.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.queryData.searchSuccess = false;
                state.errorMessage = action.payload;
                state.companiesList = [];
                state.queryData.page = '';
                state.queryData.totalCount = '';
                state.queryData.totalPages = '';
            });
    }
})


export const { updateFieldValues, resetCompanyManagementState, resetStateField } = companySlice.actions;
export default companySlice.reducer
