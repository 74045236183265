import React from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from "@mui/material";
import { format } from "date-fns";

const MUIDatePicker = ({ label, value, onChange, required, error, minDate, maxDate, disableFlag, timeZone, onClear }) => {
    const handleClear = () => {
        onClear();
        onChange(null);
    };

    return (
        disableFlag ?
            <TextField fullWidth value={format(new Date(value), "dd/MM/yyyy")} label={label} />
            :
            <DatePicker
                sx={{
                    width: '100%'
                }}
                slotProps={{
                    clearable: true,
                    field: { clearable: true, onClear: handleClear },
                }}
                minDate={minDate}
                maxDate={maxDate}
                label={label}
                inputFormat="DD-MM-YYYY"
                value={value ?? null}
                onChange={onChange}
                timeZone={timeZone}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={required}
                        error={error} // Set error to true when an error occurs
                        fullWidth
                        sx={{ width: '100%' }}
                    />
                )}
                adapter={AdapterDateFns}
            />
    );
};

export default MUIDatePicker;