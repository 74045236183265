import { privateApi } from "../../../../api";


const getUserData = async (userId) => {
    const path = `users/getUserData/${userId}`;
    return await privateApi.get(path);
}

const getTicketsByQuery = async (query) => {
    const path = `tickets/getTicketsByQuery`;
    return await privateApi.post(path, query);
}

export const getSupportAgents = async ()=>{
    const path = `users/getSupportAgents`;
    return await privateApi.get(path);
}
export default {
    getUserData,
    getTicketsByQuery
};