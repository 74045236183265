import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../AdminChatComponent.css';
import { Grid, Typography, Divider, Paper, TextField, Tooltip, Badge } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Android12SwitchComponent from '../../../../components/commons/Switch';
import AllUserChats from './AllUserChats';
import IncomingChats from './IncomingChats';
import MyChats from './MyChats';
import { useSelector } from 'react-redux';
import { privateApi } from '../../../../api';
import { toast } from 'react-toastify';
import { collection, doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove } from "@firebase/firestore";
import { db } from "../../../../utilities/auth-config/firebase-config";

const LeftSideChatSelector = () => {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.userData.data)
    const [currentTab, setCurrentTab] = useState('myChats');
    
    const chatSlice = useSelector((state) => state.chats);


    const unAttendedIncomingChats = useSelector((state) => state.chats.unAttendedChats);
    const acceptedChats = useSelector((state) => state.chats.attendedChats);

    const activeAdminsDoc = doc(db, 'activeAdmins', 'admins');

    useEffect(() => {
        const fetchActiveAdmins = async () => {
            const docSnap = await getDoc(activeAdminsDoc);
            if (docSnap.exists()) {
                const dataToChat = await docSnap.data().admins.includes(sessionUser._id)
                dispatch({ type: 'chats/setIsChatModeOn', payload: dataToChat });
            }
        };
        fetchActiveAdmins();
    }, [sessionUser._id]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleSwitchChange = async (event) => {
        if (acceptedChats.length && !event.target.checked) {
            toast.error('Please end all the active chat sessions before turning off the chat mode!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            return;
        } else {
            dispatch({ type: 'chats/setIsChatModeOn', payload: event.target.checked });
            if (event.target.checked) {
                await setDoc(activeAdminsDoc, {
                    admins: arrayUnion(sessionUser._id)
                }, { merge: true });
            } else {
                await updateDoc(activeAdminsDoc, {
                    admins: arrayRemove(sessionUser._id)
                });
            }
        }
    };


    return (
        <>
            <div className='chat-selector'>
                <Grid container>
                    <Grid item xs={8} sx={{ textAlign: 'center', alignContent: 'center', position: 'sticky', top: 0 }}>
                        <Typography variant="h6" fontWeight={500} component="span">
                            Turn Chat Mode
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ position: 'sticky', top: 5 }}>
                        <Android12SwitchComponent onChange={handleSwitchChange} value={chatSlice.isChatModeOn} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={currentTab}>
                                <Paper
                                    elevation={1}
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                        position: 'sticky',
                                        top: 5,
                                        zIndex: 99,
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        transition: 'box-shadow 0.2s',
                                        '&:hover': {
                                            boxShadow: '3px 15px 15px rgba(0, 0, 0, 0.4)',
                                            cursor: 'pointer',
                                            elevation: 5,
                                            borderBottom: "solid 5px #091B95"
                                        },
                                    }}
                                >
                                    <TabList
                                        onChange={handleTabChange}
                                        aria-label="Chat mode tabs"
                                        variant="fullWidth"
                                        sx={{ padding: 0 }}
                                    >
                                        <Tab
                                            value="myChats"
                                            label={
                                                <Tooltip title="View Attended Chats" arrow placement="left">
                                                    <Badge badgeContent={acceptedChats?.length} color="primary"> {/* Add the Badge component */}
                                                        <Typography variant="subtitle2" fontWeight={600} fontSize={14}>
                                                            My Chats
                                                        </Typography>
                                                    </Badge>
                                                </Tooltip>
                                            }
                                        />
                                        <Tab
                                            label={
                                                <Tooltip title="Un-attended Incoming Chats" arrow placement="top">
                                                    <Badge badgeContent={unAttendedIncomingChats?.length} color="primary"> {/* Add the Badge component */}
                                                        <Typography variant="subtitle2" fontWeight={600} fontSize={14}>
                                                            Incoming&nbsp;&nbsp;&nbsp;
                                                        </Typography>
                                                    </Badge>
                                                </Tooltip>
                                            }
                                            value="unattendedChats"
                                        />
                                        <Tab
                                            label={
                                                <Tooltip title="All users chats" arrow placement="right">
                                                    <Typography variant="subtitle2" fontWeight={600} fontSize={14}>
                                                        All
                                                    </Typography>
                                                </Tooltip>
                                            }
                                            value="all"
                                        />
                                    </TabList>
                                </Paper>
                                <TabPanel value="myChats" sx={{ padding: 0 }}>
                                    <div className='chat-user-list'>
                                        <MyChats />
                                    </div>
                                </TabPanel>
                                <TabPanel value="unattendedChats" sx={{ padding: 0 }}>
                                    <div className='chat-user-list'>
                                        <IncomingChats setCurrentTab={setCurrentTab} />
                                    </div>
                                </TabPanel>

                                <TabPanel value="all" sx={{ padding: 0 }}>
                                    <AllUserChats />
                                </TabPanel>

                            </TabContext>
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
            </div>
        </>
    );
};

export default LeftSideChatSelector;
