import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getTicketForBoardView, getSavedTicket } from '../../app/Ticket/TicketManagement'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
    TableContainer,
    Paper,
    Grid,
    Typography,
    Avatar,
    AvatarGroup,
    Chip,
    Divider,
    Tooltip,
} from "@mui/material";
import PreviewIcon from '@mui/icons-material/Preview';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { toast } from "react-toastify";
import { ticketStatus, statusToListInApprovalBucket, statusToListInApprovalBucketSuperAdmin, statusToListInApprovalBucketCustomerAdmin } from "./TicketStatusArray";
import { stringToColor } from "../../utilities/commonUtils";

import { collection, query, where, onSnapshot, Timestamp } from "@firebase/firestore";
import { db } from "../../utilities/auth-config/firebase-config";

import TicketViewOnlyMode from "./TicketViewOnlyMode"


const BoardView = () => {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.userData.data);


    // STATE DECLARATIONS
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const boardRef = useRef(null);
    const [boardMinHeight, setBoardMinHeight] = useState("");
    const tickets = useSelector((state) => state.ticketManagement.ticketsForTheBoard);
    const ticketViewOnlyModeState = useSelector((state) => state.ticketManagement.viewOnlyTicketModal);
    const errorMessage = useSelector((state) => state.ticketManagement.errorMessage);
    const [lastOpenedTicket, setLastOpenedTicket] = useState('');
    const [boardFilterState, setBoardFilterState] = useState('Assigned to me');

    /**
     * Adjusting the board height 
     * Overriding and hiding the default browser scrollbar
     */
    useEffect(() => {
        document.body.style.overflow = "hidden";
        document.documentElement.style.overflow = "hidden";

        const adjustTableHeight = () => {
            const windowHeight = window.innerHeight;
            const tableContainer = boardRef.current;

            if (tableContainer) {
                const rect = tableContainer.getBoundingClientRect();
                const offset = rect.top;
                const maxHeight = windowHeight - offset - 5;

                tableContainer.style.maxHeight = `${maxHeight}px`;
            }
        };

        adjustTableHeight();
        window.addEventListener("resize", adjustTableHeight);

        dispatch(getTicketForBoardView())


        return () => {
            document.body.style.overflow = "auto";
            document.documentElement.style.overflow = "auto";
            window.removeEventListener("resize", adjustTableHeight);
        };
    }, [sessionUser.franchise]);

    useEffect(() => {
        if (!isMobile) {
            setBoardFilterState('All');
        }
        else setBoardFilterState('Assigned to me')
    }, [isMobile])


    /**
     * Find out the board that has max items and then its height as well
     * finally set that height as min height for all the board for height uniformity
     */
    useLayoutEffect(() => {
        if (tickets?.length) {
            // Calculate the maximum number of items for any ticketStatus
            const maxItems = Math.max(
                ...ticketStatus.map((status) =>
                    tickets.filter((ticket) => (ticket.status === status.value) || (status.value === 'superAdminApprovals' && statusToListInApprovalBucketSuperAdmin.includes(ticket.status))).length
                )
            );
            // Find the status with the maximum items
            const statusWithMaxItems = ticketStatus.find((status) =>
                tickets.filter((ticket) => (ticket.status === status.value) || (status.value === 'superAdminApprovals' && statusToListInApprovalBucketSuperAdmin.includes(ticket.status))).length === maxItems
            );
            // Set the status with max items in state
            if (statusWithMaxItems) {
                // Calculate the height of the Paper component for the max status
                const maxStatusPaper = document.getElementById(statusWithMaxItems.value);
                if (maxStatusPaper) {
                    // Set the height
                    setBoardMinHeight(`${maxStatusPaper.clientHeight}px`);
                }
            }
        }
    });

    /**
     * generate a color code based on the due date for label background
     * @param {*} dueDate 
     * @returns hex colorCode 
     */
    const calculateBackgroundColor = (dueDate) => {
        const today = new Date();
        const due = new Date(dueDate);
        const timeDifference = due - today;
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        if (daysDifference <= 2) {
            return '#ffd6cc'; // Due in the next 2 days or earlier
        } else if (daysDifference <= 5) {
            return '#ffe6cc'; // Due within 3 to 5 days
        } else {
            return '#d9f3d9'; // Due beyond 5 days
        }
    };

    /** Handle Error message display */
    useEffect(() => {
        if (errorMessage)
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 8000,
                style: { width: '400px' },
            });
        // Reset the error message once its rendered
        dispatch({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'mainState',
                field: 'errorMessage',
                value: null,
            },
        });
    }, [errorMessage]);

    // Listen for changes from firebase
    useEffect(() => {
        if (sessionUser.role) { // Ensure sessionUser.role is set
            const currentTimestamp = Timestamp.fromDate(new Date());
            let q;

            if (sessionUser.role === 'customerAdmin') {
                q = query(
                    collection(db, 'tickets'),
                    where('company._id', '==', sessionUser.company._id),
                    where('updatedAt', '>', currentTimestamp)
                );
            } else if (sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin' || sessionUser.role === 'coreAdmin'|| sessionUser.role === 'supportAgent') {
                q = query(
                    collection(db, 'tickets'),
                    where('updatedAt', '>', currentTimestamp)
                );
            } else if (sessionUser.role === 'customerUser') {
                q = query(
                    collection(db, 'tickets'),
                    where('reportedUserIds', 'array-contains-any', [sessionUser._id]),
                    where('updatedAt', '>', currentTimestamp)
                );
            } else if (sessionUser.role === 'technician') {
                q = query(
                    collection(db, 'tickets'),
                    where('assignedUserIds', 'array-contains-any', [sessionUser._id]),
                    where('updatedAt', '>', currentTimestamp)
                );
            }

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const tickets = [];
                let ticketStatusForContinue = true
                snapshot.docChanges().forEach((change) => {
                    if (change.type === 'added' || change.type === 'modified') {
                        let ticketData = change.doc.data();
                        ticketData.createdAt = ticketData.createdAt?.seconds ? new Date(ticketData.createdAt?.seconds * 1000)?.toISOString() : '';
                        ticketData.updatedAt = ticketData?.updatedAt?.seconds ? new Date(ticketData.updatedAt?.seconds * 1000)?.toISOString() : '';
                        // console.log(ticketData, sessionUser.franchise, ticketData.franchise)
                        // console.log(ticketData.franchise)
                        ticketStatusForContinue = sessionUser?.franchise?.area === ticketData?.franchise.area

                        tickets.push(ticketData);
                    }
                });
                if (!ticketStatusForContinue) {
                    return null
                }
                if (tickets.length > 0) {
                    dispatch({
                        type: 'ticketManagement/pushOrUpdateTicketsInTheBoard',
                        payload: tickets,
                    });
                }
            }, (error) => {
                console.error('Error getting snapshots:', error);
            });

            return () => {
                unsubscribe();
            };
        }
    }, [sessionUser.role, db]);

    useEffect(() => {
        if (sessionUser.userType !== 'Admin') {
            setBoardFilterState('All');
        }
    }, [sessionUser?.userType])


    return (
        <>
            <TableContainer component={Paper} sx={{ padding: 0, border: "none", overflow: "hidden" }}>

                <Grid container spacing={2} sx={{ padding: '3px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Chip
                            label="Assigned to me"
                            clickable
                            onClick={() => { setBoardFilterState('Assigned to me') }}
                            color={boardFilterState === 'Assigned to me' ? "primary" : 'secondary'}
                            sx={{ marginRight: '10px' }}
                        />
                        <Chip
                            label="All"
                            clickable
                            onClick={() => { setBoardFilterState('All') }}
                            color={boardFilterState === 'All' ? "primary" : 'secondary'}
                            sx={{ minWidth: '100px', marginRight: '10px' }}
                        />
                        <Chip
                            label="Today"
                            clickable
                            onClick={() => { setBoardFilterState('Today') }}
                            color={boardFilterState === 'Today' ? "primary" : 'secondary'}
                            sx={{ minWidth: '100px', marginRight: '10px' }}
                        />
                    </Grid>
                </Grid>

                <div style={{ overflowY: "auto", maxHeight: "400px" }} className="custom-scrollbar" ref={boardRef}>
                    <Grid container spacing={1.5} sx={{ paddingTop: 0 }}>
                        {/* Loop through each Status option and render it as Header */}
                        {ticketStatus?.map((item) => (
                            <Grid key={`ticket-header-${item.value}`} sx={{ marginTop: "-12px" }} item xs={12} sm={12} md={12} lg={1.712}>
                                <Paper id={item.value} elevation={3} style={{
                                    padding: "16px", paddingTop: 0, minHeight: !isMobile ? boardMinHeight : "",
                                    position: "relative", backgroundColor: "#F7F8F9"
                                }}>
                                    <div style={{
                                        position: 'sticky', top: 0,
                                        backgroundColor: "#F7F8F9", marginBottom: '0', zIndex: 98
                                    }}>
                                        <div style={{
                                            display: "flex", justifyContent: "space-between",
                                            alignItems: "center", color: "#626F86",
                                            position: "sticky", top: 5, marginBottom: '15px'
                                        }}>
                                            <Typography variant="subtitle2" style={{ fontWeight: 600, textTransform: "uppercase", padding: '10px', paddingLeft: 0 }}>
                                                {item.label}
                                            </Typography>
                                            {/* Get the total num of tickets in the current status iteration and render the same */}
                                            <Typography variant="subtitle2" style={{ fontWeight: 600, textTransform: "uppercase", padding: '10px', paddingLeft: 0 }}>
                                                {`(${tickets?.filter((ticket) => (ticket?.status === item.value) || (item.value === 'customerAdminApprovals' && statusToListInApprovalBucketCustomerAdmin.includes(ticket.status)) || (item.value === 'superAdminApprovals' && statusToListInApprovalBucketSuperAdmin.includes(ticket.status)))?.length})`}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider light={true} variant="fullWidth" sx={{ height: '5px' }} />


                                    {/* Now the header is set, lets first filter the tickets array against current header status then map each ticket item */}
                                    {tickets
                                        ?.filter((ticket) =>
                                            (ticket.status === item.value ||
                                                (item.value === 'superAdminApprovals' && statusToListInApprovalBucketSuperAdmin.includes(ticket.status)) ||
                                                (item.value === 'customerAdminApprovals' && statusToListInApprovalBucketCustomerAdmin.includes(ticket.status))) &&
                                            (boardFilterState !== 'Assigned to me' || ticket.assignedUsers.some((user) => user._id === sessionUser._id)) &&
                                            (boardFilterState !== 'Today' ||
                                                (new Date(ticket.createdAt).getDate() === new Date().getDate() &&
                                                    new Date(ticket.createdAt).getMonth() === new Date().getMonth() &&
                                                    new Date(ticket.createdAt).getFullYear() === new Date().getFullYear()))
                                        )
                                        ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                                        ?.map((ticket) => (
                                            <Tooltip key={ticket.customTicketId} title={ticket.ticketHead} placement="bottom">
                                                <Paper key={ticket.customTicketId} elevation={3} sx={{
                                                    padding: "7px",
                                                    marginBottom: "4px",
                                                    position: "relative",
                                                    cursor: "pointer",
                                                    background: lastOpenedTicket === ticket._id ? '#E9F2FF' : "",
                                                    transition: "background-color 0.3s",
                                                    "&:hover": { backgroundColor: lastOpenedTicket === ticket._id ? '#E9F2FF' : "#e9ebee !important" },
                                                }}
                                                    onClick={() => {
                                                        if (isMobile && sessionUser.userType === 'Admin') return;
                                                        setLastOpenedTicket(ticket._id)
                                                        // now lets toggle existingTicketModalOpen redux state to true to open the modal
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'mainState',
                                                                field: 'existingTicketModalOpen',
                                                                value: true,
                                                            },
                                                        });
                                                        // Now lets dispatch the api call fetch the ticket data for the modal
                                                        dispatch(getSavedTicket(ticket.customTicketId))
                                                    }}
                                                >
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                fontWeight: 600,
                                                                flexGrow: 1,
                                                                "&:hover": {
                                                                    cursor: "pointer", // Change cursor on hover
                                                                    textDecoration: "underline", // Add underline on hover
                                                                },
                                                            }}>
                                                            {ticket.ticketHead}
                                                        </Typography>
                                                    </div>
                                                    <Divider sx={{ height: '2px', margin: '8px 0' }} />
                                                    <Grid container spacing={0.6}>
                                                        {
                                                            (item.value === 'superAdminApprovals' || item.value === 'customerAdminApprovals') &&
                                                            <Grid item xs={12}>
                                                                <Chip
                                                                    label={
                                                                        <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '12px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                                            {(() => {
                                                                                switch (ticket.status) {
                                                                                    case 'reqSuperAdminApproval':
                                                                                        return 'Pending SuperAdmin Approval';
                                                                                    case 'reqCustomerAdminApproval':
                                                                                        return 'Pending CustomerAdmin Approval';
                                                                                    case 'customerAdminApproved':
                                                                                        return 'CustomerAdmin Approved';
                                                                                    case 'customerAdminDeclined':
                                                                                        return 'CustomerAdmin Declined';
                                                                                    case 'superAdminApproved':
                                                                                        return 'SuperAdmin Approved';
                                                                                    case 'superAdminDeclined':
                                                                                        return 'SuperAdmin Declined';
                                                                                    default:
                                                                                        return '';
                                                                                }
                                                                            })()}
                                                                        </Typography>
                                                                    }
                                                                    sx={{
                                                                        borderRadius: '5px',
                                                                        padding: '2px ',
                                                                        minWidth: "50px",
                                                                        backgroundColor: (() => {
                                                                            switch (ticket.status) {
                                                                                case 'reqCustomerAdminApproval':
                                                                                    return '#efd3a0'; // Orange color indicating awaiting approval
                                                                                case 'reqSuperAdminApproval':
                                                                                    return '#ffe6cc'; // Light orange for 'reqSuperAdminApproval'
                                                                                case 'customerAdminApproved':
                                                                                    return '#b7e4c7'; // Light green for 'customerAdminApproved'
                                                                                case 'customerAdminDeclined':
                                                                                    return '#f4b0b0'; // Light red for 'customerAdminRejected'
                                                                                case 'superAdminApproved':
                                                                                    return '#b7e4c7'; // Light green for 'superAdminApproved'
                                                                                case 'superAdminDeclined':
                                                                                    return '#f4b0b0'; // Red for 'superAdminRejected'
                                                                                default:
                                                                                    return '#f2f2f2'; // Default light gray color for other cases
                                                                            }
                                                                        })(),
                                                                    }}
                                                                />
                                                            </Grid>
                                                        }

                                                        <Grid item xs={12}>
                                                            <Chip
                                                                label={
                                                                    <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '12px' }}>
                                                                        {ticket.priority.charAt(0).toUpperCase() + ticket.priority.slice(1)}
                                                                    </Typography>
                                                                }
                                                                sx={{
                                                                    borderRadius: '5px',
                                                                    padding: '3px !important',
                                                                    minWidth: "70px",
                                                                    backgroundColor: (() => {
                                                                        switch (ticket.priority) {
                                                                            case 'low':
                                                                                return '#d9f3d9'; // Light green for low priority
                                                                            case 'medium':
                                                                                return '#ffe6cc'; // Light orange for medium priority
                                                                            case 'high':
                                                                                return '#ffd6cc'; // Light red for high priority
                                                                            default:
                                                                                return '#f2f2f2'; // Default light gray color
                                                                        }
                                                                    })(),
                                                                }}
                                                            />
                                                        </Grid>
                                                        {
                                                            ticket?.dueDate &&
                                                            <Grid item xs={12}>
                                                                <Chip
                                                                    label={
                                                                        <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '12px', alignContent: 'left !important' }}>
                                                                            {ticket?.dueDate}
                                                                        </Typography>
                                                                    }
                                                                    sx={{
                                                                        borderRadius: '5px',
                                                                        padding: '0px',
                                                                        backgroundColor: calculateBackgroundColor(ticket.due),
                                                                    }}
                                                                />
                                                            </Grid>
                                                        }
                                                        <Grid item xs={12}>
                                                            <Chip
                                                                label={
                                                                    <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '12px', textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                        {ticket?.company?.companyName}
                                                                    </Typography>
                                                                }
                                                                sx={{
                                                                    borderRadius: '5px',
                                                                    padding: '1px',
                                                                    backgroundColor: '#a1b6de',
                                                                }}
                                                            />
                                                        </Grid>
                                                        {
                                                            ticket?.companyLocation?.locationName ?
                                                                <Grid item xs={12}>
                                                                    <Chip
                                                                        label={
                                                                            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '9px', textAlign: 'left', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                                                {ticket.companyLocation.locationName}
                                                                            </Typography>
                                                                        }
                                                                        sx={{
                                                                            borderRadius: '5px',
                                                                            maxHeight: '18px !important',
                                                                            backgroundColor: '#d3d3d3', // Light grey color
                                                                        }}
                                                                    />
                                                                </Grid> : null
                                                        }

                                                        {
                                                            item.value === 'done' && ticket?.resolutionTimeline ?
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        sx={{
                                                                            fontSize: '0.7rem',
                                                                            color: 'text.primary',
                                                                            backgroundColor: 'lightblue',
                                                                            padding: '0.2rem',
                                                                            borderRadius: '0.25rem',
                                                                            width: 'fit-content'
                                                                        }}
                                                                    >
                                                                        {`Resolution time: ${ticket?.resolutionTimeline}`}
                                                                    </Typography>
                                                                </Grid> : null
                                                        }
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                                                                {`Created At: ${new Date(ticket?.createdAt).toLocaleString()}`}
                                                            </Typography>
                                                        </Grid>
                                                        {
                                                            isMobile && sessionUser.userType === 'Admin' && (
                                                                <>
                                                                    <Grid item xs={12} container>
                                                                        <Grid item xs={12}>
                                                                            <Divider sx={{ height: '2px', margin: '8px 0' }} />
                                                                        </Grid>
                                                                        <Chip
                                                                            icon={<EditOutlinedIcon />}
                                                                            label="Edit"
                                                                            clickable
                                                                            color="primary"
                                                                            variant="outlined"
                                                                            sx={{ borderRadius: '5px', marginRight: '3px' }}
                                                                            onClick={() => {
                                                                                setLastOpenedTicket(ticket._id)
                                                                                dispatch({
                                                                                    type: 'ticketManagement/updateFieldValues',
                                                                                    payload: {
                                                                                        state: 'mainState',
                                                                                        field: 'existingTicketModalOpen',
                                                                                        value: true,
                                                                                    },
                                                                                });
                                                                                // Now lets dispatch the api call fetch the ticket data for the modal
                                                                                dispatch(getSavedTicket(ticket.customTicketId))
                                                                            }}
                                                                        />
                                                                        <Chip
                                                                            icon={<PreviewIcon />}
                                                                            label="View"
                                                                            clickable
                                                                            color="secondary"
                                                                            variant="outlined"
                                                                            sx={{ borderRadius: '5px' }}
                                                                            onClick={() => {
                                                                                setLastOpenedTicket(ticket._id)
                                                                                dispatch({
                                                                                    type: 'ticketManagement/updateFieldValues',
                                                                                    payload: {
                                                                                        state: 'mainState',
                                                                                        field: 'viewOnlyTicketModal',
                                                                                        value: true,
                                                                                    },
                                                                                });
                                                                                // Now lets dispatch the api call fetch the ticket data for the modal
                                                                                dispatch(getSavedTicket(ticket.customTicketId))
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            )
                                                        }
                                                    </Grid>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                                                        <Typography variant="subtitle2" style={{ color: "#626F86", fontWeight: 600, textTransform: "uppercase", padding: '3px' }}>
                                                            ID: {ticket.customTicketId}
                                                        </Typography>
                                                        <AvatarGroup
                                                            max={3}
                                                        >
                                                            {
                                                                ticket?.assignedUsers?.length && ticket?.assignedUsers?.map((user) => {
                                                                    const { displayName, firstName, lastName, photoURL } = user;
                                                                    const fullName = (firstName && lastName) ? `${firstName} ${lastName}` : displayName;
                                                                    return (
                                                                        <Avatar
                                                                            key={user._id}
                                                                            alt="User Avatar"
                                                                            src={user.photoURL}
                                                                            sx={{
                                                                                width: '32px',
                                                                                height: '32px',
                                                                                backgroundColor: user.photoURL ? 'transparent' : stringToColor(fullName),
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            {(!user.photoURL && (user.displayName || user.firstName)) ? (
                                                                                user.displayName
                                                                                    ? user.displayName[0]
                                                                                    : user.firstName[0]
                                                                            ) : null}
                                                                        </Avatar>
                                                                    )
                                                                })
                                                            }
                                                        </AvatarGroup>
                                                    </div>
                                                </Paper>
                                            </Tooltip>
                                        ))}
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </TableContainer>
            {
                ticketViewOnlyModeState && <TicketViewOnlyMode />
            }
        </>
    );
};

export default BoardView;