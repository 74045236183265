import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Button from "@mui/material/Button"; // Import Button from Material-UI
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import BusinessIcon from '@mui/icons-material/Business';
import InventoryIcon from '@mui/icons-material/Inventory';
import { AddBusiness, SystemUpdateAltOutlined } from "@mui/icons-material";


const themeColor = "#3f51b5";
const hoverColor = "#091B95";
const hoverColorActive = "#2130b9";
const textColor = "white";

const SidebarContainer = styled(Drawer)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    gridArea: "sidebar",
    width: 240,
    padding: "10px",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        width: 240,
        boxSizing: "border-box",
        background: themeColor,
    },
}));

const SidebarContent = styled("div")({
    marginTop: "70px",
    padding: '10px',
    flex: 1,
});

const StyledButton = styled(Button)(({ theme, isActive }) => ({
    width: "100%", // Extend the width to the full sidebar width
    marginBottom: "4px",
    color: textColor,
    background: isActive ? hoverColorActive : "transparent",
    "&:hover": {
        background: hoverColor, // Use the new hover color
        color: "white", // Change text color on hover
        "& .MuiTouchRipple-root": {
            borderRadius: 0, // Remove the border-radius to make the hover color extend till the end
        },
    },
}));

const StyledListItemIcon = styled(ListItemIcon)({
    color: "white",
});

const StyledListItemText = styled(ListItemText)({
    color: "white",
});

const Divider = styled("hr")({
    backgroundColor: "white",
    opacity: 0.1,
    margin: "0px 0",
});

const AppSidebar = ({ isMobile, setDrawerOpen }) => {
    const userType = useSelector((state) => state.userData.data.userType);
    const userRole = useSelector((state) => state.userData.data.role);
    const sessionUser = useSelector((state) => state.userData.data);

    const navigate = useNavigate();
    const location = useLocation();

    const [activeItems, setActiveItems] = useState({});

    const isItemActive = (path) => {
        return location.pathname === path;
    };

    useEffect(() => {
        const updatedActiveItems = {};
        updatedActiveItems['/'] = isItemActive('/');
        updatedActiveItems['/Support'] = isItemActive('/Support');
        updatedActiveItems['/enquiry-management'] = isItemActive('/enquiry-management');
        updatedActiveItems['/tickets'] = isItemActive('/tickets');
        updatedActiveItems['/User-Management'] = isItemActive('/User-Management');
        updatedActiveItems['/File-Downloads'] = isItemActive('/File-Downloads');
        updatedActiveItems['/Company-Management'] = isItemActive('/Company-Management');
        updatedActiveItems['/Settings'] = isItemActive('/Settings');
        updatedActiveItems['/Asset-Management'] = isItemActive('/Asset-Management');
        updatedActiveItems['/franchise'] = isItemActive('/franchise');
        updatedActiveItems['/export-assets-management'] = isItemActive('/export-assets-management');
        updatedActiveItems['/it-amc-Management'] = isItemActive('/it-amc-Management');

        setActiveItems(updatedActiveItems);
    }, [location.pathname]);

    const handleNavigation = (path) => {
        navigate(path);
        if (isMobile && setDrawerOpen) setDrawerOpen(false);
    }
    return (
        <SidebarContainer variant="permanent" anchor="left">
            <SidebarContent>
                <List>
                    <StyledButton component="li" isActive={activeItems['/']} onClick={() => handleNavigation('/')}>
                        <StyledListItemIcon>
                            <DashboardIcon />
                        </StyledListItemIcon>
                        <StyledListItemText primary="Dashboard" />
                    </StyledButton>
                    <Divider />
                    {['superAdmin', 'coreAdmin', 'supportAgent'].includes(userRole) && !isMobile && (
                        <>
                            <StyledButton component="li" isActive={activeItems['/Support']} onClick={() => handleNavigation('/Support')}>
                                <StyledListItemIcon >
                                    <MarkUnreadChatAltIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary="Support" />
                            </StyledButton>
                            <Divider />
                            <StyledButton component="li" isActive={activeItems['/enquiry-management']} onClick={() => handleNavigation('/enquiry-management')}>
                                <StyledListItemIcon >
                                    <MarkUnreadChatAltIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary="Enquiry Support" />
                            </StyledButton>
                            <Divider />
                        </>
                    )}
                    <StyledButton component="li" isActive={activeItems['/tickets']} onClick={() => handleNavigation('/tickets')}>
                        <StyledListItemIcon>
                            <ReportProblemIcon />
                        </StyledListItemIcon>
                        <StyledListItemText primary="Tickets" />
                    </StyledButton>
                    <Divider />

                    {['superAdmin', 'customerAdmin', 'technicalAdmin', 'technician', 'coreAdmin', 'supportAgent'].includes(userRole) && (
                        <>
                            <StyledButton component="li" isActive={activeItems['/User-Management']} onClick={() => handleNavigation('/User-Management')}>
                                <StyledListItemIcon>
                                    <SupervisedUserCircleIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary="Users" />
                            </StyledButton>
                            <Divider />
                        </>
                    )}
                    {['superAdmin', 'technicalAdmin', 'technician', 'coreAdmin', 'supportAgent'].includes(userRole) && (
                        <>
                            <StyledButton component="li" isActive={activeItems['/Company-Management']} onClick={() => handleNavigation('/Company-Management')}>
                                <StyledListItemIcon>
                                    <BusinessIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary="Companies" />
                            </StyledButton>
                            <Divider />
                        </>
                    )}
                    {userType === 'Admin' && (
                        <>
                            <StyledButton component="li" isActive={activeItems['/Asset-Management']} onClick={() => handleNavigation('/Asset-Management')}>
                                <StyledListItemIcon>
                                    <InventoryIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary="Assets" />
                            </StyledButton>
                            <Divider />
                        </>
                    )}
                    {userType === 'Admin' && (
                        <>
                            <StyledButton component="li" isActive={activeItems['/it-amc-Management']} onClick={() => handleNavigation('/it-amc-Management')}>
                                <StyledListItemIcon>
                                    <InventoryIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary="IT AMC " />
                            </StyledButton>
                            <Divider />
                        </>
                    )}
                    {['coreAdmin'].includes(userRole) && (
                        <>
                            <StyledButton component="li" isActive={activeItems['/franchise']} onClick={() => handleNavigation('/franchise')}>
                                <StyledListItemIcon>
                                    <AddBusiness />
                                </StyledListItemIcon>
                                <StyledListItemText primary="Franchise" />
                            </StyledButton>
                            <Divider />
                        </>
                    )}
                    {['superAdmin', 'coreAdmin'].includes(userRole) && (
                        <>
                            <StyledButton component="li" isActive={activeItems['/Settings']} onClick={() => handleNavigation('/Settings')}>
                                <StyledListItemIcon>
                                    <SettingsIcon />
                                </StyledListItemIcon>
                                <StyledListItemText primary="Settings" />
                            </StyledButton>
                            <Divider />
                        </>
                    )}
                    {sessionUser.isEditExportAsset && (
                        <>
                            <StyledButton component="li" isActive={activeItems['/export-assets-management']} onClick={() => handleNavigation('/export-assets-management')}>
                                <StyledListItemIcon>
                                    <SystemUpdateAltOutlined />
                                </StyledListItemIcon>
                                <StyledListItemText primary="Export Assets" />
                            </StyledButton>
                            <Divider />
                        </>
                    )}


                    {/* <StyledButton component="li">
                        <StyledListItemIcon>
                            <SettingsIcon />
                        </StyledListItemIcon>
                        <StyledListItemText primary="Settings" />
                    </StyledButton>
                    <StyledButton component="li" isActive={activeItems['/File-Downloads']} onClick={() => handleNavigation('/File-Downloads')}>
                        <StyledListItemIcon>
                            <CloudDownloadIcon />
                        </StyledListItemIcon>
                        <StyledListItemText primary="Downloads" />
                    </StyledButton>
                    <Divider /> */}
                </List>
            </SidebarContent>
        </SidebarContainer>
    );
};

export default AppSidebar;
