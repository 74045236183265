import { privateApi, publicApi } from "../../api";

export const createNewFranchise = async (data) => {
    return await privateApi.post('franchise/createFranchise', data);
};


export const getAllFranchisesForRegister = async ()=>{
    // return await publicApi.get('franchise/getFranchiseForRegister')
    return await publicApi('get','franchise/getFranchiseForRegister' )
}


export const getAllFranchiseData  = async () => {
    return await privateApi.get('franchise/getAllFranchises')
}


export const updateFranchise = async (data) => {
    return await privateApi.put('users/changeFranchiseOfCoreAdmin',data)
}

export const updateFranchiseData = async (data,franchiseId) => {
    return await privateApi.put(`franchise/updateFranchise/${franchiseId}`,data)
}