import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Avatar, Box, Tooltip, Collapse, IconButton, Typography, useMediaQuery, Paper, Pagination, Grid } from '@mui/material';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { useTheme } from '@mui/material/styles';
import { Timeline, TimelineSeparator, TimelineConnector, TimelineContent } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UserChip from '../../components/commons/UserChip';
import service from '../../app/Asset/service';
import { keyToLabelMap } from '../../constants/commonConstants';
import SelectInput from '../../components/commons/SelectInput';
import { stringToColor } from '../../utilities/commonUtils';

const AssetHistoryView = ({ assetId }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(5);


    useEffect(() => {
        if (open && assetId) {
            const getAssetLogs = async () => {
                const queryData = { assetId, page, limit };
                const response = await service.getAssetLogs(queryData);
                setLogs(response?.data.assetLogs || []);
                setTotalPages(response?.data.totalPages);
            };
            getAssetLogs();
        }
    }, [open, assetId, page, limit]);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
    };

    function getLabel(key) {
        return keyToLabelMap[key] || key;
    }

    function formatChange(key, change) {
        const dateFields = ['lastInvoicedDate', 'nextInvoiceDate', 'lastService', 'nextService'];
        const userFields = ['lastServiceBy', 'location', 'lastInvoiceBy'];

        if (dateFields.includes(key)) {
            return new Date(change).toLocaleDateString();
        } else if (userFields.includes(key) && typeof change === 'object') {
            return `${change?.firstName} ${change?.lastName}`;
        } else if (key === 'users' && Array.isArray(change)) {
            return change.map(user => `${user?.firstName} ${user?.lastName}`).join(', ');
        } else if (key === 'company' && typeof change === 'object') {
            return `${change?.companyName}, ${change?.address?.streetLine1}, ${change?.address?.city}, ${change?.address?.stateProvinceRegion}, ${change.address?.country}`;
        } else if (key === 'customFieldValues' && Array.isArray(change)) {
            return change.map(field => `${field.label}: ${field.value}`).join(', ');
        } else if (key === 'tonerStocksAtClient' && typeof change === 'object') {
            return Object.entries(change)
                .filter(([_, value]) => value) // filter out empty values
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ');
        } else {
            return change;
        }
    }

    const handleLimitChange = (event) => {
        event.stopPropagation();
        setLimit(event.target.value);
        setPage(1);
    }

    const handlePageChange = (event, value) => {
        event.stopPropagation();
        setPage(value);
    };

    return (
        <Box sx={{ marginBottom: 2, width: '100%', padding: isMobile ? 2 : 3 }}>
            <Alert
                severity="info"
                action={
                    <IconButton
                        aria-label="expand or shrink"
                        color="inherit"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />}
                    </IconButton>
                }
            >
                <AlertTitle>
                    <strong>Action Logs</strong>
                </AlertTitle>
                <Collapse in={open}>
                    {
                        open && (
                            <Grid container display={'flex'} justifyContent="end">
                                <div style={{ width: '20%', }}>
                                    <SelectInput
                                        name={'limit'}
                                        label={'Limit'}
                                        value={limit}
                                        options={[{ key: '5', name: '5' }, { key: '10', name: '10' }, { key: '20', name: '20' }, { key: '50', name: '50' }, { key: '100', name: '100' }]}
                                        onChange={handleLimitChange}
                                        xs={1}
                                    /></div>
                            </Grid>

                        )
                    }
                    <Timeline
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                        }}
                    >
                        {logs?.map((log, index) => (
                            <Tooltip key={log._id} title={formatDate(log.updatedAt)} placement="top" arrow>
                                <TimelineItem key={log._id}>
                                    <TimelineSeparator>
                                        <Avatar
                                            alt="User Avatar"
                                            src={log?.updatedBy?.photoURL}
                                            sx={{
                                                backgroundColor: log?.updatedBy?.photoURL ? 'transparent' : stringToColor(((firstName, lastName, displayName) => (firstName && lastName) ? `${firstName} ${lastName}` : displayName)(log?.updatedBy?.firstName, log?.updatedBy?.lastName, log?.updatedBy?.displayName)),
                                                color: 'white',
                                                width: isMobile ? 30 : 50,
                                                height: isMobile ? 30 : 50,
                                            }}
                                        >
                                            {!log?.updatedBy?.photoURL && (((firstName, lastName, displayName) => (firstName && lastName) ? `${firstName} ${lastName}` : displayName)(log?.updatedBy?.firstName, log?.updatedBy?.lastName, log?.updatedBy?.displayName)).charAt(0)}
                                        </Avatar>
                                        {index < logs.length - 1 && <TimelineConnector />}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Box paddingX={2} paddingTop={0.5}>
                                            {(typeof log?.changes !== 'string' ? Object.entries(log?.changes || { "No Changes Made": '' }) : []).map(([key, value]) => (
                                                <Paper elevation={1} style={{ padding: 0.5, marginBottom: 0.5, background: 'transparent' }} key={key}>
                                                    <Typography variant={isMobile ? "caption" : "body1"} component="span">
                                                        <strong>{getLabel(key)}: {' '}</strong>
                                                    </Typography>
                                                    <Typography variant={isMobile ? "caption" : "body1"} component="span">
                                                        {formatChange(key, value)}
                                                    </Typography>
                                                </Paper>
                                            ))}
                                            {
                                                typeof log?.changes === 'string' &&
                                                <Paper elevation={1} style={{ padding: 0.5, marginBottom: 0.5, background: 'transparent' }}>
                                                    <Typography variant={isMobile ? "caption" : "body1"} component="span">
                                                        <strong>{'Asset Movement'}: {' '}</strong>
                                                    </Typography>
                                                    <Typography variant={isMobile ? "caption" : "body1"} component="span">
                                                        {log?.changes}
                                                    </Typography>
                                                </Paper>
                                            }
                                        </Box>
                                    </TimelineContent>
                                </TimelineItem>
                            </Tooltip>
                        ))}
                    </Timeline>
                    {
                        totalPages > 1 &&
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                        </Grid>
                    }
                </Collapse>
            </Alert>
        </Box>
    );
};

export default AssetHistoryView;