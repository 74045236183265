// import React from "react";
// import {
//     FormControl,
//     InputLabel,
//     Select,
//     MenuItem,
//     FormHelperText,
// } from "@mui/material";

// const SelectBox = ({
//     label,
//     options,
//     value,
//     onChange,
//     helperText,
//     error,
//     required,
//     name,
//     defaultValue,
//     disabled,
//     color
// }) => {
//     return (
//         <FormControl fullWidth variant="outlined">
//             <InputLabel
//                 required={required ?? false}
//                 sx={{
//                     color: disabled ? 'rgba(0, 0, 0, 0.38)' : undefined,
//                 }}
//             >
//                 {label}
//             </InputLabel>
//             <Select
//                 value={value}
//                 name={name}
//                 color={color ?? null}
//                 onChange={onChange}
//                 disabled={disabled ?? false}
//                 label={label}
//                 error={error ?? false}
//                 defaultValue={defaultValue ?? ""}
//             >
//                 {options.map((option) => (
//                     <MenuItem key={option.key} value={option.key} disabled={option.disabled}>
//                         {option.name}
//                     </MenuItem>
//                 ))}
//             </Select>
//             {helperText && <FormHelperText>{helperText}</FormHelperText>}
//         </FormControl>
//     );
// };

// export default SelectBox;

import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const commonTextInput = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
      borderWidth: "1px",
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "black",
    },
  },
};

const SelectBox = ({
  label,
  options,
  value,
  onChange,
  helperText,
  error,
  required,
  name,
  defaultValue,
  disabled,
  color,
  sx,
}) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{ ...commonTextInput, ...sx }}
    >
      <InputLabel
        required={required ?? false}
        sx={{
          color: disabled ? "rgba(0, 0, 0, 0.38)" : undefined,
        }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        name={name}
        color={color ?? null}
        onChange={onChange}
        disabled={disabled ?? false}
        label={label}
        error={error ?? false}
        defaultValue={defaultValue ?? ""}
      >
        {options.map((option) => (
          <MenuItem
            key={option.key}
            value={option.key}
            disabled={option.disabled}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectBox;
