import React, { useState, useEffect } from 'react';
import { privateApi } from '../../api';
import Table from "../../components/commons/Table/Table";
import { Grid, Button, useMediaQuery, useTheme, Dialog, DialogActions, DialogContent, DialogTitle, Pagination, Stack, TextField, Divider, Autocomplete } from '@mui/material';
import SingleUserSelector from '../../components/commons/SingleUserSelector/SingleUserSelector';
import MUIDatePicker from '../../components/commons/DatePicker/DatePicker';
import { toast } from 'react-toastify';
import SelectInput from '../../components/commons/SelectInput';
import { printerInvoiceStatusOptions } from '../../constants/commonConstants';
import { getCompaniesList } from '../UserMangaement/Service';

const tableColumns = [
    { field: "invoiceDate", header: "Date", type: "date", colSize: "medium" },
    { field: "invoiceNumber", header: "Invoice Number", type: "text", colSize: "medium" },
    { field: "colorCountAtInvoice", header: "Color Count", type: "text", colSize: "medium" },
    { field: "blackCountAtInvoice", header: "Black Count", type: "text", colSize: "medium" },
    { field: "invoiceStatusLabel", header: "Status", type: "text", colSize: "medium" },
    { field: "invoicedBy", header: "Invoiced By", type: "user", colSize: "medium" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
];

const PrinterInvoiceBreakUp = ({ id, selectedCompanyId, printerType }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [invoices, setInvoices] = useState([]);
    const [inputData, setInputData] = useState({
        id: '', // mongo_id - if its an update input
        invoiceDate: null, // Date string
        invoiceNumber: '', // String
        countAtInvoice: '', // Num
        invoicedBy: null, // userId
        company: null,
    });
    const [page, setPage] = useState(1);
    const limit = 4;
    const [totalPages, setTotalPages] = useState(1);

    const [open, setOpen] = useState(false);

    const [companiesList, setCompaniesList] = useState([]);

    useEffect(() => {
        getInvoicedItems();
    }, [id, page]);

    useEffect(() => {
        getCompaniesList()
            .then((result) => {
                const createCompanyObject = (item) => ({ value: item._id, label: item.companyName, type: item.type });

                if (Array.isArray(result?.data)) {
                    const companies = result.data.map(createCompanyObject);
                    setCompaniesList(companies);
                } else if (result.data) {
                    const company = createCompanyObject(result.data);
                    setCompaniesList([company]);
                }
            });
    }, [open && !companiesList.length]);

    async function addInvoicedItem() {
        try {
            const data = {
                assetId: id,
                invoicedItem: {
                    invoiceDate: inputData.invoiceDate, // required
                    invoiceNumber: inputData.invoiceNumber, // required
                    blackCountAtInvoice: inputData.blackCountAtInvoice, // required
                    colorCountAtInvoice: inputData.colorCountAtInvoice,
                    invoiceStatus: inputData.invoiceStatus,
                    invoicedBy: inputData.invoicedBy._id,
                    company: inputData.company?.value
                },
                clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }

            if (!data.assetId || !data.invoicedItem?.invoiceDate || !data.invoicedItem?.invoiceNumber || !data.invoicedItem?.invoiceStatus || !data.invoicedItem?.invoicedBy) {
                toast.error('Please fill all the required fields.');
                return;
            }
            const response = await privateApi.post('assets/addInvoicedItem', data);

            if (response.status === 201) {
                toast.success('Invoice item saved successfully and added to the list.', {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: false,
                    draggable: true,
                    style: { width: '600px' },
                });
                setOpen(false);
                setInputData({
                    id: '', // mongo_id - if its an update input
                    invoiceDate: null, // Date string
                    invoiceNumber: '', // String
                    blackCountAtInvoice: '', // required
                    colorCountAtInvoice: '',
                    invoicedBy: null, // userId
                    company: null
                })
                setInvoices([{ ...response.data?.invoiceItem }, ...invoices])
            } else {
                toast.error('An error occurred while saving the invoice item.', {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: false,
                    draggable: true,
                    style: { width: '600px' },
                })
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function updateInvoicedItem() {
        try {
            const { _id, invoiceDate, invoiceNumber, countAtInvoice, invoicedBy, invoiceStatus, colorCountAtInvoice, blackCountAtInvoice } = inputData;
            const data = {
                assetId: id,
                invoicedItemId: _id,
                invoiceItem: {
                    invoiceDate,
                    invoiceNumber,
                    countAtInvoice,
                    invoiceStatus,
                    blackCountAtInvoice,
                    colorCountAtInvoice,
                    invoicedBy: invoicedBy._id,
                    company: inputData.company?.value
                },
                clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            };
            if (!data.assetId || !data.invoicedItemId || !data.invoiceItem?.invoiceStatus || !data.invoiceItem?.invoicedBy) {
                toast.error('Please fill all the required fields.', {
                    position: toast.POSITION.TOP_CENTER,
                    closeOnClick: false,
                    draggable: true,
                    style: { width: '600px' },
                });
                return;
            }
            const response = await privateApi.put('assets/updateInvoicedItem', data);
            const updatedItemIndex = invoices.findIndex(item => item._id === response?.data?.invoiceItem?._id);
            const newData = invoices;
            newData[updatedItemIndex] = response?.data?.invoiceItem
            setOpen(false);

            toast.success('Invoiced item updated successfully', {
                position: toast.POSITION.TOP_CENTER,
                closeOnClick: false,
                draggable: true,
                style: { width: '600px' },
            });
        } catch (error) {
            toast.error('Failed to update invoiced item: ' + JSON.stringify(error), {
                position: toast.POSITION.TOP_CENTER,
                closeOnClick: false,
                draggable: true,
                style: { width: '600px' },
            })
        }
    }


    async function getInvoicedItems() {
        try {
            const response = await privateApi.get(`assets/getInvoicedItems/?assetId=${id}&page=${page}&limit=${limit}`);
            if (response.data) {
                setInvoices(response.data.invoicedItems);
                setTotalPages(response.data.totalPages);
            } else {
                setInvoices([]);
                setTotalPages(1);
            }
        } catch (error) {
            toast.error('An error occurred while fetching the printer invoice table data.', {
                position: toast.POSITION.TOP_CENTER,
                closeOnClick: false,
                draggable: true,
                style: { width: '600px' },
            })
            setInvoices([]);
            setTotalPages(1);
        }
    }


    async function deleteInvoicedItem(invoiceId) {
        try {
            await privateApi.delete(`assets/deleteInvoicedItem/${invoiceId}`);
            getInvoicedItems();
        } catch (error) {
            console.error(error);
        }
    }

    const handleOpen = (invoice) => {
        setInputData({...invoice, id: invoice._id});
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setInputData({ ...inputData, [event.target.name]: event.target.value });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleNewEntry = () => {
        setInputData({
            id: '', // mongo_id - if its an update input
            invoiceDate: null, // Date string
            invoiceNumber: '', // String
            blackCountAtInvoice: '', // required
            colorCountAtInvoice: '',
            invoiceStatus: null,
            invoicedBy: null, // userId
            company: companiesList.find((item) => item?.value === selectedCompanyId)
        });
        setOpen(true);
    };

    return (
        <div>
            <Button sx={{marginBottom: '5px'}} variant="contained" color="primary" onClick={handleNewEntry}>Add Invoice Entry</Button>

            {
                invoices.length > 0 && (
                    <>
                        <Table
                            data={invoices}
                            columns={tableColumns}
                            onButtonClick={handleOpen}
                            autoTableHeight={true}
                            mobileOnlyColumns={tableColumns}
                            firstColumnSticky={isMobile}
                        />
                        <Stack spacing={2} sx={{ marginTop: '25px', marginBottom: '15px' }}>
                            <Pagination
                                page={page}
                                count={totalPages}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                                onChange={handlePageChange}
                            />
                        </Stack>
                    </>
                )
            }
            
            <Dialog maxWidth="md" open={open} onClose={handleClose}>
                <DialogTitle>{inputData.id ? 'Edit Invoice' : 'New Invoice'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid mt={2} item xs={12} sm={6}>
                            <MUIDatePicker
                                name="invoiceDate"
                                label="Date"
                                value={isNaN(Date.parse(inputData.invoiceDate)) ? null : new Date(inputData.invoiceDate)}
                                onChange={(e) => {
                                    setInputData({...inputData, invoiceDate: e})
                                }}
                                onClear={() => {
                                    setInputData({ ...inputData, invoiceDate: null })
                                }}
                                maxDate={new Date()}
                                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                required={true}
                                fullWidth
                            />
                        </Grid>
                        <Grid mt={2} item xs={12} sm={6}>
                            <TextField required type='number' name="blackCountAtInvoice" label="Black Count at Invoice" value={inputData.blackCountAtInvoice} onChange={handleChange} fullWidth />
                        </Grid>
                        {
                            printerType === 'color' &&
                            <Grid item xs={12} sm={6}>
                                <TextField required type='number' name="colorCountAtInvoice" label="Color Count at Invoice" value={inputData.colorCountAtInvoice} onChange={handleChange} fullWidth />
                            </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                            <TextField required={true} name="invoiceNumber" label="Invoice Number" value={inputData.invoiceNumber} onChange={handleChange} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectInput
                                required={true}
                                name={'invoiceStatus'}
                                label="Invoice Status"
                                value={inputData.invoiceStatus}
                                error={!inputData.invoiceStatus}
                                options={printerInvoiceStatusOptions}
                                onChange={(e) => { setInputData({ ...inputData, invoiceStatus: e.target.value }) }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SingleUserSelector required={true} name="invoicedBy" label="Invoiced By" value={inputData.invoicedBy} onChange={(value) => {setInputData({...inputData, invoicedBy: value})}} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                fullWidth={true}
                                label="Invoiced To"
                                name='company'
                                value={inputData.company || null}
                                onChange={(e, newValue) => { setInputData({ ...inputData, company: newValue }) }}
                                options={companiesList}
                                renderInput={(params) => <TextField {...params} label="Company" required />}
                                required
                                error={!inputData.company}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={inputData.id ? updateInvoicedItem : addInvoicedItem}>{inputData.id ? 'Update' : 'Save'}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PrinterInvoiceBreakUp;