import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {  Paper, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Badge from '@mui/material/Badge';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Chip} from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from "react-toastify";
import FilterListIcon from '@mui/icons-material/FilterList';
import BoardView from './BoardView';
import { getSavedTicket } from "../../app/Ticket/TicketManagement"
import AdvancedTicketSearch from './TicketQuickSearch/AdvancedTicketSearch';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';


function UserManagement() {
    const sessionUser = useSelector((state) => state.userData.data);
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint as needed
    const [currentTab, setCurrentTab] = useState('boardView'); // boardView, tableView, analytics
    const [filterCountForBadge, setFilterCountForBadge] = useState(0);
    const [previousTab, setPreviousTab] = useState('');
    const permissionErrorOnOpeningTicket = useSelector((state) => state.ticketManagement.existingTicket.errorMessage);
    const quickSearchModalOpen = useSelector((state) => state.ticketManagement.ticketQuickSearch.isModalOpen)

    const handleTabChange = (event, newValue = 'boardView') => {
        setCurrentTab(newValue);
    };

    let { id } = useParams();

    useEffect(() => {
        // If user trying to access a ticket through URL Params then dispatch the url param id to redux and then to evoke the api
        if (id) {
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'mainState',
                    field: 'existingTicketModalOpen',
                    value: true,
                },
            });
            dispatch(getSavedTicket(id))
        }
        
    }, []);

    // Display error message when user try to access the ticket they do not have access through URL Params
    useEffect(() => {
        if (permissionErrorOnOpeningTicket === "Sorry, you do not have permission to access this ticket.") {
            toast.error(permissionErrorOnOpeningTicket, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
        };
        dispatch({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'existingTicket',
                field: 'errorMessage',
                value: true,
            },
        });
    }, [permissionErrorOnOpeningTicket])

    return (
        <div>
            <TabContext value={currentTab}>
                <Box sx={{ width: isMobile ? '100%' : '65%', typography: 'body1', display: 'flex', flexDirection: 'column' }}>
                    <Paper
                        elevation={1}
                        sx={{
                            borderColor: 'divider',
                            zIndex: 99,
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            transition: 'box-shadow 0.3s',
                            '&:hover': {
                                boxShadow: '3px 15px 15px rgba(0, 0, 0, 0.4)',
                                cursor: 'pointer',
                                elevation: 5,
                            },
                        }}
                    >
                        <TabList
                            onChange={handleTabChange}
                            aria-label="Tickets Management"
                            variant={isMobile ? 'scrollable' : 'fullWidth'}
                            allowScrollButtonsMobile={isMobile ? true : false}
                            orientation={'horizontal'}
                            sx={{
                                '& .MuiTab-root': {
                                    padding: '0 16px',
                                    minHeight: '48px',
                                    maxHeight: '48px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:not(:last-child)': {
                                        borderRight: '1px solid #ccc', // Apply border to all but the last child
                                    },
                                },
                                '& .Mui-selected': {
                                    borderBottom: 'solid 5px #97C3F0',
                                    backgroundColor: '#f5f5f5',
                                },
                            }}
                        >
                            <Tab
                                value="boardView"
                                label={
                                    <div id='boardView' style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                        <Tooltip title="Tickets Board View" arrow placement="left">
                                            <Chip
                                                label="Board"
                                                clickable={false}
                                                icon={<GridViewRoundedIcon style={{ color: '#fff' }} />} 
                                                sx={{
                                                    marginRight: '10px', // Add margin
                                                    borderRadius: '8px',
                                                    width: '100px', // Increase the width
                                                    backgroundColor: 'primary.main', // Change the color
                                                    color: '#fff', // Change the color of the text
                                                    '&:hover': {
                                                        backgroundColor: 'primary',
                                                    },
                                                }}
                                            />
                                        </Tooltip>
                                        {currentTab === 'boardView' && (
                                            <Tooltip title="Advanced Tickets Management (ctr + shift + k)" arrow placement="top">
                                                <Chip
                                                    label="Reports"
                                                    clickable
                                                    color="secondary"
                                                    onClick={() => {
                                                        if (['technicalAdmin', 'superAdmin', 'technician' , 'coreAdmin'].includes(sessionUser.role)) {
                                                            dispatch({
                                                                type: 'ticketManagement/updateFieldValues',
                                                                payload: {
                                                                    state: 'ticketQuickSearch',
                                                                    field: 'isModalOpen',
                                                                    value: !quickSearchModalOpen,
                                                                },
                                                            });
                                                        } else {
                                                            toast.error('No permission to access the reports module.', {
                                                                position: toast.POSITION.TOP_CENTER,
                                                                autoClose: 3000,
                                                                style: { width: '400px' },
                                                            });
                                                        }
                                                    }}
                                                    sx={{
                                                        borderRadius: '8px',
                                                        width: '100px', // Increase the width
                                                        '&:hover': {
                                                            backgroundColor: 'primary.main',
                                                        },
                                                    }}
                                                    icon={
                                                        <Badge badgeContent={filterCountForBadge} color="secondary">
                                                            <SearchIcon fontSize="small" />
                                                        </Badge>
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    </div>
                                }
                            />

                          

                        </TabList>
                    </Paper>
                </Box>

                <TabPanel value="boardView">
                    <BoardView />
                </TabPanel>

                <TabPanel value="reports">
                    <AdvancedTicketSearch
                        calledFrom='reportsTab'
                    />
                </TabPanel>

                <TabPanel value="analytics">

                </TabPanel>


            </TabContext>

        </div>
    )
}

export default UserManagement;
