import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StorageIcon from '@mui/icons-material/Storage';
import BuildIcon from '@mui/icons-material/Build';
import BackupIcon from '@mui/icons-material/Backup';
import ScannerRoundedIcon from '@mui/icons-material/ScannerRounded';
import FingerprintRoundedIcon from '@mui/icons-material/FingerprintRounded';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import DesktopMacRoundedIcon from '@mui/icons-material/DesktopMacRounded';
import CoronavirusRoundedIcon from '@mui/icons-material/CoronavirusRounded';
import NetworkCellRoundedIcon from '@mui/icons-material/NetworkCellRounded';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import PrintDisabledRoundedIcon from '@mui/icons-material/PrintDisabledRounded';
import FormatAlignLeftRoundedIcon from '@mui/icons-material/FormatAlignLeftRounded';
import TryRoundedIcon from '@mui/icons-material/TryRounded';
import FormatColorFillRoundedIcon from '@mui/icons-material/FormatColorFillRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import MoveDownRoundedIcon from '@mui/icons-material/MoveDownRounded';
import CleaningServicesRoundedIcon from '@mui/icons-material/CleaningServicesRounded';
import StickyNote2RoundedIcon from '@mui/icons-material/StickyNote2Rounded';
import AlignHorizontalRightRoundedIcon from '@mui/icons-material/AlignHorizontalRightRounded';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import DeveloperBoardOffRoundedIcon from '@mui/icons-material/DeveloperBoardOffRounded';
import DockRoundedIcon from '@mui/icons-material/DockRounded';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';


export const emiratesInDubai = [
    { key: 'Abu Dhabi', name: 'Abu Dhabi' },
    { key: 'Dubai', name: 'Dubai' },
    { key: 'Sharjah', name: 'Sharjah' },
    { key: 'Ajman', name: 'Ajman' },
    { key: 'Umm Al-Quwain', name: 'Umm Al-Quwain' },
    { key: 'Ras Al Khaimah', name: 'Ras Al Khaimah' },
    { key: 'Fujairah', name: 'Fujairah' },
];


export const acceptedCountries = [{ key: 'UAE', name: 'UAE' }, { key: 'QATAR', name: 'QATAR' }];

export const companyCodeOrNameOptions = [{ key: 'Code', name: 'Code' }, { key: 'Name', name: 'Name' }];

export const preferredContactOptions = [{ key: 'Email', name: 'Email' }, { key: 'Phone', name: 'Phone' }]

export const qatarMunicipalities = [
    { key: 'Doha', name: 'Doha' },
    { key: 'Al Rayyan', name: 'Al Rayyan' },
    { key: 'Al Wakrah', name: 'Al Wakrah' },
    { key: 'Al Daayen', name: 'Al Daayen' },
    { key: 'Al Khor', name: 'Al Khor' },
    { key: 'Al Shahaniya', name: 'Al Shahaniya' },
    { key: 'Umm Salal', name: 'Umm Salal' },
    { key: 'Al Shamal', name: 'Al Shamal' },
];

export const signupCompanyOptions = [
    'Link with an an already registered company', 'Register a company and act as Admin'
]

export const uaecCompanyNames = [
    "Emirates Tech Solutions",
    "Dubai Express Logistics",
    "Abu Dhabi Trading Co.",
    "Sharjah Global Services",
    "Ras Al Khaimah Industries",
    "Fujairah Builders",
    "Ajman Food Distributors",
    "Umm Al Quwain Electronics",
    "Al Ain Tourism Group",
    "Ras Al Khaimah Real Estate",
    "Fujairah Construction",
    "Dubai Health Care",
    "Abu Dhabi Fashion",
    "Sharjah Marketing Solutions",
    "Umm Al Quwain Motors",
    "Al Ain Agriculture",
    "Ajman IT Solutions",
    "Ras Al Khaimah Shipping",
    "Fujairah Energy",
    "Dubai Media Productions",
    "Abu Dhabi Consulting",
    "Sharjah Financial Services",
    "Umm Al Quwain Technologies",
    "Al Ain Education",
    "Ajman Hospitality",
];

export const validAcceptedCountries = [
    'uae',
    'uae (united arab emirates)',
    'united arab emirates',
    'uae (emirates)',
    'emirati',
    'emirates',
    'الإمارات العربية المتحدة',
    'dubai',
    'qatar',
    'state of qatar',
    'qat',
    'قطر',
];

export const roleOptionsForSuperAdmin = [
    { key: 'superAdmin', name: 'Super Admin' },
    { key: 'technicalAdmin', name: 'Technical Admin' },
    { key: 'technician', name: 'Technician' },
    { key: 'supportAgent' , name: 'Support Agent'},
];

export const roleOptionsForTechnicalAdmin = [
    { key: 'technician', name: 'Technician' },
];



export const roleOptionsForCustomerAdmin = [
    { key: 'customerAdmin', name: 'Customer Admin' },
    { key: 'customerUser', name: 'Customer User' },
];

export const roleOptionsForVendorAdmin = [
    { key: 'vendorAdmin', name: 'Vendor Admin' },
    { key: 'vendorUser', name: 'Vendor User' },
];

export const userRoles = {
    superAdmin: 'superAdmin',
    technician: 'technician',
    technicalAdmin: 'technicalAdmin',
    customerAdmin: 'customerAdmin',
    customerUser: 'customerUser',
    vendorAdmin: 'vendorAdmin',
    vendorUser: 'vendorUser'
}

export const allUserRoles = [
    { key: 'superAdmin', name: 'Super Admin' },
    { key: 'technicalAdmin', name: 'Technical Admin' },
    { key: 'technician', name: 'Technician' },
    { key: 'customerAdmin', name: 'Customer Admin' },
    { key: 'customerUser', name: 'Customer User' },
    { key: 'vendorAdmin', name: 'Vendor Admin' },
    { key: 'vendorUser', name: 'Vendor User' },
    { key : 'supportAgent', name : 'Support Agent'}
]

export const userTypes = [
    { key: 'Customer', name: 'Customer' },
    { key: 'Admin', name: 'Admin' },
    { key: 'Vendor', name: 'Vendor' }
];

export const companyTypes = [
    { key: 'customer', name: 'Customer' },
];

export const authProviders = [
    { key: 'email', name: 'Email' },
    { key: 'Google', name: 'Google' },
    { key: 'Microsoft', name: 'Microsoft' }
]

export const authProvidersForAutoComplete = [
    { value: 'email', label: 'Email' },
    { value: 'Google', label: 'Google' },
    { value: 'Microsoft', label: 'Microsoft' }
]

export const ticketStatusOptions = [
    { key: 'todo', name: 'To Do' },
    { key: 'inProgress', name: 'Inprogress' },
    { key: 'escalated', name: 'Escalated' },
    { key: 'done', name: 'Done' },
    { key: 'hold', name: 'Hold' },
    { key: 'discarded', name: 'Discarded' },
]

export const ticketStatusForSearch = [
    { value: "todo", label: "To Do", backgroundColor: "#d9f3d9" },
    { value: "inProgress", label: "In Progress", backgroundColor: "#ffe6cc" },
    { value: "paymentPending", label: "PAYMENT-PENDING", backgroundColor: "#ffe6cc" },
    { value: "done", label: "Done", backgroundColor: "#b7e4c7" },
    { value: "hold", label: "Hold", backgroundColor: "#ffd6cc" },
    { value: "discarded", label: "Discarded", backgroundColor: "#f4b0b0" },
    { value: "reqSuperAdminApproval", label: "Require Super Admin Approval", backgroundColor: "#ffe6cc" },
    { value: "reqCustomerAdminApproval", label: "Require Customer Admin Approval", backgroundColor: "#efd3a0" },
    { value: "customerAdminApproved", label: "Customer Admin Approved", backgroundColor: "#b7e4c7" },
    { value: "customerAdminDeclined", label: "Customer Admin Declined", backgroundColor: "#f4b0b0" },
    { value: "superAdminApproved", label: "Super Admin Approved", backgroundColor: "#b7e4c7" },
    { value: "superAdminDeclined", label: "Super Admin Declined", backgroundColor: "#f4b0b0" }
];


export const labels = [
    { value: 'Printer Issue', label: 'Printer Issue', icon: <ErrorOutlineIcon /> },
    { value: 'Server Issue', label: 'Server Issue', icon: <StorageIcon /> },
    { value: 'Maintenance Issue', label: 'Maintenance Issue', icon: <BuildIcon /> },
    { value: 'Backup Issue', label: 'Backup Issue', icon: <BackupIcon /> },
    { value: 'SCAN ISSUE', label: 'SCAN ISSUE', icon: <ScannerRoundedIcon /> },
    { value: 'BIO METRIC ISSUE', label: 'BIO METRIC ISSUE', icon: <FingerprintRoundedIcon /> },
    { value: 'CCTV CAMERA ISSUE', label: 'CCTV CAMERA ISSUE', icon: <CameraAltRoundedIcon /> },
    { value: 'MAINTENANCE ISSUE', label: 'MAINTENANCE ISSUE', icon: <EngineeringRoundedIcon /> },
    { value: 'TIME ATTENDANCE MACHINE ISSUE', label: 'TIME ATTENDANCE MACHINE ISSUE', icon: <AccessTimeRoundedIcon /> },
    { value: 'DRUM PRINTER', label: 'DRUM PRINTER', icon: <LocalPrintshopRoundedIcon /> },
    { value: 'EMAIL ISSUE', label: 'EMAIL ISSUE', icon: <EmailRoundedIcon /> },
    { value: 'PC ISSUE', label: 'PC ISSUE', icon: <DesktopMacRoundedIcon /> },
    { value: 'ANTIVIRUS ISSUE', label: 'ANTIVIRUS ISSUE', icon: <CoronavirusRoundedIcon /> },
    { value: 'NETWORK ISSUE', label: 'NETWORK ISSUE', icon: <NetworkCellRoundedIcon /> },
    { value: 'SENSOR ISSUE', label: 'SENSOR ISSUE', icon: <SensorsRoundedIcon /> },
    { value: 'PRINTER ISSUE', label: 'PRINTER ISSUE', icon: <PrintDisabledRoundedIcon /> },
    { value: 'LINE ISSUE', label: 'LINE ISSUE', icon: <FormatAlignLeftRoundedIcon /> },
    { value: 'TRY ISSUE', label: 'TRY ISSUE', icon: <TryRoundedIcon /> },
    { value: 'COLOR ISSUE', label: 'COLOR ISSUE', icon: <FormatColorFillRoundedIcon /> },
    { value: 'DRUM UNIT ISSUE', label: 'DRUM UNIT ISSUE', icon: <ForumRoundedIcon /> },
    { value: 'TONER ISSUE', label: 'TONER ISSUE', icon: <PrintRoundedIcon /> },
    { value: 'TRANSFER BELT ISSUE', label: 'TRANSFER BELT ISSUE', icon: <MoveDownRoundedIcon /> },
    { value: 'CLEANING BLADE ISSUE', label: 'CLEANING BLADE ISSUE', icon: <CleaningServicesRoundedIcon /> },
    { value: 'PAPER STUCKING ISSUE', label: 'PAPER STUCKING ISSUE', icon: <StickyNote2RoundedIcon /> },
    { value: 'RIGHT PRINTIG ISSUE', label: 'RIGHT PRINTIG ISSUE', icon: <AlignHorizontalRightRoundedIcon /> },
    { value: 'FEED ROLLER ISSUE', label: 'FEED ROLLER ISSUE', icon: <FeedRoundedIcon /> },
    { value: 'HETER UNIT ISSUE', label: 'HETER UNIT ISSUE', icon: <AcUnitRoundedIcon /> },
    { value: 'DEVELOPER ISSUE', label: 'DEVELOPER ISSUE', icon: <DeveloperBoardOffRoundedIcon /> },
    { value: 'CHARGER ISSUE', label: 'CHARGER ISSUE', icon: <DockRoundedIcon /> },
    { value: 'OTHER', label: 'OTHER', icon: <ScatterPlotIcon /> },
]

export const assetTypes = [
    { key: 'laptop', name: 'Laptop' },
    { key: 'printer', name: 'Printer' },
    { key: 'cctv', name: 'CCTV' },
];

export const assetHealthStatus = [
    { key: 'ok', name: 'Ok - At Client' },
    { key: 'repairNeeded', name: 'Maintenance Required - At Client' },
    { key: 'repairNeededAtWareHouse', name: 'Maintenance Required - At Warehouse' },
    { key: 'stock', name: 'In Stock - Ready for Dispatch' },
    { key: 'inactive', name: 'Inactive or Scrapped' },
    { key: 'return', name: 'Return' },
];

export const tonerLevel = [
    { key: 'full', name: 'Full (75 - 100)' },
    { key: 'medium', name: 'Medium (50 - 74)' },
    { key: 'low', name: 'Low (25 - 49)' },
    { key: 'criticallyLow', name: 'Critically Low (1 - 24)' },
    { key: 'nil', name: 'Empty (0)' }
];

export const printerTypes = [
    { key: 'color', name: 'Color' },
    { key: 'black&White', name: 'Black & White' }
];

export const tonerTypes = [
    { key: 'original', name: 'Original' },
    { key: 'compatible', name: 'Compatible' },
]

export const reduceQunatityOptions = [
    { key: 'byDigit', name: 'By Digit' },
    { key: 'byPercent', name: 'By Percentage' }
]


export const yesOrNo = [
    { key: 'yes', name: 'Yes' },
    { key: 'no', name: 'No' },
];

export const partsRequiredOptions = [
    { key: 'yes', name: 'Yes' },
    { key: 'no', name: 'No' }
];


export const assetContractType = [
    { key: 'monthlyRental', name: 'Monthly Rental' },
    { key: 'annualRental', name: 'Annual Rental' },
    { key: 'salesWarranty', name: 'Sales Warranty' },
    { key: 'quarterly', name: 'Quarterly' },
    { key: 'amcwithparts', name: 'AMC With Parts' },
    { key: 'amcwithoutparts', name: 'AMC Without Parts' },
    { key: 'amc', name: 'AMC' },
    { key: 'other', name: 'Other' },
];

export const keyToLabelMap = {
    assetType: 'Asset Type',
    contractType: 'Contract Type',
    assetName: 'Asset Name',
    machineCode: 'Machine Code',
    model: 'Model',
    serialNumber: 'Serial Number',
    series: 'Series',
    healthStatus: 'Health Status',
    lastServiceBy: 'Last Service By',
    serviceDurationInDays: 'Service Duration (Days)',
    lastService: 'Last Service',
    nextService: 'Next Service',
    users: 'Users',
    company: 'Company',
    location: 'Location',
    printerType: 'Printer Type',
    counterLimitBlack: 'Counter Limit (Black)',
    currentCounterBlack: 'Current Counter (Black)',
    counterLimitColor: 'Counter Limit (Color)',
    currentCounterColor: 'Current Counter (Color)',
    wasteToner: 'Waste Toner',
    tonerLevelBlack: 'Toner Level (Black)',
    tonerLevelYellow: 'Toner Level (Yellow)',
    tonerLevelCyan: 'Toner Level (Cyan)',
    tonerLevelMagenta: 'Toner Level (Magenta)',
    partsRequired: 'Parts Required',
    partsNotes: 'Parts Notes',
    consumerStockNotes: 'Consumer Stock Notes',
    lastInvoicedDate: 'Last Invoiced Date',
    invoiceDayOrDuration: 'Invoice Day or Duration',
    invoiceDurationType: 'Invoice Duration Type',
    nextInvoiceDate: 'Next Invoice Date',
    lastInvoiceBy: 'Last Invoice By',
    lastInvoiceNumber: 'Last Invoice Number',
    remoteConnectionId: 'Remote Connection ID',
    remoteConnectionUserName: 'Remote Connection User Name',
    remoteConnectionPassword: 'Remote Connection Password',
    systemIp: 'System IP',
    customFieldValues: 'Custom Field Values',
    remarks: 'Remarks',
    invoiceDuration: 'Invoice Duration',
    printerInvoiceData: 'Printer Invoice Data',
};

export const printerInvoiceStatusOptions = [
    { key: 'done', name: 'Done' },
    { key: 'hold', name: 'Hold' },
    { key: 'pending', name: 'Pending' },
]


export const countriesFranchies = [
    {
        key: "UAE",
        name: "UAE"
    }, {
        key: "Qatar",
        name: "Qatar"
    },
]

export const areasFrachise = {
    UAE: [
        {
            name: "Dubai",
            key: 'Dubai'
        }, {
            name: "Abu Dhabi",
            key: "Abu Dhabi"
        }
    ],
    Qatar: [
        {
            name: "Qatar",
            key: 'Qatar'
        }
    ]
}


export const assetTypesCommon = [
    { name: 'Laptop', val: 'laptop' },
    { name: 'Printer', val: 'printer' },
    { name: 'Toner', val: 'toner' }
];

export const commonFieldTypesOfExportAsset =  [
    {name:"Brand" , val:"brand"},
    {name:"Model" , val:"model"},
    {name:"Origin" , val:"origin"},
]