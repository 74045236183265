import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, useMediaQuery, Tooltip, Badge } from '@mui/material'
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ChatViewComponent from "./ChatViewComponent";
import ChatInputController from "./ChatInputController";
import { toast } from "react-toastify";

import "./CustomerChatStyles.css";

import { collection, onSnapshot, query, where, orderBy, Timestamp, limit, startAfter, getDocs } from "@firebase/firestore";
import { db } from "../../utilities/auth-config/firebase-config";

import notificationSound from '../../MediaContents/Sounds/notifications-sound-127856.mp3'
import newMessageTickSound from '../../MediaContents/Sounds/audiomass-output.mp3'

function CustomerChatComponent() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const showChatWindow = useSelector(
        (state) => state.userData.setting.showCustomerChatWindow
    );
    const isAdminsOnline = useSelector((state) => state.chats.isAdminsOnline);
    const isCustomerChatAttended = useSelector((state) => state.chats.isCustomerChatAttended);
    const user = useSelector((state) => state.userData.data);
    const pageSize = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [lastDoc, setLastDoc] = useState(null);
    const [newChatFlag, setNewChatFlag] = useState(true);
    const scrollDivRef = useRef(null);

    const [chats, setChats] = useState([]);

    const messageDbRef = collection(db, 'messages');

    function playNotifySound(soundType) {
        if (soundType === 'tick') {
            const audio = new Audio(newMessageTickSound);
            audio.play();
        } else {
            const audio = new Audio(notificationSound);
            audio.play();
        }

    }

    useEffect(() => {
        getMessagesWithPagination(1)
    }, [user._id])

    const roomRef = collection(db, 'rooms');
    useEffect(() => {
        let unsubscribe;
        let unsubscribeFromRooms;
        const currentTimestamp = Timestamp.fromDate(new Date());
        
        if (user._id) {

            /*** UPDATE SESSION STATUS IF USER ALREADY HS AND ACTIVE CHAT ****/
            const q = query(roomRef, where("roomId", "==", user._id));
            unsubscribeFromRooms = onSnapshot(q, (querySnapshot) => {
                const userRoom = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                if (userRoom[0]?.status === 'attended') {
                    dispatch({ type: 'chats/updateIsCustomerChatAttended', payload: true });
                } else {
                    dispatch({ type: 'chats/updateIsCustomerChatAttended', payload: false });
                }
            });


            const queryMessage = query(messageDbRef,
                where("roomId", "==", user._id),
                where('timestamp', '>', currentTimestamp),
                orderBy("timestamp")
            );
            unsubscribe = onSnapshot(queryMessage, (snapshot) => {
                if (!snapshot.docChanges().length) {
                    return; // No changes in the snapshot, so return early
                }
                let messages = [];
                snapshot.forEach((doc) => {
                    messages.push({ ...doc.data(), id: doc.id });
                });

                const lastMessage = messages[messages.length - 1];
                if (showChatWindow === true && isCustomerChatAttended) {
                    playNotifySound('tick')
                } else if (lastMessage && lastMessage.userId !== user._id && document.hidden) {
                    playNotifySound(); // Play sound only if the last message is from the other user and the user has minimized the tab or is currently in another tab
                } else if (lastMessage && lastMessage.userId !== user._id && !showChatWindow) {
                    playNotifySound('tick'); // Play tick sound only if the last message is from the other user and the chat window is not shown
                }

                setChats(prevChats => {
                    // Create a copy of prevChats
                    let updatedChats = [...prevChats];

                    // Update chats that are also in messages
                    updatedChats = updatedChats.map(chat => {
                        const matchingMessage = messages.find(message => message.id === chat.id);
                        return matchingMessage ? matchingMessage : chat;
                    });

                    // Add messages that are not already in prevChats
                    const newMessages = messages.filter(message => !prevChats.some(chat => chat.id === message.id));
                    
                    dispatch({
                        type: 'user/updateFieldValues',
                        payload: {
                            state: 'chatStates',
                            field: 'lastMessages',
                            value: newMessages?.map(item => ({
                                ...item,
                                message: item.message || (item.type === 'file' ? "A file has been shared." : "")
                            }))
                        },
                    });
                    return [...updatedChats, ...newMessages];
                });
                setNewChatFlag(true);
            });
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
            if (unsubscribeFromRooms) {
                unsubscribeFromRooms()
            }
        };
    }, [user._id]);


    const activeAdminsRef = collection(db, 'activeAdmins');
    useEffect(() => {
        const unsubscribe = onSnapshot(activeAdminsRef, (snapshot) => {
            let admins = [];
            snapshot.forEach((doc) => {
                const data = doc.data();
                if (data.admins && data.admins.length > 0) {
                    admins.push({ ...data, id: doc.id });
                }
            });
            // console.log(admins)

            dispatch({ type: 'chats/updateIsAdminsAvailable', payload: admins.length >= 0 });
        });

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    const handleClose = () => {
        dispatch({ type: 'user/setShowCustomerChatWindow' });
    };

    const getMessagesWithPagination = async (page) => {
        // Calculate the number of documents to skip
        const skip = (page - 1) * pageSize;

        // Get the total number of messages
        const totalSnapshot = await getDocs(query(messageDbRef, where("roomId", "==", user._id)));
        setTotalPages(Math.ceil(totalSnapshot.size / pageSize));

        let messagesQuery = query(
            messageDbRef,
            where("roomId", "==", user._id),
            orderBy("timestamp", "desc"),
            limit(skip + pageSize)
        );

        const snapshot = await getDocs(messagesQuery);
        const messages = snapshot.docs.slice(skip).map(doc => {
            const data = doc.data();
            return { ...data, id: doc.id, view: data.view || null }; // Add a null check for the 'view' property
        });
        setChats([...messages.reverse(), ...chats]);

        // Set the current page and last document
        setCurrentPage(page);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
    };

    return (
        <Dialog
            open={showChatWindow}
            fullScreen={isMobile}
            fullWidth
            maxWidth={'md'}
            PaperProps={{
                style: {
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    minHeight: 'calc(100vh - 100px)'
                },
            }}
        >
            <DialogTitle
                className={isMobile ? 'fixed-header' : ''}
                sx={{
                    backgroundColor: '#244fac',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ForumRoundedIcon style={{ marginRight: '4px' }} />
                    {isAdminsOnline ?
                        <Tooltip title="Start a chat with our support team">
                            <strong>Chat Support</strong>
                        </Tooltip>
                        :
                        <Tooltip title="Our chat support is currently offline - Connect @ +971 0507828055">
                            <span style={{ marginLeft: '10px', color: '#fff', fontWeight: 'bold' }}>
                                We are currently offline
                                <Badge style={{ marginLeft: '5px', marginBottom: '15px' }} color="error" variant="dot" overlap="circular" />
                            </span>
                        </Tooltip>
                    }
                </div>
                <Tooltip title="Close chat">
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        sx={{
                            transition: 'background-color 0.3s, color 0.3s',
                            '&:hover': {
                                color: 'grey',
                                backgroundColor: '#fff',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </DialogTitle>
            <DialogContent dividers id='customer-chat-view' className='custom-scrollbar' ref={scrollDivRef}>
                <ChatViewComponent
                    chats={chats}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    getMessagesWithPagination={getMessagesWithPagination}
                    newChatFlag={newChatFlag}
                    setNewChatFlag={setNewChatFlag}
                    scrollDivRef={scrollDivRef}
                />
            </DialogContent>
            <DialogActions>
                <ChatInputController />
            </DialogActions>
        </Dialog>
    );
}

export default CustomerChatComponent;