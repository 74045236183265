import React from 'react';
import { Paper, Link } from '@mui/material';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(Paper)({
    width: '100%',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Align items to the left
    alignItems: 'flex-start', // Align items to the left
    gap: '16px',
    border: '1px solid var(--grey-300, #E0E0E0)',
    background: 'var(--grey-ff, #FFF)',
    padding: '16px 24px',
    borderRadius: '8px 8px 0px 0px',
});

const StyledLink = styled(Link)({
    cursor: 'pointer',
    textDecoration: 'none', // Remove underline
    fontWeight: 'bold', // Add font weight
});

function Settings() {
    const dictionaryItems = [
        {
            name: 'Custom Fields',
            path: '/Settings/CustomFields'
        },
        {
            name: 'Labels',
            path: '/' //ToDon
        }
    ];

    const navigate = useNavigate();

    return (
        <StyledPaper elevation={2}>
            {dictionaryItems.map((item, index) => (
                <StyledLink
                    key={index}
                    onClick={() => navigate(item.path)}
                >
                    {item.name}
                </StyledLink>
            ))}
        </StyledPaper>
    );
}

export default Settings;