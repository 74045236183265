import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFranchiseData, updateFranchise } from '../app/Franchise/service';
import { updateChangeFranchise } from '../app/User/UserSlice';
import { saveAllFranchises, updateCurrentFranchise } from '../app/Franchise/FranchiseSlice';

const FranchiseSelect = () => {
    const user = useSelector((state) => state.userData?.data);
    const AllFranchises = useSelector(state => state.franchiseMangement.franchises);
    const currentFranchise = useSelector(state => state.franchiseMangement.currentFranchise);
    const dispatch = useDispatch()
    const [options,setOptions] = useState([]);

    useEffect(() => {
        const getFranchiseData = async () => {
            try {
                const frData  = await getAllFranchiseData()
                dispatch(saveAllFranchises(frData.data.franchises))
                // dispatch(updateCurrentFranchise(user.franchise))
                setOptions(frData.data.franchises)
            } catch (error) {
                // console.log(error)
            }
        }
        getFranchiseData()
    }, [])

    const handleChange = async (event) => {
        const selectedValue = event.target.value;
        const selectedFranchise = options.find(option => `${option.area} - ${option.country}` === selectedValue);
        const result = await updateFranchise({franchise:selectedFranchise})
       
        dispatch(updateChangeFranchise(selectedFranchise))
        // dispatch(updateCurrentFranchise(selectedFranchise))
    };
    if(!currentFranchise){
        return null
    }
    return (
        <Select
            value={`${currentFranchise.area} - ${currentFranchise.country}`}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Select only' }}
            style={{ backgroundColor: 'white', maxHeight: '2.2rem' }} // Setting background color to white
        >
            {AllFranchises.map((option, index) => (
                <MenuItem key={index} value={`${option.area} - ${option.country}`}>
                    {`${option.area} - ${option.country}`}
                </MenuItem>
            ))}
        </Select>
    );
}

export default FranchiseSelect;
