import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Divider,
    Paper,
    useMediaQuery,
    useTheme,
    Grid,
    InputLabel,
    TextField,
    Skeleton,
    Stack,
    Chip,
    Avatar,
    InputAdornment,
    Card,
    CardHeader,
    Popover,
    Typography,
    Box,
    FormHelperText,
    Button,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction
} from '@mui/material';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import '../../../index.css'
import '../ticket.css'
import SelectInput from '../../../components/commons/SelectInput';
import MUIDatePicker from '../../../components/commons/DatePicker/DatePicker';
import { getCompaniesList } from '../../UserMangaement/Service';
import Autocomplete from '../../../components/commons/Autocomplete';
import FileUploader from '../../../components/FileUploader/FileUploader';
import TicketStatusSelector from '../../../components/commons/TicketStatusSelector';
import LabelSelector from '../../../components/commons/LabelSelector';
import MultipleUserSelector from '../../../components/commons/MultipleUserSelector/MultipleUserSelector';
import TicketPrioritySelector from '../../../components/commons/TicketPrioritySelector';
import { stringToColor } from '../../../utilities/commonUtils';
import { format } from 'date-fns';
import { ExportButton, ResetButton, SearchButton } from '../../../components/commons/Buttons';
import { ticketStatusForSearch } from '../../../constants/commonConstants';
import TicketSearchResultView from './QuickSearchResultView';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchQueryComponent from './SearchQueryComponent';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';


function AdvancedTicketSearch({calledFrom}) {
    const sessionUser = useSelector((state) => state.userData.data);
    const quickSearchData = useSelector((state) => state.ticketManagement.ticketQuickSearch)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [companiesList, setCompaniesList] = useState([]);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const dialogContentStyle = {
        overflow: 'hidden',
        // maxHeight: !isMobile || !quickSearchData.isFullScreen  ? '85vh' : '',
        overflowY: 'auto',
    };

    useEffect(() => {
        getCompaniesList()
            .then((result) => {
                if (result?.data?.length) {
                    let tempArr = [];
                    result.data.forEach(item => {
                        tempArr.push({ value: item._id, label: item.companyName });
                    });
                    setCompaniesList(tempArr)
                } else if (result.data) {
                    setCompaniesList([{ value: result.data._id, label: result.data.companyName }]);
                }
            });

    }, []);

    const actions = [

        { icon: <ManageSearchIcon />, name: 'Open Search Box', id: 1 },
        { icon: <OpenInFullIcon />, name: 'Open and Lock Search Box', id: 2 },
    ];



    return (
        <div>
            <Grid container spacing={2}>
                {!quickSearchData.isFilterComponentCollapsed && (
                    <Grid className='custom-scrollbar' item xs={3} sx={{ paddingRight: '30px', border: '1px solid #ccc', borderRadius: '4px', overflowY: 'auto', maxHeight: quickSearchData.isFullScreen ? calledFrom === 'reportsTab' ? 'calc(100vh - 100px)' : 'calc(100vh - 120px)' : '700px' }}>
                        <SearchQueryComponent companiesList={companiesList} />
                    </Grid>
                )}
                <Grid item xs={quickSearchData.isFilterComponentCollapsed ? 12 : 9} style={{ paddingRight: '30px', border: '1px solid #ccc', borderRadius: '4px', maxHeight: quickSearchData.isFullScreen ? calledFrom === 'reportsTab' ? 'calc(100vh - 100px)' : 'calc(100vh - 120px)' : '700px' }}>
                    <TicketSearchResultView calledFrom={calledFrom}/>
                </Grid>
            </Grid>
            {quickSearchData.isFilterComponentCollapsed && (
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'absolute', bottom: 16, left: calledFrom === 'reportsTab' ? 285 : 16 }}
                    icon={<SpeedDialIcon />}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={(event) => {
                                if (action.id === 1) {
                                    setAnchorEl(event.currentTarget);
                                    setIsPopoverOpen(true);
                                } else {
                                    dispatch({
                                        type: 'ticketManagement/updateFieldValues',
                                        payload: {
                                            state: 'ticketQuickSearch',
                                            field: 'isFilterComponentCollapsed',
                                            value: !quickSearchData.isFilterComponentCollapsed,
                                        },
                                    });
                                    setIsPopoverOpen(false);
                                    setAnchorEl(null);
                                }
                            }}
                        />
                    ))}
                </SpeedDial>
            )}
            <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={() => setIsPopoverOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    padding: '20px !important',
                    maxWidth: '30%',
                    width: '100%',
                    '@media (max-width: 600px)': {
                        maxWidth: '100%',
                    },
                }}
                className='custom-scrollbar'
            >
                <div style={{ padding: '20px' }}>
                    <SearchQueryComponent companiesList={companiesList} />
                </div>
            </Popover>
        </div>
    )
}

export default AdvancedTicketSearch
