import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle, Box, IconButton, List, ListItem, Typography, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const AssetActionItems = ({
    propData
}) => {
    const reduxData = useSelector((state) => state.assetManagement.createOrEditData);
    const data = propData ? propData : reduxData;
    const [open, setOpen] = useState(true);

    if (data.actionRequiredItems.length === 0) {
        return null;
    }

    return (
        <Box sx={{ marginBottom: 2 }}>
            <Alert
                severity="error"
                action={
                    <IconButton
                        aria-label="expand or shrink"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        {open ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />}
                    </IconButton>
                }
            >
                <AlertTitle>Action Required</AlertTitle>
                <Collapse in={open}>
                    <List>
                        {data.actionRequiredItems.map((item, index) => (
                            <ListItem key={index}>
                                <Typography>{item.action}</Typography>
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            </Alert>
        </Box>
    );
};

export default AssetActionItems;