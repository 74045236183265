import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';

const StyledChatContainer = styled('div')(({ theme }) => ({
    zIndex: 99,
    position: 'fixed',
    bottom: '50px',
    right: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '10px',
}));

const StyledChatMessage = styled('div')({
    zIndex: 99,
    color: 'white',
    padding: '13px',
    marginRight: '10px',
    maxWidth: '300px',
    background: '#3f51b5',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&:hover': {
        background: '#091B95',
    },
});

const StyledChatSupportButton = styled(Button)(({ theme }) => ({
    zIndex: 99,
    background: '#3f51b5 !important',
    color: 'white',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    transition: 'background 0.3s ease',
    '&:hover': {
        background: '#091B95 !important',
    },
}));

const ChatSupportButton = () => {
    const dispatch = useDispatch();
    const newMessages = useSelector((state) => state.userData.chatStates.lastMessages);
    const sessionUser = useSelector((state) => state.userData.data);
    const showChatWindow = useSelector(
        (state) => state.userData.setting.showCustomerChatWindow
    );
    

    return (
        <StyledChatContainer>
            {/* Take only the last message received which are not seen yet and it not belongs to this user*/}
            {newMessages[newMessages.length - 1]?.message &&
                !newMessages[newMessages.length - 1]?.seen &&
                (newMessages[newMessages.length - 1]?.userId !== sessionUser?._id) &&
                !showChatWindow &&
                (
                    <StyledChatMessage>{newMessages[newMessages.length - 1]?.message}</StyledChatMessage>
                )}
            <div id='dummyDiv'></div>
            <Tooltip title="Open chat">
                <StyledChatSupportButton onClick={() => {
                    dispatch({ type: 'user/setShowCustomerChatWindow' });
                    dispatch({ type: 'user/updateFieldValues', payload: { state: 'chatStates', field: 'lastMessages', value: [] }, });
                }}>
                    <Badge badgeContent="New Message" variant="dot" color="error">
                        <ChatIcon />
                    </Badge>    
                </StyledChatSupportButton>
            </Tooltip>
        </StyledChatContainer>
    );
};

export default ChatSupportButton;