import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    unAttendedChats: [],
    attendedChats: [],
    openedChatId: null,
    openedChat: [],
    customerChats: [],
    isCustomerChatAttended: false,
    isAdminsOnline: false,
    unSeenMessages: {},
    isChatModeOn: false,
    currentChatRoom: {
        allowAdminInput: false,
        isSupportAgent: false,
        currentSupportAgent:null
    }
};

const chatSlice = createSlice({
    name: "chats",
    initialState,
    reducers: {
        updateUnattendedChats: (state, action) => {
            state.unAttendedChats = action.payload;
        },
        updateAttendedChats: (state, action) => {
            state.attendedChats = action.payload;
        },
        setOpenedChatId: (state, action) => {
            state.openedChatId = action.payload;
        },
        setAllCustomerChats: (state, action) => {
            state.customerChats = action.payload;
        },
        pushNewChatToCustomer: (state, action) => {
            state.customerChats = [...action.payload, state.customerChats]
        },
        updateIsCustomerChatAttended: (state, action) => {
            state.isCustomerChatAttended = action.payload;
        },
        updateIsAdminsAvailable: (state, action) => {
            state.isAdminsOnline = action.payload;
        },
        setIsChatModeOn: (state, action) => {
            if (!action.payload) {
                return initialState
            }
            state.isChatModeOn = action.payload;
        },
        updateUnSeenMessages: (state, action) => {
            if (!Array.isArray(action.payload)) {
                console.error("Payload for updateUnSeenMessages should be an array");
                return;
            }
            action.payload.forEach(key => {
                if (key !== state.openedChatId) {
                    if (state.unSeenMessages[key]) {
                        state.unSeenMessages[key] += 1;
                    } else {
                        state.unSeenMessages[key] = 1;
                    }
                }
            });
        },
        removeFromUnseenMessages: (state, action) => {
            if (typeof action.payload !== 'string') {
                console.error("Payload for removeFromUnseenMessages should be a string");
                return;
            }
            const key = action.payload;
            if (state.unSeenMessages[key]) {
                delete state.unSeenMessages[key];
            }
        },
        updateCurrentChatRoom: (state, action) => {
            state.currentChatRoom = { ...state.currentChatRoom, ...action.payload }
        },
        resetToInitial: () => initialState,
    }
});

export const {
    updateUnattendedChats,
    updateAttendedChats,
    setOpenedChatId,
    updateIsCustomerChatAttended,
    updateUnSeenMessages,
    removeFromUnseenMessages,
    updateIsAdminsAvailable,
    setIsChatModeOn,
    updateCurrentChatRoom
} = chatSlice.actions;
export default chatSlice.reducer;
