import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useTransition, useRef } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TextInput from "../components/commons/TextInput";
import { toast } from "react-toastify";
import SelectBox from "../components/commons/SelectInput";
import {
  emiratesInDubai,
  validAcceptedCountries,
  acceptedCountries,
  uaecCompanyNames,
  signupCompanyOptions,
  qatarMunicipalities,
  preferredContactOptions,
  companyCodeOrNameOptions,
  countriesFranchies,
  areasFrachise,
} from "../constants/commonConstants";
import Switch from "../components/commons/Switch";
import { registerCustomerInputValidation } from "./registerCustomerInputValidation";
import CustomDialogueMedium from "../components/commons/CustomDialogueMedium";
import PhoneInputCustom from "../components/commons/PhoneInput";
import { getAllFranchisesForRegister } from "../app/Franchise/service";
import {
  signInWithGoogle,
  signInWithMicrosoft,
} from "../utilities/auth-config/firebase-config";
import {
  getLocationAndCountry,
  getLocationAndCountryByIp,
} from "../utilities/locationDetectUtil";
import {
  isValidEmail,
  isValidPassword,
  validatePhoneNumberUAEQatar,
} from "../utilities/commonUtils";
import { publicApi } from "../api";
import { SendOutlined } from "@mui/icons-material";
import UserManual from "../components/manual/UserManual";

const PageContainer = styled(Box)({
  minHeight: "100vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "linear-gradient(45deg, #f3e5f5, #e1bee7, #ce93d8)",
  position: "relative",
  overflowX: "hidden",
  pt: "40px", // Add padding to ensure space for curved shapes
});

const RegisterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "50%",
  maxWidth: "1000px",
  borderRadius: "20px",
  backgroundColor: "#fff",
  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
  margin: "40px 0",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    margin: "20px 0",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    margin: "10px 0",
  },
}));

const RegisterSection = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "40px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    padding: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const CurvedShape = styled(Box)({
  position: "fixed",
  width: "300px",
  height: "300px",
  borderRadius: "50%",
  background: "rgba(255, 255, 255, 0.1)",
  pointerEvents: "none",
});

const StyledButton = styled(Button)({
  marginBottom: "15px",
  color: "#6c3169",
  borderColor: "#6c3169",
  "&:hover": {
    backgroundColor: "#6c3169",
    color: "#fff",
    borderColor: "#6c3169",
  },
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.1)",
});

const AuthSection = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px 40px 0 40px",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 20px 0 20px",
  },
}));

const FlexContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "16px",
});

const SubmitButton = styled(Button)({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  fontWeight: "bold",
  width: "100%",
  padding: 12,
  margin: 0,
  borderRadius: 20,
  boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.1)",
  backgroundColor: "#7b4678",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#6c3169",
  },
});

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [steps, setSteps] = useState(["Select Auth", "Add Company", "Submit"]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isWaitingAuth, setIsWaitingAuth] = useState(false);

  const [isServerValidationInprocess, setIsServerValidationInprocess] =
    useState(false);
  const [authByEmail, setAuthByEmail] = useState(false);
  const [emailError, setEmailError] = useState({ flag: false, message: "" });
  const [otpError, setOtpError] = useState(false);
  const [companyError, setCompanyError] = useState({
    flag: false,
    message: "",
  });
  const [isPending, startTransition] = useTransition();

  const [franchiseData, setFranchiseData] = useState({
    areas: {},
    countries: [],
  });
  const [registerData, setRegisterData] = useState({
    displayName: "",
    firstName: "",
    lastName: "",
    preferredContact: "",
    emailId: "",
    companyName: "",
    companyCode: "",
    isCompanyCodeOrName: "Code",
    phoneNumber: "+971",
    companyAddress: {
      streetLine1: "",
      city: "",
      stateProvinceRegion: "",
      postalCode: "",
      country: "",
    },
    authProvider: "",
    password: "",
    trnNum: "",
    linkWithExistingCompany: false,
    linkCompanyId: "",
    authObj: "",
    franchise: {
      country: "",
      area: "",
    },
  });
  const [otpState, setOtpState] = useState(false);
  const [pendingAdminApproval, setPendingAdminApproval] = useState(false);
  const otpInputRef = useRef(null);
  const [readmeModalOpen, setReadmeModalOpen] = useState(false);

  useEffect(() => {
    const authorizedUser = localStorage.getItem("_id");
    if (authorizedUser) {
      toast.warning("You are already registered and logged in.");
      navigate("/");
    }
    // const fetchUserLocData = async () => {
    // 	try {
    // 		// Call the global utility function to get the location and country
    // 		const data = await getLocationAndCountry();
    // 		// Handle the location and country data
    // 		const { country } = data;
    // 		if (country) {
    // 			setRegisterData({
    // 				...registerData,
    // 				companyAddress: { ...registerData.companyAddress, country: country }
    // 			});
    // 		}
    // 	} catch (error) {
    // 		try {
    // 			const userLocByIp = await getLocationAndCountryByIp();
    // 			if (userLocByIp.data.city) {
    // 				setRegisterData({
    // 					...registerData,
    // 					companyAddress: { ...registerData.companyAddress, city: userLocByIp.data.city ?? "", country: userLocByIp.data.country ?? "", stateProvinceRegion: userLocByIp.data.regionName ?? "" }
    // 				})
    // 			}
    // 		} catch (ipError) {
    // 			// Handle the error from getLocationAndCountryByIp() if necessary
    // 			console.error("Error getting location by IP:", ipError);
    // 		}
    // 	}
    // };

    const getAllFranchiseData = async () => {
      const frDatas = await getAllFranchisesForRegister();
      const { areas, countries } = frDatas.data;
      // console.log(frDatas.data, areas, countries)
      setFranchiseData({ countries: countries, areas: areas });
      // fetchUserLocData();
    };
    getAllFranchiseData();
  }, []);

  const handleRegistration = async (method) => {
    setIsWaitingAuth(true); // Turn on the Skeleton
    let authUser;
    if (method === "Google") {
      authUser = await signInWithGoogle();
    } else if (method === "Microsoft") {
      authUser = await signInWithMicrosoft();
    } else if (method === "email") {
      // Update the state first for 'email' method
      setTimeout(() => {
        setRegisterData({ ...registerData, authProvider: "email" });
      }, 100);
      setIsWaitingAuth(false); // Turn off the Skeleton
      setAuthByEmail(true);
      setCurrentStep(2);
      return; // Exit the function here to prevent further execution for 'email' method.
    }
    if (authUser?.uid) {
      // Server validation - checks if user exists with the authenticated email
      let response;
      try {
        response = await publicApi("POST", "users/checkUserExistWithEmail", {
          email: authUser.email,
        });
        if (response.message === "Network Error") {
          toast.error("An unexpected error occurred while fetching data.");
          setCurrentStep(1);
          setIsWaitingAuth(false);
          return;
        }
      } catch (error) {
        toast.error("An unexpected error occurred while fetching data.");
        setCurrentStep(1);
        setIsWaitingAuth(false);
        return;
      }
      if (response?.data?.userExists === false) {
        toast.success(`Authenticated by ${method}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });
        setRegisterData({
          ...registerData,
          displayName: authUser.displayName,
          emailId: authUser.email,
          photoURL: authUser.photoURL,
          uni: authUser.uni,
          phoneNumber: authUser.phoneNumber,
          authProvider: method,
          authObj: authUser,
        });
        setCurrentStep(2);
        setIsWaitingAuth(false);
        setIsServerValidationInprocess(true);
        setTimeout(() => {
          setIsServerValidationInprocess(false);
        }, 200);
      } else if (response?.data?.userExists) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });
        navigate("/login");
        setCurrentStep(1);
      } else {
        toast.error("An unexpected error occurred.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });
        navigate("/login");
        setCurrentStep(1);
      }
    }
    if (authUser instanceof Error) {
      if (authUser.code === "auth/account-exists-with-different-credential") {
        toast.warning(
          "Account associated to this email address is registered using a different provider."
        );
      } else
        toast.error(
          `Authorization failed with ${method}. Please try again later.`
        );
    }
    setIsWaitingAuth(false);
  };

  const handleValueChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // console.log(name, value)
    if (name == "franchiseArea") {
      setRegisterData((prev) => ({
        ...prev,
        franchise: { ...prev.franchise, area: value },
        companyAddress: { ...prev.companyAddress, stateProvinceRegion: value },
      }));
    } else if (name == "franchiseCountry") {
      setRegisterData((prev) => ({
        ...prev,
        franchise: { ...prev.franchise, country: value, area: "" },
        companyAddress: { ...prev.companyAddress, country: value },
      }));
    } else if (
      ["streetLine1", "city", "stateProvinceRegion", "country"].includes(name)
    ) {
      setRegisterData({
        ...registerData,
        companyAddress: {
          ...registerData.companyAddress,
          [name]: value,
        },
      });
    } else if (name === "linkWithExistingCompany") {
      if (e.target.checked) setReadmeModalOpen(true);
      setRegisterData({
        ...registerData,
        linkWithExistingCompany: e.target.checked,
      });
    } else if (name === "phoneNumber") {
      if (!validatePhoneNumberUAEQatar(value)) {
        return;
      } else {
        setRegisterData({ ...registerData, phoneNumber: value });
      }
    } else if (name === "emailId") {
      setRegisterData({ ...registerData, emailId: value });
      setEmailError({
        flag: value.length >= 1 ? !isValidEmail(value) : false,
        message:
          value.length >= 1 ? (isValidEmail(value) ? "" : "Invalid email") : "",
      });
      startTransition(() => checkUserExistWithEmail(value));
    } else if (name === "companyName") {
      setRegisterData({ ...registerData, companyName: value });
      startTransition(() =>
        checkCompanyExistsWithNameOrTRN(name, value, registerData.franchise)
      );
    } else if (name === "companyCode") {
      setRegisterData({ ...registerData, companyCode: value });
      startTransition(() =>
        checkCompanyExistsWithNameOrTRN(name, value, registerData.franchise)
      );
    } else {
      setRegisterData({
        ...registerData,
        [name]: value,
      });
    }
  };

  const handleRegistrationSubmit = async () => {
    // Client validations
    if (companyError.flag) {
      toast.error(`${companyError.message}`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 8000, // Optional: Adjust the duration before the toast closes
        style: { width: "400px" }, // Adjust the width
      });
      return;
    }

    const inputErrors = registerCustomerInputValidation(registerData);
    if (inputErrors.length > 0) {
      const missingFields = inputErrors?.reverse().join(", ");
      toast.error(`Please add all the required fields: ${missingFields}`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 8000, // Optional: Adjust the duration before the toast closes
        style: { width: "400px" }, // Adjust the width
      });
      return;
    }
    setIsServerValidationInprocess(true);
    const formData = registerData;
    if (authByEmail) formData.authProvider = "email";
    const registerResponse = await publicApi(
      "POST",
      "users/registerCustomer",
      formData
    );
    // Server validations
    if (registerResponse?.error) {
      if (registerResponse?.error) {
        if (registerResponse.error?.message) {
          toast.error(registerResponse.error?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000,
            style: { width: "400px" },
          });
        }
        if (registerResponse.error?.length) {
          let errMessage = "";
          registerResponse?.error.forEach((item, index) => {
            errMessage += item.message + ", ";
          });
          toast.error(`Fill all the required fields: ${errMessage}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000, // Optional: Adjust the duration before the toast closes
            style: { width: "400px" }, // Adjust the width
          });
        }
      }
      setTimeout(() => {
        setIsServerValidationInprocess(false);
      }, 300);
      return;
    }

    if (registerResponse?.data?.message === "Registration successful.") {
      toast.success("Registration successful.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 8000,
        style: { width: "400px" },
      });
      dispatch({
        type: "user/updateUserFromAuth",
        payload: registerResponse.data,
      });
      setCurrentStep(3);
      setIsServerValidationInprocess(false);
      setTimeout(() => {
        navigate("/");
      }, 2500);
    }

    if (registerResponse?.data?.message === "OTP has been Sent.") {
      setCurrentStep(3);
      setIsServerValidationInprocess(false);
      toast.success("A OTP has been sent to your email for verification.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 8000,
        style: { width: "400px" },
      });
      setOtpState(true);
      setTimeout(() => {
        otpInputRef.current.focus();
      }, 400);
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[2] = "Submit OTP";
        return updatedSteps;
      });
    }
    if (registerResponse?.data?.message === "Sent email for approval.") {
      setIsServerValidationInprocess(false);
      setOtpState(false);
      setPendingAdminApproval(true);
      toast.success(
        "Sent an email to Admins to approve your request to link with company profile.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        }
      );
      setSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];
        updatedSteps[2] = "Pending Admin Approval";
        return updatedSteps;
      });
      setCurrentStep(3);
      setTimeout(() => {
        navigate("/login");
      }, 8000);
    }
  };

  const handleOtpValidation = async (otp) => {
    if (otp && otp.length === 5) {
      setIsServerValidationInprocess(true);
      const verifyOtpResponse = await publicApi(
        "POST",
        "users/verifyCustomerRegisterOtp",
        { email: registerData.emailId, otp: otp }
      );
      // console.log(verifyOtpResponse)
      if (verifyOtpResponse?.data?.message === "Registration successful.") {
        toast.success(verifyOtpResponse?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });
        // localStorage.setItem("_id", verifyOtpResponse.data?.data?._id);
        dispatch({
          type: "user/updateUserFromAuth",
          payload: verifyOtpResponse.data.data,
        });
        setOtpError(false);
        setOtpState(false);
        setIsServerValidationInprocess(false);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      } else if (verifyOtpResponse?.error?.message === "Incorrect OTP") {
        setIsServerValidationInprocess(false);
        toast.error(verifyOtpResponse?.error?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });
        setOtpError(true);
        setTimeout(() => {
          otpInputRef.current.focus();
        }, 400);
      } else if (verifyOtpResponse?.data?.linkWithExistingCompany) {
        setIsServerValidationInprocess(false);
        setOtpState(true);
        setPendingAdminApproval(true);
        toast.success(
          "Sent an email to Admins to approve your request to link with company profile.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000,
            style: { width: "400px" },
          }
        );
        setSteps((prevSteps) => {
          const updatedSteps = [...prevSteps];
          updatedSteps[2] = "Pending Admin Approval";
          return updatedSteps;
        });
        setCurrentStep(3);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      } else {
        setIsServerValidationInprocess(false);
        toast.error("An error occurred while verifying the otp.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });
      }
    }
  };

  const handleResendOtp = async () => {
    setIsServerValidationInprocess(true);
    try {
      const resendOtpResponse = await publicApi(
        "POST",
        "users/resendRegisterOtp",
        { email: registerData.emailId }
      );
      if (resendOtpResponse?.data?.status === true) {
        toast.success(resendOtpResponse.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });
        setIsServerValidationInprocess(false);
        setRegisterData({ ...registerData, otp: "" });
        setTimeout(() => {
          otpInputRef.current.focus();
        }, 200);
        setOtpError(false);
      } else {
        toast.error("An error occurred while trying to send the otp.");
        setIsServerValidationInprocess(false);
      }
    } catch (error) {
      toast.error("An error occurred while trying to send the otp.");
      setIsServerValidationInprocess(false);
    }
  };

  const checkUserExistWithEmail = async (email) => {
    if (isValidEmail(email)) {
      const response = await publicApi(
        "POST",
        "users/checkUserExistWithEmail",
        { email: email }
      );
      if (response?.success)
        setEmailError({
          flag: response.data?.userExists,
          message: response?.data?.message ?? "",
        });
    }
  };

  const checkCompanyExistsWithNameOrTRN = async (key, value, franchise) => {
    // If user creating a new company along with the registration then, validate if the company already exists with that name or TRN
    // If another company exists with the given name or TRN then validation error, should display error
    if (!registerData.linkWithExistingCompany) {
      if (key === "companyName" && value && value.length) {
        const response = await publicApi(
          "POST",
          "company/isCompanyAlreadyRegistered",
          { key: "companyName", value: value, franchise }
        );

        if (response?.data?.companyExists === false) {
          setCompanyError({ flag: false, message: "" });
        } else if (response?.data?.companyExists) {
          setCompanyError({ flag: true, message: response?.data?.message });
        } else if (response?.error) {
          toast.error(response.error);
        }
      }
    }
    // If User wants to link with the company then should check if the company exists with the given code or nam
    // If exists the validation is true else false
    if (registerData.linkWithExistingCompany) {
      if (
        (key === "companyName" || key === "companyCode") &&
        value &&
        value.length
      ) {
        const response = await publicApi(
          "POST",
          "company/isCompanyAlreadyRegistered",
          { key: key, value: value, franchise }
        );

        if (response?.data?.companyExists === false) {
          setCompanyError({
            flag: true,
            message: `Could not find company by given ${key === "companyCode" ? "Code" : "Name"
              }.`,
          });
        } else if (response?.data?.companyExists) {
          setCompanyError({
            flag: false,
            message: `Company found with given ${key === "companyCode" ? "Code" : "Name"
              }`,
          });
        } else if (response?.error) {
          toast.error(response.error);
        }
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleReadmeModal = () => {
    setReadmeModalOpen(!readmeModalOpen);
  };

  useEffect(() => {
    if (registerData.linkWithExistingCompany) {
      // console.log(registerData.franchise, registerData.isCompanyCodeOrName)
      if (registerData.isCompanyCodeOrName === "Code") {
        startTransition(() =>
          checkCompanyExistsWithNameOrTRN(
            "companyCode",
            registerData.companyCode,
            registerData.franchise
          )
        );
      } else {
        startTransition(() =>
          checkCompanyExistsWithNameOrTRN(
            "companyName",
            registerData.companyName,
            registerData.franchise
          )
        );
      }
    }
  }, [registerData.franchise.area]);

  const redirectToLogin = () => {
    navigate("/login");
  };

  // return <UserManual />

  return (
    <PageContainer>
      <CurvedShape style={{ top: "-150px", left: "-150px" }} />
      <CurvedShape style={{ bottom: "-100px", right: "-100px" }} />
      <RegisterContainer>
        <RegisterSection>
          <Box sx={{ pb: 2 }}>
            <img
              src={"/fixmyofficeHeroLogo.png"}
              alt="Logo"
              style={{ maxWidth: "250px", height: "auto", marginLeft: "-20px" }}
            />
          </Box>
          <Typography variant="body2" sx={{ pb: 2, color: "text.secondary" }}>
            Welcome ! Register with Fixmyoffice.
          </Typography>
          <Box sx={{ width: "100%", padding: "5px" }}>
            <Stepper activeStep={currentStep - 1} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": {
                        fontSize: "0.875rem", // Adjust this value as needed
                      },
                    }}
                    StepIconProps={{
                      sx: {
                        "&.Mui-active": {
                          color: "#6c3169",
                        },
                        "&.Mui-completed": {
                          color: "#6c3169",
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {currentStep === 1 && (
            <>
              <AuthSection>
                <StyledButton
                  variant="outlined"
                  fullWidth
                  startIcon={
                    <img
                      src="/googleIcon.png"
                      alt="Google"
                      style={{ width: 24, height: 24 }}
                    />
                  }
                  onClick={() => handleRegistration("Google")}
                >
                  {isWaitingAuth ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Google"
                  )}
                </StyledButton>
                <StyledButton
                  variant="outlined"
                  fullWidth
                  startIcon={
                    <img
                      src="/microsoftIcon.png"
                      alt="Microsoft"
                      style={{ width: 24, height: 24 }}
                    />
                  }
                  onClick={() => handleRegistration("Microsoft")}
                >
                  {isWaitingAuth ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Microsoft"
                  )}
                </StyledButton>
                <StyledButton
                  variant="outlined"
                  fullWidth
                  startIcon={<EmailIcon />}
                  onClick={() => handleRegistration("email")}
                >
                  Email
                </StyledButton>
                <Typography
                  variant="body2"
                  sx={{ pb: 2, color: "text.secondary" }}
                >
                  Already have an account?{" "}
                </Typography>
                <StyledButton
                  variant="outlined"
                  fullWidth
                  onClick={redirectToLogin}
                >
                  Login
                </StyledButton>
              </AuthSection>
            </>
          )}
          {currentStep === 2 && !isServerValidationInprocess && (
            <>
              <Grid container spacing={2} sx={{ pt: 4 }}>
                {/* First Section */}
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectBox
                        required={true}
                        label="Country"
                        options={franchiseData.countries.map((country) => ({
                          name: country,
                          key: country,
                        }))}
                        name={"franchiseCountry"}
                        value={registerData.franchise.country}
                        onChange={handleValueChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <SelectBox
                        required={true}
                        label="Area"
                        options={
                          registerData.franchise.country
                            ? franchiseData.areas[
                              registerData.franchise.country
                            ].map((area) => ({ name: area, key: area }))
                            : []
                        }
                        name={"franchiseArea"}
                        value={registerData.franchise.area}
                        onChange={handleValueChange}
                      />
                    </Grid>

                    {["Microsoft", "Google"].includes(
                      registerData.authProvider
                    ) ? (
                      <Grid item xs={12}>
                        <TextInput
                          required={true}
                          name={"displayName"}
                          label="Full Name"
                          value={registerData.displayName}
                          onChange={handleValueChange}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            required={true}
                            name={"firstName"}
                            label="First Name"
                            value={registerData.firstName.toUpperCase()}
                            onChange={handleValueChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            required={true}
                            name={"lastName"}
                            label="Last Name"
                            onChange={handleValueChange}
                            value={registerData.lastName.toUpperCase()}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <PhoneInputCustom
                        label="Phone"
                        value={registerData.phoneNumber}
                        name={"phoneNumber"}
                        onChange={(value) => {
                          setRegisterData({
                            ...registerData,
                            phoneNumber: value,
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextInput
                        disabled={["Microsoft", "Google"].includes(
                          registerData.authProvider
                        )}
                        required={true}
                        type={"email"}
                        name={"emailId"}
                        error={emailError.flag}
                        label="Email"
                        value={registerData.emailId}
                        onChange={handleValueChange}
                        helperText={emailError.message}
                      />
                    </Grid>

                    {authByEmail && (
                      <Grid item xs={12}>
                        <TextInput
                          required={true}
                          type={"password"}
                          name={"password"}
                          error={
                            registerData.password.length >= 1
                              ? !isValidPassword(registerData.password)
                              : false
                          }
                          label="Password"
                          value={registerData.password}
                          onChange={handleValueChange}
                          helperText={
                            registerData.password.length >= 1 &&
                              !isValidPassword(registerData.password)
                              ? "Weak Password: Uppercase, lowercase & at least a digit required."
                              : ""
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* Second Section */}
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FlexContainer>
                        <InputLabel variant="body1" fontStyle="italic">
                          Link with existing company
                        </InputLabel>
                        <Switch
                          checked={registerData.linkWithExistingCompany}
                          name={"linkWithExistingCompany"}
                          onChange={handleValueChange}
                        />
                      </FlexContainer>
                    </Grid>
                    {registerData.linkWithExistingCompany &&
                      registerData.franchise.country &&
                      registerData.franchise.area ? (
                      <>
                        <Grid item xs={12}>
                          <SelectBox
                            value={registerData.isCompanyCodeOrName}
                            name={"isCompanyCodeOrName"}
                            label="Choose one"
                            defaultValue={"Code"}
                            options={companyCodeOrNameOptions}
                            required={true}
                            onChange={handleValueChange}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ paddingBottom: "18px" }}>
                          {registerData.isCompanyCodeOrName === "Code" ? (
                            <TextInput
                              id={"link-company-code"}
                              label={`Company Code`}
                              name={"companyCode"}
                              value={registerData.companyCode}
                              inputProps={{
                                maxLength: 5,
                                minLength: 5,
                              }}
                              required={true}
                              onChange={handleValueChange}
                              error={companyError.flag}
                              helperText={
                                companyError.message
                                  ? companyError.message
                                  : `Please enter the company ${registerData.isCompanyCodeOrName} as register.`
                              }
                            />
                          ) : (
                            <TextInput
                              id={"link-company-name"}
                              label={`Company Name`}
                              name={"companyName"}
                              value={registerData.companyName}
                              required={true}
                              onChange={handleValueChange}
                              error={companyError.flag}
                              helperText={
                                companyError.message
                                  ? companyError.message
                                  : `Please enter the company ${registerData.isCompanyCodeOrName} as register.`
                              }
                            />
                          )}
                        </Grid>
                      </>
                    ) : registerData.linkWithExistingCompany ? (
                      <Grid item xs={12}>
                        <InputLabel
                          variant="body1"
                          fontStyle="italic"
                          style={{ color: "red" }}
                        >
                          Select{" "}
                          {(!registerData.franchise.country ||
                            !registerData.franchise.area) &&
                            (!registerData.franchise.country
                              ? `Country & Area`
                              : `Area`)}{" "}
                          to Continue
                        </InputLabel>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="textSecondary">
                            Please ensure that this company name is unique and not already used.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            label="Company Name"
                            name={"companyName"}
                            value={registerData.companyName}
                            required={true}
                            onChange={handleValueChange}
                            error={companyError.flag}
                            helperText={companyError.message}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            label="Street Address"
                            name={"streetLine1"}
                            value={registerData.companyAddress.streetLine1}
                            required={true}
                            onChange={handleValueChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            required={true}
                            label="City"
                            name={"city"}
                            value={registerData.companyAddress.city}
                            onChange={handleValueChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            required={true}
                            label="Region / Emirate"
                            name={"stateProvinceRegion"}
                            value={registerData.companyAddress.stateProvinceRegion ?? ""}
                            onChange={handleValueChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            label="TRN/TAX No"
                            name={"trnNum"}
                            value={registerData.trn}
                            onChange={handleValueChange}
                          />
                        </Grid>


                      </>
                    )}

                    <Grid item xs={12}>
                      <SelectBox
                        value={registerData.preferredContact}
                        name={"preferredContact"}
                        label="Preferred contact Method"
                        options={preferredContactOptions}
                        required={true}
                        onChange={handleValueChange}
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ width: "100%" }}>
                      <SubmitButton
                        onClick={() => handleRegistrationSubmit()}
                        fullWidth
                        variant="contained"
                      >
                        Submit
                        <SendOutlined style={{ marginLeft: 14 }} />
                      </SubmitButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {currentStep === 3 && !otpState && !pendingAdminApproval && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              pt={3}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  align="center"
                  color="primary"
                  fontWeight="bold"
                >
                  Success
                </Typography>
                <br />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutlineIcon color="primary" fontSize="large" />
                </div>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ fontStyle: "italic", fontSize: "1.2rem" }}
                >
                  Welcome to Fix-My-Office &amp; Thanks for Registering
                </Typography>
              </Grid>
            </Grid>
          )}
          {currentStep === 3 && pendingAdminApproval && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              pt={3}
            >
              <Grid item xs={12}>
                {otpState && (
                  <>
                    <Typography
                      variant="h4"
                      align="center"
                      color="#333333"
                      fontWeight="bold"
                    >
                      OTP Verified
                    </Typography>
                    <br />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutlineIcon color="primary" fontSize="large" />
                </div>
                <br />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ fontStyle: "italic", fontSize: "1.2rem" }}
                >
                  We have notified company admin to approve your request, once
                  he/she accepted your request you will be able login with your
                  email, Google or Microsoft account.
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ fontStyle: "italic", fontSize: "1.2rem" }}
                >
                  Thanks for Registering
                </Typography>
              </Grid>
            </Grid>
          )}
          {currentStep === 3 && otpState && !pendingAdminApproval && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "200px", pt: 3 }}
            >
              <Grid container spacing={2} alignItems="center" justify="center">
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">
                    Enter OTP
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    variant="outlined"
                    label="OTP"
                    name="otp"
                    value={registerData.otp}
                    onChange={(e) => {
                      setRegisterData({
                        ...registerData,
                        [e.target.name]: e.target.value,
                      });
                      if (e.target.value?.length === 5) {
                        handleOtpValidation(e.target.value);
                      }
                    }}
                    inputProps={{
                      maxLength: 5,
                      minLength: 5,
                    }}
                    error={isServerValidationInprocess ? false : otpError}
                    helperText={
                      isServerValidationInprocess
                        ? ""
                        : otpError
                          ? "Incorrect OTP"
                          : ""
                    }
                    disabled={isServerValidationInprocess}
                    inputRef={otpInputRef}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "12px" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleResendOtp()}
                    disabled={isServerValidationInprocess}
                  >
                    Resend OTP
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </RegisterSection>
      </RegisterContainer>
    </PageContainer>
  );
};

export default Register;
