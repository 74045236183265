import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Divider,
    Paper,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../index.css';

const CustomDialogueSmall = ({
    open,
    onClose,
    showBackdrop = true,
    headerText,
    children,
    footerButtons,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dialogContentStyle = {
        overflow: 'hidden',
        maxHeight: '50vh', // Reduced max height
        overflowY: 'auto',
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={false} // Removed fullScreen for mobile
            fullWidth
            maxWidth='sm' // Set maxWidth to 'sm' for small size
        >
            {showBackdrop && <div className="backdrop" />}
            <div className="dialog-wrapper">
                <Paper className="dialog-paper">
                    <DialogTitle
                        sx={{
                            backgroundColor: '#244fac',
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {headerText}
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                            sx={{
                                transition: 'background-color 0.3s, color 0.3s', // Add transition effect
                                '&:hover': {
                                    color: 'grey', // Change the color to your desired hover color
                                    backgroundColor: '#fff', // Change the background color on hover
                                },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent className="dialog-content custom-scrollbar" style={dialogContentStyle}>
                        <div>{children}</div>
                    </DialogContent>
                    <Divider />
                    <DialogActions
                        sx={{
                            backgroundColor: '#f4f4f4',
                        }}
                    >
                        {footerButtons}
                    </DialogActions>
                </Paper>
            </div>
        </Dialog>
    );
};

export default CustomDialogueSmall;