import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDialogueMedium from "../../components/commons/CustomDialogueMedium";
import { OPEN_EXPORT_ASSET_MODAL, SET_SELECTED_EXPORT_ASSET } from "../../app/ExportAsset/ExportAssetSlice";
import { EditButton, SaveButton } from "../../components/commons/Buttons";
import { Grid, Typography, useMediaQuery, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import PrintIcon from "@mui/icons-material/Print";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { tonerLevel } from "../../constants/commonConstants";
import CreateNewExportAsset from "./CreateNewExportAsset";

const ViewExportAsset = () => {
  const exportAssetSession = useSelector((state) => state.exportAssetMangement);
  const selectedExportAsset = useSelector(
    (state) => state.exportAssetMangement.selectedExportAsset
  );
  const dispatch = useDispatch();

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleUpdateModalClose = () => {
    dispatch(OPEN_EXPORT_ASSET_MODAL(false));
    dispatch(SET_SELECTED_EXPORT_ASSET(null));
  };
 
  const handleEditClick = () => {
    dispatch(OPEN_EXPORT_ASSET_MODAL("update"));
  };
  const formattedDate = (CurDate) => {
    const date = new Date(CurDate);
    return date.toLocaleDateString('en-IN').split("/").map((val) => (val.toString().length === 1) ? "0" + val : val).join("/")

  }
  const footerButtons = (
    <>
      {exportAssetSession.isOpen === "update" && (
        <SaveButton disabled={false} />
      )}
      {exportAssetSession.isOpen === "view" && (
        <EditButton
          disabled={false}
          onClick={() => {
            handleEditClick();
          }}
        />
      )}
    </>
  );

  return (
    <CustomDialogueMedium
      open={
        exportAssetSession.isOpen === "view"
      }
      onClose={() => {
        handleUpdateModalClose();
      }}
      headerText={"View Export Asset"}
      footerButtons={footerButtons}
    >
      <Grid container spacing={2}>
        {exportAssetSession.isOpen === "view" && (
          <>
            {selectedExportAsset.assetType !== "toner" && (
              <>
                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle2" component="span" gutterBottom>
                    <span style={{ display: 'inline-block', width: '120px' }}>Machine Code:</span>
                    <Typography
                      component="span"
                      variant={isLargeScreen ? "body1" : "caption"}
                      gutterBottom
                    >
                      {selectedExportAsset?.machineCode}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px' }}>Machine Name:</span>

                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {selectedExportAsset?.machineName}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px' }}>Machine Model:</span>

                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {selectedExportAsset?.machineModel}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px' }}>Asset Type:</span>

                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {selectedExportAsset?.assetType}
                </Typography>
              </Typography>
            </Grid>
            {selectedExportAsset.assetType !== "toner" && (
              <>
                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle2" component="span" gutterBottom>
                    <span style={{ display: 'inline-block', width: '120px' }}>Serial Number:</span>

                    <Typography
                      component="span"
                      variant={isLargeScreen ? "body1" : "caption"}
                      gutterBottom
                    >
                      {selectedExportAsset?.serialNumber}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px' }}>Origin:</span>

                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {selectedExportAsset?.origin}
                </Typography>
              </Typography>
            </Grid>
            {selectedExportAsset.assetType !== "toner" && (
              <>
                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle2" component="span" gutterBottom>
                    <span style={{ display: 'inline-block', width: '120px' }}>Health Status:</span>

                    <Typography
                      component="span"
                      variant={isLargeScreen ? "body1" : "caption"}
                      gutterBottom
                    >
                      {selectedExportAsset?.healthStatus}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6} md={6}>
              <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px' }}>Asset Status:</span>

                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {selectedExportAsset?.assetStatus}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px' }}>Cost Price</span>

                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {selectedExportAsset?.costPrice}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px' }}>Selling Price</span>

                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {selectedExportAsset?.sellingPrice}
                </Typography>
              </Typography>
            </Grid>
            {selectedExportAsset?.assetType === "toner" && (
              <>
                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle2" component="span" gutterBottom>
                    <span style={{ display: 'inline-block', width: '120px' }}>Quanity</span>

                    <Typography
                      component="span"
                      variant={isLargeScreen ? "body1" : "caption"}
                      gutterBottom
                    >
                      {selectedExportAsset?.tonerDetails?.quantity}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}
            {selectedExportAsset.isPartsRequired && (
              <>
                <Grid item md={12}>
                  <Typography variant="subtitle2" component="span" gutterBottom>
                    <span style={{ display: 'inline-block', width: '120px' }}>Parts Description:</span>
                    <Typography
                      component="span"
                      variant={isLargeScreen ? "body1" : "caption"}
                      gutterBottom
                    >
                      {selectedExportAsset?.partsDescription}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item xs={6} md={4}>

              <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px', color: '#e85231' }}>Created At:</span>

                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {formattedDate(selectedExportAsset.createdAt)}
                </Typography>
              </Typography>
              <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px', color: '#e85231' }}>Created By:</span>

                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {selectedExportAsset?.createdBy?.displayName}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              {selectedExportAsset?.updatedAt && <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px', color: '#e85231' }}>  Updated At:</span>
                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {formattedDate(selectedExportAsset.updatedAt)}
                </Typography>
              </Typography>}
              {selectedExportAsset?.updatedBy && <Typography variant="subtitle2" component="span" gutterBottom>
                <span style={{ display: 'inline-block', width: '120px', color: '#e85231' }}>  Updated By:</span>
                <Typography
                  component="span"
                  variant={isLargeScreen ? "body1" : "caption"}
                  gutterBottom
                >
                  {selectedExportAsset?.updatedBy}
                </Typography>
              </Typography>}
            </Grid>

            {selectedExportAsset?.assetType === "printer" && (
              <>
                <Grid item xs={12}>
                  <hr />
                  <Chip
                    label="Printer Details"
                    variant="outlined"
                    color="info"
                    icon={<PrintOutlinedIcon />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    gutterBottom
                    style={{ textTransform: "uppercase" }}
                  >
                    Printer Type:{" "}
                    <Typography
                      component="span"
                      variant={isLargeScreen ? "body1" : "caption"}
                      gutterBottom
                    >
                      {selectedExportAsset?.printerDetails?.printerType}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box BorderBottom={2}>
                    <Typography
                      variant="button"
                      component="span"
                      gutterBottom
                      sx={{ textTransform: "uppercase" }}
                    >
                      Toner Levels:{" "}
                    </Typography>
                  </Box>
                </Grid>
                {Object.entries(
                  selectedExportAsset?.printerDetails?.tonerLevel || {}
                ).map(([color, level], index) => {
                  // Find the corresponding name in the tonerLevel array
                  const tonerLevelName = tonerLevel.find((t) => t.key === level)?.name || level;
                  if (!tonerLevelName) {
                    return null
                  }
                  return (
                    <Grid item xs={6} key={index}>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        gutterBottom
                        style={{ textTransform: "uppercase" }}
                      >
                        {color}:{" "}
                        <Typography
                          component="span"
                          variant={isLargeScreen ? "body1" : "caption"}
                          gutterBottom
                        >
                          {tonerLevelName}
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </>
            )}
          </>
        )}
      </Grid>
    </CustomDialogueMedium>
  );
};

export default ViewExportAsset;
