// React and hooks
import React, { useCallback, useEffect } from 'react';

// Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from 'react-redux';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

// Material UI
import { useTheme as muiUseTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import BlockIcon from '@mui/icons-material/Block';

// Date locale
import enLocale from "date-fns/locale/en-GB";

// Page Components and Child Components
import PrivateRoutes from "./PrivateRoutes";
import CustomerLogin from "./pages/Login";
import AdminChatComponent from "./pages/Support/AdminChatSupport/AdminChatSupport";
import UserManagement from "./pages/UserMangaement/UserManagement";
import TicketsManagement from "./pages/TicketManagement/TicketsPage";
import CreateUser from "./pages/UserMangaement/CreateUser";
import TicketQuickSearch from './pages/TicketManagement/TicketQuickSearch/TicketQuickSearch';
import Dashboard from "./pages/Dashboard/Dashboard";
import CreateCompany from './pages/ComapnyManagement/CompanyCreate';
import CompanyManagement from './pages/ComapnyManagement/CompanyManagement';
import Settings from './pages/Settings/Settings';
import CustomFields from './pages/Settings/CustomFields/CustomFields';
import AssetManagement from './pages/AssetManagement/AssetManagement'

// Toast
import { ToastContainer } from 'react-toastify';

// Theme
import theme from './theme';
import CreateOrUpdateAssetView from './pages/AssetManagement/CreateOrUpdateAssetView';
import CreateFrachise from './pages/FranchiseMangement/CreateFrachise';
import ListFranchise from './pages/FranchiseMangement/ListFranchise';
import { updateCurrentFranchise } from './app/Franchise/FranchiseSlice';
import { superAdminCompanyExist } from './app/Ticket/Service';
import ListExportAssets from './pages/ExportAssetMangement/ListExportAssets';
import ItAmcManagement from './pages/ItAmcManagement/ItAmcManagement';

import Register from './pages/Register';

import EnquiryManagement from './pages/EnquirySupport/EnquiryManagement';



function NoPermission() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            textAlign="center"
        >
            <BlockIcon style={{ fontSize: 120, color: '#f44336' }} />
            <Typography variant="h4" gutterBottom>
                Access Denied
            </Typography>
            <Typography variant="subtitle1">
                Sorry, you do not have the necessary permissions to view this page or requested page does not exist.
            </Typography>
        </Box>
    );
}

function Home() {
    return (
        <div>
            <h1>Dashboard - Work Inprogress</h1>
        </div>
    )
}

const App = () => {
    const muiTheme = muiUseTheme();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const sessionUserRole = useSelector((state) => state?.userData?.data?.role);
    const sessionUser = useSelector((state) => state?.userData?.data);
    const quickSearchModalOpen = useSelector((state) => state.ticketManagement.ticketQuickSearch.isModalOpen)

    const handleKeyDown = useCallback((event) => {
        if (event.ctrlKey && event.shiftKey && event.key === 'K') {
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'ticketQuickSearch',
                    field: 'isModalOpen',
                    value: !quickSearchModalOpen,
                },
            });
        }
    }, [dispatch, quickSearchModalOpen]);

    useEffect(() => {
        if (sessionUserRole === 'superAdmin' || sessionUserRole === 'technicalAdmin' || sessionUserRole === 'coreAdmin') {
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [sessionUserRole, handleKeyDown]);
    useEffect(() => {

        if (sessionUser?.franchise?.area) {
            const getSuperAdmin = async () => {
                const respo = await superAdminCompanyExist()

                if (respo.data) {
                    dispatch(updateCurrentFranchise(respo.data))
                }
                // setSuperAdminCompanyExist(!respo.data.isSuperAdminExist)
            }
            getSuperAdmin()
        }
    }, [sessionUser.franchise])

    const renderRoutes = () => (
        <Routes>
            <Route element={<PrivateRoutes />}>
                <Route
                    element={['coreAdmin', 'superAdmin', 'technicalAdmin', 'customerAdmin', 'customerUser', 'supportAgent'].includes(sessionUserRole) ? <Dashboard /> : <Home />}
                    path="/"
                />
                <Route
                    element={
                        isMobile
                            ? <div>This page is not compatible for mobile view</div>
                            : (['coreAdmin', 'superAdmin', 'supportAgent'].includes(sessionUserRole) ? <AdminChatComponent /> : <NoPermission />)
                    }
                    path="/Support"
                />
                <Route
                    element={['coreAdmin', 'superAdmin', 'customerAdmin', 'technicalAdmin', 'technician', 'supportAgent'].includes(sessionUserRole) ? <UserManagement /> : <NoPermission />}
                    path="/User-Management"
                />
                <Route
                    element={<TicketsManagement />}
                    path="/tickets"
                />
                <Route element={<TicketsManagement />} path="/tickets/:id" />
                <Route
                    element={<CompanyManagement />}
                    path="/Company-Management"
                />
                <Route
                    element={sessionUser.userType === 'Admin' ? <AssetManagement /> : <NoPermission />}
                    path="/Asset-Management"
                />
                <Route
                    element={sessionUser.userType === 'Admin' ? <AssetManagement /> : <NoPermission />}
                    path="/Asset-Management/:id"
                />
                <Route
                    element={['superAdmin', 'coreAdmin'].includes(sessionUserRole) ? <Settings /> : <NoPermission />}
                    path="/Settings"
                />
                <Route
                    element={['superAdmin', 'coreAdmin'].includes(sessionUserRole) ? <CustomFields /> : <NoPermission />}
                    path="/Settings/CustomFields"
                />
                <Route
                    element={['coreAdmin'].includes(sessionUserRole) ? <ListFranchise /> : <NoPermission />}
                    path="/franchise"
                />
                <Route
                    element={sessionUser.isEditExportAsset ? <ListExportAssets /> : <NoPermission />}
                    path="/export-assets-management"
                />
                <Route
                    element={sessionUser.userType === "Admin" ? <ItAmcManagement /> : <NoPermission />}
                    path="/it-amc-Management"
                />
                <Route
                    element={sessionUser.userType === "Admin" ? <EnquiryManagement /> : <NoPermission />}
                    path="/enquiry-management"
                />
            </Route>
            <Route element={<Register />} path="/register" />
            
            <Route element={<CustomerLogin />} path="/login" />
        </Routes>
    );

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                <Router>
                    {renderRoutes()}
                </Router>
                {
                    localStorage.getItem('isSessionOut') !== 'true' &&
                    <>
                        <CreateUser />
                        <TicketQuickSearch />
                        <CreateCompany />
                        <CreateOrUpdateAssetView />
                        <CreateFrachise />
                    </>
                }
            </LocalizationProvider>
            <ToastContainer />
        </ThemeProvider>

    );
};

export default App;