import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { closeModal, updateFranchiseFieldValues } from '../../app/Franchise/FranchiseSlice';
import CustomDialogueMedium from '../../components/commons/CustomDialogueMedium';
import { CreateButton } from '../../components/commons/Buttons';
import { Grid, Typography, useMediaQuery, TextField, Autocomplete, Button } from '@mui/material'; // Import Typography
import { useTheme } from '@mui/material/styles';
import { createNewFranchise, getAllFranchisesForRegister, updateFranchise } from '../../app/Franchise/service';
import { updateChangeFranchise } from '../../app/User/UserSlice';

const CreateFranchise = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userData?.data);
    const data = useSelector(state => state.franchiseMangement);
    const [countryOptions, setCountryOptions] = useState([]);
    const [formError, setFormError] = useState({
        areaError: { flag: false, msg: '' },
        countryError: { flag: false, msg: '' },
        imageError: { flag: false, msg: '' },
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleValueChange = (e) => {
        const { name, value } = e.target;

        if (value.trim() === '') {
            setFormError(prev => ({ ...prev, [`${name}Error`]: { flag: true, msg: `${name.charAt(0).toUpperCase() + name.slice(1)} is required` } }));
        } else {
            setFormError(prev => ({ ...prev, [`${name}Error`]: { flag: false, msg: '' } }));
        }

        dispatch(updateFranchiseFieldValues({ key: name, value }));

    };

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            // Update the state with the selected image
            dispatch(updateFranchiseFieldValues({ key: 'image', value: imageFile }));
        }
    };

    useEffect(() => {
        const getData = async () => {
            if (!data.isCreateOrEditModalOpen) return null;
            try {
                const franchiseData = await getAllFranchisesForRegister();
                setCountryOptions(franchiseData.data.countries); // Set country options for autocomplete
            } catch (error) {
                // console.log(error);
            }
        };
        getData();
    }, [data.isCreateOrEditModalOpen]);

    const handleSubmit = async () => {
        try {
            // Basic validation for area, minRange, maxRange, and image fields
            if (data.create.area.trim() === '') {
                setFormError(prev => ({ ...prev, areaError: { flag: true, msg: 'Area is required' } }));
                return;
            }

            if (user.role === 'coreAdmin') {
                const formData = new FormData();
                formData.append('area', data.create.area);
                formData.append('country', data.create.country);
                formData.append('image', data.create.image);
                // console.log(formData.get('image'))
                const result = await createNewFranchise(formData);
                // console.log(result)
                // const response = await updateFranchise({ franchise: data.create });
                toast.success('Franchise created successfully!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    style: { width: '400px' },
                });
                dispatch(closeModal());
                dispatch(updateChangeFranchise(data.create));
            }
        } catch (error) {
            toast.error('Failed to create Franchise', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            // console.log(error);
        }
    };

    const footerButtons = (
        <>
            <CreateButton onClick={handleSubmit} disabled={data.isLoading} />
        </>
    );

    if (user.role !== 'coreAdmin') {
        return <></>;
    }

    return (
        <div>
            <CustomDialogueMedium
                open={data.isCreateOrEditModalOpen && data.modalMode === 'create'}
                onClose={() => { dispatch(closeModal()) }}
                headerText={"Create Franchise "}
                footerButtons={footerButtons} 
            >
                {/* Add Heading Here */}
                <Typography variant="h4" style={{ textAlign: "center" }} gutterBottom>
                    Create Franchise
                </Typography>

                <Grid container spacing={4}>
                    {/* Grid item for image preview */}
                    {data.create.image && (
                        <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                            <img src={URL.createObjectURL(data.create.image)} alt="Selected" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                        </Grid>
                    )}
                    {/* Grid item for image upload */}
                    <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                        <input
                            accept="image/*"
                            id="image-upload"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleImageChange}
                        />
                        <label htmlFor="image-upload">
                            <Button variant="contained" component="span">
                                Upload Image
                            </Button>
                        </label>
                        {formError.imageError.flag && (
                            <Typography variant="body2" color="error">
                                {formError.imageError.msg}
                            </Typography>
                        )}
                    </Grid>
                    {/* Grid items for country and area */}
                    <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                        <Autocomplete
                            options={countryOptions}
                            value={data.create.country}
                            onChange={(event, newValue) => {
                                dispatch(updateFranchiseFieldValues({ key: 'country', value: newValue }));
                            }}
                            renderInput={(params) => <TextField {...params} label="Country" onChange={(e) => dispatch(updateFranchiseFieldValues({ key: 'country', value: e.target.value }))} />}
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                        <TextField
                            required
                            label="Area"
                            value={data.create.area}
                            name="area"
                            type="text"
                            onChange={handleValueChange}
                            error={formError.areaError.flag}
                            helperText={formError.areaError.msg}
                        />
                    </Grid>
                </Grid>


            </CustomDialogueMedium>
        </div>
    );
}

export default CreateFranchise;
