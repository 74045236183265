import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import FirebaseActiveUser from "./FirebaseActiveUser";
import { useSelector, useDispatch } from "react-redux";
import IncomingChatsRedux from "../pages/Support/AdminChatSupport/ChatSection-1-LeftComponent/IncomingChatsRedux";
import AttendedChatsRedux from "../pages/Support/AdminChatSupport/ChatSection-1-LeftComponent/AttendedChatsRedux";
import CustomerChatComponent from "./CustomerChatComponent/CustomerChatComponent";
import { privateApi } from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Menu, MenuItem, Divider, Select } from '@mui/material';
import { getAuth, signOut } from "firebase/auth";
import { stringToColor } from "../utilities/commonUtils";
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import AppSidebar from "./Sidebar";
import UserRoleChip from "../components/commons/UserRoleChip";
import { CreateButton, HeaderCreateBtn } from "./commons/Buttons";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import NewTicketView from "../pages/TicketManagement/NewTicketView";
import ExistingTicketView from "../pages/TicketManagement/SavedTicketView";
// import {messaging} from '../utilities/auth-config/firebase-config'
// import { getToken } from "firebase/messaging";
import BusinessIcon from '@mui/icons-material/Business';
import InventoryIcon from '@mui/icons-material/Inventory';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import FranchiseSelect from "./FranchiseSelect";
import { openModal } from "../app/Franchise/FranchiseSlice";
const themeColor = "#3f51b5";

const Header = styled(AppBar)({
    gridArea: "header",
    zIndex: 1201,
    background: themeColor,
});

const HeaderTitle = styled(Typography)({
    flexGrow: 1,
});

const AppHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [createAnchorE1, setCreateAnchorE1] = useState(null);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const user = useSelector((state) => state.userData?.data);
    const newTicketModalOpen = useSelector((state) => state.ticketManagement.newTicketModalOpen);
    const existingTicketModalOpen = useSelector((state) => state.ticketManagement.existingTicketModalOpen);
    const isAdminAttendingChats = useSelector((state) => state.chats.isChatModeOn);

    const franchise = useSelector((state) => state.franchiseMangement.currentFranchise);

    const getUserData = async () => {
        const userId = localStorage.getItem('_id');
        if (!userId) {
            navigate('/login');
            return; // Exit the function if there's no userId
        }

        const path = `users/getUserData/${userId}`;
        try {
            const response = await privateApi.get(path);
            if (response?.data?.message === 'Ok') dispatch({ type: 'user/updateUserFromAuth', payload: response?.data?.data });
        } catch (error) {
            toast.warning('An un-expected error occurred in authorization.')
            navigate('/login');
            localStorage.removeItem('_id');
        }
    };

    async function getNotificationPermission() {
        return
        // const permission = await Notification.requestPermission();
        // if (permission === 'granted') {
        // generate token
        // const currentToken = await getToken(messaging, {
        //     vapidKey: 'BKfFnJhK7ptn834uVkpYokDHbiXD3q2_pO3VlVOB3C_823iAGdAKpZhcVy7cCysd1iIHn5V8q28kgxcby2iypVg'
        // });
        // if (currentToken) {
        //     await privateApi.put('users/updateTokenAndChatMode', {
        //         token: currentToken
        //     });
        // }

        // } else if (permission === 'denied') {
        //     alert('Denied')
        // }
    }

    useEffect(() => {
        if (!user._id) getUserData();

        // get notification permission from the client user
        getNotificationPermission()
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await privateApi.delete('users/logout');
            // Firebase logout
            const auth = getAuth();
            await signOut(auth);
            // After successful logout, you can perform any necessary cleanup or redirect
            localStorage.removeItem('_id'); // Clear user data
            dispatch({ type: 'user/resetUserState' });
            dispatch({ type: 'ticketManagement/resetToInitial' });
            dispatch({ type: 'chats/resetToInitial' });
            dispatch({ type: 'userManagement/resetToInitial' });
            navigate('/login'); // Redirect to login page
        } catch (error) {
            // Handle any error that occurs during logout
            toast.error('Logout failed. Please try again.');
        }
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleCreateDrawerOpen = (event) => {
        setCreateAnchorE1(event.currentTarget);
        setCreateDrawerOpen(true);
    };

    const handleCreateDrawerClose = () => {
        setCreateAnchorE1(null);
        setCreateDrawerOpen(false);
    };


    const getCurrentPage = () => {
        switch (window.location.pathname) {
            case '/':
                return 'Dashboard'
            case '/tickets':
                return 'Tickets Management'
            case '/Support':
                return 'Chat Support'
            case '/User-Management':
                return 'User Management'
            case '/Settings':
                return 'Settings'
            case '/Settings/CustomFields':
                return 'CustomFields'
            case '/Asset-Management':
                return 'Asset Management'
            case '/Company-Management':
                return 'Company Management'
            case '/export-assets-management':
                return 'Export Asset'
            default:
                return ''
        }
    }



    return (
        <Header id="app-header" sx={{ height: '4.5rem', padding: '.2rem' }}>
            <Toolbar>
                {isMobile && (
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer}
                        sx={{ marginRight: '20px' }}
                    >
                        <MenuIcon fontSize="large" />
                    </IconButton>
                )}
                <div style={{ width: '200px' }}> {/* Set a specific width for the container */}
                    <img src={franchise && franchise?.logoUrl ? franchise.logoUrl : require("../MediaContents/AFT Logo 2.png")} alt="Logo" style={{ Width: 'auto', height: '100%', maxHeight: '4rem' }} />
                </div>
                {/* Page Name */}
                <div style={{ width: "110px" }}></div>
                {!isMobile && (
                    <HeaderTitle variant="h6" noWrap component="div">
                        {getCurrentPage()}
                    </HeaderTitle>
                )}



                {
                    <div style={{ marginRight: '40px' }}>
                        {
                            user.role !== 'customerUser' &&
                            <HeaderCreateBtn
                                onClick={handleCreateDrawerOpen}
                            />
                        }
                    </div>
                }
                {
                    <div style={{ marginRight: '40px' }}>
                        {
                            user.role === 'coreAdmin' && <FranchiseSelect />}
                    </div>
                }
                <Menu
                    sx={{ marginTop: '5px' }}
                    anchorEl={createAnchorE1}
                    open={createDrawerOpen}
                    onClose={handleCreateDrawerClose}
                >
                    {['coreAdmin', 'superAdmin', 'technicalAdmin', 'technician' , 'supportAgent'].includes(user.role) && (
                        <MenuItem
                            onClick={() => {
                                dispatch({ type: 'ticketManagement/handleTicketModals', payload: { modalType: 'newTicketModalOpen' } })
                                handleCreateDrawerClose();
                            }}>
                            <LabelOutlinedIcon sx={{ marginRight: 1 }} />
                            Ticket
                        </MenuItem>
                    )}
                    {['coreAdmin', 'superAdmin', 'customerAdmin', 'technicalAdmin', 'technician', 'supportAgent'].includes(user.role) && (
                        <MenuItem
                            sx={{ minWidth: '125px' }}
                            onClick={() => {
                                dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'isCreateModalOpen', value: true, } })
                                handleCreateDrawerClose();
                            }}>
                            <AccountCircleIcon sx={{ marginRight: 1 }} />
                            User
                        </MenuItem>
                    )}
                    {['coreAdmin', 'superAdmin', 'technicalAdmin', 'technician', 'supportAgent'].includes(user.role) && (
                        <MenuItem
                            onClick={() => {
                                dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'mainState', field: 'isCreateOrEditModalOpen', value: true, } })
                                handleCreateDrawerClose();
                            }}>
                            <BusinessIcon sx={{ marginRight: 1 }} />
                            Company
                        </MenuItem>
                    )}
                    {user.userType === 'Admin' && (
                        <MenuItem
                            onClick={() => {
                                dispatch({ type: 'assetManagement/updateFieldValues', payload: { state: 'mainState', field: 'isCreateOrEditModalOpen', value: true, } })
                                handleCreateDrawerClose();
                            }}>
                            <InventoryIcon sx={{ marginRight: 1 }} />
                            Asset
                        </MenuItem>
                    )}
                    {'coreAdmin' === user.role && (
                        <MenuItem
                            onClick={() => {
                                dispatch(openModal())
                                handleCreateDrawerClose();
                            }}>
                            <AddBusinessIcon sx={{ marginRight: 1 }} />
                            Franchise
                        </MenuItem>
                    )}
                    {
                        user.userType === 'Admin' &&
                        <MenuItem disabled onClick={handleCreateDrawerClose}>
                            <DescriptionOutlinedIcon sx={{ marginRight: 1 }} />
                            Invoice
                        </MenuItem>
                    }
                </Menu>

                <div>
                    <div style={{ cursor: 'pointer' }} onClick={handleClick}>
                        {user.photoURL ? <Avatar src={user.photoURL} /> :
                            <Avatar
                                alt="User Avatar"
                                src={user.photoURL} // src attribute will render the avatar from the photoURL if available
                                style={{
                                    backgroundColor: user.photoURL
                                        ? 'transparent'
                                        : user.displayName
                                            ? stringToColor(user.displayName)
                                            : stringToColor(`${user.firstName} ${user.lastName}`),
                                }}
                            >
                                {/* Only render initial character if photoURL is not available and displayName or firstName is available */}
                                {(!user.photoURL && (user.displayName || user.firstName)) ? (
                                    user.displayName
                                        ? user.displayName[0]
                                        : user.firstName[0]
                                ) : null}
                            </Avatar>
                        }


                    </div>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                width: '300px',
                                marginTop: '5px',
                                padding: '7px',
                                borderRadius: '8px',
                                backgroundColor: '#fff',
                            },
                        }}
                    >
                        <MenuItem
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                borderRadius: '10px',
                                border: 'solid 1px #e0f7fa',
                                backgroundColor: '#e0f7fa !important',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Added box shadow
                                '&:hover': {
                                    backgroundColor: '#e0f7fa !important', // Hover background color
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Adjusted box shadow on hover
                                },
                            }}
                        >
                            {/* Avatar */}
                            <Avatar
                                alt="User Avatar"
                                src={user.photoURL}
                                style={{
                                    marginRight: '8px',
                                    backgroundColor: user.photoURL
                                        ? 'transparent'
                                        : user.displayName
                                            ? stringToColor(user.displayName)
                                            : stringToColor(`${user.firstName} ${user.lastName}`),
                                }}
                            >
                                {!user.photoURL && (user.displayName || user.firstName) ? (
                                    user.displayName
                                        ? user.displayName[0]
                                        : user.firstName[0]
                                ) : null}
                            </Avatar>

                            {/* User information */}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* Name */}
                                {/* Display Name */}
                                <span style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                    {`${user.displayName || `${user.firstName} ${user.lastName}`}`?.slice(0, 25)}
                                    {`${user.displayName || `${user.firstName} ${user.lastName}`}`?.length > 25 ? '...' : ''}
                                </span>

                                {/* Company Name */}
                                <span style={{ marginBottom: '4px' }}>
                                    {user.company?.companyName?.slice(0, 28)}
                                    {user.company?.companyName?.length > 28 ? '...' : ''}
                                </span>

                                {/* Company Code */}
                                <span style={{ marginBottom: '4px' }}>
                                    {'Company Code: '}
                                    {user.company?.companyCode}
                                </span>
                                {/* Role */}
                                <UserRoleChip role={user.role} />
                            </div>
                        </MenuItem>
                        <Divider />

                        <MenuItem
                            onClick={handleLogout}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '8px',
                                borderRadius: '4px',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0', // Hover background color
                                },
                            }}
                        >
                            <ExitToAppIcon fontSize="medium" style={{ marginRight: '8px' }} />
                            <span style={{ fontWeight: 'bold' }}>Logout</span>
                        </MenuItem>
                        {/* <Divider /> */}
                    </Menu>
                </div>
            </Toolbar>
            {/* <FirebaseActiveUser user={user} /> */}
            {
                isAdminAttendingChats &&
                <>
                    <IncomingChatsRedux />
                    <AttendedChatsRedux />
                </>
            }
            {
                (user.userType === 'Customer' ||  user.role === 'supportAgent') &&
                <CustomerChatComponent />
            }

            {newTicketModalOpen && (
                <NewTicketView />
            )}
            {existingTicketModalOpen && (
                <ExistingTicketView />
            )}

            {/* Render the sidebar in app drawer from Header */}
            {isMobile && (
                <Drawer anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                    ModalProps={{ BackdropProps: { invisible: true } }}
                >
                    <AppSidebar isMobile={isMobile} setDrawerOpen={setDrawerOpen} />
                </Drawer>
            )}
        </Header>
    );
};

export default AppHeader;
