import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from './service';
import { formatAssetInput, formatFieldValues } from "../../utilities/commonUtils";

const initialState = {
    createOrEditData: {
        id: '',
        customMachineId: '',
        assetType: null,
        contractType: null,
        assetName: null,
        machineCode: null,
        model: null,
        serialNumber: null,
        series: null,
        healthStatus: null,
        lastService: null,
        serviceDurationInDays: 30,
        nextService: null,
        lastServiceBy: null,
        company: null,
        location: null,
        users: [],
        printerType: null,
        counterLimitBlack: '',
        currentCounterBlack: '',
        counterLimitColor: '',
        currentCounterColor: '',
        wasteToner: '',
        tonerLevelBlack: '',
        tonerLevelYellow: '',
        tonerLevelCyan: '',
        tonerLevelMagenta: '',
        partsRequired: '',
        partsNotes: '',
        consumerStockNotes: '',
        tonerStocksAtClient: {
            blackToner: "",
            yellowToner: "",
            magentaToner: "",
            cyanToner: ""
        },
        lastInvoicedDate: null,
        invoiceDayOrDuration: 1,
        invoiceDurationType: 'fixedDayOfMonth',
        nextInvoiceDate: null,
        lastInvoiceBy: null,
        lastInvoiceNumber: '',
        remoteConnectionId: null,
        remoteConnectionUserName: null,
        remoteConnectionPassword: null,
        systemIp: null,
        customFieldValues: [],
        actionRequiredItems:[],
        remarks: null,
        uploads: [],
        isActive: true,
        createdAt: '',
        createdBy: '',
        updatedBy: '',
        updatedAt: '',
    },
    queryData: {
        isActive: true,
        page: '',
        limit: 10,
        totalCount: '',
        totalPages: '',
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    assetsList: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMessage: '',
    errorMessages: [],
    isCreateOrEditModalOpen: false,
    modalMode: 'create' // accepted enums  'create' || 'update'
};


export const createAsset = createAsyncThunk(
    'assetManagement/createAsset',
    async (newAssetInput, { rejectWithValue }) => {
        try {
            const formattedData = formatAssetInput(newAssetInput)

            const response = await service.createAsset(formattedData);

            if (response.status === 201) {
                return response.data;
            }

            return rejectWithValue('An un-expected error occurred while making the request.');
        } catch (error) {
            throw rejectWithValue(error?.message ?? 'An un-expected error occurred while making the request.');
        }
    }
);

export const getAsset = createAsyncThunk(
    'assetManagement/getAsset',
    async (id, { rejectWithValue }) => {
        try {
            const response = await service.getAsset(id);
            if (response.status === 200) {
                return response.data;
            }
            return rejectWithValue('An un-expected error occurred while making the request.');
        } catch (error) {
            return rejectWithValue(error?.message ?? 'An un-expected error occurred while making the request.');
        }
    }
)

export const editAsset = createAsyncThunk(
    'companyManagement/editAsset',
    async (editAssetInput, { rejectWithValue }) => {
        try {
            const { createdAt, updatedAt, createdBy, updatedBy, locationInput, isSuccess, ...assetData } = editAssetInput;

            const formattedData = formatAssetInput(assetData);

            const response = await service.editAsset(formattedData);

            if (response.status === 200) {
                return response.data;
            }

            return rejectWithValue('An un-expected error occurred while making the request.');
        } catch (error) {
            return rejectWithValue(error?.message ?? 'An un-expected error occurred while making the request.');
        }
    }
);

export const getAssetsByQuery = createAsyncThunk(
    'assetManagement/getAssetsByQuery',
    async (queryData, { rejectWithValue }) => {
        try {
            const response = await service.getAssetsByQuery(queryData);

            if (!response || (!queryData.exportCSV && !response.data)) {
                throw new Error('No data received from the service.');
            }

            if (queryData.exportCSV) {
                if (!response) {
                    throw new Error('No data received for CSV export.');
                }
                return { data: response, exportCSV: true };
            } else {
                return response.data;
            }
        } catch (error) {
            return rejectWithValue(error?.message ?? 'An un-expected error occurred while making the request.');
        }
    }
);



const assetSlice = createSlice({
    name: 'assetManagement',
    initialState,
    reducers: {
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },
        updateTonerStocksAtClient: (state, action) => {
            const { field, value } = action.payload;
            state.createOrEditData.tonerStocksAtClient[field] = value;
        },
        resetStateField: (state, actions) => {
            // reset a specific field like create or edit data to its initial state; this will set a given state field to initial state
            // Usage: dispatch(resetStateField({ state: 'createOrEditData', field: 'companyName' })); 
            // Usage: dispatch(resetStateField({ state: 'mainState', field: 'createOrEditData' }));
            const updateState = actions.payload.state;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: initialState[updateState][field],
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: initialState[field],
                }
            }
        },
        resetAssetManagementState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            // CREATE COMPANY EXTRA REDUCERS
            .addCase(createAsset.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(createAsset.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.modalMode = 'update';
                // let formattedFieldValues = []
                // if (action.payload.customFieldValues?.length) {
                //     formattedFieldValues = formatFieldValues(action.payload?.customFieldValues)
                // }
                state.createOrEditData = { ...action.payload};
            })
            .addCase(createAsset.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload instanceof Error ? action.payload.message : action.payload;
            })

            // EDIT ASSET EXTRA REDUCERS
            .addCase(editAsset.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(editAsset.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.createOrEditData = { ...action.payload };
            })
            .addCase(editAsset.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
            })

            // Get AN ASSET TO EDIT 
            .addCase(getAsset.pending, (state) => {
                state.modalMode = 'update';
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(getAsset.fulfilled, (state, action) => {
                state.isCreateOrEditModalOpen = true;
                state.isLoading = false;
                state.isError = false;
                state.createOrEditData = { ...action.payload };
            })
            .addCase(getAsset.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
                state.modalMode = 'create';
                state.createOrEditData = initialState.createOrEditData;
            })
            
            // GET ASSETS TABLE DATA 
            .addCase(getAssetsByQuery.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(getAssetsByQuery.fulfilled, (state, action) => {
                if (action.payload.exportCSV) {
                    state.csvData = action.payload.data; 
                } else {
                    state.assetsList = action.payload.assets;
                }
                state.isLoading = false;
                state.queryData.page = action.payload.page;
                state.queryData.totalCount = action.payload.totalCount;
                state.queryData.totalPages = action.payload.totalPages;

            })
            .addCase(getAssetsByQuery.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
                state.assetsList = [];
                state.queryData.page = '';
                state.queryData.totalCount = '';
                state.queryData.totalPages = '';
            });
    }
})


export const { updateFieldValues, resetAssetManagementState, resetStateField, updateTonerStocksAtClient } = assetSlice.actions;
export default assetSlice.reducer

