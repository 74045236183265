export const isValidEmail = (email) => {
    // Regular expression pattern for email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Test the email against the pattern and return true or false
    return emailPattern.test(email);
};


export const isValidPassword = (password, minLength = 8) => {
    if (!password) return false;
    // Check if the password length is at least the specified minimum length
    if (password.length < minLength) {
        return false;
    }

    // Check if the password contains both uppercase and lowercase letters
    if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
        return false;
    }

    // Check if the password contains at least one digit
    if (!/\d/.test(password)) {
        return false;
    }

    // All checks passed, the password is considered valid
    return true;
};


export const validatePhoneNumberUAEQatar = (phoneNumber) => {
    const pattern = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;

    return (pattern.test(phoneNumber) && phoneNumber.length < 14);

}

const colors = [
    "#F44336", // Red
    "#E91E63", // Pink
    "#9C27B0", // Purple
    "#673AB7", // Deep Purple
    "#3F51B5", // Indigo
    "#2196F3", // Blue
    "#03A9F4", // Light Blue
    "#00BCD4", // Cyan
    "#009688", // Teal
    "#4CAF50", // Green
    "#8BC34A", // Light Green
    "#CDDC39", // Lime
    "#FFEB3B", // Yellow
    "#FFC107", // Amber
    "#FF9800", // Orange
    "#FF5722", // Deep Orange
];

export const stringToColor = (inputString) => {
    // Convert the input string to a numerical value
    let hash = 0;
    for (let i = 0; i < inputString.length; i++) {
        hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Get the index of the color to use
    const index = Math.abs(hash) % colors.length;

    // Return the color
    return colors[index];
};

export const convertFirestoreTimestampToDate = (timestamp) => {
    if (!timestamp?.seconds || !timestamp?.nanoseconds) {
        return "";
    }

    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds;
    const milliseconds = seconds * 1000 + nanoseconds / 1000000;

    const date = new Date(milliseconds);
    const now = new Date();

    const dayDifference = now.getDate() - date.getDate();
    const monthDifference = now.getMonth() - date.getMonth();
    const yearDifference = now.getFullYear() - date.getFullYear();

    if (dayDifference === 0 && monthDifference === 0 && yearDifference === 0) {
        return "Today";
    } else if (dayDifference === 1 && monthDifference === 0 && yearDifference === 0) {
        return "Yesterday";
    } else {
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')
        }-${date.getFullYear()}`;
        return formattedDate;
    }
};


export const  convertFirestoreTimestampToIST = (timestamp) => {
    if (!timestamp?.seconds || !timestamp?.nanoseconds) {
        return ""
    };
    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds;
    const milliseconds = seconds * 1000 + nanoseconds / 1000000;

    const date = new Date(milliseconds);
    const now = new Date();

    const minuteDifference = Math.floor((now - date) / (1000 * 60));
    const isSameDay = now.toDateString() === date.toDateString();

    if (minuteDifference < 1) {
        return "Just now";
    } else if (isSameDay) {
        const options = {
            timeZone: "Asia/Kolkata",
            hour: "2-digit",
            minute: "2-digit",
        };
        return `Today, ${date.toLocaleTimeString("en-IN", options)}`;
    } else {
        const options = {
            timeZone: "Asia/Kolkata",
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        };
        return date.toLocaleDateString("en-IN", options);
    }
};


export const generateCompanyCode = (companyName) => {
    const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    // Extract the first letter of the company name
    const firstLetter = companyName.charAt(0);
    // Remove spaces and convert the remaining name to uppercase
    const nameWithoutSpaces = companyName.replace(/\s/g, '').toUpperCase();
    // If the name is less than 5 characters, generate random characters for the code
    let randomChars = '';
    if (nameWithoutSpaces.length < 5) {
        for (let i = 0; i < 5 - nameWithoutSpaces.length; i++) {
            const randomIndex = Math.floor(Math.random() * possibleChars.length);
            randomChars += possibleChars.charAt(randomIndex);
        }
    }
    // Combine the first letter and random characters to form the code
    let companyCode = firstLetter + nameWithoutSpaces.slice(1, 5) + randomChars;
    // Randomize the characters in the code except for the first character
    companyCode = companyCode
        .split('')
        .map((char, index) => {
            if (index === 0) {
                return char;
            } else {
                const inputIndex = Math.floor(Math.random() * nameWithoutSpaces.length);
                return nameWithoutSpaces.charAt(inputIndex);
            }
        })
        .join('');

    // Return the randomized code with a maximum length of 5 characters
    return companyCode.slice(0, 5);
}


export const formatFieldValues = (payload) => {
    const formattedFieldValue = [];
    if (payload.customFieldValues?.length) {
        payload.customFieldValue?.forEach((item) => {
            const dateString = item.value;
            const [day, month, year] = dateString.split('/');
            const date = new Date(year, month - 1, day);
            formattedFieldValue.push({
                ...item,
                value: item.inputType === 'date' ? date : item.value
            });
        });
    }
    return formattedFieldValue;
}


export const  getOrdinalSuffix = (day) => {
    if (day % 10 === 1 && day !== 11) {
        return day + 'st';
    } else if (day % 10 === 2 && day !== 12) {
        return day + 'nd';
    } else if (day % 10 === 3 && day !== 13) {
        return day + 'rd';
    } else {
        return day + 'th';
    }
}

export const formatAssetInput = (assetInput) => {
    const formattedData = {
        // Basic Details
        assetType: assetInput.assetType?.trim() || null,
        contractType: assetInput?.contractType?.trim() || null,
        assetName: assetInput.assetName?.trim() || null,
        machineCode: assetInput.machineCode?.trim() || null,
        model: assetInput.model?.trim() || null,
        serialNumber: assetInput.serialNumber?.trim() || null,
        series: assetInput.series?.trim() || null,
        // Maintenance Details
        healthStatus: assetInput.healthStatus?.trim() || null,
        lastService: assetInput.lastService || null,
        serviceDurationInDays: Number(assetInput.serviceDurationInDays) || null,
        nextService: assetInput.nextService || null,
        lastServiceBy: assetInput?.lastServiceBy?._id || null,
        // Company & Users
        company: assetInput?.company?.value,
        location: assetInput?.location?.value || null,
        users: assetInput.users.map(user => user._id),
        // Printer Details
        printerType: assetInput.printerType || null,
        // Counter limits
        counterLimitBlack: assetInput.counterLimitBlack || '',
        currentCounterBlack: assetInput.currentCounterBlack || '',
        counterLimitColor: assetInput.counterLimitColor || '',
        currentCounterColor: assetInput.currentCounterColor || '',
        // Toner Details
        wasteToner: assetInput.wasteToner || null,
        tonerLevelBlack: assetInput.tonerLevelBlack || null,
        tonerLevelYellow: assetInput.tonerLevelYellow || null,
        tonerLevelCyan: assetInput.tonerLevelCyan || null,
        tonerLevelMagenta: assetInput.tonerLevelMagenta || null,
        // Parts & Stock Notes
        partsRequired: assetInput.partsRequired || '',
        partsNotes: assetInput.partsNotes || '',
        consumerStockNotes: assetInput.consumerStockNotes || '',
        // Invoice Details
        lastInvoicedDate: assetInput.lastInvoicedDate || null,
        invoiceDayOrDuration: assetInput.invoiceDayOrDuration || 0,
        invoiceDurationType: assetInput.invoiceDurationType || null,
        nextInvoiceDate: assetInput.nextInvoiceDate || null,
        lastInvoiceBy: assetInput.lastInvoiceBy?._id || null,
        lastInvoiceNumber: assetInput.lastInvoiceNumber || '',
        // Connectivity Details
        remoteConnectionId: assetInput.remoteConnectionId?.trim() || null,
        remoteConnectionUserName: assetInput.remoteConnectionUserName?.trim() || null,
        remoteConnectionPassword: assetInput.remoteConnectionPassword?.trim() || null,
        systemIp: assetInput.systemIp?.trim() || null,
        // Custom Fields & Values
        customFieldValues: assetInput.customFieldValues,
        // Remarks & Notes
        remarks: assetInput.remarks?.trim() || null,
        // Files & Document uploads
        uploads: assetInput.uploads || [],
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        tonerStocksAtClient: {
            blackToner: assetInput.tonerStocksAtClient?.blackToner || '',
            yellowToner: assetInput.tonerStocksAtClient?.yellowToner || '',
            cyanToner: assetInput.tonerStocksAtClient?.cyanToner || '',
            magentaToner: assetInput.tonerStocksAtClient?.magentaToner || ''
        }
    }
    if (assetInput.id) formattedData.id = assetInput.id;
    
    return formattedData;
}