import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Grid, Typography, Paper, Button, FormControlLabel, Tooltip, InputLabel, Avatar, Box, Skeleton } from '@mui/material';
import TextInput from '../../components/commons/TextInput';
import SelectInput from '../../components/commons/SelectInput';
import {
    userRoles,
    roleOptionsForSuperAdmin,
    roleOptionsForCustomerAdmin,
    roleOptionsForVendorAdmin,
    userTypes,
    authProviders,
    preferredContactOptions
} from '../../constants/commonConstants';
import { isValidEmail, isValidPassword, stringToColor, validatePhoneNumberUAEQatar } from '../../utilities/commonUtils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff, Edit } from "@mui/icons-material";
import '../../index.css'
import UserRoleChip from '../../components/commons/UserRoleChip';
import Switch from '@mui/material/Switch';
import { ResetButton, SaveButton } from '../../components/commons/Buttons';
import { toast } from "react-toastify";
import { getCompanyLocations, updateUserProfile } from './Service';
import UserEditorSkeleton from './UserEditorSkeleton';
import UserChip from '../../components/commons/UserChip';
import PhoneInputCustom from '../../components/commons/PhoneInput';
import Autocomplete from '../../components/commons/Autocomplete';

const userObjFormat = {
    "_id": "",
    "userType": "",
    "role": "",
    "authProvider": "",
    "displayName": "",
    "thirdPartyUid": "",
    "firstName": "",
    "lastName": "",
    "contactPhoneNumber": "",
    "email": "",
    "preferredContactMethod": "",
    "isActive": null,
    "photoURL": "",
    "createdAt": "",
    "updatedAt": "",
    "customUserId": "",
    "company": {
        "_id": "",
        "companyName": "",
        "companyCode": "",
        "trnNumber": "",
        "companyPhone": "",
        "isActive": "",
        "address": {
            "streetLine1": "",
            "city": "",
            "postalCode": "",
            "stateProvinceRegion": "",
            "country": "",
            "_id": ""
        },
        "createdAt": "",
        "updatedAt": "",
        "__v": ""
    },
    "companyLocation": {
    },
    "companyName": ""
};

const UserEditor = ({ userBeingEdited, setUserBeingEdited }) => {
    const theme = useTheme();
    const containerRef = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const currentAdminUser = useSelector((state) => state.userData.data.role);
    const sessionUser  =  useSelector((state) => state.userData.data);
    const [user, setUser] = useState(userObjFormat);
    const [roleOptions, setRoleOptions] = useState([]);
    const [formError, setFormError] = useState({
        emailError: { flag: false, msg: '' },
        passwordError: { flag: false, msg: '' },
    })
    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordEditEnabled, setPasswordEditEnabled] = useState(false);
    const [isPasswordEdited, setIsPasswordEdited] = useState(false);
    const [companyLocationsList, setCompanyLocationsList] = useState([]);


    useEffect(() => {
        const adjustContainerHeight = () => {
            const windowHeight = window.innerHeight;
            const parentContainer = containerRef.current;

            if (parentContainer) {
                const rect = parentContainer.getBoundingClientRect();
                const offset = rect.top;
                const maxHeight = windowHeight - offset - 50; // Adjust as needed

                parentContainer.style.maxHeight = `${maxHeight}px`;
            }
        };

        adjustContainerHeight();
        window.addEventListener('resize', adjustContainerHeight);

        return () => {
            window.removeEventListener('resize', adjustContainerHeight);
        };
    }, []);


    const getUserRoles = () => {
        let options;
        if (user.userType === 'Admin') {
            options = currentAdminUser === 'coreAdmin' ? [{ key: 'coreAdmin', name: "Core Admin" }, ...roleOptionsForSuperAdmin] : roleOptionsForSuperAdmin;
            // options = roleOptionsForSuperAdmin;
        } else if (user.userType === 'Customer') {
            options = roleOptionsForCustomerAdmin;
        } else if (user.userType === 'Vendor') {
            options = roleOptionsForVendorAdmin;
        } else {
            // Default options if none of the above conditions match
            options = [];
        }
        setRoleOptions(options)
    }

    useEffect(() => {
        if (userBeingEdited) {
            setUser({ ...userBeingEdited });
            // console.log(userBeingEdited)
            getUserCompaniesLocations(userBeingEdited?.company?._id)
        }
    }, []);

    useEffect(() => {
        getUserRoles();
    }, [user.userType])


    const handleValueChange = (e) => {
        const { name, value, checked } = e.target;
        isInputValid(name, value);

        if (name === 'isActive' || name === 'isEditExportAsset') {
            setUser({ ...user, [name]: checked });
        } else if (name === 'firstName' || name === 'lastName') {
            setUser((prevState) => ({
                ...prevState,
                [name]: value,
                displayName: `${name === 'firstName' ? value : prevState.firstName ?? ""} ${name === 'lastName' ? value : prevState.lastName ?? ""}`,
            }));
        } else if (name === 'authProvider' && value !== 'email' && user.password) {
            setUser((prevState) => {
                const { password, ...tempUser } = prevState;
                return { ...tempUser, [name]: value };
            });
        } else if (name === 'userType') {
            setUser({ ...user, userType: value, role: "" });
        } else {
            setUser({ ...user, [name]: value });
        }
    };



    const isInputValid = (name, value) => {
        if (name === 'email') {
            if (!isValidEmail(value)) setFormError({ ...formError, emailError: { flag: true, msg: 'Invalid email' } })
            else setFormError({ ...formError, emailError: { flag: false, msg: userBeingEdited.email !== value ? '** Please choose a Login provider' : '' } });
            return false;
        }
        if (name === 'password') {
            if (!isValidPassword(value)) setFormError({ ...formError, passwordError: { flag: true, msg: 'Weak Password: Uppercase, lowercase & at least a digit required.' } })
            if (!isPasswordEdited) setIsPasswordEdited(true);
            else setFormError({ ...formError, passwordError: { flag: false, msg: '' } });
        }
        return true;
    }


    const handlePasswordVisibilityClick = () => {
        setPasswordVisible((prevVisible) => !prevVisible);
    };

    const handlePasswordEditEnableBtnClick = () => {
        setPasswordEditEnabled(!passwordEditEnabled);
    };

    const handleSubmit = async () => {
        try {
            const requestData = {
                email: user.email,
                preferredContact: user.preferredContactMethod,
                authProvider: user.authProvider,
                isActive: user?.isActive,
                password: user?.password,
                contactPhoneNumber: user.contactPhoneNumber,
                isPasswordEdited: isPasswordEdited,
                companyLocation: user?.companyLocation?.value,
                userType: user.userType,
                role: user.role,
                isEditExportAsset:user.isEditExportAsset
            };
            if (!user.email || !isValidEmail(user.email)) {
                toast.error(`Email is missing or invalid.`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 8000,
                    style: { width: '400px' },
                });
                return
            }
            if (user.authProvider === 'email' && user.authProvider !== userBeingEdited.authProvider && !isValidPassword(user.password)) {
                toast.error(`Please provide a valid password when choosing email as login method, or keep the previous selection of ${userBeingEdited.authProvider}.`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 8000,
                    style: { width: '400px' },
                });
                return;
            }
            if (user.userType !== userBeingEdited.userType && !user.role) {
                toast.error('Please select a valid user role.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 8000,
                    style: { width: '400px' },
                });
                return;
            }
            if (user.authProvider === 'email') {
                if (isPasswordEdited && !isValidPassword(user.password)) {
                    toast.error('Please provide a valid password, if you did not mean to update the password, please close and reopen this user.', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 8000,
                        style: { width: '400px' },
                    });
                    return;
                }
            } else {
                delete requestData.password;
            }

            if (user?.firstName?.trim() === "" && user?.lastName?.trim() === "" && user?.displayName?.trim() === "") {
                toast.error('Please add both first name and last name.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 8000,
                    style: { width: '400px' },
                });
                return;
            } else if (user?.firstName?.trim() !== "" && user?.lastName?.trim() === "") {
                toast.error('Please add both first name and last name.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 8000,
                    style: { width: '400px' },
                });
                return;
            } else if (user?.firstName?.trim() === "" && user?.lastName?.trim() !== "") {
                toast.error('Please add both first name and last name.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 8000,
                    style: { width: '400px' },
                });
                return;
            } else if (userBeingEdited?.displayName != user?.displayName) {
                requestData.lastName = user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);
                requestData.firstName = user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1);
                requestData.displayName = `${user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)} ${user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}`
            }
            setLoading(true);
            const response = await updateUserProfile(user._id, requestData);
            if (response.success === true) {
                toast.success('Profile has been updated successfully.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 8000,
                    style: { width: '400px' },
                });
                setUserBeingEdited({ ...response.data, companyLocation: response.data?.companyLocation?.id ? { value: response.data?.companyLocation?.id, label: response.data?.companyLocation?.locationName, } : null });
                setUser({ ...response.data, companyLocation: response.data?.companyLocation?.id ? { value: response.data?.companyLocation?.id, label: response.data?.companyLocation?.locationName, } : null });
                setIsPasswordEdited(false);
            } else {
                throw new Error(response.message || response)
            }
            setLoading(false);
        } catch (error) {
            if (error.message) {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    style: { width: '400px' },
                });
                setLoading(false);
                return;
            }
            let errorMessage = 'An error occurred while updating the record.';
            if (error.message && Array.isArray(error.message)) {
                errorMessage = error.message.map(err => `${err.filed}: ${err.message}`).join(', ');
            }
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
                style: { width: '400px' },
            });
            setLoading(false);
        }
    };

    const getUserCompaniesLocations = async (userId) => {
        try {
            const locationsList = await getCompanyLocations(userId);
            setCompanyLocationsList(locationsList?.data?.map((item) => ({ value: item.id, label: item.locationName })) || []);
        } catch (error) {
            setCompanyLocationsList([]);
            console.error(`Failed to get locations: ${error.message}`);
        }
    }

    return (
        <div className='custom-scrollbar container-scroll' style={{ marginBottom: '3px', padding: isMobile ? '10px' : '80px', border: '1px solid #ccc', borderRadius: '10px', marginBottom: '20px' }} ref={containerRef}>
            <Grid container spacing={4}>
                {!loading && (
                    <>
                        <Grid item xs={8}>
                            <Typography variant="h5" style={{ color: '#091B95' }}>Edit User</Typography>
                            {/* <br /> */}
                            <Typography variant="body1" fontStyle="italic"> Last Update on: {new Date(user.updatedAt).toLocaleString()} </Typography>
                            <Typography variant="body1" sx={{ marginBottom: '10px' }} fontStyle="italic"> Last Updated by: {user.updatedBy ? <UserChip user={{ displayName: user.updatedBy?.displayName, firstName: user.updatedBy?.firstName, lastName: user.updatedBy?.lastName, photoURL: user.updatedBy?.photoURL }} /> : <UserRoleChip role='superAdmin' />} </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <ResetButton onClick={() => { setUser({ ...userBeingEdited }) }} />
                                </Grid>
                                <Grid item>
                                    <SaveButton onClick={handleSubmit} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={isMobile ? 2 : 1} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ borderRadius: '4px', border: 'solid 1px rgba(0, 0, 0, 0.38)', padding: '4px', height: '105%' }}>
                                        <Avatar
                                            alt="User Avatar"
                                            src={user.photoURL} // src attribute will render the avatar from the photoURL if available
                                            style={{
                                                backgroundColor: user.photoURL
                                                    ? 'transparent'
                                                    : user.displayName
                                                        ? stringToColor(user.displayName)
                                                        : stringToColor(`${user.firstName} ${user.lastName}`),
                                            }}
                                        >
                                            {/* Only render initial character if photoURL is not available and displayName or firstName is available */}
                                            {(!user.photoURL && (user.displayName || user.firstName)) ? (
                                                user.displayName
                                                    ? user.displayName[0]
                                                    : user.firstName[0]
                                            ) : null}
                                        </Avatar>
                                    </div>
                                </Grid>
                                <Grid item xs={isMobile ? 4 : 5}>
                                    <TextInput label="User ID" value={userBeingEdited.customUserId} disabled />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput label="Reference#" value={userBeingEdited._id} disabled />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                                    <SelectInput
                                        name={'userType'}
                                        label="User Type"
                                        value={user.userType}
                                        options={userTypes}
                                        disabled={true}
                                        onChange={handleValueChange}
                                    />
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 6} >
                                    <SelectInput
                                        label="Role"
                                        name='role'
                                        value={user.role}
                                        disabled={!['coreAdmin', 'superAdmin'].includes(currentAdminUser)}
                                        onChange={handleValueChange}
                                        options={[...roleOptions]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                                    <TextInput
                                        label="First Name"
                                        name={'firstName'}
                                        value={user.firstName}
                                        onChange={handleValueChange}
                                    />
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 6} >
                                    <TextInput
                                        label="Last Name"
                                        value={user.lastName}
                                        name={'lastName'}
                                        onChange={handleValueChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput
                                label="Display Name"
                                value={user.displayName}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                                    <TextInput
                                        label="Email"
                                        value={user.email}
                                        name="email"
                                        type="email"  // This enforces email validation
                                        helperText={formError.emailError.msg}
                                        error={formError.emailError.flag}
                                        onChange={handleValueChange}
                                    />
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 6} >
                                    <SelectInput
                                        name={'authProvider'}
                                        label="Login Method"
                                        value={user.authProvider}
                                        options={authProviders}
                                        onChange={handleValueChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            user.authProvider === 'email' && (
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Password"
                                        name="password"
                                        value={user.password}
                                        type={passwordVisible ? "text" : "password"}
                                        onChange={handleValueChange}
                                        required={true}
                                        disabled={!passwordEditEnabled}
                                        error={formError.passwordError.flag}
                                        helperText={formError.passwordError.msg}
                                        InputProps={{
                                            startAdornment:
                                                <span style={{ cursor: "pointer" }} onClick={handlePasswordEditEnableBtnClick}>
                                                    <Edit color='primary' />
                                                </span>,
                                            endAdornment: (
                                                <span
                                                    onClick={handlePasswordVisibilityClick}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {passwordVisible ? (
                                                        < VisibilityOff color='primary' />
                                                    ) : (
                                                        <Visibility color='primary' />
                                                    )}
                                                </span>
                                            ),
                                        }}
                                        onStartAdornmentClick={handlePasswordEditEnableBtnClick}
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                                    <PhoneInputCustom
                                        label="Phone number"
                                        name={'contactPhoneNumber'}
                                        value={user.contactPhoneNumber}
                                        onChange={(value) => {
                                            setUser({ ...user, contactPhoneNumber: value });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 6} >
                                    <SelectInput
                                        label="Preferred Contact Method"
                                        value={user.preferredContactMethod}
                                        onChange={handleValueChange}
                                        name='preferredContactMethod'
                                        options={preferredContactOptions}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                sx={{ marginLeft: '20px' }}
                                control={<Switch
                                    label="Active"
                                    checked={user.isActive}
                                    name='isActive'
                                    onChange={handleValueChange} />}
                                label={user.isActive ? 'Active' : 'Inactive'}
                            />
                        </Grid>
                       {sessionUser.accessExportAsset && <Grid item xs={12} md={6}>
                            <FormControlLabel
                                sx={{ marginLeft: '20px' }}
                                control={<Switch
                                    label="Edit ExportAsset"
                                    checked={user.isEditExportAsset}
                                    name='isEditExportAsset'
                                    onChange={handleValueChange} />}
                                label={user.isEditExportAsset ? 'Edit ExportAsset Active' : ' Edit ExportAsset Inactive'}
                            />
                        </Grid>}
                        {user.role !== "coreAdmin" && <Grid item xs={12}>
                            <Typography variant="h5" sx={{ marginBottom: 1, color: '#091B95' }}>
                                Company Information
                            </Typography>
                            <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
                                <Box sx={{ marginBottom: 2 }}>
                                    <TextInput
                                        label="Company Name"
                                        value={user.company?.companyName}
                                    />
                                </Box>
                                <Box sx={{ marginBottom: 2 }}>
                                    <Autocomplete
                                        label="User Company Location"
                                        name='companyLocation'
                                        value={user?.companyLocation || ""}
                                        onChange={(e) => { setUser({ ...user, companyLocation: e.target.value }) }}
                                        options={companyLocationsList}
                                        required={false}
                                    // error={(!data.company && !data.userType === 'Admin')}
                                    />
                                </Box>
                                <Box sx={{ marginBottom: 2 }}>
                                    <TextInput label="Company Code" value={user.company?.companyCode} disabled />
                                </Box>
                                <Box sx={{ marginBottom: 2 }}>
                                    <TextInput label="TRN Number" value={user.company?.trnNumber} disabled />
                                </Box>
                            </Paper>
                        </Grid>}
                    </>
                )}

                {loading && (
                    <UserEditorSkeleton />
                )}
            </Grid>
        </div>
    );
}


export default UserEditor;
