import { Box, Grid, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextInput from '../../components/commons/TextInput';
import { CreateButton, ResetButton, SearchButton } from '../../components/commons/Buttons';
import CreateItAmcIssue from './CreateItAmcIssue';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataItAmcCsvWithQuery, fetchItAmcIssueWithQuery, OPEN_IT_AMC_CREATE_ISSUE, RESET_IT_AMC_FILTER_DATA, RESET_IT_AMC_UPDATE_DATA, UPDATE_IT_AMC_FILTER_DATA } from '../../app/ItAmc/ItAmcManagement';
import { getCompaniesList } from '../UserMangaement/Service';
import Autocomplete from '../../components/commons/Autocomplete';
import { StyledIconButton } from '../ComapnyManagement/CompanyManagement';
import FileDownload from '@mui/icons-material/FileDownload';
import DownloadCsvButton from './DownloadCsvButton';


const issueStatusOptions = [
    { key: "todo", name: "To Do" },
    { key: "inProgress", name: "In Progress" },
    { key: "done", name: "Done" },
    { key: "hold", name: "Hold" },
]


const FilterItAmcIssue = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const itAmcIssueFilter = useSelector(state => state.itAmcManagement.itAmcIssuesFilter)
    const [companiesList, setCompaniesList] = useState([]);
    const [filterData, setFilterData] = useState({})

    useEffect(() => {
        getCompaniesList()
            .then((result) => {
                if (result?.data?.length) {
                    let tempArr = [];
                    result.data.forEach(item => {
                        tempArr.push({ value: item._id, label: item.companyName, type: item.type });
                    });
                    setCompaniesList(tempArr);
                } else if (result.data) {
                    setCompaniesList([{ value: result.data._id, label: result.data.companyName, type: result?.data?.type }]);
                }
            });


    }, []);

    const handleFilterChange = (e) => {
        setFilterData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const handleSearch = () => {
        dispatch(UPDATE_IT_AMC_FILTER_DATA(filterData))
    }
    const hanldeResetSearch = () => {
        dispatch(RESET_IT_AMC_FILTER_DATA())
        setFilterData({})
    }


    return (
        <>
            <Box sx={{ marginY: "1rem" }}>
                <Grid container spacing={2} justifyContent="start">
                    <Grid item xs={12} md={6} lg={3}>
                        <TextInput
                            label="Code"
                            name={"customIssueId"}
                            value={filterData.customIssueId || ""}
                            onChange={handleFilterChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextInput
                            label="Issue Heading"
                            name={"issueHeading"}
                            value={filterData.issueHeading || ""}
                            onChange={handleFilterChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <TextInput
                            label="Status"
                            name={"status"}
                            value={filterData.status || ""}
                            onChange={handleFilterChange}
                            fullWidth
                            select
                        >
                            {issueStatusOptions.map((brand) => (
                                <MenuItem key={brand.key} value={brand.key}>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </TextInput>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            default={null}
                            label="Company"
                            value={filterData?.company ? filterData.company : null}
                            name='company'
                            onChange={handleFilterChange}
                            options={companiesList}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ gap: "1rem", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <DownloadCsvButton />
                <CreateButton
                    onClick={() => dispatch(OPEN_IT_AMC_CREATE_ISSUE("create"))}
                    text="Create Issue"
                />
                <ResetButton onClick={hanldeResetSearch} />
                <SearchButton onClick={handleSearch} />
            </Box>
            <CreateItAmcIssue />
        </>
    );
}

export default FilterItAmcIssue;
