import { privateApi } from "../../api";



const createExportAsset = async (inputData) => {
    return await privateApi.post('exportAsset/createExportAsset', inputData);
};

const updateExportAsset = async (inputData) => {
    return await privateApi.put(`exportAsset/updateExportAsset/${inputData?._id}`, inputData);
};


export const getExportAssetList = async (query) => {
    return await privateApi.get('exportAsset/getExportAssets', query);
};

export const createBrandModelOrigin = async (inputData) => {
    return await privateApi.put('exportAsset/add-field-options', inputData)
}
export const getBrandModelOrigin = async (inputData) => {
    return await privateApi.get('exportAsset/get-brand-model-origin', inputData)
}

export const getExportAssetForCsvOrPdf = async ({ exportAssetSession, exportAssetCsv }) => {
    return await privateApi.get('exportAsset/getExportAssetForCsv', {
        exportAssetSession,
        exportAssetCsv
    });
};

export const postAddRemarksComment = async (data, exportAssetId) => await privateApi.post('exportAsset/add-remark-export-asset/' + exportAssetId, data)



export { createExportAsset, updateExportAsset };

