import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneInput.css'; 

const PhoneInputCustom = (props) => {
    const { value, name, label, onChange, required, disabled } = props;
    return (
        <div>
            <div className="">
                <PhoneInput
                    placeholder={label}
                    value={value ? value : ""}
                    country={'AE'}
                    id={name ? name : "myPhoneinput"}
                    onChange={onChange}
                    inputClass="phone-input"
                    containerStyle={{
                        width: '100%',
                    }}
                    disabled={disabled ? disabled : ""}
                />
            </div>
        </div>
    )
}

export default PhoneInputCustom;