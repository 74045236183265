import React from 'react';
import { commonFieldTypesOfExportAsset } from '../../constants/commonConstants';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

const CreateBrandModelOrigin = ({ setData, data }) => {
    const fieldOptions = useSelector(state => state.exportAssetMangement.options);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prev => ({ ...prev, [name]: value }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <TextField
                    select
                    fullWidth
                    label="Field Type"
                    variant="outlined"
                    name="fieldType"
                    value={data.fieldType}
                    onChange={handleChange}
                >
                    {commonFieldTypesOfExportAsset.map(option => (
                        <MenuItem key={option.val} value={option.val}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {data.fieldType === "model" && (
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        select
                        fullWidth
                        label="Brand"
                        variant="outlined"
                        name="brand"
                        value={data.brand}
                        onChange={handleChange}
                    >
                        {fieldOptions?.brand?.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            )}

            {data.fieldType && (
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        fullWidth
                        label={data.fieldType}
                        variant="outlined"
                        name="value"
                        value={data.value}
                        onChange={handleChange}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default CreateBrandModelOrigin;
