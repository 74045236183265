import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import "./ChatViewComponent.css"; // Import your custom CSS file for styling
import { convertFirestoreTimestampToIST } from "../../../../utilities/commonUtils";
import { limit, doc, collection, Timestamp, onSnapshot, query, where, orderBy, getDocs, updateDoc, writeBatch, WriteBatch } from "@firebase/firestore";
import { db } from "../../../../utilities/auth-config/firebase-config";
import notificationSound from '../../../../MediaContents/Sounds/notifications-sound-127856.mp3'
import { markMessagesAsSeen } from "../../../../utilities/auth-config/firebase-config";
import ChatViewComponent from "../../../../components/CustomerChatComponent/ChatViewComponent";
import newMessageTickSound from '../../../../MediaContents/Sounds/audiomass-output.mp3'

const ChatViewComponent1 = (props) => {
    const thisUserId = useSelector((state) => state.userData.data._id);
    const selectedChatRoom = useSelector((state) => state.chats.openedChatId);
    const [newChatFlag, setNewChatFlag] = useState(true);

    const [chats, setChats] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [lastDoc, setLastDoc] = useState(null);

    const messageDbRef = collection(db, 'messages');

    function playNotifySound() {
        const audio = new Audio(notificationSound);
        audio.play();
    }

    useEffect(() => {
        let unsubscribe;
        const audio = new Audio(notificationSound); // Notification sound

        if (selectedChatRoom) {
            getMessagesWithPagination(1);

            const currentTimestamp = Timestamp.fromDate(new Date());
            const queryMessage = query(messageDbRef,
                where("roomId", "==", selectedChatRoom),
                where('timestamp', '>', currentTimestamp),
                orderBy("timestamp")
            );
            unsubscribe = onSnapshot(queryMessage, (snapshot) => {
                let messages = [];
                snapshot.forEach((doc) => {
                    messages.push({ ...doc.data(), id: doc.id });
                });

                // Play the notification sound if the tab is not active
                if (document.hidden) {
                    audio.play();
                } else {
                    const audio = new Audio(newMessageTickSound);
                    audio.play();
                }

                setChats(prevChats => {
                    // Create a copy of prevChats
                    let updatedChats = [...prevChats];

                    // Update chats that are also in messages
                    updatedChats = updatedChats.map(chat => {
                        const matchingMessage = messages.find(message => message.id === chat.id);
                        return matchingMessage ? matchingMessage : chat;
                    });

                    // Add messages that are not already in prevChats
                    const newMessages = messages.filter(message => !prevChats.some(chat => chat.id === message.id));

                    return [...updatedChats, ...newMessages];
                });
                setNewChatFlag(true);
                // Since this component is already in open, mark all the messages sent by other user as seen;
                markMessagesAsSeen(selectedChatRoom, thisUserId);
            });
        } else {
            setChats([]);
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [selectedChatRoom]);

    const pageSize = 20;
    const getMessagesWithPagination = async (page) => {
        // Calculate the number of documents to skip
        const skip = (page - 1) * pageSize;

        // Get the total number of messages
        const totalSnapshot = await getDocs(query(messageDbRef, where("roomId", "==", selectedChatRoom)));
        setTotalPages(Math.ceil(totalSnapshot.size / pageSize));

        let messagesQuery = query(
            messageDbRef,
            where("roomId", "==", selectedChatRoom),
            orderBy("timestamp", "desc"),
            limit(skip + pageSize)
        );

        const snapshot = await getDocs(messagesQuery);
        const messages = snapshot.docs.slice(skip).map(doc => {
            const data = doc.data();
            return { ...data, id: doc.id, view: data.view || null }; // Add a null check for the 'view' property
        });
        setChats([...messages.reverse(), ...(page !== 1 ? chats : [])]);

        // Set the current page and last document
        setCurrentPage(page);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        if (page === 1) setNewChatFlag(true);
    };

    return (
        <ChatViewComponent
            chats={chats}
            currentPage={currentPage} totalPages={totalPages}
            getMessagesWithPagination={getMessagesWithPagination}
            newChatFlag={newChatFlag}
            setNewChatFlag={setNewChatFlag}
            scrollDivRef={props.scrollDivRef}
            isAdminChatView={true}
        />
    );
};

export default ChatViewComponent1;