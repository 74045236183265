import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";

import { Typography, Paper, Tooltip, Stack, FormHelperText, Grid, Box, Tab, Badge, useMediaQuery, IconButton } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled, useTheme } from '@mui/material/styles';
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from '@mui/icons-material/FilterList';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ResetButton, SearchButton } from '../../components/commons/Buttons';
import CompaniesTable from './CompaniesTable';
import CustomPopover from '../../components/commons/CustomPopover';
import TextInput from '../../components/commons/TextInput';
import { getAllCompaniesList, getCompaniesByQuery } from '../../app/Company/CompanySlice';
import CustomDialogueMedium from '../../components/commons/CustomDialogueMedium';
import { downloadCompanyListCsv, downloadCompanyListPdf } from '../../utilities/downloadable';
import FileDownload from '@mui/icons-material/FileDownload';
// import { downloadCompanyListCsv } from './../../utilities/downloadable';


export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
}));

function CompanyManagement() {
    const dispatch = useDispatch()
    const sessionUserId = useSelector((state) => state.userData.data._id);
    const queryData = useSelector((state) => state.companyManagement.queryData);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [dialogOpen, setDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTab, setCurrentTab] = useState('activeCompanies');
    const [filterCountForBadge, setFilterCountForBadge] = useState(0);
    const [searchFlag, setSearchFlag] = useState(false);

    const handleTabChange = (event, newValue = 'activeUsers') => {
        dispatch({
            type: 'companyManagement/updateFieldValues', payload: {
                state: 'mainState', field: 'queryData',
                value: {
                    isActive: newValue === 'activeCompanies' ? true : false,
                    companyCode: '',
                    companyName: '',
                    companyEmail: '',
                    trnNumber: '',
                    page: '',
                    limit: 10,
                    totalCount: '',
                    totalPages: ''
                },
            }
        });
        setCurrentTab(newValue);
    };

    const handleDialogOpen = (event) => {
        if (!isMobile) setAnchorEl(event.currentTarget);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // Close the Search modal when there's data received against a given query
    useEffect(() => {
        if (queryData.searchSuccess && searchFlag) {
            setSearchFlag(false);
            handleDialogClose();
        }
    }, [queryData.searchSuccess, searchFlag]);

    // Reset search query data
    const resetFields = () => {
        const resetQueryData = {
            ...queryData,
            companyCode: '',
            companyName: '',
            companyEmail: '',
            trnNumber: '',
            page: '',
            limit: 10,
            totalCount: '',
            totalPages: ''
        };

        dispatch({
            type: 'companyManagement/updateFieldValues',
            payload: { state: 'mainState', field: 'queryData', value: resetQueryData }
        });

        dispatch(getCompaniesByQuery({ ...resetQueryData, page: 1 }));
    };

    // On search btn click
    const searchCompanies = () => {
        setSearchFlag(true);
        dispatch(getCompaniesByQuery({ ...queryData, page: 1 }));
    };

    const handleExportPDF = () => {
        dispatch(getAllCompaniesList()).then((result) => {
            if (!result.error) {
                const companyList = result.payload.companies
                downloadCompanyListPdf(companyList);
            } else {
                toast.error("Ooops..!something went wrong");
            }
        })
    };
    const handleExportCSV = () => {
        dispatch(getAllCompaniesList()).then((result) => {
            if (!result.error) {
                const companyList = result.payload.companies
                downloadCompanyListCsv(companyList);
            } else {
                toast.error("Ooops..!something went wrong");
            }
        })
    };


    // Textfield render utility for query component
    const renderTextInput = (label, name, max = null) => (
        <TextInput
            label={label}
            name={name}
            max={max}
            value={queryData[name]}
            onChange={(e) => dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'queryData', field: name, value: e.target.value } })}
        />
    );
    const renderGrid = () => (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={isMobile ? 12 : 6}>
                        {renderTextInput("Company Name", "companyName", 100)}
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        {renderTextInput("Company Code", "companyCode")}
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        {renderTextInput("Email", "companyEmail")}
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        {renderTextInput("TRN", "trnNumber")}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <div>
            <TabContext value={currentTab}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: isMobile ? '100%' : '65%', typography: 'body1', display: 'flex', flexDirection: 'column' }}>
                        <Paper
                            elevation={1}
                            sx={{
                                borderColor: 'divider',
                                zIndex: 99,
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                transition: 'box-shadow 0.3s',
                                '&:hover': {
                                    boxShadow: '3px 15px 15px rgba(0, 0, 0, 0.4)',
                                    cursor: 'pointer',
                                    elevation: 5,
                                },
                            }}
                        >
                            <TabList
                                onChange={handleTabChange}
                                aria-label="CompanyManagement Tabs"
                                variant={isMobile ? 'scrollable' : 'fullWidth'}
                                allowScrollButtonsMobile={isMobile ? true : false}
                                orientation={'horizontal'}
                                sx={{
                                    '& .MuiTab-root': {
                                        padding: '0 16px',
                                        minHeight: '48px',
                                        maxHeight: '48px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:not(:last-child)': {
                                            borderRight: '1px solid #ccc', // Apply border to all but the last child
                                        },
                                    },
                                    '& .Mui-selected': {
                                        borderBottom: 'solid 5px #97C3F0',
                                        backgroundColor: '#f5f5f5',
                                    },
                                }}
                            >
                                <Tab
                                    value="activeCompanies"
                                    label={
                                        <div id='activeCompanies' style={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip title="All Active Companies" arrow placement="left">
                                                <Typography variant="subtitle2" fontWeight={600} fontSize={14}>
                                                    Active Companies
                                                </Typography>
                                            </Tooltip>
                                            {currentTab === 'activeCompanies' && (
                                                <Tooltip title="Filter" arrow placement="top">
                                                    <span style={{ marginLeft: "8px", cursor: "pointer" }} onClick={(e) => { handleDialogOpen(e) }}>
                                                        <Badge badgeContent={filterCountForBadge} color="primary">
                                                            <FilterListIcon color={'primary'} fontSize="small" />
                                                        </Badge>
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </div>
                                    }
                                />

                                <Tab
                                    label={
                                        <div id='inActiveCompanies' style={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip title="In-Active Companies" arrow placement="left">
                                                <Typography variant="subtitle2" fontWeight={600} fontSize={14}>
                                                    Inactive Companies
                                                </Typography>
                                            </Tooltip>
                                            {currentTab === 'inactiveCompanies' && (
                                                <Tooltip title="Filter" arrow placement="top">
                                                    <span style={{ marginLeft: "8px", cursor: "pointer" }} onClick={(e) => { handleDialogOpen(e) }}>
                                                        <Badge badgeContent={filterCountForBadge} color="primary">
                                                            <FilterListIcon color={'primary'} fontSize="small" />
                                                        </Badge>
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </div>
                                    }
                                    value="inactiveCompanies"
                                />
                            </TabList>
                        </Paper>
                    </Box>
                    <Tooltip title="Export PDF" arrow placement="top">
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", marginLeft: "2rem", }}>
                            <StyledIconButton onClick={handleExportPDF}>
                                <PictureAsPdfIcon />
                            </StyledIconButton>

                        </div>
                    </Tooltip>
                    <Tooltip title="Export PDF" arrow placement="top">
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", marginLeft: "2rem", }}>
                            <StyledIconButton onClick={handleExportCSV}>
                                <FileDownload />
                            </StyledIconButton>
                        </div>
                    </Tooltip>
                </div>

                <TabPanel value="activeCompanies">
                    <div className='chat-user-list'>
                        <CompaniesTable isActive={true} />
                    </div>
                </TabPanel>

                <TabPanel value="inactiveCompanies">
                    <div className='chat-user-list'>
                        <CompaniesTable isActive={false} />
                    </div>
                </TabPanel>
            </TabContext>


            {isMobile ? (
                <CustomDialogueMedium
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    headerText={"Companies Filter"}
                    footerButtons={
                        <>
                            <ResetButton onClick={resetFields} />
                            <SearchButton onClick={searchCompanies} />
                        </>
                    }
                >
                    {renderGrid()}
                </CustomDialogueMedium>
            ) : (
                <CustomPopover
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    anchorEl={anchorEl}
                    headerText={"Filter Companies"}
                    footerButtons={
                        <>
                            <ResetButton onClick={resetFields} />
                            <SearchButton onClick={searchCompanies} />
                        </>
                    }
                >
                    <div style={{ width: '650px' }}>
                        <div>
                            {renderGrid()}
                        </div>
                    </div>
                </CustomPopover>
            )}


        </div>
    )
}

export default CompanyManagement;
