import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from './service';

const initialState = {
    /*****************************************************/
    /*********************ACTIVE-USERS********************/
    activeUsersFilter: {
        displayName: "",
        email: "",
        role: null,
        company: null,
        authProvider: null,
        customUserId: "",
        isActive: ""
    },
    activeUsersFilterErrorFlag: false,
    activeUsersFilterResetFlag: 0,
    /*****************************************************/
    /***********PENDING-APPROVAL-USERS********************/
    pendingApprovalUsersFilter: {
        email: "",
        company: null,
        authProvider: null,
    },
    pendingApprovalUsersFilterErrorFlag: false,
    pendingApprovalUsersFilterResetFlag: 0,
    createUserData: {
        userType: '', // valid enums ['Customer', 'Admin', 'Vendor'],
        role: '', // ['customerAdmin', 'customerUser', 'vendorAdmin', 'vendorUser', 'technicalAdmin', 'technician', 'superAdmin'],
        firstName: '', // string
        lastName: '', // string
        email: '', // string
        authProvider: '', // enums: ['Microsoft', 'Google', 'email']
        contactPhoneNumber: '+971',
        preferredContactMethod: '', // Enums ['Phone', 'Email'],
        company: null,
        companyLocation: null,
        countryCode: '',
        password: '',
        isError: false,
        isSuccess: false,
        errorMessages: [],
        isCreateModalOpen: false
    }
};

export const createUserAsync = createAsyncThunk(
    'userManagement/createUserAsync',
    async (userData, { rejectWithValue }) => {
        // Input validations
        try {
            const response = await service.createNewUser({ ...userData });
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.message ?? error);
        }
    }
);

const userManagementSlice = createSlice({
    name: "userManagement",
    initialState,
    reducers: {
        // Accepts the name of the state, the field, and the value to handle value change in tickets add or edit modal
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                };
            } else {
                return {
                    ...state,
                    [field]: value,
                };
            }
        },

        /*****************************************************/
        /*********************ACTIVE-USERS********************/
        handleActiveUsersFilterValueChange: (state, action) => {
            const { name, value } = action.payload;
            state.activeUsersFilter[name] = value;
        },
        setActiveUsersFilterErrorFlag: (state, actions) => {
            state.activeUsersFilterErrorFlag = actions.payload;
        },
        resetActiveUsersFilterData: (state) => {
            state.activeUsersFilter = initialState.activeUsersFilter;
            state.activeUsersFilterErrorFlag = initialState.activeUsersFilterErrorFlag;
            state.activeUsersFilterResetFlag = state.activeUsersFilterResetFlag + 1;
        },

        /*****************************************************/
        /***********PENDING-APPROVAL-USERS********************/
        handlePendingApprovalUsersFilterValueChange: (state, action) => {
            const { name, value } = action.payload;
            state.pendingApprovalUsersFilter[name] = value;
        },

        setPendingApprovalUsersFilterErrorFlag: (state, actions) => {
            state.pendingApprovalUsersFilterErrorFlag = actions.payload;
        },
        resetPendingApprovalUsersFilterData: (state) => {
            state.pendingApprovalUsersFilter = initialState.pendingApprovalUsersFilter;
            state.pendingApprovalUsersFilterErrorFlag = initialState.pendingApprovalUsersFilterErrorFlag;
            state.pendingApprovalUsersFilterResetFlag = state.pendingApprovalUsersFilterResetFlag + 1;
        },

        /*****************************************************/
        /**********CREATE-USER-DATA-REDUCERS******************/
        resetCreateUserData: (state) => {
            state.createUserData = { ...initialState.createUserData, isCreateModalOpen: state.createUserData.isCreateModalOpen };
        },
        resetToInitial: () => initialState,


    },
    extraReducers: (builder) => {
        builder
            /******<<<<<< CASES FOR CREATE NEW USER BY ADMIN >>>>>>******/
            .addCase(createUserAsync.pending, (state) => {
                state.createUserData.isLoading = true;
                state.createUserData.isError = false;
                state.createUserData.isSuccess = false;
                state.createUserData.errorMessages = [];
            })
            .addCase(createUserAsync.fulfilled, (state, action) => {
                state.createUserData.isLoading = false;
                state.createUserData.isSuccess = true;
                state.createUserData.errorMessages = [];
            })
            .addCase(createUserAsync.rejected, (state, action) => {
                state.createUserData.isError = true;
                state.createUserData.errorMessages = action.payload;
                state.createUserData.isLoading = false;
            });
    },
});

export const {
    handleActiveUsersFilterValueChange,
    setActiveUsersFilterErrorFlag,
    resetActiveUsersFilterData,
    handleInActiveUsersFilterValueChange,
    setActiveInUsersFilterErrorFlag,
    resetInActiveUsersFilterData,
    resetCreateUserData
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
