import React, { useEffect, useState } from 'react';
import { CreateButton, SaveButton } from '../../components/commons/Buttons';
import CustomDialogueMedium from '../../components/commons/CustomDialogueMedium';
import { Typography, TextField, Grid, MenuItem, Switch, Chip, Box, useMediaQuery, useTheme } from '@mui/material';
import { validateExportAsset, validateExportAssetChange } from './exportAssetValidation';
import { useDispatch, useSelector } from 'react-redux';
import {
    OPEN_EXPORT_ASSET_MODAL,
    UPDATE_ERROR_FIELDS_EXPORT_ASSETS,
    UPDATE_FIELDS_EXPORT_ASSETS,
    exportAssetInitialState,
    createExportAsset,
    UPDATE_LOADING_EXPORT_ASSETS,
    creationBrandModelOrigin,
    getFieldsOptionData,
} from '../../app/ExportAsset/ExportAssetSlice';
import FileUploader from '../../components/FileUploader/FileUploader';
import { printerTypes, tonerLevel, tonerTypes } from '../../constants/commonConstants';
import LoadingTemplate from './LoadingTemplate';
import { toast } from 'react-toastify';
import CreateBrandModelOrigin from './CreateBrandModelOrigin';
import CustomPopover from '../../components/commons/CustomPopover';


const assetTypes = [
    'laptop',
    'printer',
    //  'toner'
];
const healthStatusOptions = ['Good Condition', 'Maintenance Required',];
const colorOptions = ['Black', 'Cyan', 'Magenta', 'Yellow'];

const CreateNewExportAsset = () => {
    const dispatch = useDispatch();
    const theme = useTheme()
    const exportAssetSession = useSelector(state => state.exportAssetMangement);
    const fieldOptions = useSelector(state => state.exportAssetMangement.options);
    const formAssetData = useSelector(state => state.exportAssetMangement.createOrUpdate);
    const formErrors = useSelector(state => state.exportAssetMangement.errors);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint as needed
    const [dialogOpen, setDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [createNewField, setCreateNewField] = useState({
        view: false,
        fieldType: "",
        value: "",
        brand: ""
    })
    const handleCreateDialogClose = () => {
        dispatch(OPEN_EXPORT_ASSET_MODAL(false))
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let error = validateExportAssetChange(value, name);

        if (name.startsWith('tonerDetails')) {
            const keyField = name.split('.').pop(); // Extract the color from the name
            dispatch(UPDATE_FIELDS_EXPORT_ASSETS({
                ...formAssetData,
                tonerDetails: {
                    ...formAssetData.tonerDetails,
                    [keyField]: value
                },
            }));
            dispatch(UPDATE_ERROR_FIELDS_EXPORT_ASSETS({
                tonerDetails: {
                    ...formErrors.tonerDetails,
                    [keyField]: error
                },
            }))
        } else if (name.startsWith('printerDetails.tonerLevel.')) {
            const tonerColor = name.split('.').pop(); // Extract the color from the name
            dispatch(UPDATE_FIELDS_EXPORT_ASSETS({
                ...formAssetData,
                printerDetails: {
                    ...formAssetData.printerDetails,
                    tonerLevel: {
                        ...formAssetData.printerDetails.tonerLevel,
                        [tonerColor]: value,
                    },
                },
            }));
            dispatch(UPDATE_ERROR_FIELDS_EXPORT_ASSETS({
                ...formErrors,
                isTonerLevelNotFilled: "",
            }));
        } else if (name === 'printerType') {

            dispatch(UPDATE_FIELDS_EXPORT_ASSETS({
                ...formAssetData,
                printerDetails: {
                    tonerLevel: exportAssetInitialState.createOrUpdate.printerDetails.tonerLevel,
                    type: value,
                },
            }));
            dispatch(UPDATE_ERROR_FIELDS_EXPORT_ASSETS({
                ...formErrors,
                isTonerLevelNotFilled: "",
            }));
        } else {
            dispatch(UPDATE_FIELDS_EXPORT_ASSETS({
                ...formAssetData,
                [name]: value,
            }));
            dispatch(UPDATE_ERROR_FIELDS_EXPORT_ASSETS({
                ...formErrors,
                [name]: error,
            }));
        }
    };

    useEffect(() => {

        const clearInput = () => {
            dispatch(UPDATE_FIELDS_EXPORT_ASSETS(exportAssetInitialState.createOrUpdate));
            dispatch(UPDATE_ERROR_FIELDS_EXPORT_ASSETS({}));
            dispatch(UPDATE_LOADING_EXPORT_ASSETS(false))
        };
        return () => {
            clearInput();
            setCreateNewField(false)
        };
    }, [exportAssetSession.isOpen, dispatch]);
    useEffect(() => {
        dispatch(getFieldsOptionData())
    }, [createNewField.view])
    function saveUploadedFilesInRedux(fileData) {
        dispatch(UPDATE_FIELDS_EXPORT_ASSETS({
            ...formAssetData,
            attachments: [...formAssetData.attachments, fileData],
        }));
    }

    function removeUploadedFileFromRedux(fileName) {
        if (fileName) {
            dispatch(UPDATE_FIELDS_EXPORT_ASSETS({
                ...formAssetData,
                attachments: formAssetData.attachments?.filter(item => item.filename !== fileName),
            }));
        }
    }


    const handleSubmit = () => {
        if (exportAssetSession.isLoading) return
        const { hasError, errors } = validateExportAsset(formAssetData);
        if (hasError) {
            // console.log(hasError, errors)
            toast.error("input error occured", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                style: { width: '400px' },
            })
            dispatch(UPDATE_ERROR_FIELDS_EXPORT_ASSETS(errors));
            return;
        }
        dispatch(UPDATE_LOADING_EXPORT_ASSETS("true"))
        dispatch(createExportAsset(formAssetData)).then((result) => {
            dispatch(UPDATE_LOADING_EXPORT_ASSETS(false));
            if (!result.error) {
                dispatch(UPDATE_FIELDS_EXPORT_ASSETS(exportAssetInitialState.createOrUpdate));
                toast.success("successfully created export asset", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    style: { width: '400px' },
                })
                handleCreateDialogClose(); // Close the modal on successful response
            } else {
                toast.error(result.payload ?? "failed create export asset", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    style: { width: '400px' },
                })
            }
        }).catch((err) => {
            // console.log(err)
            dispatch(UPDATE_LOADING_EXPORT_ASSETS(false))

            toast.error("failed create export asset", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                style: { width: '400px' },
            })
        });
    };
    const handleAddFieldData = () => {
        dispatch(creationBrandModelOrigin(createNewField)).then((res) => {
            // console.log(res.payload)
            if (!res.payload.error) {
                setCreateNewField(prev => ({
                    view: false,
                    fieldType: "",
                    value: "",
                    brand: ""
                }))
            }
        })
    }



    const handleDialogClose = () => {
        setCreateNewField({
            view: false,
            fieldType: "",
            value: "",
            brand: ""
        })
    };



    const footerButtons = (
        <>
            {!createNewField.view && <CreateButton onClick={handleSubmit} disabled={false} />}
            {createNewField.view && <SaveButton onClick={handleAddFieldData} disabled={false} />}
        </>
    );


    return (<>
        {createNewField.view && !exportAssetSession.isLoading &&
            <CustomDialogueMedium
                open={createNewField.view}
                onClose={handleDialogClose}
                anchorEl={anchorEl}
                headerText={"Brand Or Model Or Origin"}
                footerButtons={footerButtons}
            >
                <div style={{ width: '650px' }}>

                    <CreateBrandModelOrigin setData={setCreateNewField} data={createNewField} />
                </div>
            </CustomDialogueMedium>
        }

        {!createNewField.view && <CustomDialogueMedium
            open={exportAssetSession.isOpen === 'create'}
            onClose={() => { handleCreateDialogClose() }}
            headerText={'Create Export Asset'}
            footerButtons={footerButtons}
        >

            <Box style={{ gap: "1rem", display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: "1rem" }}>
                <Chip
                    label={createNewField.view ? "Cancel" : "Create New Brand or Model or Origin"}
                    variant={'outlined'}
                    sx={{
                        borderRadius: '8px',
                        fontSize: '11px',
                        cursor: 'pointer',
                        border: createNewField.view ? '1px solid red' : '1px solid #000000', // Red outline when view is true, otherwise black
                    }}
                    onClick={() => {
                        setCreateNewField(prev => ({ ...prev, view: !prev.view }))
                        // dispatch(UPDATE_VIEW_CONTROLLER_EXPORT_ASSET("showTable"))
                    }}
                />
            </Box>


            {(exportAssetSession.isLoading || createNewField.view) && <LoadingTemplate />}

            {!exportAssetSession.isLoading &&
                <Grid container xs={12} spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            select
                            fullWidth
                            label="Asset Type"
                            variant="outlined"
                            name="assetType"
                            value={formAssetData.assetType}
                            onChange={handleChange}
                            error={!!formErrors.assetType}
                            helperText={formErrors.assetType}
                        >
                            {assetTypes.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            select
                            fullWidth
                            label="Brand Name"
                            variant="outlined"
                            name="machineName"
                            value={formAssetData.machineName}
                            onChange={handleChange}
                            error={!!formErrors.machineName}
                            helperText={formErrors.machineName}
                        >
                            {fieldOptions.brand?.map(option => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {<Grid item xs={12} sm={6} md={3}>
                        <TextField
                            select
                            fullWidth
                            label="Machine Model"
                            variant="outlined"
                            name="machineModel"
                            value={formAssetData.machineModel}
                            onChange={handleChange}
                            error={!!formErrors.machineModel}
                            helperText={formErrors.machineModel}
                        >
                            {formAssetData.machineName && fieldOptions?.model[formAssetData.machineName]?.map(option => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>}
                    {formAssetData.assetType !== "toner" && (
                        <>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Serial Number"
                                    variant="outlined"
                                    name="serialNumber"
                                    value={formAssetData.serialNumber}
                                    onChange={handleChange}
                                    error={!!formErrors.serialNumber}
                                    helperText={formErrors.serialNumber}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Health Status"
                                    variant="outlined"
                                    name="healthStatus"
                                    value={formAssetData.healthStatus}
                                    onChange={handleChange}
                                    error={!!formErrors.healthStatus}
                                    helperText={formErrors.healthStatus}
                                >
                                    {healthStatusOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </>
                    )
                    }


                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            label="Cost Price"
                            variant="outlined"
                            name="costPrice"
                            value={formAssetData.costPrice}
                            onChange={handleChange}
                            error={!!formErrors.costPrice}
                            helperText={formErrors.costPrice}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            label="Selling Price"
                            variant="outlined"
                            name="sellingPrice"
                            value={formAssetData.sellingPrice}
                            onChange={handleChange}
                            error={!!formErrors.sellingPrice}
                            helperText={formErrors.sellingPrice}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            select
                            fullWidth
                            label="Origin"
                            variant="outlined"
                            name="origin"
                            value={formAssetData.origin}
                            onChange={handleChange}
                            error={!!formErrors.origin}
                            helperText={formErrors.origin}
                        >
                            {fieldOptions.origin?.map(option => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {formAssetData.assetType === 'printer' && (
                        <>
                            <Grid item xs={12}>
                                <hr />
                                <Typography>Printer Details</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Printer Type"
                                    variant="outlined"
                                    name="printerType"
                                    value={formAssetData.printerDetails.type}
                                    onChange={handleChange}
                                >
                                    {printerTypes.map((option) => (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} />
                            {(
                                <>
                                    {formAssetData.printerDetails.type && <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            label={`Black Toner Level`}
                                            variant="outlined"
                                            name={`printerDetails.tonerLevel.black`}
                                            value={formAssetData.printerDetails.tonerLevel.black}
                                            onChange={handleChange}
                                        >
                                            {tonerLevel.map((level) => (
                                                <MenuItem key={level.key} value={level.key}>
                                                    {level.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}

                                    {formAssetData.printerDetails.type === 'color' && <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            label={`Cyan Toner Level`}
                                            variant="outlined"
                                            name={`printerDetails.tonerLevel.cyan`}
                                            value={formAssetData.printerDetails.tonerLevel.cyan || ''}
                                            onChange={handleChange}
                                        >
                                            {tonerLevel.map((level) => (
                                                <MenuItem key={level.key} value={level.key}>
                                                    {level.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}

                                    {formAssetData.printerDetails.type === 'color' && <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            label={`Magenta Toner Level`}
                                            variant="outlined"
                                            name={`printerDetails.tonerLevel.magenta`}
                                            value={formAssetData.printerDetails.tonerLevel.magenta}
                                            onChange={handleChange}
                                        >
                                            {tonerLevel.map((level) => (
                                                <MenuItem key={level.key} value={level.key}>
                                                    {level.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}
                                    {formAssetData.printerDetails.type === 'color' && <Grid item xs={12} sm={6} md={3}>

                                        <TextField
                                            fullWidth
                                            select
                                            label={`Yellow Toner Level`}
                                            variant="outlined"
                                            name={`printerDetails.tonerLevel.yellow`}
                                            value={formAssetData.printerDetails.tonerLevel.yellow}
                                            onChange={handleChange}
                                        >
                                            {tonerLevel.map((level) => (
                                                <MenuItem key={level.key} value={level.key}>
                                                    {level.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}


                                </>
                            )}
                            {formErrors.isTonerLevelNotFilled && <Grid xs={12}>
                                <Typography color="error" sx={{ paddingX: '1rem' }}>{formErrors.isTonerLevelNotFilled}</Typography>
                            </Grid>}
                            <Grid xs={12}>
                                <hr />
                            </Grid>
                        </>
                    )}
                    {formAssetData.assetType === 'toner' && (<>
                        <Grid item xs={12}>
                            <hr />
                            <Typography>Toner Details</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                select
                                label={`Toner Type`}
                                variant="outlined"
                                name={`tonerDetails.tonerType`}
                                value={formAssetData.tonerDetails.tonerType}
                                onChange={handleChange}
                            >
                                {tonerTypes.map((option) => (
                                    <MenuItem key={option.key} value={option.key}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                select
                                label={`Toner Color`}
                                variant="outlined"
                                name={`tonerDetails.tonerColor`}
                                value={formAssetData.tonerDetails.tonerColor}
                                onChange={handleChange}
                            >
                                {colorOptions.map((level) => (
                                    <MenuItem key={level} value={level}>
                                        {level}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                label={`Quanity`}
                                variant="outlined"
                                name={`tonerDetails.quantity`}
                                value={formAssetData.tonerDetails?.quantity}
                                onChange={handleChange}
                                error={!!formErrors.tonerDetails?.quantity}
                                helperText={formErrors.tonerDetails?.quantity}
                            />
                        </Grid>

                        <Grid xs={12}>
                            <hr />
                        </Grid>
                    </>)}

                    {formAssetData.assetType !== "toner" && <Grid xs={12} sx={{ paddingX: '2rem' }}>
                        <Typography>Required Parts</Typography>
                        <Switch
                            checked={formAssetData.isPartsRequired}
                            onChange={(event) => {
                                const { checked } = event.target;
                                dispatch(UPDATE_FIELDS_EXPORT_ASSETS({
                                    ...formAssetData,
                                    isPartsRequired: checked,
                                }));
                            }}
                            inputProps={{ 'aria-label': 'toggle required parts' }}
                        />
                    </Grid>}
                    {formAssetData.isPartsRequired && formAssetData.assetType !== "toner" && <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Parts Description"
                            multiline
                            rows={2}
                            variant="outlined"
                            name="partsDescription"
                            value={formAssetData.partsDescription}
                            onChange={handleChange}
                            error={!!formErrors.partsDescription}
                            helperText={formErrors.partsDescription}
                        />
                    </Grid>}
                    <Grid item xs={12}>
                        <FileUploader
                            onFileUploadSuccess={saveUploadedFilesInRedux}
                            onDeleteFile={removeUploadedFileFromRedux}
                            destinationParentFolder="exportAsset"
                            destinationSubFolder="attachments"
                            savedFiles={formAssetData.attachments}
                            disabled={false}
                            setAdminOnly={true}
                        />
                    </Grid>
                </Grid>}
        </CustomDialogueMedium>}
    </>);
};

export default CreateNewExportAsset;
