import Send from '@mui/icons-material/Send';
import { Box, Grid, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { addDoc, collection, doc, onSnapshot, orderBy, query, updateDoc, where } from '@firebase/firestore';
import { db } from '../../utilities/auth-config/firebase-config';
import Close from '@mui/icons-material/Close';

const MessageCard = ({ message, type = true, seen = "send" }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    function formatTime(date) {
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12;
        return `${hours}:${minutes} ${period}`;
    }

    return (
        <Grid item xs={12} display={"flex"} justifyContent={type ? "flex-end" : "flex-start"}>
            <Box sx={{ backgroundColor: "#f1f1f1", padding: ".5rem", borderRadius: "1rem" }}>
                <Grid>
                    <Typography sx={{ wordWrap: "break-word", maxWidth: isMobile ? "25rem" : "35rem" }}>{message.message}</Typography>
                    <Typography fontSize={".7rem"} textAlign={"end"}>{formatTime(message.timestamp?.toDate())}</Typography>
                </Grid>
                {type && <Grid display={"flex"} justifyContent={"flex-end"}>
                    {seen === "send" && <DoneIcon />}
                    {seen === "seen" && (
                        <>
                            <DoneIcon color="primary" sx={{ marginRight: "-1.2rem" }} />
                            <DoneIcon color="primary" sx={{ ml: 0.5 }} />
                        </>
                    )}
                </Grid>}
            </Box>
        </Grid>
    );
};

const ChatEnquiryUser = ({ dialogOpen, setDialogOpen, userData }) => {
    const messagesRef = useRef(null);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [unsubscribe, setUnsubscribe] = useState(null);

    const saveMessageToFirestore = async () => {
        try {
            if (!message.trim()) return;
            await addDoc(collection(db, "chatMessage"), {
                message: message,
                timestamp: new Date(),
                type: "admin",
                userId: dialogOpen,
                status: "send"
            });
            setMessage("");
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (!dialogOpen) return;

        const updateUserNotSeenMessageCount = async () => {
            try {
                const userDocRef = doc(db, "enquiryUser", userData.id);
                await updateDoc(userDocRef, {
                    notSeenMessageCount: 0
                });
            } catch (e) {
                console.error("Error updating user: ", e);
            }
        };

        const loadMessages = () => {
            if (!dialogOpen) return;

            const q = query(collection(db, "chatMessage"), where("userId", "==", userData._id), orderBy("timestamp", "asc"));

            try {
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const messageList = querySnapshot.docs.map(doc => doc.data());
                    setMessages(messageList);
                    updateUserNotSeenMessageCount();
                });

                setUnsubscribe(() => unsubscribe); // Save unsubscribe function to state
            } catch (e) {
                console.error("Error loading messages: ", e);
            }
        };

        const userSnapshot = onSnapshot(doc(db, "enquiryUser", userData.id), (doc) => {
            // This will trigger when there's a change in the user document
            updateUserNotSeenMessageCount();
        });

        loadMessages();

        return () => {
            if (unsubscribe) unsubscribe(); // Unsubscribe from messages listener
            userSnapshot();  // Unsubscribe from user snapshot listener
            setMessages([]); // Clear messages
            setMessage(""); // Clear message input
        };
    }, [dialogOpen]);

    return (
        <div style={{
            width: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
        }}>
            <Grid item xs={12} display={"flex"} justifyContent={'space-between'} padding={"1rem"}>
                <Grid>
                    <Typography fontWeight={600} fontSize={"1.4rem"}>{userData.name || "Guest"}</Typography>
                    <Typography fontWeight={600} fontSize={".7rem"}>{userData.email || "Guest User@gmail.com"}</Typography>
                </Grid>
                <Grid>
                    <IconButton onClick={() => {
                        setDialogOpen(false);
                        setMessages([]); // Clear messages
                        setMessage(""); // Clear message input
                        if (unsubscribe) unsubscribe(); // Unsubscribe from messages listener
                    }}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid sx={{ flex: 1, overflowY: "auto" }} display={"flex"} flexDirection={"column"} gap={".5rem"} padding={"1rem"} ref={messagesRef}>
                {messages.map((msg, index) => (
                    <MessageCard
                        type={msg.type === "admin"}
                        key={index}
                        message={msg}
                        seen={msg.status}  // Pass the status to control the tick marks
                    />
                ))}
            </Grid>
            <Grid item xs={12} display={"flex"} padding={"1rem"}>
                <TextField
                    variant="outlined"
                    placeholder="Send a message"
                    fullWidth
                    multiline
                    sx={{ marginRight: '4px' }}
                    value={message}
                    onChange={(e) => {
                        setMessage(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            saveMessageToFirestore();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={saveMessageToFirestore} color='primary' disabled={message.trim() === ""}>
                                    <Send fontSize='large' />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    disabled={false}
                />
            </Grid>
        </div>
    );
};

export default ChatEnquiryUser;
