import React, { useState, useEffect } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import UserChip from '../../components/commons/UserChip';
import { Box, Skeleton, Alert } from '@mui/material';
import service from '../../app/Ticket/Service';

const statusLabels = {
    'open': 'Open',
    'todo': 'To Do',
    'inProgress': 'In Progress',
    'escalated': 'Escalated',
    'done': 'Done',
    'hold': 'Hold',
    'discarded': 'Discarded',
    'reqSuperAdminApproval': 'Req Super Admin Approval',
    'reqCustomerAdminApproval': 'Req Customer Admin Approval',
    'customerAdminApproved': 'Customer Admin Approved',
    'customerAdminDeclined': 'Customer Admin Declined',
    'superAdminApproved': 'Super Admin Approved',
    'superAdminDeclined': 'Super Admin Declined',
    'reOpen': 'Reopen'
};


const StatusChangesTimeline = ({ticketId, isExpanded}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(true);
    const [statusChanges, setStatusChanges] = useState([]);


    useEffect(() => {
        if (isExpanded)
            getTicketStatusChanges();
    }, [isExpanded]);

    const getTicketStatusChanges = async () => {
        const response = await service.getTicketStatusChanges(ticketId);
        if (response.data?.length) {
            // console.log(response)
            setStatusChanges(response.data);
            setIsLoading(false);
        } else {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
        
    }


    const renderSkeleton = () => (
        Array.from({ length: 2 }).map((_, index) => (
            <TimelineItem key={index}>
                <TimelineSeparator>
                    <TimelineDot />
                    {index < 2 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="text" width="40%" />
                </TimelineContent>
            </TimelineItem>
        ))
    );
    
    return (
        
        <Timeline position={isMobile ? 'left' : 'alternate'}>
            {isLoading ? renderSkeleton() : statusChanges.length > 0 ? statusChanges?.map((change, index) => (
                <TimelineItem key={change.id}>
                    <TimelineSeparator>
                        <TimelineDot />
                        {index < statusChanges.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant={isMobile ? "body2" : "h6"} component="span">
                            {statusLabels[change.currentStatus]}
                        </Typography>
                        <Box sx={{ width: isMobile ? '80%' : '100%' }}>
                            <UserChip user={change?.user} avatarNeeded={true} />
                        </Box>
                        <Typography variant={isMobile ? "body2" : "body1"}>Date: {new Date(change.changeDate).toLocaleString()}</Typography>
                    </TimelineContent>
                </TimelineItem>
            )) : (
                <Alert severity="info">Not much changes to show</Alert>
            )}
        </Timeline>
    );
};

export default StatusChangesTimeline;