import React, { useState, useEffect, memo } from "react";
import { useDispatch } from 'react-redux';
import { db } from "../../../../utilities/auth-config/firebase-config";
import { collection, serverTimestamp, addDoc, doc, updateDoc, arrayUnion } from "@firebase/firestore";
import '../AdminChatComponent.css';
import { Grid, Typography, Divider, Avatar, Paper, Chip, Box } from '@mui/material';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { stringToColor } from "../../../../utilities/commonUtils";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';



const UserCard = React.memo(({ user, handleChatAcceptClick }) => {
    return (
        <>
            <Paper
                key={user.id}
                elevation={1}
                sx={{
                    margin: '2px',
                    marginBottom: '5px',
                    borderRadius: '8px',
                    transition: 'box-shadow 0.3s',
                    '&:hover': {
                        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.4)',
                    },
                }}
            >
                <Grid container alignItems="center" sx={{ padding: '2px', overFlow: 'hidden' }}>
                    <Grid item xs={12} container alignItems="center" spacing={2} direction="row">
                        <Grid item xs={3} container justifyContent="center" alignItems="center">
                            <Avatar
                                src={user.photoURL || undefined}
                                alt={user.displayName ? user.displayName.charAt(0) : user.firstName.charAt(0)}
                                sx={{
                                    bgcolor: user.displayName
                                        ? stringToColor(user.displayName)
                                        : stringToColor(`${user.firstName} ${user.lastName}`),
                                    width: 48,
                                    height: 48,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {user.displayName ? user.displayName.charAt(0) : user.firstName.charAt(0)}
                            </Avatar>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 'bold',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {user.displayName ? user.displayName : `${user.firstName} ${user.lastName}`}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                {user.company.companyName.length > 25
                                    ? `${user.company.companyName.slice(0, 25)}...`
                                    : user.company.companyName}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '25ch',
                                }}
                            >
                                {user.email}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingLeft: '3px' }}>
                        <Typography variant="body2">
                            {(() => {
                                switch (user.status) {
                                    case 'open':
                                        return (
                                            <Chip label={`Status: ${user.status}`} color="primary" size="small" />
                                        );
                                    case 'attended':
                                        return (
                                            <Chip label={`Status: ${user.status}`} color="success" size="small" />
                                        );
                                    case 'closed':
                                        return (
                                            <Chip label={`Status: ${user.status}`} color="secondary" size="small" />
                                        );
                                    default:
                                        return (
                                            <Chip label={`Status: ${user.status}`} color="default" size="small" />
                                        );
                                }
                            })()}
                        </Typography>
                        {user.status === "attended" && (
                            <Chip
                                label={`Attended by: ${user.attendedByName}`}
                                color="primary"
                                size="small"
                                icon={<CheckCircleOutlineRoundedIcon />}
                            />
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Chip
                                label="Add to My List"
                                clickable
                                color="primary"
                                sx={{ borderRadius: '8px', marginRight: '3px' }}
                                onClick={() => handleChatAcceptClick(user)}
                            />
                            <Chip
                                label="Accept"
                                clickable
                                color="primary"
                                sx={{borderRadius: '8px'}}
                                onClick={() => handleChatAcceptClick(user, true)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
            </Paper>

        </>
    );
});


const IncomingChats = ({ setCurrentTab }) => {
    const dispatch = useDispatch();
    const admin = useSelector((state) => state.userData?.data);
    const unAttendedIncomingChats = useSelector((state) => state.chats.unAttendedChats);


    async function handleChatAcceptClick(user, addToScreen) {
        const adminName = admin.displayName ? admin.displayName : `${admin.firstName} ${admin.lastName}`
        
        await updateDoc(doc(db, "rooms", user.roomId), {
            status: "attended",
            attendedBy: arrayUnion({ id: admin._id, role: admin.role, displayName: adminName }),
            attendedByName: adminName,
            lastSession: serverTimestamp() 
        });

        const messageDbRef = collection(db, 'messages');

        if (adminName && user.roomId) {
            const messageObj = {
                message: 'System Generated: Chat session has begun with Customer Support Specialist ' + adminName,
                photoURL: "",
                roomId: user.roomId,
                timestamp: serverTimestamp(),
                type: 'text',
                userId: 'na',
                userName: `System Generated`,
                userType: 'Admin',
                seen: false,
                fileURL: '',
            };

            try {
                await addDoc(messageDbRef, messageObj);

            } catch (error) {
                console.error("Error adding document: ", error);
            }
        } else {
            console.error("adminName, user.roomId, or user._id is undefined");
        }

        if (addToScreen) {
            setCurrentTab('myChats')
            dispatch({ type: 'chats/setOpenedChatId', payload: user.roomId });
        }
    }

    return (
        <>
            {unAttendedIncomingChats?.length >= 1 && unAttendedIncomingChats?.map((user, index) => (
                <UserCard key={user.id} user={user} handleChatAcceptClick={handleChatAcceptClick} />
            ))}
        </>
    );
};

export default IncomingChats;
