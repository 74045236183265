import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import { useMediaQuery, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AssetFilter from './AssetFilter'
import AssetsTable from "./AssetsTable";
import AssetFilterChips from './AssetFilterChips';
import { getAsset } from '../../app/Asset/AssetSlice';

const StyledPaper = styled(Paper)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Align items to the left
    alignItems: 'flex-start', // Align items to the left
    gap: '16px',
    border: '1px solid var(--grey-300, #E0E0E0)',
    background: 'var(--grey-ff, #FFF)',
    padding: '16px 16px',
    borderRadius: '8px 8px 0px 0px',
});


function CompanyManagement() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    let { id } = useParams();

    useEffect(() => {
        // If user trying to access a ticket through URL Params then dispatch the url param id to redux and then to evoke the api
        if (id) {
            dispatch(getAsset(id));
        }
    }, []);

    return (
        <>
            {
                !isMobile &&
                <StyledPaper elevation={1}>
                    <AssetFilter />
                </StyledPaper>
            }

            {
                isMobile &&
                <AssetFilterChips />
            }

            <div style={{ height: '15px' }}/>
            <AssetsTable/>
        </>

    )
}

export default CompanyManagement;
