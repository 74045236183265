import { privateApi } from "../../api";

const createCompany = async (inputData) => {
    return await privateApi.post('company/createCompany', inputData);
};

const editCompany = async (inputData) => {
    return await privateApi.put(`company/editCompany/${inputData?.id}`, inputData);
};

const getCompany = async (id) => {
    return await privateApi.get(`company/getCompany/${id}`);
}

const getAllCompaniesList = async () => {
    return await privateApi.get('company/getAllCompaniesList');
}

const getCompaniesByQuery = async (queryData) => {
    const { companyCode, companyName, companyEmail, isActive, trnNumber, page = 1, limit } = queryData;

    let queryParameters = [];
    if (companyCode) queryParameters.push(`companyCode=${companyCode}`);
    if (companyName) queryParameters.push(`companyName=${companyName}`);
    if (companyEmail) queryParameters.push(`companyEmail=${companyEmail}`);
    if (isActive !== undefined) queryParameters.push(`isActive=${isActive}`);
    if (trnNumber) queryParameters.push(`trnNumber=${trnNumber}`);
    if (page) queryParameters.push(`page=${page}`);
    if (limit) queryParameters.push(`limit=${limit}`);

    let queryString = queryParameters.join('&');

    return await privateApi.get(`company/getCompanies?${queryString}`);
};



export const getGlobalCompanyList = async (params) => await privateApi.get('company/getGolbalCompanyList', params)
export default {
    createCompany,
    editCompany,
    getCompaniesByQuery,
    getCompany,
    getAllCompaniesList,
};

