import axios from 'axios';

let baseURL = process.env.REACT_APP_BASE_URL


const api = axios.create({
    withCredentials: true,
    baseURL,
    timeout: 50000,
});

api.interceptors.response.use(
    (response) => response.data,
    (error) => {
        throw handleApiError(error);
    }
);

export async function publicApi(method, path, data = null, params = null) {
    try {
        const response = await api({
            method,
            url: path,
            data,
            params,
        });
        return response;
    } catch (error) {
        return error.response ? error.response : error;
    }
}

export const privateApi = {
    async get(path, params = null) {
        try {
            const response = await api.get(path, {params} );
            return response;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    async post(path, data = null, params = null) {
        try {
            const response = await api.post(path, data, { params });
            return response;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    async put(path, data = null, params = null) {
        try {
            const response = await api.put(path, data, { params });
            return response;
        } catch (error) {
            throw handleApiError(error);
        }
    },

    async delete(path, params = null) {
        try {
            const response = await api.delete(path, { params });
            return response;
        } catch (error) {
            throw handleApiError(error);
        }
    },

};

function handleApiError(error) {
    if (error.response) {
        if (error.response.status === 401) {
            const userId = localStorage.getItem('_id');

            if (userId) {
                localStorage.removeItem('_id');
            }
            localStorage.setItem('isSessionOut', 'true');
            window.location.reload();
        }

        return error.response.data;
    } else if (error.request) {
        return { message: 'No response from the server' };
    } else {
        return { message: error?.error?.message || (typeof error?.error === 'string' || Array.isArray(error?.error) ? error?.error : 'An error occurred while making the request') };

    }
}
