import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { approveTempUserJoinRequest, declineUserJoinRequest, getPendingApprovalUsers } from "./Service";
import Table from "../../components/commons/Table/Table";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { toast } from "react-toastify";
import CustomDialogueMedium from "../../components/commons/CustomDialogueMedium";
import { DeleteButton, SaveButton } from "../../components/commons/Buttons";
import { Chip, Grid, Skeleton } from "@mui/material";
import TextInput from "../../components/commons/TextInput";
import SelectInput from "@mui/material/Select/SelectInput";
import { allUserRoles, roleOptionsForCustomerAdmin, roleOptionsForSuperAdmin, roleOptionsForVendorAdmin } from "../../constants/commonConstants";
import SelectBox from "../../components/commons/SelectInput";

const tableColumns = [
    { field: "displayName", header: "Full Name", type: "text", colSize: "large" },
    { field: "email", header: "Email", type: "text", colSize: "large" },
    { field: "companyName", header: "Company", type: "text", colSize: "large" },
    { field: "authProvider", header: "Login Method", type: "text", colSize: "medium" },
    { field: "createdAt", header: "Requested At", type: "date", colSize: "medium" },
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
];

const mobileOnlyColumns = [
    { field: "actions", header: "Edit", type: "button", colSize: "small" },
    { field: "displayName", header: "Full Name", type: "text", colSize: "large" },
    { field: "companyName", header: "Company", type: "text", colSize: "small" },
];

const PendingApprovalUsersTable = forwardRef((props, ref) => {
    const sessionUser = useSelector((state) => state.userData.data.role);
    const sessionUserFrachise = useSelector((state) => state.userData.data.franchise);
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.userData.data.userType)
    const filterValues = useSelector((state) => state.userManagement.pendingApprovalUsersFilter);
    const filterResetFlag = useSelector((state) => state.userManagement.pendingApprovalUsersFilterResetFlag);
    const [usersList, setUsersList] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const pageSize = 20;
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userBeingEdited, setUserBeingEdited] = useState({});
    const [roleOptions, setRoleOptions] = useState([]);
    const [loading, setLoading] = useState(false);


    const getUserRoles = () => {
        let options;
        if (sessionUser === 'superAdmin' || sessionUser === 'coreAdmin') {
            options = [...roleOptionsForCustomerAdmin, ...roleOptionsForVendorAdmin];
        } else if (sessionUser === 'customerAdmin') {
            options = roleOptionsForCustomerAdmin;
        } else if (sessionUser === 'vendorAdmin') {
            options = roleOptionsForVendorAdmin;
        } else {
            // Default options if none of the above conditions match
            options = [];
        }
        setRoleOptions(options)
    }

    useEffect(() => {
        getAllPendingActiveUsersToList(1, pageSize, generateQueryString());
        getUserRoles();
    }, [sessionUserFrachise]);

    const generateQueryString = () => {
        let queryString = "";
        for (let key in filterValues) {
            if (filterValues[key] !== "") {
                // Autosuggest component query
                if (key === 'company' || key === 'authProvider') {
                    queryString += `&${key}=${filterValues[key]?.value ?? ""}`
                } else {
                    // other input queries that uses Selectbox or Textfield 
                    queryString += `&${key}=${filterValues[key]}`
                }
            }
        }
        return queryString;
    }


    const getAllPendingActiveUsersToList = async (page, pageSize, filterQuery) => {
        setIsLoading(true);
        try {
            const response = await getPendingApprovalUsers(page, pageSize, filterQuery);
            if (response?.data?.message === 'Ok') {
                const formattedUsersArr = [];
                response.data.users.forEach((user) => {
                    formattedUsersArr.push({
                        _id: user._id,
                        displayName: user.data.displayName !== "" ? user.data.displayName : `${user.data.firstName} ${user.data.lastName}`,
                        email: user.email,
                        companyName: user.companyId?.companyName || user?.data?.companyName,
                        authProvider: user.data?.authProvider,
                        photoURL: user.data?.photoURL ?? "",
                        createdAt: user.createdAt,
                    })
                })
                setActivePage(response.data.page || 1);
                setTotalPages(response.data.totalPages || 0);
                setUsersList(formattedUsersArr || []);
            }
            setIsLoading(false);
            if (props.isFilterModalOpen) {
                if (response?.data?.users?.length) {
                    dispatch({ type: 'userManagement/setPendingApprovalUsersFilterErrorFlag', payload: "" })
                    setTimeout(() => {
                        props.closeFilterModal(false);
                    }, 400);
                } else {
                    dispatch({ type: 'userManagement/setPendingApprovalUsersFilterErrorFlag', payload: "No result for given query." })
                }
            }
        } catch (error) {
            toast.warning('Invalid query parameters!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                style: { width: '400px' },
            });
        }
    };

    const handlePageChange = (event, newPage) => {
        if (
            Object.values(filterValues).some(value => value !== "")
        ) getAllPendingActiveUsersToList(newPage, pageSize, generateQueryString());
        else getAllPendingActiveUsersToList(newPage, pageSize, true);
    };

    useImperativeHandle(ref, () => ({
        triggerSearchFromParent,
    }));

    const triggerSearchFromParent = () => {
        const hasAtLeastOneFilterValue = Object.values(filterValues).some(value => value !== "");
        dispatch({ type: 'userManagement/setPendingApprovalUsersFilterErrorFlag', payload: "Please choose at least one filter option." })
        if (!hasAtLeastOneFilterValue) return;

        const filterQuery = generateQueryString();
        getAllPendingActiveUsersToList(activePage, pageSize, filterQuery);
    };

    useEffect(() => {
        if (filterResetFlag) getAllPendingActiveUsersToList(1, pageSize);
    }, [filterResetFlag]);

    const openUserApprovalModal = (user) => {
  
        setUserBeingEdited(user);
        setDialogOpen(true);
    }

    const handleValueChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setUserBeingEdited({
            ...userBeingEdited,
            [name]: value
        })
    }

    const handleDialogClose = () => {
        if (!loading) {
            setDialogOpen(false);
            setUserBeingEdited({});
        }
    }

    const footerButtons = (
        <>
            <DeleteButton text={"Decline"} onClick={() => { handleDecline(); }} disabled={loading} />
            <SaveButton text={"Approve"} onClick={() => { handleApprove(); }} disabled={loading} />
        </>
    );

    const handleDecline = async () => {
        setLoading(true);
        try {
            // Make the API request to decline the user request
            const result = await declineUserJoinRequest(userBeingEdited._id);

            // Check if the API request was successful
            if (result.success) {
                // Remove the declined user from the usersList state
                setUsersList(usersList.filter((user) => user._id !== userBeingEdited._id));

                // Close the dialog
                handleDialogClose();

                // Display a success message using toast
                toast.success('User request declined successfully', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    style: { width: '400px' },
                });
            } else {
                // Handle other possible error scenarios, e.g., display an error message
                toast.error('Failed to decline user request', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    style: { width: '400px' },
                });
            }
        } catch (error) {
            if (error.message) {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    style: { width: '400px' },
                });
            } else {
                toast.error('An error occurred while declining the user request', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    style: { width: '400px' },
                });
            }
        } finally {
            // Set loading state to false regardless of success or failure
            setLoading(false);
        }
    };

    const handleApprove = async () => {
        if (!userBeingEdited.role) {
            toast.warning('Please select a valid role', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                style: { width: '400px' },
            });
            return;
        }
        setLoading(true);
        try {
            // Make the API request to approve the user request
            const response = await approveTempUserJoinRequest({
                _id: userBeingEdited._id,
                role: userBeingEdited.role,
            });

            // Check if the API request was successful
            if (response.success) {
                setUsersList(usersList.filter((user) => user._id !== userBeingEdited._id));
                // Close the dialog
                handleDialogClose();

                // Display a success message using toast
                toast.success('User request approved successfully', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    style: { width: '400px' },
                });
            } else {
                toast.error('Failed to approve user request', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    style: { width: '400px' },
                });
            }
        } catch (error) {
            if (error.message) {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    style: { width: '400px' },
                });
            } else {
                toast.error('An error occurred while approving the user request', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    style: { width: '400px' },
                });
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: `${userType === 'Customer' ? 'flex-start' : 'flex-end'}` }}>
            <Table
                data={usersList}
                columns={tableColumns}
                mobileOnlyColumns={mobileOnlyColumns}
                onButtonClick={openUserApprovalModal}
                isLoading={isLoading}
                firstColumnSticky={true}
            />
            <Stack spacing={2} sx={{ marginTop: '25px' }}>
                <Pagination
                    defaultPage={1}
                    page={activePage}
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>

            <CustomDialogueMedium
                open={dialogOpen}
                onClose={handleDialogClose}
                headerText={'Approve or Decline Register Requests'}
                footerButtons={footerButtons}
            >
                {
                    !loading && (
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Full Name"
                                    name={'displayName'}
                                    value={userBeingEdited.displayName}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Email"
                                    name={'email'}
                                    value={userBeingEdited.email}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Company Name"
                                    name={'company'}
                                    value={userBeingEdited.companyName}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectBox
                                    label="Role"
                                    name='role'
                                    value={userBeingEdited.role ?? ""}
                                    onChange={handleValueChange}
                                    options={roleOptions}
                                    required={true}
                                    helperText={!userBeingEdited.role ? 'Please choose a role' : ""}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', }}>
                                <Chip
                                    label={`Requested on: ${new Date(userBeingEdited.createdAt).toLocaleString()}`}
                                    color="primary"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    )
                }
                {
                    loading && (
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Skeleton variant="text" width="100%" height={40} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Skeleton variant="text" width="100%" height={40} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Skeleton variant="text" width="100%" height={40} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Skeleton variant="text" width="100%" height={40} />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Skeleton variant="text" width="100%" height={40} />
                            </Grid>
                        </Grid>
                    )
                }
            </CustomDialogueMedium>
        </div>
    );
});

export default PendingApprovalUsersTable;


