// import React from "react";
// import { TextField as MuiTextField, InputAdornment } from "@mui/material";

// const TextInput = (props) => {
//     const {
//         startAdornment,
//         endAdornment,
//         onStartAdornmentClick,
//         onEndAdornmentClick,
//         variant,
//         ...otherProps
//     } = props;

//     return (
//         <MuiTextField
//             variant={variant ?? "outlined"}
//             fullWidth
//             {...otherProps}
//             sx={{ height: '50px' }}
//             InputProps={{
//                 startAdornment: startAdornment && (
//                     <InputAdornment position="start" onClick={onStartAdornmentClick}>
//                         {startAdornment}
//                     </InputAdornment>
//                 ),
//                 endAdornment: endAdornment && (
//                     <InputAdornment position="end" onClick={onEndAdornmentClick}>
//                         {endAdornment}
//                     </InputAdornment>
//                 ),
//                 ...otherProps.InputProps,
//             }}
//         />
//     );
// };

// export default TextInput;


import React from "react";
import { TextField as MuiTextField, InputAdornment } from "@mui/material";

const commonTextInput = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
      borderWidth: "1px",
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "black",
    },
  },
};

const TextInput = (props) => {
  const {
    startAdornment,
    endAdornment,
    onStartAdornmentClick,
    onEndAdornmentClick,
    variant,
    sx,
    ...otherProps
  } = props;

  return (
    <MuiTextField
      variant={variant ?? "outlined"}
      fullWidth
      {...otherProps}
      sx={{ height: '50px', ...commonTextInput, ...sx }}
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start" onClick={onStartAdornmentClick}>
            {startAdornment}
          </InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end" onClick={onEndAdornmentClick}>
            {endAdornment}
          </InputAdornment>
        ),
        ...otherProps.InputProps,
      }}
    />
  );
};

export default TextInput;

