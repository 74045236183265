import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createNewItAmcIssue, getAllListItAmcIssueList, addNewComment, updateItAmcIssue, updateIssueAttachments } from './service';

const initialState = {
    isModalOpen: false,
    data: {
        issueHeading: "",
        description: "",
        company: null,
        companyLocation: null,
        status: "",
        paymentStatus: "",
        attendedBy: [],
        reportedUsers: [],
        uploads: []
    },
    editData: null,
    itAmcIssuesList: [],
    itAmcIssuesFilter: {
        currentPage: 1,
        limit: 10,
        dataType: "table"
    },
    totalPages: 1,
    loading: false // New loading state
}

export const createNewIssue = createAsyncThunk(
    "itAmcManagement/createNewIssue",
    async (data, { rejectWithValue }) => {
        try {
            const response = await createNewItAmcIssue(data)
            return response.data
        } catch (err) {
            return rejectWithValue(err.message ?? 'An unexpected error occurred while making the request.')
        }
    }
)

export const updateIssue = createAsyncThunk(
    "itAmcManagement/updateIssue",
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateItAmcIssue(data._id, data)
            return response.data
        } catch (err) {
            return rejectWithValue(err.message ?? 'An unexpected error occurred while making the request.')
        }
    }
)
export const addComment = createAsyncThunk(
    "itAmcManagement/addComment",
    async (data, { rejectWithValue }) => {
        try {
            const response = await addNewComment(data._id, data)
            return response.data
        } catch (err) {
            return rejectWithValue(err.message ?? 'An unexpected error occurred while making the request.')
        }
    }
)


export const fetchItAmcIssueWithQuery = createAsyncThunk(
    "itAmcManagement/fetchItAmcIssueWithQuery",
    async (params, { rejectWithValue }) => {
        try {
            const response = await getAllListItAmcIssueList(params)
            return response.data
        } catch (err) {
            return rejectWithValue(err.message ?? 'An unexpected error occurred while making the request.')
        }
    }
)
export const fetchDataItAmcCsvWithQuery =  createAsyncThunk(
    "itAmcManagement/fetchDataItAmcCsvWithQuery",
    async (params, { rejectWithValue }) => {
        try {
            const response = await getAllListItAmcIssueList(params)
            return response.data
        } catch (err) {
            return rejectWithValue(err.message ?? 'An unexpected error occurred while making the request.')
        }
    }
)

export const updateIssueUploads = createAsyncThunk(
    "itAmcManagement/updateIssueUploads",
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateIssueAttachments(data._id, data)
            return response.data
        } catch (err) {
            return rejectWithValue(err.message ?? 'An unexpected error occurred while making the request.')
        }
    }
)

const itAmcManagementSlice = createSlice({
    name: "itAmcManagement",
    initialState,
    reducers: {
        OPEN_IT_AMC_CREATE_ISSUE: (state, action) => {
            state.isModalOpen = action.payload
        },
        UPDATE_IT_AMC_UPDATE_DATA: (state, action) => {
            state.data[action.payload.name] = action.payload.value;
        },
        RESET_IT_AMC_UPDATE_DATA: (state) => {
            state.data = initialState.data
        },
        UPDATE_IT_AMC_FILTER_DATA: (state, action) => {
            state.itAmcIssuesFilter = { ...state.itAmcIssuesFilter, ...action.payload };
        },
        RESET_IT_AMC_FILTER_DATA: (state, action) => {
            state.itAmcIssuesFilter = initialState.itAmcIssuesFilter
        },
        SELECT_EDIT_DATA: (state, action) => {

            const editDataUpdate = state.itAmcIssuesList.find(issue => issue._id === action.payload._id)

            state.editData = {
                ...editDataUpdate,
                company: { value: editDataUpdate.company._id, label: editDataUpdate.company.companyName, type: editDataUpdate.company.type },
                ...(editDataUpdate.companyLocation && {
                    companyLocation: {
                        value: editDataUpdate.companyLocation.id,
                        label: editDataUpdate.companyLocation.locationName
                    }
                })
            }
            if (action.payload) {
                state.isModalOpen = "update"
            } else {
                state.isModalOpen = false
                state.editData = null
            }
        },
        resetItAmcInitialState: () => initialState
    },
    extraReducers: (builder) => {
        builder
            // createNewIssue
            .addCase(createNewIssue.pending, (state) => {
                state.loading = true
            })
            .addCase(createNewIssue.fulfilled, (state, action) => {
                state.itAmcIssuesList = [action.payload.newIssue, ...state.itAmcIssuesList]
                state.data = initialState.data
                state.isModalOpen = false
                state.loading = false
            })
            .addCase(createNewIssue.rejected, (state, action) => {
                state.loading = false
            })
            // fetchItAmcIssueWithQuery
            .addCase(fetchItAmcIssueWithQuery.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchItAmcIssueWithQuery.fulfilled, (state, action) => {
                state.itAmcIssuesList = action.payload.issueList
                state.totalPages = action.payload.totalPage
                state.loading = false
            })
            .addCase(fetchItAmcIssueWithQuery.rejected, (state, action) => {
                state.loading = false
            })
            // addComment
            .addCase(addComment.pending, (state) => {
                state.loading = true
            })
            .addCase(addComment.fulfilled, (state, action) => {
                const { internalComments, _id } = action.payload.updatedData
                state.itAmcIssuesList = state.itAmcIssuesList.map((val) => {
                    if (val._id === _id) {
                        return ({ ...val, internalComments })
                    }
                    return val
                })
                state.loading = false
            })
            .addCase(addComment.rejected, (state, action) => {
                state.loading = false
            })

            // updateIssue
            .addCase(updateIssue.pending, (state) => {
                state.loading = true
            })
            .addCase(updateIssue.fulfilled, (state, action) => {
                state.itAmcIssuesList = [action.payload.updatedIssue, ...state.itAmcIssuesList.filter((val) => val._id !== action.payload.updatedIssue._id)]
                state.isModalOpen = false
                state.loading = false
            })
            .addCase(updateIssue.rejected, (state, action) => {
                state.loading = false
            })
        // update upload file or remove file
        // .addCase()
    }
})

export const {
    OPEN_IT_AMC_CREATE_ISSUE,
    UPDATE_IT_AMC_UPDATE_DATA,
    resetItAmcInitialState,
    RESET_IT_AMC_UPDATE_DATA,
    UPDATE_IT_AMC_FILTER_DATA,
    RESET_IT_AMC_FILTER_DATA,
    SELECT_EDIT_DATA
} = itAmcManagementSlice.actions

export default itAmcManagementSlice.reducer
