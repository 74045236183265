import React, { useState, useEffect } from 'react';
import {
    IconButton, Typography, Dialog, DialogTitle, DialogContent, TextField, Autocomplete, useMediaQuery,
    useTheme, Chip, Stack, Box,
    Grid
} from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import service from '../../../app/Asset/service';

// contractType
const AssetController = ({
    selectedAssets,
    onChange,
    company,
    disabled,
    location
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [assetsList, setAssetsList] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getAssets('');
    }, [company])

    const getAssets = async (searchString) => {
        try {
            const queryData = {};
            if (company) queryData.company = company;
            if (searchString) queryData.searchString = searchString;
            const response = await service.searchAssets(queryData);
            if (response?.data?.assets?.length) {
                setAssetsList(response?.data?.assets);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSearch = (event, value) => {
        getAssets(value);
    }

    const handleSelection = (event, value) => {
        onChange(value);
    }

    const handleClear = () => {
        onChange([]);
    }

    return (
        <div>
            <Box sx={{ border: '1px solid #ced4da', cursor: 'pointer', borderRadius: '4px', padding: '10px', display: 'flex', alignItems: 'center' }} role="button" onClick={() => disabled ? null : setOpen(true)}>
                {selectedAssets?.slice(0, 3).map(asset =>
                    <Chip
                        size={isMobile ? "small" : "medium"}
                        sx={{ borderRadius: '8px', marginRight: isMobile ? '1px' : '2px', fontWeight: isMobile ? 400 : 500 }}
                        key={asset?.value}
                        label={asset?.label}
                    />
                )}
                {selectedAssets?.length > 3 && <Typography variant="body2" color="textSecondary">...{selectedAssets.length - 3} more</Typography>}
                {!selectedAssets?.length && 'Search & Add Machine / Asset'}
                {!disabled &&
                    <Stack direction="row" spacing={1} marginLeft="auto">
                        <IconButton onClick={() => setOpen(true)}>
                            <Search />
                        </IconButton>
                        <IconButton onClick={handleClear}>
                            <Clear />
                        </IconButton>
                    </Stack>
                }
            </Box>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>
                    Search by Machine Code or Name
                    <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={() => setOpen(false)}>
                        <Clear />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ marginTop: '10px' }}>
                    <Autocomplete
                        disabled={disabled}
                        multiple
                        options={assetsList.filter((asset) => {
                            return asset.location === "No Location" || asset.location === location || !location
                        }) || []}
                        value={selectedAssets || []}
                        onInputChange={handleSearch}
                        onChange={handleSelection}
                        sx={{ width: !isMobile ? '500px' : 'auto', marginTop: '5px' }}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderInput={(params) => <TextField {...params} label="Search" helperText="Start typing to search for machines" />}
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                </DialogContent>
            </Dialog>
            
        </div>
    )
}

export default AssetController;