
export function companyCreateOrEditValidation(data) {
    // Validations
    let errors = [];

    if (!data.companyName) {
        errors.push('Company name is required');
    }

    // if (!data.trnNumber) {
    //     errors.push('TRN number is required');
    // }

    if (!data.type) {
        errors.push('Type is required');
    }

    for (let i = 0; i < data.locations.length; i++) {
        let location = data.locations[i];

        if (!location.id) {
            errors.push(`ID of location ${i + 1} is required`);
        }

        if (!location.locationName) {
            errors.push(`Location name of location ${i + 1} is required`);
        }

        if (!location.address) {
            errors.push(`Address of location ${i + 1} is required`);
        } else {
            if (!location.address.streetLine1) {
                errors.push(`Street line 1 of address of location ${i + 1} is required`);
            }

            if (!location.address.city) {
                errors.push(`City of address of location ${i + 1} is required`);
            }
        }
    }
    return errors;
} 