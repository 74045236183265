import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { labels } from '../../constants/commonConstants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function LabelSelector({ value, onChange, disableFlag }) {
    const [selectedLabels, setSelectedLabels] = useState(value || []);
    const [isFocused, setIsFocused] = useState(false);
    const [menuWidth, setMenuWidth] = useState(null);

    useEffect(() => {
        if (value !== undefined) {
            setSelectedLabels(value);
        }
    }, [value]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedLabels(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const handleDelete = (valueToDelete) => () => {
        setSelectedLabels((prevSelected) =>
            prevSelected.filter((item) => item !== valueToDelete)
        );
    };

    const [options, setOptions] = useState(labels);

    useEffect(() => {
        if (disableFlag) {
            // Filter options where value is in the selectedLabels
            setOptions((prevState) => {
                return options.filter((option) =>
                    selectedLabels.includes(option.value)
                );
            })
        }
    }, [disableFlag, selectedLabels]);


    const label = 'Labels';

    const menuStyle = isFocused && selectedLabels.length > 2 ? { width: menuWidth } : {};

    return (
        <div>
            <FormControl fullWidth variant='outlined'>
                <InputLabel id="label-selector-label" htmlFor={"label-selector"} shrink={value ? true : undefined}>{label}</InputLabel>
                <Select
                    autoClose={true}
                    labelId="label-selector-label"
                    id="label-selector"
                    multiple
                    value={selectedLabels}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    input={
                        <OutlinedInput
                            id="select-label-selector"
                            label={label}
                        />
                    }
                    renderValue={(selected) => {
                        if (!isFocused && selected.length > 2) {
                            const selectedChips = selected.slice(0, 2);
                            return (
                                <>
                                    {selectedChips.map((value) => (
                                        <Chip
                                            key={value}
                                            label={value}
                                            onDelete={null} // Remove close icon
                                            icon={options.find(option => option.value === value).icon}
                                        />
                                    ))}
                                    {`+${selected.length - 2} more`}
                                </>
                            );
                        }

                        return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip
                                        key={value}
                                        label={value}
                                        onDelete={null} // Remove close icon
                                        icon={options.find(option => option.value === value).icon}
                                    />
                                ))}
                            </Box>
                        );
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                ...menuStyle,
                                maxHeight: `${window.innerHeight * 0.5}px`,
                            },
                        },
                    }}
                    onOpen={() => {
                        if (selectedLabels.length > 2) {
                            const width = selectedLabels.reduce((acc, value) => {
                                const chip = document.getElementById(`chip-${value}`);
                                return acc + (chip ? chip.offsetWidth : 0);
                            }, 0);
                            setMenuWidth(width + 16);
                        }
                    }}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value} disabled={disableFlag}>
                            {option.icon}&nbsp;&nbsp;{option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
