import { privateApi } from "../../api";

const getTicketTotalsIn10Days = async () => {
    return await privateApi.get(`tickets/kpi/getTicketTotalsIn10Days`);
}


const getTicketStatusCount = async () => {
    return await privateApi.get(`tickets/kpi/getTicketStatusCount`);
}


const activeTicketPrioritiesCount = async () => {
    return await privateApi.get(`tickets/kpi/activeTicketPrioritiesCount`);
}

const getTicketCountsPerUser = async (fromDate, toDate) => {
    return await privateApi.post(`tickets/kpi/getTicketCountsPerUser`, {fromDate, toDate});
}

const getTicketCountsPerCompany = async (fromDate, toDate) => {
    return await privateApi.post(`tickets/kpi/getTicketCountsPerCompany`, { fromDate, toDate });
}


export default {
    getTicketTotalsIn10Days,
    getTicketStatusCount,
    activeTicketPrioritiesCount,
    getTicketCountsPerUser,
    getTicketCountsPerCompany
};
