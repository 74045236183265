import React, { useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import ListEnquiryUsers from './ListEnquiryUsers';
import ChatEnquiryUser from './ChatEnquiryUser';

const EnquiryManagement = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <Box sx={{
            position: "relative",
            height: "calc(100vh - 7rem)",
            display: "flex",
        }}>
            {(!isMobile || !dialogOpen) && (
                <ListEnquiryUsers dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
            )}
            {((isMobile || dialogOpen) && dialogOpen) && (
                <ChatEnquiryUser dialogOpen={dialogOpen._id} userData={dialogOpen} setDialogOpen={setDialogOpen} />
            )}
        </Box>
    );
};

export default EnquiryManagement;
