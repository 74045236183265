
export const validateNewTicketInput = (body) => {
    const errors = [];
    if (!body.ticketHead) {
        errors.push('Ticket heading is required.')
    }
    if (!body.ticketHead?.length >= 5) {
        errors.push('Ticket head should have a minimum of 5 chars.')
    }
    if (!body.company.value) {
        errors.push('Company is required');
    }
    if (!body.status) {
        errors.push('Ticket status is required.');
    }
    if (!body.priority) {
        errors.push('Priority is required')
    }
    if (!body.assignedUsers?.length) {
        errors.push('Ticket should be assigned to at least one user.')
    }
    if (body.assignedUsers?.length) {
        body.assignedUsers.forEach((user) => {
            if (!user._id) {
                errors.push('A valid user id is required to assign the ticket.')
            }
        })
    }

    if (body.reportedUsers?.length) {
        body.reportedUsers.forEach((user) => {
            if (!user._id) {
                errors.push('A valid user id is required to assign the ticket.')
            }
        })
    }

    if (typeof (body.proposalBreakUps) !== 'function' && body.proposalBreakUps?.length) {
        body.proposalBreakUps?.forEach((item) => {
            if (
                !item.id ||
                String(item.id).trim() === "" ||
                !item.count ||
                String(item.count).trim() === "" ||
                !item.item ||
                String(item.item).trim() === "" ||
                !item.unitPrice ||
                !item.units ||
                String(item.units).trim() === "" ||
                !item.type ||
                String(item.type).trim() === "" ||
                isNaN(Number(item.count)) ||
                isNaN(Number(item.unitPrice)) ||
                isNaN(Number(item.units)) ||
                isNaN(Number(item.amount)) ||
                String(item.amount).trim() === ""
            ) {
                errors.push(`One or more fields are missing or invalid in the input in row ${item.id}.`);
            }
        });
    } else if (typeof (body.proposalBreakUps) === 'function') {
        errors.push("Proposal breakups are not saved.");
    }
    
    if (body.publishAndSendProposal === true & !body.publishProposal) {
        errors.push('Proposal should be published before sending it via email.')
    }
    if ((body.status === 'reqSuperAdminApproval' || body.status === 'reqCustomerAdminApproval') && !body.approvalReason) {
        errors.push('Approval reason is required when status is requireCompanyAdminApproval or requireSuperAdminApproval.')
    }
    if (body.uploads?.length) {
        body.uploads.forEach((file) => {
            if (
                !file._id ||
                !file.destinationParentFolder ||
                !file.destinationSubFolder ||
                !file.filename ||
                !file.downloadURL ||
                !file.destinationPath ||
                !file.userName ||
                !file.contentType ||
                !file.fileSize
            ) {
                errors.push('File details are not in a valid format, please remove and re-upload the file.')
            }
        })
    }
    return errors;
};


export const validateUpdateTicketInput = (body) => {
    const errors = [];
    if (!body.ticketHead) {
        errors.push('Ticket heading is required.')
    }
    if (!body.ticketHead?.length >= 5) {
        errors.push('Ticket head should have a minimum of 5 chars.')
    }
    if (!body.company.value) {
        errors.push('Company is required');
    }
    if (!body.status) {
        errors.push('Ticket status is required.');
    }
    if (!body.priority) {
        errors.push('Priority is required')
    }
    if (!body.assignedUsers?.length) {
        errors.push('Ticket should be assigned to at least one user.')
    }
    if (body.assignedUsers?.length) {
        body.assignedUsers.forEach((user) => {
            if (!user._id) {
                errors.push('A valid user id is required to assign the ticket.')
            }
        })
    }

    if (body.reportedUsers?.length) {
        body.reportedUsers.forEach((user) => {
            if (!user._id) {
                errors.push('A valid user id is required to assign the ticket.')
            }
        })
    }

    if (typeof (body.proposalBreakUps) !== 'function' && body.proposalBreakUps?.length) {
        body.proposalBreakUps?.forEach((item) => {
            if (
                !item.id ||
                String(item.id).trim() === "" ||
                !item.count ||
                String(item.count).trim() === "" ||
                !item.item ||
                String(item.item).trim() === "" ||
                !item.unitPrice ||
                !item.units ||
                String(item.units).trim() === "" ||
                !item.type ||
                String(item.type).trim() === "" ||
                isNaN(Number(item.count)) ||
                isNaN(Number(item.unitPrice)) ||
                isNaN(Number(item.units)) ||
                isNaN(Number(item.amount)) ||
                String(item.amount).trim() === ""
            ) {
                errors.push(`One or more fields are missing or invalid in the input in row ${item.id}.`);
            }
        });
    } else if (typeof (body.proposalBreakUps) === 'function') {
        errors.push("Proposal breakups are not saved.");
    }

    if (body.publishAndSendProposal === true & !body.publishProposal) {
        errors.push('Proposal should be published before sending it via email.')
    }
    if ((body.status === 'reqSuperAdminApproval' || body.status === 'reqCustomerAdminApproval') && !body.approvalReason) {
        errors.push('Approval reason is required when status is requireCompanyAdminApproval or requireSuperAdminApproval.')
    }
    return errors;
};