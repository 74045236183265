import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { ImportButton } from '../commons/Buttons';
import service from './service';
import { toast } from "react-toastify";
import MUIDatePicker from '../commons/DatePicker/DatePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const CustomFieldController = ({
    onChange,
    fieldValues
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const sessionUser = useSelector((state) => state.userData.data)
    const [customFieldList, setCustomFieldList] = useState([]);
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const getSearchQueryResult = async (searchQuery) => {
        const response = await service.getCustomFieldValuesByQuery(searchQuery);
        if (response.status === 200 && response.data?.customFields?.length) {
            setCustomFieldList(response.data.customFields);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSearch = (event, newValue) => {
        setSearchValue(newValue);
        getSearchQueryResult(newValue);
    };

    const handleImportSelection = (event, value) => {
        if (!value) {
            return
        };
        // Check if the selected value already exists in the fieldValues array
        const exists = fieldValues?.some(field => field.label === value.label);

        // If it doesn't exist, call the onChange function
        if (!exists) {
            let date;
            if (value.inputType === 'date') {
                const dateString = value.value;
                const [day, month, year] = dateString.split('/');
                date = new Date(year, month - 1, day);
            }
            onChange([...fieldValues, { inputType: value.inputType, label: value.label, permissions: value.permissions, value: value.inputType === 'date' ? date : value.value }])
            handleClose();
            setTimeout(() => {
                const labelWithoutSpaces = value.label.replace(/\s+/g, '').toLowerCase();
                const fieldElement = document.querySelector(`#${labelWithoutSpaces}`);
                if (fieldElement) {
                    fieldElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300);
        } else {
            toast.warning(`Selected item already in added.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                style: { width: '400px' },
            });
        }
    };

    const handleInputChange = (e, label, inputType) => {
        const newValue = inputType === 'date' ? e : e.target.value;
        onChange(fieldValues.map((item) => item?.label === label ? { ...item, value: newValue } : item));
    }

    const handleRemoveField = (label) => {
        onChange(fieldValues.filter(item => item.label !== label));
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <ImportButton text={'Import Custom Fields'} onClick={handleClickOpen} />
                <Dialog open={open} onClose={handleClose} maxWidth="md">
                    <DialogTitle>Import Custom Fields</DialogTitle>
                    <DialogContent sx={{ marginTop: '10px' }}>
                        <Autocomplete
                            label={''}
                            options={customFieldList}
                            value={searchValue}
                            onInputChange={handleSearch}
                            onChange={handleImportSelection}
                            renderInput={(params) => <TextField {...params} label="Search" helperText="Start typing to search for custom fields" />}
                        />
                    </DialogContent>
                </Dialog>
            </Grid>
            {
                fieldValues?.filter(item => item.permissions.includes(sessionUser.role))
                    ?.map((fieldValue) => {
                        return (
                            <>
                                <Grid item xs={(fieldValue.inputType === 'longText' ||  isMobile) ? 12 : 6} id={fieldValue?.label?.replace(/\s+/g, '')?.toLowerCase()}>
                                    {
                                        ['longText', 'shortText', 'number'].includes(fieldValue.inputType) &&
                                        <TextField
                                            type={fieldValue.inputType === 'number' ? 'number' : 'text'}
                                            label={fieldValue.label}
                                            value={fieldValue.value}
                                            multiline={fieldValue.inputType === 'longText'}
                                            minRows={2}
                                            fullWidth={true}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip title="Remove this field from the Grid" placement="top" arrow>
                                                            <IconButton onClick={() => handleRemoveField(fieldValue.label)}>
                                                                <HighlightOffIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={(e) => { handleInputChange(e, fieldValue.label, 'text') }}
                                        />
                                    }
                                    {
                                        fieldValue.inputType === 'date' &&
                                        <Grid container spacing={1} id={fieldValue?.label?.replace(/\s+/g, '')?.toLowerCase()}>
                                            <Grid item xs={11}>
                                                <MUIDatePicker
                                                    fullWidth={true}
                                                    label={fieldValue.label}
                                                    value={isNaN(Date.parse(fieldValue.value)) ? null : new Date(fieldValue.value)}
                                                    timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                                    onChange={(e) => {
                                                        const dateString = e?.toLocaleDateString('en-GB') || '';
                                                        handleInputChange(dateString, fieldValue.label, 'date')
                                                    }}
                                                    onClear={() => {
                                                        handleInputChange('', fieldValue.label, 'date')
                                                    }}

                                                />
                                            </Grid>
                                            <Grid item xs={1} container alignItems="center" justifyContent="center">
                                                <InputAdornment position="end">
                                                    <Tooltip title="Remove this field from the Grid" placement="top" arrow>
                                                        <IconButton onClick={() => handleRemoveField(fieldValue.label)}>
                                                            <HighlightOffIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </>
                        )
                    })
            }
        </Grid>
    )
}

export default CustomFieldController;