export const ticketStatus = [
    {
        value: 'todo', label: 'TODO'
    },
    {
        value: 'inProgress', label: 'IN-PROGRESS'
    },
    {
        value: 'paymentPending', label: 'PAYMENT-PENDING'
    },
    {
        value: 'customerAdminApprovals', label: 'Customer Admin Approvals'
    },
    {
        value: 'superAdminApprovals', label: 'Super Admin Approvals'
    },
    {
        value: 'hold', label: 'HOLD'
    },
    {
        value: 'done', label: 'DONE'
    },
]

export const statusToListInApprovalBucketSuperAdmin = [
    'reqSuperAdminApproval',
    'superAdminApproved',
    'superAdminDeclined'
]

export const statusToListInApprovalBucketCustomerAdmin = [
    'reqCustomerAdminApproval',
    'customerAdminApproved',
    'customerAdminDeclined',
]


