import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AdfScannerOutlinedIcon from '@mui/icons-material/AdfScannerOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
    useMediaQuery,
    useTheme
} from '@mui/material';
import {
    DataGrid,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridToolbar
} from '@mui/x-data-grid';
import { InputLabel } from '@mui/material';

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AED',
});

const calculateAmount = (unitPrice, units) => {
    // Calculate the amount
    const amount = unitPrice * units;
    // Limit the decimals to two digits
    const roundedAmount = parseFloat(amount.toFixed(2));

    return roundedAmount;
};

function EditToolbar(props) {
    const { setRows, setRowModesModel, rowsLength, sessionUser, isMobile } = props;

    const handleClick = () => {
        const newId = (rowsLength + 1).toString(); // Generate a unique ID
        setRows((oldRows) => [...oldRows, { id: newId, count: newId, item: '', unitPrice: '', units: '', amount: '', isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [newId]: { mode: 'edit' },
        }));
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClick}
                disabled={sessionUser !== 'coreAdmin' && sessionUser !== 'superAdmin' && sessionUser !== "technicalAdmin" && sessionUser !== 'technician'}
            >
                Add Quotation or Proposal
            </Button>
            {!isMobile && <GridToolbar />}
        </div>
    );
}


function ProposalDataGrid({ rows, setRows, disabled }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.userData.data.role);
    const isNewTicketNode = useSelector((state) => state.ticketManagement.newTicketModalOpen);
    const publishProposal = useSelector((state) => state.ticketManagement[isNewTicketNode ? 'newTicket' : 'existingTicket'].publishProposal);
    const publishAndSendProposal = useSelector((state) => state.ticketManagement[isNewTicketNode ? 'newTicket' : 'existingTicket'].publishAndSendProposal);
    const numberOfProposalSent = useSelector((state) => state.ticketManagement.existingTicket.numOfProposalSent);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: 'edit' } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: 'view' } });
    };

    const handleDeleteClick = (id) => () => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: 'view', ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        // Calculate the total amount for the updated row
        const newAmount = calculateAmount(newRow.unitPrice, newRow.units);

        // Create an updatedRow object with the new amount and set isNew to false
        const updatedRow = { ...newRow, amount: newAmount, isNew: false };

        // Update the rows with the updatedRow
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

        return updatedRow;
    };


    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const calculateTotalAmount = () => {
        // Calculate the total amount by summing up all the row amounts
        const totalAmount = rows.reduce((total, row) => total + calculateAmount(row.unitPrice, row.units), 0);
        const vat = totalAmount * 0.05; // Calculate the Value Added Tax (VAT)
        return totalAmount + vat;
    };

    function GridFooter(props) {
        const label = { inputProps: { 'aria-label': 'Publish Proposal' } };

        const handleProposalCheckBoxChange = (e) => {
            const value = e.target.checked;
            const name = e.target.name;
            if (value) {
                // Validate the rows before allowing change in proposal publish;
                const validateItems = (rows) => {
                    for (const item of rows) {
                        if (!item.item || item.item === "") {
                            toast.error('Error: Item is missing in one or more items.', {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 3000, // You can adjust the duration of the error message
                            });
                            return false;
                        }
                        if (
                            isNaN(Number(item.unitPrice)) ||
                            isNaN(Number(item.units)) ||
                            isNaN(Number(item.amount))
                        ) {
                            toast.error('Error: Invalid numeric value in one or more items.', {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 3000, // You can adjust the duration of the error message
                            });
                            return false;
                        }
                    }
                    return true;
                };
                if (!validateItems(rows)) return;
            }
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: isNewTicketNode ? 'newTicket' : 'existingTicket',
                    field: name,
                    value: value,
                },
            });
            if (name === 'publishAndSendProposal' && value && !publishProposal) {
                dispatch({
                    type: 'ticketManagement/updateFieldValues',
                    payload: {
                        state: isNewTicketNode ? 'newTicket' : 'existingTicket',
                        field: 'publishProposal',
                        value: true,
                    },
                });
            }
            if (name === 'publishProposal' && !value && publishAndSendProposal) {
                dispatch({
                    type: 'ticketManagement/updateFieldValues',
                    payload: {
                        state: isNewTicketNode ? 'newTicket' : 'existingTicket',
                        field: 'publishAndSendProposal',
                        value: false,
                    },
                });
            }
        }

        return (
            <>
                {
                    (sessionUser === 'superAdmin' || sessionUser === 'technicalAdmin' || sessionUser === 'technician' ) &&
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px', borderTop: 'solid 1px #E0E0E0' }}>
                            <div style={{ display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', alignItems: props.isMobile ? 'left' : 'center', padding: isMobile ? '3px' : '' }}>
                            <Tooltip title="This will make the proposal visible to all the stakeholders of this ticket.">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                    <InputLabel variant="body1" fontStyle="italic">Publish Proposal</InputLabel>
                                    <Switch
                                        {...label}
                                            disabled={rows?.length <= 0 || disabled}
                                            name={'publishProposal'}
                                            checked={publishProposal}
                                            onChange={handleProposalCheckBoxChange}
                                        />
                                    </div>
                                </Tooltip>
                                <Divider orientation="vertical" />
                                <Tooltip title="This will make the Proposal Visible to the customers as well as an email will be sent to respective stakeholders of this ticket">
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                        <InputLabel variant="body1" fontStyle="italic">{numberOfProposalSent > 0 ? 'Re-Send Proposal Email' : 'Send Proposal Email'}</InputLabel>
                                        <Switch
                                            {...label}
                                            name={'publishAndSendProposal'}
                                            disabled={rows?.length <= 0 || disabled}
                                            checked={publishAndSendProposal}
                                            onChange={handleProposalCheckBoxChange}
                                        />
                                    </div>
                                </Tooltip>
                                {
                                    !isNewTicketNode && (
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                            <InputLabel variant="body1" fontStyle="italic">{`Total Proposals Sent`} <span><strong>{numberOfProposalSent}</strong></span></InputLabel>
                                        </div>
                                    )
                                }
                            </div>
                            <div ><strong>Total Amount including 5% VAT: {currencyFormatter.format(calculateTotalAmount())}</strong></div>
                        </div>
                }
                {/* If session user is not a super admin the render only the total amount in the footer */}
                {
                    (sessionUser !== 'superAdmin' || sessionUser !== 'technicalAdmin' || sessionUser !== 'technician') && (
                        <div style={{padding: '5px'}}><strong>Total Amount including 5% VAT: {currencyFormatter.format(calculateTotalAmount())}</strong></div>
                    )
                }
            </>
        );
    }


    const columns = [
        { field: 'id', headerName: '#', width: 50 },
        { field: 'item', headerName: 'Item', width: 230, editable: (sessionUser === 'superAdmin' || sessionUser === 'technicalAdmin' || sessionUser === 'technician') },
        { field: 'type', headerName: 'Type', width: 120, editable: (sessionUser === 'superAdmin' || sessionUser === 'technicalAdmin' || sessionUser === 'technician'), type: 'singleSelect', valueOptions: ['Product', 'Service'], },
        {
            field: 'unitPrice',
            headerName: 'Unit Price',
            width: 235,
            editable: (sessionUser === 'superAdmin' || sessionUser === 'technicalAdmin' || sessionUser === 'technician'),
            valueFormatter: ({ value }) => currencyFormatter.format(value),
        },
        { field: 'units', headerName: 'Units', width: 100, editable: (sessionUser === 'superAdmin' || sessionUser === 'technicalAdmin' || sessionUser === 'technician') },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 235,
            valueFormatter: ({ value }) => currencyFormatter.format(value),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 80,
            sortable: false,
            renderCell: (params) => {
                const id = params.row.id;
                const isInEditMode = rowModesModel[id]?.mode === 'edit';

                if (isInEditMode) {
                    return (
                        <div>
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={handleSaveClick(id)}
                                disabled={(sessionUser !== 'superAdmin' && sessionUser !== 'technicalAdmin' && sessionUser !== 'technician')}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                disabled={(sessionUser !== 'superAdmin' && sessionUser !== 'technicalAdmin' && sessionUser !== 'technician')}
                                color="inherit"
                            />
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={handleEditClick(id)}
                                disabled={(sessionUser !== 'superAdmin' && sessionUser !== 'technicalAdmin' && sessionUser !== 'technician')}
                                color="inherit"
                            />,
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={handleDeleteClick(id)}
                                disabled={(sessionUser !== 'superAdmin' && sessionUser !== 'technicalAdmin' && sessionUser !== 'technician')}
                                color="inherit"
                            />
                        </div>
                    );
                }
            },
        },
    ];


    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: !disabled ? EditToolbar : null,
                    footer: GridFooter
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel, rowsLength: rows?.length, sessionUser: sessionUser, isMobile: isMobile },
                    footer: {isMobile: isMobile}
                }}
                hideFooterPagination={true}
                hideFooterRowCount={true}
                hideFooterSelectedRowCount={true}
                loading={false}
                disabled={disabled ?? false}
            />
        </Box>
    );
}

export default ProposalDataGrid;