import { useEffect } from "react";
import { db } from "../../../../utilities/auth-config/firebase-config";
import { collection, query, where, onSnapshot, arrayUnion, Timestamp, orderBy, limit } from "@firebase/firestore";
import '../AdminChatComponent.css';
import notificationSound from '../../../../MediaContents/Sounds/notifications-sound-127856.mp3'
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from 'reselect';
import { Bounce, toast } from "react-toastify";

const AttendedChatsRedux = () => {
    const dispatch = useDispatch();

    /** GETTING ONLY THE IDS OF ACCEPTED CHAT ROOMS TO LISTEN FOR */
    const selectAttendedChats = state => state.chats.attendedChats;
    const selectAttendedChatIds = createSelector(
        selectAttendedChats,
        attendedChats => attendedChats.map(chat => chat.id)
    );
    const attendedChatIds = useSelector(selectAttendedChatIds);
    /** *******************ENDS HERE******************** */

    const admin = useSelector((state) => state.userData?.data);
    const openedChatRoomId = useSelector((state) => state.chats.openedChatId);

    // Firebase db refs
    const roomRef = collection(db, 'rooms');
    const messageDbRef = collection(db, 'messages');



    useEffect(() => {
        /*** ACCEPTED INCOMING CHATS ****/
        const adminName = admin.displayName
            ? admin.displayName
            : `${admin.firstName} ${admin.lastName}`;
        const q = query(roomRef, 
            where("attendedBy", "array-contains", { id: admin._id, role: admin.role, displayName: adminName }),
            where("status", "==", "attended"),
            where("franchiseArea", "==", admin.franchise.area));
        const unsubscribeFromRooms = onSnapshot(q, (querySnapshot) => {
            const attendedChats = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            dispatch({ type: 'chats/updateAttendedChats', payload: attendedChats });
        });
        /*********************************/
        return () => {
            unsubscribeFromRooms();
        };
    }, [admin._id , admin.franchise]);


    useEffect(() => {
        let unsubscribe;
        const currentTimestamp = Timestamp.fromDate(new Date());

        if (attendedChatIds && attendedChatIds.length) {
            const queryMessage = query(messageDbRef,
                where("roomId", "in", attendedChatIds?.filter((item) => item.id !== openedChatRoomId)),
                where('timestamp', '>', currentTimestamp),
                orderBy("timestamp")
            );

            unsubscribe = onSnapshot(queryMessage, { includeMetadataChanges: true }, (snapshot) => {
                const addedMessages = snapshot.docChanges()
                    .filter(change => change.type === 'added')
                    .map(change => ({ ...change.doc.data(), id: change.doc.id }));

                if (addedMessages?.length >= 1) {
                    const roomIds = [...new Set(addedMessages.map(item => item.roomId))];
                    dispatch({ type: 'chats/updateUnSeenMessages', payload: roomIds, });

                    if (!roomIds?.includes(openedChatRoomId) || window.location.pathname !== '/Support') {
                        const audio = new Audio(notificationSound);
                        audio.play().catch(error => {
                            console.error("Audio play failed due to", error);
                        });
                    }
                    if (window.location.pathname !== '/Support') {
                        const lastMessageIndex = addedMessages.length - 1;
                        const lastMessage = addedMessages[lastMessageIndex];
                        // Customize how you want to display the message here
                        const messagePreview = lastMessage.message.substring(0, 20) + (lastMessage.message.length > 20 ? "..." : "");
                        toast.info(`New message from ${lastMessage.userName}, ${messagePreview}`, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 8000,
                            transition: Bounce,
                        });
                    }
                }
            });
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [attendedChatIds, openedChatRoomId]);



    return (
        <>
        </>
    )
}

export default AttendedChatsRedux

