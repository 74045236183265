import { configureStore } from '@reduxjs/toolkit';
import UserSlice from './User/UserSlice';
import UserManagementSlice from './User/UserManagementSlice';
import ticketManagementSlice from './Ticket/TicketManagement'
import chatSlice from "../pages/Support/AdminChatSupport/ChatSlice"
import CompanySlice from './Company/CompanySlice';
import AssetSlice from './Asset/AssetSlice';
import franchiseSlice from './Franchise/FranchiseSlice'
import ExportAssetSlice from './ExportAsset/ExportAssetSlice';
import ItAmcManagement from './ItAmc/ItAmcManagement';

export const store = configureStore({
  reducer: {
    userData: UserSlice,
    chats: chatSlice,
    userManagement: UserManagementSlice,
    ticketManagement: ticketManagementSlice,
    companyManagement: CompanySlice,
    assetManagement: AssetSlice,
    franchiseMangement:franchiseSlice,
    exportAssetMangement:ExportAssetSlice,
    itAmcManagement:ItAmcManagement
  },
});
