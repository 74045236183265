import { useRef } from 'react';
import './AdminChatComponent.css';
import LeftSideChatSelector from './ChatSection-1-LeftComponent/LeftSideChatSelector';
import ChatInputController from './ChatSection-2-CenterComponent/ChatInputController';
import ChatViewComponent1 from './ChatSection-2-CenterComponent/ChatViewComponent';
import ChatRightComponent from './ChatSection-3-RightComponent/ChatRightComponent';

function AdminChatComponent() {
    const scrollDivRef = useRef(null);

    return (
        <div className="grid-container">
            <div className="grid-item col1 custom-scrollbar">
                {/* Left Side Chat Selector Component */}
                <LeftSideChatSelector />
            </div>
            <div className="grid-item col2">
                <div className="col2-row1 custom-scrollbar" ref={scrollDivRef}>
                    <ChatViewComponent1 scrollDivRef={scrollDivRef} />
                </div>
                <div className="col2-row2">
                    <ChatInputController />
                </div>
            </div>
            <div className="grid-item col3">
                <ChatRightComponent/>
            </div>
        </div>
    );
}

export default AdminChatComponent;
