import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchDataItAmcCsvWithQuery } from './path-to-your-action';
import { FileDownload } from '@mui/icons-material';
// import StyledIconButton from './path-to-your-styled-icon-button';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { StyledIconButton } from '../ComapnyManagement/CompanyManagement';
import { fetchDataItAmcCsvWithQuery } from '../../app/ItAmc/ItAmcManagement';

const DownloadCsvButton = () => {
    const dispatch = useDispatch();
    const itAmcIssueFilter = useSelector(state => state.itAmcManagement.itAmcIssuesFilter)

    const handleDownloadCsv = async () => {
        try {
            const res = await dispatch(fetchDataItAmcCsvWithQuery({ ...itAmcIssueFilter, dataType: "csv" }));
            const data = res.payload.issueList;
            const getLocation = (location) => {
                return location ? location.locationName : ""
            }
            const formatDate = (notFormatedDate) => {
                let date = new Date(notFormatedDate)
                return date.toLocaleDateString()
            }
            // Prepare the data for CSV writing
            const csvData = data.map(issue => ({
                // ID: issue._id,
                'Custom Issue ID': issue.customIssueId,
                'Issue Heading': issue.issueHeading,
                Description: issue.description,
                'Company Name': issue.company?.companyName,
                'Company Location': getLocation(issue.companyLocation),
                'Status': issue.status,
                'Payment Status': issue.paymentStatus,
                'Created By': issue.createdBy?.displayName,
                'Updated By': issue.updatedBy?.displayName,
                'Created At': formatDate(issue.createdAt),
                'Updated At': formatDate(issue.updatedAt)
            }));
            // Convert data to CSV
            const csv = unparse(csvData);

            // Create a Blob from the CSV string
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

            // Use file-saver to save the CSV file
            saveAs(blob, 'ItAmcIssues.csv');
        } catch (error) {
            console.error('Error fetching data and generating CSV:', error);
        }
    };

    return (
        <StyledIconButton onClick={handleDownloadCsv}>
            <FileDownload />
        </StyledIconButton>
    );
};

export default DownloadCsvButton;
