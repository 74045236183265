import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Avatar,
    Chip,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    AvatarGroup,
    Tooltip,
    Pagination,
    Stack,
    Box,
    Skeleton,
    TextField,
    MenuItem
} from '@mui/material'
import { useSelector } from 'react-redux';
import service, { getSupportAgents } from './service';
import { stringToColor } from '../../../../utilities/commonUtils';
import UserRoleChip from '../../../../components/commons/UserRoleChip';
import { ticketStatusForSearch } from '../../../../constants/commonConstants';
import { getSavedTicket, getTicketsByQuery } from '../../../../app/Ticket/TicketManagement';
import { createSelector } from 'reselect';

import { collection, query, where, onSnapshot, Timestamp, doc, getDoc, updateDoc, arrayUnion, arrayRemove } from "@firebase/firestore";
import { db } from "../../../../utilities/auth-config/firebase-config"
import { Circle, FiberManualRecord } from '@mui/icons-material';

const ChatRightComponent = () => {
    const dispatch = useDispatch();
    const currentChatUser = useSelector((state) => state.chats.openedChatId);
    const currentChatRoomConfig = useSelector(state => state.chats.currentChatRoom)
    const attendedChats = state => state.chats.attendedChats;
    const selectedChatRoomId = useSelector((state) => state.chats.openedChatId);
    const selectOpenedChat = createSelector(
        attendedChats,
        attendedChats => attendedChats.find(chat => chat.id === selectedChatRoomId)
    );
    const currentChat = useSelector(selectOpenedChat);
    const [user, setUser] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [activeAgents, setActiveAgents] = useState([])
    const activeAdminsDoc = doc(db, 'activeAdmins', 'admins');
    const sessionUser = useSelector((state) => state.userData.data);
    useEffect(() => {
        if (currentChatUser) {
            getUserData();
            getTicketsData();
        } else {
            setUser(null)
        }
    }, [currentChatUser , currentChat])

    useEffect(() => {
        const fetchActiveAdmins = async () => {
            const docSnap = await getDoc(activeAdminsDoc);
            if (docSnap.exists()) {
                const activeAdmins = docSnap.data().admins;
                const supportAgentsResponse = await getSupportAgents()
                setActiveAgents(supportAgentsResponse.
                    data?.
                    supportAgentList?.
                    map((admins) => ({ ...admins, online: [...activeAdmins].includes(admins._id) })) || [])

            }
        };


        const unsubscribeAdmins = onSnapshot(activeAdminsDoc, fetchActiveAdmins);

        return () => {
            unsubscribeAdmins();
        };
    }, []);


    async function getTicketsData() {
        const response = await service.getTicketsByQuery({ company: currentChat.company.companyId });

        if (response.data?.tickets?.length) {
            setTickets(response.data?.tickets);
        } else {
            setTickets([])
        }
    }

    async function getUserData() {
        const response = await service.getUserData(currentChatUser);
        if (response?.data?.data) {
            setUser(response?.data?.data)
        } else {
            setUser(null)
        }
    }

    /**
  * generate a color code based on the due date for label background
  * @param {*} dueDate 
  * @returns hex colorCode 
  */
    const calculateBackgroundColor = (dueDate) => {
        const today = new Date();
        const due = new Date(dueDate);
        const timeDifference = due - today;
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        if (daysDifference <= 2) {
            return '#ffd6cc'; // Due in the next 2 days or earlier
        } else if (daysDifference <= 5) {
            return '#ffe6cc'; // Due within 3 to 5 days
        } else {
            return '#d9f3d9'; // Due beyond 5 days
        }
    };

    const openTicketEditModal = (customTicketId) => {
        dispatch({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'mainState',
                field: 'existingTicketModalOpen',
                value: true,
            },
        });
        // Now lets dispatch the api call fetch the ticket data for the modal
        dispatch(getSavedTicket(customTicketId))
    }

    // Listen for changes from firebase
    useEffect(() => {
        const currentTimestamp = Timestamp.fromDate(new Date());

        const q1 = query(
            collection(db, 'tickets'),
            where('reportedUserIds', 'array-contains-any', [currentChatUser]),
            where('updatedAt', '>', currentTimestamp)
        );

        const q2 = query(
            collection(db, 'tickets'),
            where('assignedUserIds', 'array-contains-any', [currentChatUser]),
            where('updatedAt', '>', currentTimestamp)
        );

        const unsubscribe1 = onSnapshot(q1, handleSnapshot, handleError);
        const unsubscribe2 = onSnapshot(q2, handleSnapshot, handleError);

        function handleSnapshot(snapshot) {
            const firebaseTickets = [];
            snapshot.docChanges().forEach((change) => {
                if (change.type === 'added' || change.type === 'modified') {
                    let ticketData = change.doc.data();
                    ticketData.createdAt = new Date(ticketData.createdAt?.seconds * 1000).toISOString();
                    ticketData.updatedAt = new Date(ticketData.updatedAt?.seconds * 1000).toISOString();
                    firebaseTickets.push(ticketData);
                }
            });
            if (firebaseTickets.length > 0) {
                setTickets(tickets => [...firebaseTickets, ...tickets]);
            }
        }

        function handleError(error) {
            console.error('Error getting snapshots:', error);
        }

        return () => {
            unsubscribe1();
            unsubscribe2();
        };

    }, [currentChatUser]);
    const handleEscalation = async (id) => {
        const roomRef = doc(db, "rooms", currentChatUser);
        const excalateAgent = activeAgents.find(ag => ag._id === id)
        const adminName = excalateAgent.displayName
            ? excalateAgent.displayName
            : `${excalateAgent.firstName} ${excalateAgent.lastName}`;
        await updateDoc(roomRef, {
            status: 'attended',
            attendedBy: arrayUnion({ id: excalateAgent._id, role: excalateAgent.role, displayName: adminName }),
            attendedByName: adminName
        });
        if (currentChatRoomConfig.currentSupportAgent) {
            await updateDoc(roomRef, {
                attendedBy: arrayRemove(currentChatRoomConfig.currentSupportAgent),
            });
        }
        if (sessionUser.role === 'supportAgent') {
            dispatch({ type: 'chats/setOpenedChatId', payload: null });
        }
    }

    return (
        <>
            {
                user?._id &&
                <Paper elevation={3} sx={{ paddingBottom: '5px' }}>
                    <Grid container alignItems="center" sx={{ padding: '2px', overFlow: 'hidden' }}>
                        <Grid item xs={12} container alignItems="center" spacing={2} direction="row">
                            <Grid item xs={3} container justifyContent="center" alignItems="center">
                                <Avatar
                                    src={user?.photoURL || undefined}
                                    alt={user?.displayName ? user?.displayName.charAt(0) : user?.firstName.charAt(0)}
                                    sx={{
                                        bgcolor: user.displayName
                                            ? stringToColor(user.displayName)
                                            : stringToColor(`${user.firstName} ${user.lastName}`),
                                        width: 48,
                                        height: 48,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {user.displayName ? user.displayName.charAt(0) : user.firstName.charAt(0)}
                                </Avatar>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: 'bold',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {user.displayName ? user.displayName : `${user.firstName} ${user.lastName}`}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                    {user.company.companyName.length > 25
                                        ? `${user.company.companyName.slice(0, 25)}...`
                                        : user.company.companyName}
                                </Typography>
                                {
                                    user?.companyLocation?.locationName ?
                                        <Typography
                                            variant="subtitle1"
                                            color="textSecondary"
                                            sx={{
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '25ch',
                                            }}
                                        >
                                            {user.companyLocation?.locationName}
                                        </Typography>
                                        // <Chip label={`Location: ${user.companyLocation?.locationName}`} sx={{ margin: 0.5 }} />
                                        : null
                                }
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        maxWidth: '25ch',
                                    }}
                                >
                                    {user.email}
                                </Typography>
                                <UserRoleChip role={user.role} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ paddingLeft: '3px', paddingTop: '5px' }}>
                            {/* Using Chips for additional styling */}
                            <Chip label={`Custom User Id: ${user.customUserId}`} sx={{ margin: 0.5 }} />
                            <Chip label={`Joined: ${new Date(user.createdAt).toLocaleDateString()}`} sx={{ margin: 0.5 }} />
                            <Chip label={`Preferred Contact Method: ${user.preferredContactMethod}`} sx={{ margin: 0.5 }} />
                            <Chip label={`Phone Number: ${user.contactPhoneNumber}`} sx={{ margin: 0.5 }} />
                            <Chip label={`Company Code: ${user.company.companyCode}`} sx={{ margin: 0.5 }} />
                            {/* Add more Chips for additional details */}
                            <Chip label={`Auth Provider: ${user.authProvider}`} sx={{ margin: 0.5 }} />
                        </Grid>
                        {!currentChatRoomConfig.isSupportAgent && <Grid item xs={12} sx={{ paddingLeft: '3px', paddingTop: '5px' }}>
                            <TextField
                                select
                                fullWidth
                                label="Escalate Chat"
                                variant="outlined"
                                name="machineName"
                            // onClick={handleEscalation}
                            >
                                {[...activeAgents]
                                .filter(agent => {
                                    if(currentChatRoomConfig.currentSupportAgent){
                                        return agent._id !== currentChatRoomConfig.currentSupportAgent.id
                                    }

                                    return true
                                })
                                .map((agent) => (<MenuItem key={agent._id} value={agent._id} onClick={() => handleEscalation(agent._id)}>
                                    <Grid display={"flex"} gap={".5rem"}>
                                        {agent.online ? <FiberManualRecord color="success" /> : <FiberManualRecord color="primary" />}
                                        <Typography>{agent.displayName}</Typography>
                                    </Grid>
                                </MenuItem>))}
                            </TextField>
                        </Grid>}
                    </Grid>
                </Paper>
            }

            {user?._id && <Paper elevation={3} sx={{ marginTop: '5px' }}>
                <TableContainer className='custom-scrollbar' sx={{ overflowY: 'auto', minHeight: '200px' }}>
                    <Table aria-label="simple table">
                        <TableHead sx={{ backgroundColor: '#f2f2f2', position: 'sticky !important', top: 0, zIndex: 5 }}>
                            <TableRow>
                                <TableCell sx={{ width: '5%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>ID</TableCell>
                                <TableCell sx={{ width: '50%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Ticket Head</TableCell>
                                <TableCell sx={{ width: '10%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Status</TableCell>
                                <TableCell sx={{ width: '10%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Company</TableCell>
                                <TableCell sx={{ width: '10%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Assigned Users</TableCell>
                                <TableCell sx={{ width: '5%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Priority</TableCell>
                                <TableCell sx={{ width: '5%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Due Date</TableCell>
                                <TableCell sx={{ width: '5%', fontWeight: 'bold', color: 'white', padding: '8px 4px' }}>Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tickets?.length > 0 && tickets.map((ticket) => (
                                    <TableRow
                                        key={ticket.customTicketId}
                                        sx={{ borderBottom: '1px solid #e0e0e0' }}
                                        hover
                                    >
                                        <TableCell
                                            sx={{
                                                width: '5%',
                                                cursor: 'pointer',
                                                padding: '4px',
                                            }}
                                            onClick={() => { openTicketEditModal(ticket.customTicketId) }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 600,
                                                    cursor: 'pointer',
                                                    color: '#1769aa', // Use the primary color for links (blue by default)
                                                    textDecoration: 'none',
                                                    transition: 'text-decoration 0.2s ease-in-out',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                            >
                                                {ticket.customTicketId}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ width: '30%', padding: '4px' }}>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontWeight: 600,
                                                    cursor: 'pointer',
                                                    color: '#1769aa', // Use the primary color for links (blue by default)
                                                    textDecoration: 'none',
                                                    transition: 'text-decoration 0.2s ease-in-out',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                                onClick={() => { openTicketEditModal(ticket.customTicketId) }}
                                            >
                                                {ticket.ticketHead}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ width: '10%', padding: '4px' }}>
                                            <Chip
                                                label={(() => {
                                                    const statusItem = ticketStatusForSearch.find(item => item.value === ticket.status);
                                                    return statusItem ? statusItem.label : ticket.status;
                                                })()}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '2px',
                                                    minWidth: "50px",
                                                    backgroundColor: (() => {
                                                        const statusItem = ticketStatusForSearch.find(item => item.value === ticket.status);
                                                        return statusItem ? statusItem.backgroundColor : '#f2f2f2';
                                                    })(),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '10%', padding: '4px' }}>
                                            <Chip
                                                label={ticket?.company?.companyName?.substring(0, 17)}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '1px',
                                                    backgroundColor: '#f2f2f2',
                                                    width: '100%',
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '5%', padding: '4px' }}>
                                            <Tooltip title={ticket?.assignedUsers?.map((user) => user.displayName || `${user.firstName} ${user.lastName}`).join(', ')} arrow>
                                                <AvatarGroup max={3}>
                                                    {ticket?.assignedUsers?.length && ticket?.assignedUsers?.map((user) => {
                                                        const { displayName, firstName, lastName, photoURL } = user;
                                                        const fullName = (firstName && lastName) ? `${firstName} ${lastName}` : displayName;
                                                        return (
                                                            <Avatar
                                                                key={user._id}
                                                                alt="User Avatar"
                                                                src={user.photoURL}
                                                                sx={{
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    backgroundColor: user.photoURL ? 'transparent' : stringToColor(fullName),
                                                                    color: 'white',
                                                                    cursor: 'pointer',
                                                                    zIndex: 1,
                                                                }}
                                                            >
                                                                {(!user.photoURL && (user.displayName || user.firstName)) ? (
                                                                    user.displayName
                                                                        ? user.displayName[0]
                                                                        : user.firstName[0]
                                                                ) : null}
                                                            </Avatar>
                                                        )
                                                    })}
                                                </AvatarGroup>
                                            </Tooltip>
                                        </TableCell>

                                        <TableCell sx={{ width: '10%', padding: '4px' }}>
                                            <Chip
                                                label={ticket.priority.charAt(0).toUpperCase() + ticket.priority.slice(1)}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '3px',
                                                    minWidth: "70px",
                                                    backgroundColor: (() => {
                                                        switch (ticket.priority) {
                                                            case 'low':
                                                                return '#d9f3d9'; // Light green for low priority
                                                            case 'medium':
                                                                return '#ffe6cc'; // Light orange for medium priority
                                                            case 'high':
                                                                return '#ffd6cc'; // Light red for high priority
                                                            default:
                                                                return '#f2f2f2'; // Default light gray color
                                                        }
                                                    })(),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '5%', padding: '4px' }}>
                                            <Chip
                                                label={isNaN(Date.parse(ticket?.dueDate)) ? null : new Date(ticket?.dueDate).toLocaleDateString('en-GB')}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '0px',
                                                    backgroundColor: isNaN(Date.parse(ticket?.dueDate)) ? 'transparent' : calculateBackgroundColor(ticket?.dueDate),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ width: '5%', padding: '4px' }}>
                                            <Chip
                                                label={new Date(ticket?.createdAt).toLocaleDateString('en-GB')}
                                                sx={{
                                                    borderRadius: '5px',
                                                    padding: '0px',
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>}

        </>
    );
};

export default ChatRightComponent;
