import React, { useTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Divider,
    Paper,
    useMediaQuery,
    useTheme,
    Grid,
    InputLabel,
    TextField,
    Skeleton,
    Stack,
    Tooltip,
    Chip,
    Avatar,
    InputAdornment,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Box,
    FormHelperText,
    Button,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction
} from '@mui/material';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import '../../../index.css'
import '../ticket.css'
import SelectInput from '../../../components/commons/SelectInput';
import MUIDatePicker from '../../../components/commons/DatePicker/DatePicker';
import { getCompaniesList } from '../../UserMangaement/Service';
import Autocomplete from '../../../components/commons/Autocomplete';
import FileUploader from '../../../components/FileUploader/FileUploader';
import TicketStatusSelector from '../../../components/commons/TicketStatusSelector';
import LabelSelector from '../../../components/commons/LabelSelector';
import MultipleUserSelector from '../../../components/commons/MultipleUserSelector/MultipleUserSelector';
import TicketPrioritySelector from '../../../components/commons/TicketPrioritySelector';
import { stringToColor } from '../../../utilities/commonUtils';
import { format } from 'date-fns';
import { ExportButton, ResetButton, SearchButton } from '../../../components/commons/Buttons';
import { labels, ticketStatusForSearch } from '../../../constants/commonConstants';
import TicketSearchResultView from './QuickSearchResultView';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import '../../../index.css'
import { getTicketsByQuery } from '../../../app/Ticket/TicketManagement';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GetAppIcon from '@mui/icons-material/GetApp';
import { styled } from "@mui/material/styles";


const StyledIconButton = styled(IconButton)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
}));


function SearchQueryComponent({ companiesList }) {
    const [isPending, startTransition] = useTransition();

    const sessionUser = useSelector((state) => state.userData.data);
    const quickSearchData = useSelector((state) => state.ticketManagement.ticketQuickSearch)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    function dispatchApiCall(queryString) {
        dispatch(getTicketsByQuery({ data: { queryString }, page: 1 }))
    }

    return (
        <div>
            <Grid container spacing={2} className='custom-scrollbar'>
                {/* SEARCH QUERY */}
                <Grid item xs={12}>
                    <TextField
                        id="company-location"
                        label="Ticket Id or Ticket Heading"
                        fullWidth
                        placeholder='Ticket Id or Ticket Heading'
                        value={quickSearchData.queryString}
                        onChange={(e) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'queryString',
                                    value: e.target.value,
                                },
                            });
                            startTransition(() => {
                                if (!isPending) dispatchApiCall(e.target.value);
                            })

                        }}
                    />
                </Grid>

                {/* TICKET STATUS */}
                <Grid item xs={12}>
                    <Autocomplete
                        name={'status'}
                        label="Status"
                        multiple={true}
                        fullWidth
                        value={quickSearchData.status}
                        onChange={(e) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'status',
                                    value: e.target.value,
                                },
                            });
                        }}
                        options={ticketStatusForSearch}
                    />
                </Grid>

                {/* COMPANY */}
                <Grid item xs={12} >
                    <Autocomplete
                        label="Company"
                        name='company'
                        fullWidth
                        value={quickSearchData.company}
                        onChange={(e) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'company',
                                    value: e.target.value,
                                },
                            });
                        }}
                        options={companiesList}
                    />
                </Grid>

                {/* ASSIGNED USERS */}
                <Grid item xs={12}>
                    <MultipleUserSelector
                        label='Assigned Technicians'
                        value={quickSearchData.assignedUsers}
                        onChange={(newValue) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'assignedUsers',
                                    value: newValue,
                                },
                            });
                        }}
                        companyId={quickSearchData.company?.value}
                    />
                </Grid>
                {/* REPORTED USERS */}
                <Grid item xs={12}>
                    <MultipleUserSelector
                        label='Reported Users'
                        value={quickSearchData.reportedUsers}
                        onChange={(newValue) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'reportedUsers',
                                    value: newValue,
                                },
                            });
                        }}
                        companyId={quickSearchData.company?.value}
                    />
                </Grid>

                {/* DUE DATE */}
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <MUIDatePicker
                        label="Due From"
                        value={quickSearchData.dueDateFrom}
                        onChange={(e) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'dueDateFrom',
                                    value: e,
                                },
                            });
                        }}
                        onClear={() => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'dueDateFrom',
                                    value: null,
                                },
                            });
                        }}
                        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    />
                </Grid>

                {/* DUE DATE */}
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <MUIDatePicker
                        label="Due To"
                        value={quickSearchData.dueDateTo}
                        onChange={(e) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'dueDateTo',
                                    value: e,
                                },
                            });
                        }}
                        onClear={() => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'dueDateTo',
                                    value: null,
                                },
                            });
                        }}
                        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    />
                </Grid>

                {/* CREATED DATE */}
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <MUIDatePicker
                        label="Created From"
                        value={quickSearchData.createdAtFrom}
                        onChange={(e) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'createdAtFrom',
                                    value: e,
                                },
                            });
                        }}
                        onClear={() => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'createdAtFrom',
                                    value: null,
                                },
                            });
                        }}
                        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    />
                </Grid>

                {/* CREATED DATE */}
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <MUIDatePicker
                        label="Created To"
                        value={quickSearchData.createdAtTo}
                        onChange={(e) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'createdAtTo',
                                    value: e,
                                },
                            });
                        }}
                        onClear={() => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'createdAtTo',
                                    value: null,
                                },
                            });
                        }}
                        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    />
                </Grid>

                {/* PAYMENT STATUS */}
                <Grid item xs={12}>
                    <Autocomplete
                        name={'paymentStatus'}
                        label="Payment Status"
                        fullWidth
                        value={quickSearchData.paymentStatus}
                        onChange={(e) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'paymentStatus',
                                    value: e.target.value,
                                },
                            });
                        }}
                        options={[{
                            value: 'required',
                            label: 'Payment Required'
                        },
                        { value: 'notRequired', label: 'Payment Not Required' },
                        { value: 'paid', label: 'Paid' },
                        { value: 'paymentPending', label: 'Payment Pending' }
                        ]}
                    />
                </Grid>

                {/* LABEL */}
                <Grid item xs={12}>
                    <Autocomplete
                        name={'labels'}
                        label="Labels"
                        multiple={true}
                        fullWidth
                        value={quickSearchData.labels}
                        onChange={(e) => {
                            dispatch({
                                type: 'ticketManagement/updateFieldValues',
                                payload: {
                                    state: 'ticketQuickSearch',
                                    field: 'labels',
                                    value: e.target.value,
                                },
                            });
                        }}
                        options={labels}
                    />
                </Grid>

                <Grid item xs={12} sx={{ position: 'sticky', bottom: 0 }} >
                    <Paper elevation={3} sx={{ borderRadius: '8px', width: '100%', padding: '3px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 1, mb: 2, }}>
                        <Stack direction="row" spacing={2}>
                            <Tooltip title="Export to CSV">
                                <StyledIconButton
                                    onClick={() => { dispatch(getTicketsByQuery({ data: { ...quickSearchData, export: true }, page: 1 })) }}
                                >
                                    <GetAppIcon />
                                </StyledIconButton>
                            </Tooltip>

                            <Tooltip title="Export to PDF">
                                <StyledIconButton
                                    onClick={() => { dispatch(getTicketsByQuery({ data: { ...quickSearchData, exportPdf: true }, page: 1 })) }}
                                >
                                    <PictureAsPdfIcon />
                                </StyledIconButton>
                            </Tooltip>
                            <SearchButton variant="contained" onClick={() => { dispatch(getTicketsByQuery({ data: quickSearchData, page: 1 })) }}>
                                Search
                            </SearchButton>
                            {!quickSearchData.isFilterComponentCollapsed &&
                                <IconButton
                                    onClick={() => {
                                        dispatch({
                                            type: 'ticketManagement/updateFieldValues',
                                            payload: {
                                                state: 'ticketQuickSearch',
                                                field: 'isFilterComponentCollapsed',
                                                value: !quickSearchData.isFilterComponentCollapsed,
                                            },
                                        });
                                    }}
                                    sx={{
                                        transition: 'background-color 0.3s, color 0.3s',
                                        '&:hover': {
                                            color: '#fff',
                                            backgroundColor: '#81d4fa',
                                        },
                                    }}
                                >
                                    <KeyboardArrowLeftIcon />
                                </IconButton>
                            }
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchQueryComponent;
