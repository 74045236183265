import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../AdminChatComponent.css';
import {
    Grid,
    Typography,
    Divider,
    Avatar,
    Paper,
    TextField,
    Chip,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { db } from '../../../../utilities/auth-config/firebase-config';
import { stringToColor } from '../../../../utilities/commonUtils';
import { collection, query, where, onSnapshot, updateDoc, doc, arrayUnion, getDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const UserCard = React.memo(({ user }) => {
    const dispatch = useDispatch();
    const currentChatRoom = useSelector((state) => state.chats.openedChatId);
    const admin = useSelector((state) => state.userData.data);
    const chatSlice = useSelector((state) => state.chats);
    const handleClickOnUser = async (type, user) => {
        const adminName = admin.displayName
            ? admin.displayName
            : `${admin.firstName} ${admin.lastName}`;
        try {
            const roomRef = doc(db, "rooms", user.id);
            const roomSnap = await getDoc(roomRef);

            if (roomSnap.exists()) {
                const roomData = roomSnap.data();
                const attendedByCount = roomData.attendedBy.length;
                await updateDoc(doc(db, 'rooms', user.id), {
                    status: 'attended',
                    attendedBy: arrayUnion({ id: admin._id, role: admin.role, displayName: adminName }),
                    ...(attendedByCount === 0 ? { attendedByName: adminName } : {}),
                });
            }

            if (type === 'add_to_chat_screen' && user.id !== currentChatRoom) {
                dispatch({ type: 'chats/setOpenedChatId', payload: user.id });
            }
            handleDialogClose()
        } catch (error) {
            console.error('Error handling user click:', error);
            toast.error('An error occurred while adding the user to the chat.');
        }
    };

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Paper
                key={user.id}
                elevation={1}
                onClick={handleDialogOpen}
                sx={{
                    margin: '2px',
                    marginBottom: '5px',
                    borderRadius: '8px',
                    transition: 'box-shadow 0.3s',
                    '&:hover': {
                        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.4)',
                        cursor: 'pointer',
                    },
                }}
            >
                <Grid container alignItems="center" sx={{ padding: '2px', overFlow: 'hidden' }}>
                    <Grid item xs={12} container alignItems="center" spacing={2} direction="row">
                        <Grid item xs={3} container justifyContent="center" alignItems="center">
                            <Avatar
                                src={user.photoURL || undefined}
                                alt={user.displayName ? user.displayName.charAt(0) : user.firstName.charAt(0)}
                                sx={{
                                    bgcolor: user.displayName
                                        ? stringToColor(user.displayName)
                                        : stringToColor(`${user.firstName} ${user.lastName}`),
                                    width: 48,
                                    height: 48,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {user.displayName ? user.displayName.charAt(0) : user.firstName.charAt(0)}
                            </Avatar>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 'bold',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {user.displayName ? user.displayName : `${user.firstName} ${user.lastName}`}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                {user.company.companyName.length > 25
                                    ? `${user.company.companyName.slice(0, 25)}...`
                                    : user.company.companyName}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '25ch',
                                }}
                            >
                                {user.email}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingLeft: '3px' }}>
                        <Typography variant="body2">
                            {(() => {
                                switch (user.status) {
                                    case 'open':
                                        return (
                                            <Chip label={`Status: ${user.status}`} color="primary" size="small" />
                                        );
                                    case 'attended':
                                        return (
                                            <Chip label={`Status: ${user.status}`} color="success" size="small" />
                                        );
                                    case 'closed':
                                        return (
                                            <Chip label={`Status: ${user.status}`} color="secondary" size="small" />
                                        );
                                    default:
                                        return (
                                            <Chip label={`Status: ${user.status}`} color="default" size="small" />
                                        );
                                }
                            })()}
                        </Typography>
                        {user.status === "attended" && (
                            <Chip
                                label={`Attended by: ${user.attendedByName}`}
                                color="primary"
                                size="small"
                                icon={<CheckCircleOutlineRoundedIcon />}
                            />
                        )}
                        {/* {user.status === "attended" && (
                            <Chip
                                label={`Attended by: ${user.attendedByName}`}
                                color="primary"
                                size="small"
                                icon={<CheckCircleOutlineRoundedIcon />}
                            />
                        )} */}
                    </Grid>
                </Grid>
                <Divider />
            </Paper>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Handle Chat: {user.displayName || `${user.firstName} ${user.lastName}`}</DialogTitle>
                <DialogContent>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {user.company.companyName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {user.email}
                        </Typography>
                        {user.status === "attended" && (
                            <Chip
                                label={`Attended by: ${user.attendedByName}`}
                                color="primary"
                                size="small"
                                icon={<CheckCircleOutlineRoundedIcon />}
                            />
                        )}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button
                        startIcon={<ChatBubbleOutlineRoundedIcon />}
                        onClick={() => handleClickOnUser('add_to_chat_screen', user)}
                        color="primary"
                        variant="contained"
                        disabled={
                            !chatSlice.isChatModeOn ||
                            (user.status === "attended" && (admin.role !== "coreAdmin" && admin.role !== "superAdmin" && admin.role === "supportAgent")) ||
                            (user.status === "attended" && admin.role === "supportAgent")
                        }>
                        Add to chat screen
                    </Button>
                    <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => handleClickOnUser('add_to_list', user)}
                        color="secondary"
                        variant="contained"
                        disabled={
                            !chatSlice.isChatModeOn ||
                            (user.status === "attended" && (admin.role !== "coreAdmin" && admin.role !== "superAdmin")) ||
                            (user.status === "attended" && admin.role === "supportAgent")
                        }>
                        Add to list
                    </Button>
                </DialogActions>


            </Dialog>
        </>
    );
});

const AllUserChats = () => {
    const [allUsers, setAllUsers] = useState([]);
    const allUsersFirebaseRef = collection(db, 'rooms');
    const sessionUser = useSelector((state) => state.userData.data);

    const [allUsersOriginalArray, setAllUsersOriginalArray] = useState([])

    useEffect(() => {

        const q = query(
            allUsersFirebaseRef,
            where("franchiseArea", "==", sessionUser.franchise.area),
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const roomData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            const updatedRoomData = roomData
                .filter(room => {
                    const roomAttended = [...room.attendedBy].some(admins => admins.id === sessionUser._id)
                    if (sessionUser.role !== 'coreAdmin' && sessionUser.role !== 'superAdmin') {
                        return (!roomAttended && room.userType !== 'Admin')
                    }
                    return (!roomAttended)
                })
                .sort((a, b) => {
                    const aLastSession = a.lastSession ? a.lastSession.seconds : 0;
                    const bLastSession = b.lastSession ? b.lastSession.seconds : 0;
                    return bLastSession - aLastSession;
                });

            setAllUsersOriginalArray(updatedRoomData)
            setAllUsers(updatedRoomData);
        });

        return () => {
            unsubscribe();
        };
    }, [sessionUser.franchise]);

    const handleSearchInputChange = (queryValue) => {
        if (queryValue === '') {
            setAllUsers(allUsersOriginalArray)
        } else {
            const searchResult = allUsersOriginalArray.filter((item) => {
                let matches;
                matches = item.displayName?.toLowerCase()?.includes(queryValue.toLowerCase());
                if (!matches) {
                    matches = item.email?.toLowerCase()?.includes(queryValue.toLowerCase());
                }
                return matches
            })
            setAllUsers(searchResult)
        }
    }

    return (
        <>
            <div className="chat-user-list">
                <Paper
                    elevation={1}
                    sx={{
                        borderRadius: '8px',
                        transition: 'box-shadow 0.3s',
                        position: 'sticky',
                        zIndex: 99,
                        top: '55px',
                        '&:hover': {
                            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.4)',
                            cursor: 'pointer',
                            borderBottom: 'solid 5px #091B95',
                        },
                    }}
                >
                    <TextField
                        variant="outlined"
                        placeholder="Search user"
                        fullWidth
                        sx={{
                            borderRadius: '8px',
                            '&:hover': {
                                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                                cursor: 'pointer',
                            },
                        }}
                        onChange={(e) => handleSearchInputChange(e.target.value)}
                    />
                </Paper>
                {allUsers.map((user) => (
                    <UserCard key={user.id} user={user} />
                ))}
            </div>
        </>
    );
};

export default AllUserChats;
