import { privateApi } from "../../api";


const createNewTicket = async (ticketData) => {
    return await privateApi.post('tickets/createTicket', ticketData);
};

const updateTicket = async (_id, ticketData) => {
    return await privateApi.put(`tickets/updateTicket/${_id}`, ticketData);
};

const getTicketForBoardView = async () => {
    return await privateApi.get('tickets/getTicketForBoardView');
    
};

const getSavedTicket = async (ticketId) => {
    return await privateApi.get(`tickets/getTicket/${ticketId}`);
}

const addCommentToTicket = async (ticketId, commentString) => {
    return await privateApi.post('tickets/addCommentToTicket', { ticketId, commentString })
}

const addInternalCommentsToTicket = async (ticketId, commentString) => {
    return await privateApi.post('tickets/addInternalCommentsToTicket', { ticketId, commentString })
}

const getAiSuggestionsForTicketHeading = async (query, isUniqueSuggestionNeeded)=>{
    return await privateApi.post('ai/getAiSuggestionsForTicketHeading', { query, isUniqueSuggestionNeeded })
}

const getTicketsByQuery = async (query) => {
    const path = `tickets/getTicketsByQuery`;
    return await privateApi.post(path, query);
}

const handleTicketApproval = async (id, data) => {
    const path = `tickets/handleTicketApproval/${id}`;
    return await privateApi.put(path, data);
}

const getTicketStatusChanges = async (id) => {
    const path = `tickets/getStatusChanges/${id}`;
    return await privateApi.get(path);
}
export const superAdminCompanyExist = async ()=>{
    const path = `franchise/getFranchiseCompanySuperAdmin`
    return await privateApi.get(path)
}

export default {
    createNewTicket,
    updateTicket,
    getTicketForBoardView,
    getSavedTicket,
    addCommentToTicket,
    getAiSuggestionsForTicketHeading,
    getTicketsByQuery,
    handleTicketApproval,
    addInternalCommentsToTicket,
    getTicketStatusChanges 
};
