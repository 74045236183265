import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCommentToTicket, addInternalCommentsToTicket, updateTicket, handleTicketApproval } from '../../app/Ticket/TicketManagement'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Divider,
    Paper,
    useMediaQuery,
    useTheme,
    Grid,
    InputLabel,
    TextField,
    Skeleton,
    Stack,
    Chip,
    Avatar,
    InputAdornment,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Box,
    FormHelperText,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { styled } from "@mui/material/styles";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import TicketModalSkeleton from './TicketModalSkeleton';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import '../../index.css'
import { SaveButton } from '../../components/commons/Buttons';
import './ticket.css'
import SelectInput from '../../components/commons/SelectInput';
import MUIDatePicker from '../../components/commons/DatePicker/DatePicker';
import { getCompaniesList } from '../UserMangaement/Service';
import Autocomplete from '../../components/commons/Autocomplete';
import FileUploader from '../../components/FileUploader/FileUploader';
import TicketStatusSelector from '../../components/commons/TicketStatusSelector';
import LabelSelector from '../../components/commons/LabelSelector';
import MultipleUserSelector from '../../components/commons/MultipleUserSelector/MultipleUserSelector';
import TicketPrioritySelector from '../../components/commons/TicketPrioritySelector';
import ProposalDataGrd from './ProposalDataGrid';
import { stringToColor } from '../../utilities/commonUtils';
import { format } from 'date-fns';
import DeclineDialog from './DeclineModal';
import { ticketStatusForSearch } from '../../constants/commonConstants';
import UserChip from '../../components/commons/UserChip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StatusChangesTimeline from './StatusChangesTimeline';
import AssetController from '../../components/commons/AssetController/AssetController';
import CommentController from '../../components/commons/CommentController/CommentController';

const paymentStatusOptions = {
    required: { name: 'Payment Required', color: '#FF4D4F' }, // Red
    notRequired: { name: 'Payment Not Required' }, // Green
    paid: { name: 'Paid', color: '#1890FF' }, // Blue
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    // margin: '10px 0',
    padding: 0
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '0',
    minHeight: 'auto',
    '&.Mui-expanded': {
        minHeight: 'auto',
    },
    '& > .MuiAccordionSummary-content': {
        // margin: '0.5rem 0', 
        '&.Mui-expanded': {
            // margin: '0.5rem 0',
        },
    },
    borderRadius: '4px 4px 0 0',
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: '#f5f5f5',
    borderRadius: '0 0 4px 4px',
    padding: '1rem',
}));


const TicketViewOnlyMode = () => {
    const sessionUser = useSelector((state) => state.userData.data)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const existingTicketData = useSelector((state) => state.ticketManagement.existingTicket);
    const [toggleCommentsAndActivities, setToggleCommentsAndActivities] = useState('comments')
    const [declineModaleOpen, setDeclineModalOpen] = useState(false);

    const dialogContentStyle = {
        overflow: 'hidden',
        maxHeight: isMobile ? '100vh' : '80vh',
        overflowY: 'auto',
    };
    const [companiesList, setCompaniesList] = useState([]);
    const [proposalBreakUps, setProposalBreakUps] = useState([]);

    const [expanded, setExpanded] = useState(false);
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    useEffect(() => {
        getCompaniesList()
            .then((result) => {
                if (result?.data?.length) {
                    let tempArr = [];
                    result.data.forEach(item => {
                        tempArr.push({ value: item._id, label: item.companyName });
                    });
                    setCompaniesList(tempArr)
                } else if (result.data) {
                    setCompaniesList([{ value: result.data._id, label: result.data.companyName }]);
                    dispatch({
                        type: 'ticketManagement/updateFieldValues',
                        payload: {
                            state: 'existingTicket',
                            field: 'company',
                            value: { value: sessionUser.company._id, label: sessionUser.company.companyName },
                        },
                    })
                }
            });
    }, []);

    useEffect(() => {
        if (sessionUser.userType === 'Admin') {
            setToggleCommentsAndActivities('Internal Comments')
        }
    }, [sessionUser.userType])

    useEffect(() => {
        // If proposal break values are there in the redux state then sync that data with local state
        if (existingTicketData?.proposalBreakUps?.length && proposalBreakUps.length === 0) {
            setProposalBreakUps([...existingTicketData?.proposalBreakUps])
        }
    }, [existingTicketData?.proposalBreakUps?.length])

    function saveUploadedFilesInRedux(fileData) {
        dispatch({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'existingTicket',
                field: 'uploads',
                value: [...existingTicketData.uploads, fileData],
            },
        });
    }

    function removeUploadedFileFromRedux(fileName) {
        if (fileName) {
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'existingTicket',
                    field: 'uploads',
                    value: existingTicketData.uploads?.filter(item => item.filename !== fileName),
                },
            });
        }
    }

    const handleProposalBreakUpsGridDataChange = (newValue) => {
        dispatch({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'existingTicket',
                field: 'proposalBreakUps',
                value: newValue,
            },
        });
        setProposalBreakUps(newValue)
    }

    /**
     * Handle API Request state - Error Handling
     */
    useEffect(() => {
        if (existingTicketData.isErrorInTicketCreate && existingTicketData?.validationErrors) {
            const errorMessages = Object.values(existingTicketData.validationErrors);

            // Format error messages as a list
            const formattedErrors = (
                <ul>
                    {errorMessages.map((errorMessage, index) => (
                        <li key={index}>{errorMessage}</li>
                    ))}
                </ul>
            );

            toast.error(formattedErrors, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
                closeOnClick: false,
                draggable: true,
                style: { width: '600px' },
            });
        }
    }, [existingTicketData?.validationErrors]);

    /**
     * Handle API Request state - Information Message
     */
    useEffect(() => {
        if (existingTicketData?.errorMessage !== "") {
            toast.error(existingTicketData?.errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
        }
    }, [existingTicketData?.errorMessage]);

    function formatDate(dateTimeString) {
        return format(new Date(dateTimeString), 'dd/MM/yyyy hh:mm a'); // Format the date and time
    }


    /**
     * Handle API Request state - Success Message
     */
    useEffect(() => {
        if (existingTicketData?.isSuccessInTicketCreate) {
            toast.success('Ticket has been updated successfully.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'existingTicket',
                    field: 'isSuccessInTicketCreate',
                    value: false,
                },
            });
        }
    }, [existingTicketData?.isSuccessInTicketCreate]);

    const handleAdminApproval = (type, declineReason) => {
        if (type === 'approved') {
            dispatch(handleTicketApproval({
                data: {
                    id: existingTicketData._id,
                    status: ['superAdmin', 'technicalAdmin'].includes(sessionUser.role) ? 'superAdminApproved' : 'customerAdminApproved'
                }
            }));
        } else if (type === 'declined-without-reason') {
            setDeclineModalOpen(true);
        } else if (type === 'decline-with-reason') {
            dispatch(handleTicketApproval({
                data: {
                    id: existingTicketData._id,
                    status: ['superAdmin', 'technicalAdmin'].includes(sessionUser.role) ? 'superAdminDeclined' : 'customerAdminDeclined',
                    declineReason: declineReason
                }
            }));
        }
    }

    useEffect(() => {
        if (existingTicketData?.handleApprovalApiSuccess) {
            toast.success(`Ticket status changed to ${existingTicketData?.status}.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'existingTicket',
                    field: 'isSuccessInTicketCreate',
                    value: false,
                },
            });
            setDeclineModalOpen(false)

        }
    }, [existingTicketData?.handleApprovalApiSuccess])



    return (
        <Dialog
            open={true}
            fullScreen={isMobile}
            fullWidth
            maxWidth={isMobile ? 'md' : 'lg'}
        >
            <div className="backdrop" />
            <div className="dialog-wrapper">
                <Paper className="dialog-paper">
                    <DialogTitle
                        className={'fixed-header'}
                        sx={{
                            backgroundColor: '#244fac',
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {existingTicketData.isLoading ? (
                            <Skeleton variant="text" width={150} /> // Adjust the width as needed
                        ) : (
                            <>
                                <strong>{`Ticket Preview: ${existingTicketData.customTicketId ?? ""}`}</strong>
                            </>
                        )}
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                dispatch({ type: 'ticketManagement/handleTicketModals', payload: { modalType: 'viewOnlyTicketModal' } });
                                dispatch({ type: 'ticketManagement/resetState', payload: { field: 'existingTicket' } })
                            }}
                            aria-label="close"
                            sx={{
                                transition: 'background-color 0.3s, color 0.3s',
                                '&:hover': {
                                    color: 'grey',
                                    backgroundColor: '#fff',
                                },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    {/* <Divider /> */}
                    <DialogContent className="dialog-content custom-scrollbar" style={dialogContentStyle}>
                        <div>
                            <Grid container spacing={2}>
                                {
                                    !existingTicketData.isLoading && (
                                        <>
                                            {/* TICKET HEADING */}
                                            <Grid item xs={12}>
                                                <InputLabel sx={{ marginTop: '10px', }} htmlFor="ticketHead">Ticket Heading</InputLabel>
                                                <div
                                                    className={(!existingTicketData.ticketHead || existingTicketData.ticketHead === "") ? 'ticket-head-div ticket-head-no-input-error' : "ticket-head-div"}
                                                    name="ticketHead"
                                                >
                                                    {existingTicketData.ticketHead}
                                                </div>
                                            </Grid>

                                            {/* TICKET DESCRIPTION */}
                                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                                <InputLabel htmlFor="ticketDescription">Ticket Description</InputLabel>
                                                <div
                                                    className="ticket-description-div"
                                                    name="ticketHead"
                                                >
                                                    {existingTicketData.ticketDescription}
                                                </div>
                                            </Grid>

                                            {/* COMPANY */}
                                            <Grid item xs={isMobile ? 12 : 6} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <InputLabel shrink htmlFor="company">
                                                    Company
                                                </InputLabel>
                                                <Box style={{ border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: '5px', padding: '5px', width: '100%' }}>
                                                    <Chip
                                                        label={existingTicketData.company.label}
                                                        sx={{
                                                            borderRadius: '5px',
                                                            padding: '2px',
                                                            minWidth: "50px",
                                                            fontSize: '1.25em',
                                                            fontWeight: 600 
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>

                                            {/* COMPANY LOCATION */}
                                            {((sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin') || (existingTicketData.companyLocation && existingTicketData.companyLocation !== "")) &&
                                                <Grid item xs={isMobile ? 12 : 6} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <InputLabel shrink htmlFor="company-location">
                                                        Company Location
                                                    </InputLabel>
                                                    <Box style={{ border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: '5px', padding: '5px', width: '100%', minHeight: '36px' }}>
                                                        {existingTicketData?.companyLocation?.label && (
                                                            <Chip
                                                                label={existingTicketData.companyLocation?.label}
                                                                sx={{
                                                                    borderRadius: '5px',
                                                                    padding: '2px',
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                </Grid>
                                            }

                                            <Grid item xs={isMobile ? 12 : 6} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <InputLabel shrink htmlFor="labels">
                                                    Labels
                                                </InputLabel>
                                                <Box style={{ border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: '5px', padding: '5px', width: '100%', minHeight: '36px' }}>
                                                    {existingTicketData?.labels?.map((label) => (
                                                        <Chip
                                                            key={label}
                                                            label={label}
                                                            sx={{
                                                                borderRadius: '5px',
                                                                padding: '2px',
                                                                margin: '2px',
                                                            }}
                                                        />
                                                    ))}
                                                </Box>
                                            </Grid>

                                            {/* TICKET STATUS */}
                                            <Grid item xs={isMobile ? 12 : 6} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <InputLabel shrink htmlFor="status">
                                                    Status
                                                </InputLabel>
                                                <Box style={{ border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: '5px', padding: '5px', width: '100%' }}>
                                                    <Chip
                                                        label={(() => {
                                                            const statusItem = ticketStatusForSearch.find(item => item.value === existingTicketData.status);
                                                            return statusItem ? statusItem.label : existingTicketData.status;
                                                        })()}
                                                        sx={{
                                                            borderRadius: '5px',
                                                            padding: '2px',
                                                            minWidth: "50px",
                                                            backgroundColor: (() => {
                                                                const statusItem = ticketStatusForSearch.find(item => item.value === existingTicketData.status);
                                                                return statusItem ? statusItem.backgroundColor : '#f2f2f2';
                                                            })(),
                                                        }}
                                                    />
                                                </Box>
                                                {existingTicketData.approvalRequireFlag && (
                                                    <FormHelperText>
                                                        ** This ticket requires Admin review and approval.
                                                    </FormHelperText>
                                                )}
                                            </Grid>

                                            {/* APPROVAL REASON TEXT INPUT */}
                                            {((existingTicketData.status === 'reqCustomerAdminApproval' && ['superAdmin', 'customerAdmin', 'technicalAdmin', 'technician'].includes(sessionUser.role)) || (existingTicketData.status === 'reqSuperAdminApproval' && ['superAdmin', 'technicalAdmin', 'technician'].includes(sessionUser.role))) && (
                                                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <InputLabel shrink htmlFor="admins-approval-reason">
                                                        Approval Reason
                                                    </InputLabel>
                                                    <Typography id="admins-approval-reason" variant="body1" style={{ border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: '5px', padding: '5px', width: '100%' }}>
                                                        {existingTicketData.approvalReason || 'N/A'}
                                                    </Typography>
                                                </Grid>
                                            )}

                                            {/* DUE DATE */}
                                            <Grid item xs={isMobile ? 12 : 6} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <InputLabel shrink htmlFor="due-date">
                                                    Due Date
                                                </InputLabel>
                                                <Typography id="due-date" variant="body1" style={{ border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: '5px', padding: '5px', width: '100%' }}>
                                                    {existingTicketData.dueDate && !isNaN(Date.parse(existingTicketData.dueDate))
                                                        ? format(new Date(existingTicketData.dueDate), 'dd MMMM yyyy')
                                                        : 'Not set'}
                                                </Typography>
                                            </Grid>

                                            {/* TICKET PRIORITY */}
                                            <Grid item xs={isMobile ? 12 : 6} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <InputLabel shrink htmlFor="priority">
                                                    Priority
                                                </InputLabel>
                                                <Box style={{ border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: '5px', padding: '5px', width: '100%', minHeight: '36px' }}>
                                                    <Chip
                                                        label={
                                                            <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '12px' }}>
                                                                {existingTicketData.priority.charAt(0).toUpperCase() + existingTicketData.priority.slice(1)}
                                                            </Typography>
                                                        }
                                                        sx={{
                                                            borderRadius: '5px',
                                                            padding: '3px !important',
                                                            minWidth: "70px",
                                                            backgroundColor: (() => {
                                                                switch (existingTicketData.priority) {
                                                                    case 'low':
                                                                        return '#d9f3d9'; // Light green for low priority
                                                                    case 'medium':
                                                                        return '#ffe6cc'; // Light orange for medium priority
                                                                    case 'high':
                                                                        return '#ffd6cc'; // Light red for high priority
                                                                    default:
                                                                        return '#f2f2f2'; // Default light gray color
                                                                }
                                                            })(),
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>


                                            {/* PAYMENT STATUS */}
                                            {
                                                (sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin' || sessionUser.role === 'technician') && (
                                                    <>
                                                        <Grid item xs={isMobile ? 12 : 6} style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <InputLabel shrink htmlFor="payment-status">
                                                                Payment Status
                                                            </InputLabel>
                                                            <Box style={{ border: '1px solid rgba(0, 0, 0, 0.38)', borderRadius: '5px', padding: '5px', width: '100%', minHeight: '36px' }}>
                                                                <Chip
                                                                    label={paymentStatusOptions[existingTicketData.paymentStatus].name}
                                                                    sx={{
                                                                        borderRadius: '5px',
                                                                        padding: '2px',
                                                                        backgroundColor: paymentStatusOptions[existingTicketData.paymentStatus]?.color,
                                                                        // color: 'white',
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </>
                                                )
                                            }

                                            {existingTicketData?.assets &&
                                                <Grid item xs={isMobile ? 12 : 6}>
                                                    <AssetController
                                                        onChange={(newValue) => {
                                                            dispatch({
                                                                type: 'ticketManagement/updateFieldValues',
                                                                payload: {
                                                                    state: 'existingTicket',
                                                                    field: 'assets',
                                                                    value: newValue,
                                                                },
                                                            });
                                                        }}
                                                        selectedAssets={existingTicketData.assets}
                                                        company={existingTicketData?.company?.value}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                            }

                                            {/* PROPOSAL DATA GRID */}
                                            {/* {
                                                ((sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin' || sessionUser.role === 'technician') || (sessionUser.role === 'customerAdmin' && proposalBreakUps?.length >= 1)) && (
                                                    <Grid item xs={12} className='custom-scrollbar'>
                                                        <InputLabel shrink htmlFor="payment-status">
                                                            {proposalBreakUps?.length >= 1 ? "Proposal Grid": "Proposal details not added."}
                                                        </InputLabel>
                                                        {(proposalBreakUps?.length >= 1 && (sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin' || sessionUser.role === 'technician') || (sessionUser.role === 'customerAdmin' && proposalBreakUps?.length >= 1)) && (
                                                            <TableContainer component={Paper} className='custom-scrollbar'>
                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell>Item</StyledTableCell>
                                                                            <StyledTableCell align="right">Unit Price</StyledTableCell>
                                                                            <StyledTableCell align="right">Units</StyledTableCell>
                                                                            <StyledTableCell align="right">Amount</StyledTableCell>
                                                                            <StyledTableCell align="right">Type</StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {proposalBreakUps.map((row, index) => (
                                                                            <TableRow key={row.id} sx={{ backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.04)' : 'transparent' }}>
                                                                                <TableCell scope="row">
                                                                                    {row.item}
                                                                                </TableCell>
                                                                                <TableCell align="right">{row.unitPrice}</TableCell>
                                                                                <TableCell align="right">{row.units}</TableCell>
                                                                                <TableCell align="right">{row.amount}</TableCell>
                                                                                <TableCell align="right">{row.type}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        {(() => {
                                                                            const totalAmount = proposalBreakUps.reduce((total, item) => total + item.amount, 0);
                                                                            const vat = totalAmount * 0.05;
                                                                            return (
                                                                                <>
                                                                                    <TableRow>
                                                                                        <TableCell colSpan={3}>Total</TableCell>
                                                                                        <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>{totalAmount}</TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell colSpan={3}>VAT (5%)</TableCell>
                                                                                        <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>{vat}</TableCell>
                                                                                    </TableRow>
                                                                                    <TableRow>
                                                                                        <TableCell colSpan={3}>Total (incl. VAT)</TableCell>
                                                                                        <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>{totalAmount + vat}</TableCell>
                                                                                    </TableRow>
                                                                                </>
                                                                            );
                                                                        })()}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        )}
                                                    </Grid>
                                                )
                                            } */}

                                            {/* ASSIGNED USERS */}

                                            <Grid item xs={12}>
                                                <InputLabel shrink htmlFor="assigned users">
                                                    Assigned Users
                                                </InputLabel>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        alignItems: 'center',
                                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                                        borderRadius: '4px',
                                                        minHeight: '56px',
                                                        padding: '18.5px 14px',
                                                    }}
                                                >
                                                    {existingTicketData?.assignedUsers?.map((user, index) => (
                                                        <Box sx={{ margin: '4px' }}>
                                                            <UserChip user={user} avatarNeeded={true} />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Grid>

                                            {/* REPORTED USERS */}
                                            <Grid item xs={12}>
                                                <InputLabel shrink htmlFor="reported users">
                                                    Reported Users
                                                </InputLabel>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        alignItems: 'center',
                                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                                        borderRadius: '4px',
                                                        minHeight: '56px',
                                                        padding: '18.5px 14px',
                                                    }}
                                                >
                                                    {existingTicketData?.reportedUsers?.map((user, index) => (
                                                        <Box sx={{ margin:  '4px' }}>
                                                            <UserChip user={user} avatarNeeded={true} />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Grid>

                                            {/* FILE UPLOADS || ATTACHMENTS */}
                                            <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                                                <FileUploader
                                                    onFileUploadSuccess={saveUploadedFilesInRedux}
                                                    onDeleteFile={removeUploadedFileFromRedux}
                                                    destinationParentFolder='tickets'
                                                    destinationSubFolder={existingTicketData._id}
                                                    savedFiles={existingTicketData.uploads}
                                                    disabled={true}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    marginBottom: '20px',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    justifyContent: { xs: 'flex-start', md: 'center' },
                                                }}
                                            >
                                                <Chip
                                                    label={`Created At: ${new Date(existingTicketData.createdAt).toLocaleString()}`}
                                                    color="primary"
                                                    sx={{ marginRight: '10px', marginBottom: '10px' }}
                                                />
                                                <Chip
                                                    label={`Created By: ${existingTicketData.createdBy?.displayName}`}
                                                    avatar={<Avatar>{existingTicketData.createdBy?.displayName?.charAt(0)}</Avatar>}
                                                    sx={{ marginRight: '10px', marginBottom: '10px' }}
                                                />
                                                <Chip
                                                    label={`Updated At: ${new Date(existingTicketData?.updatedAt).toLocaleString()}`}
                                                    color="secondary"
                                                    sx={{ marginRight: '10px', marginBottom: '10px' }}
                                                />
                                                <Chip
                                                    label={`Updated By: ${existingTicketData.updatedBy?.displayName}`}
                                                    avatar={<Avatar>{existingTicketData.updatedBy?.displayName?.charAt(0)}</Avatar>}
                                                    sx={{ marginBottom: '10px' }}
                                                />
                                            </Grid>

                                            {/* STATUS CHANGES TIMELINE */}
                                            <Grid item xs={12}>
                                                <StyledAccordion
                                                    expanded={expanded}
                                                    onChange={handleAccordionChange}
                                                >
                                                    <StyledAccordionSummary
                                                        expandIcon={<ExpandMoreIcon style={{ marginRight: '15px', color: '#fff' }} />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Box display="flex" justifyContent="center" width="100%">
                                                            <Typography variant={'h6'}>Status Timeline</Typography>
                                                        </Box>
                                                    </StyledAccordionSummary>
                                                    <StyledAccordionDetails>
                                                        {
                                                            existingTicketData?._id && expanded &&
                                                            <StatusChangesTimeline ticketId={existingTicketData?._id} isExpanded={expanded} />
                                                        }
                                                    </StyledAccordionDetails>
                                                </StyledAccordion>
                                            </Grid>

                                            {/* COMMENTS & ACTIVITIES SECTION */}
                                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                                                <Grid container spacing={0.5}>
                                                    <Grid item xs={isMobile ? 12 : 6}>
                                                        {/* CHIPS TO SWITCH B/W COMMENTS & ACTIVITIES */}
                                                        <Stack direction="row" spacing={1} >
                                                            {   // Render activities only the current session user is a superAdmin
                                                                (sessionUser.userType === 'Admin') && (
                                                                    <Chip label="Internal Comments" sx={{ borderRadius: '7px', backgroundColor: toggleCommentsAndActivities === 'Internal Comments' ? '#8EACCD' : '', fontWeight: 600 }} onClick={() => setToggleCommentsAndActivities('Internal Comments')} />
                                                                )
                                                            }
                                                            <Chip label="Customer Comments" sx={{ borderRadius: '7px', backgroundColor: toggleCommentsAndActivities === 'Customer Comments' ? '#8EACCD' : '', fontWeight: 600 }} onClick={() => setToggleCommentsAndActivities('Customer Comments')} />
                                                            {   // Render activities only the current session user is a superAdmin
                                                                (sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin' || sessionUser.role === 'technician') && (
                                                                    <Chip label="Activities" sx={{ borderRadius: '7px', backgroundColor: toggleCommentsAndActivities === 'activities' ? '#8EACCD' : '', fontWeight: 600 }} onClick={() => setToggleCommentsAndActivities('activities')} />
                                                                )
                                                            }
                                                        </Stack>
                                                    </Grid>

                                                    {toggleCommentsAndActivities === 'Internal Comments' && (
                                                        <>
                                                            {/* ADD COMMENT BOX */}
                                                            <Grid item xs={12}>
                                                                <Stack direction={'row'} spacing={1.5} sx={{ marginTop: '10px' }}>
                                                                    <Avatar
                                                                        alt="User Avatar"
                                                                        src={sessionUser.photoURL}
                                                                        style={{
                                                                            marginRight: '8px',
                                                                            marginTop: '4px',
                                                                            backgroundColor: sessionUser.photoURL
                                                                                ? 'transparent'
                                                                                : sessionUser.displayName
                                                                                    ? stringToColor(sessionUser.displayName)
                                                                                    : stringToColor(`${sessionUser.firstName} ${sessionUser.lastName}`),
                                                                        }}
                                                                    >
                                                                        {!sessionUser.photoURL && (sessionUser.displayName || sessionUser.firstName) ? (
                                                                            sessionUser.displayName
                                                                                ? sessionUser.displayName[0]
                                                                                : sessionUser.firstName[0]
                                                                        ) : null}
                                                                    </Avatar>

                                                                    {!existingTicketData.isCommentSubmitInProgress ? (
                                                                        <TextField
                                                                            id="new-comment-add-textbox"
                                                                            label="Add an Internal Comment"
                                                                            fullWidth
                                                                            multiline
                                                                            minRows={2}
                                                                            maxRows={10}
                                                                            value={existingTicketData.newComment}
                                                                            onChange={(e) => {
                                                                                dispatch({
                                                                                    type: 'ticketManagement/updateFieldValues',
                                                                                    payload: {
                                                                                        state: 'existingTicket',
                                                                                        field: 'newComment',
                                                                                        value: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment
                                                                                        position="end"
                                                                                        onClick={() =>
                                                                                            dispatch(
                                                                                                addInternalCommentsToTicket({
                                                                                                    ticketId: existingTicketData._id,
                                                                                                    commentString: existingTicketData.newComment,
                                                                                                })
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <IconButton>
                                                                                            <SendRoundedIcon />
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Skeleton
                                                                            variant="rect"
                                                                            sx={{ boarderRadius: `10px` }}
                                                                            width="100%"
                                                                            height="60px"
                                                                            component="div"
                                                                        />
                                                                    )}
                                                                </Stack>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {
                                                                    existingTicketData?.internalComments
                                                                        .slice()
                                                                        // .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                                                        .map((comment, index) => (
                                                                            <Card
                                                                                key={`card-${index}`}
                                                                                sx={{
                                                                                    margin: 0.5,
                                                                                    backgroundColor: 'lightgray', // Customize the background color
                                                                                    border: '1px solid #ccc', // Add a border
                                                                                    borderRadius: '4px', // Add some border radius
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#d9d9d9', // Change background color on hover
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <CardHeader
                                                                                    avatar={
                                                                                        <Avatar
                                                                                            alt="Commented User"
                                                                                            src={comment?.user?.photoURL}
                                                                                            sx={{
                                                                                                marginRight: '8px',
                                                                                                marginTop: '4px',
                                                                                                backgroundColor: comment?.user?.photoURL
                                                                                                    ? 'transparent'
                                                                                                    : comment?.user?.displayName
                                                                                                        ? stringToColor(comment.user.displayName)
                                                                                                        : stringToColor(`${comment?.user?.firstName} ${comment?.user?.lastName}`),
                                                                                            }}
                                                                                        >
                                                                                            {!comment?.user?.photoURL && (comment?.user?.displayName || comment?.user?.firstName) ? (
                                                                                                comment?.user?.displayName
                                                                                                    ? comment?.user?.displayName[0]
                                                                                                    : comment?.user?.firstName[0]
                                                                                            ) : null}
                                                                                        </Avatar>
                                                                                    }
                                                                                    title={comment?.user?.displayName ?? `${comment?.user?.firstName} ${comment?.user?.lastName}`}
                                                                                    subheader={formatDate(comment.createdAt)} // Format the date and time
                                                                                />
                                                                                <CardContent sx={{ paddingTop: '0' }}> {/* Reduce the top padding */}
                                                                                    <Typography variant="body1">
                                                                                        {comment.comment}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        ))}
                                                            </Grid>

                                                        </>
                                                    )}


                                                    {toggleCommentsAndActivities === 'Customer Comments' && (
                                                        <>
                                                            {/* ADD COMMENT BOX */}
                                                            <Grid item xs={12}>
                                                                <Stack direction={'row'} spacing={1.5} sx={{ marginTop: '10px' }}>
                                                                    <Avatar
                                                                        alt="User Avatar"
                                                                        src={sessionUser.photoURL}
                                                                        style={{
                                                                            marginRight: '8px',
                                                                            marginTop: '4px',
                                                                            backgroundColor: sessionUser.photoURL
                                                                                ? 'transparent'
                                                                                : sessionUser.displayName
                                                                                    ? stringToColor(sessionUser.displayName)
                                                                                    : stringToColor(`${sessionUser.firstName} ${sessionUser.lastName}`),
                                                                        }}
                                                                    >
                                                                        {!sessionUser.photoURL && (sessionUser.displayName || sessionUser.firstName) ? (
                                                                            sessionUser.displayName
                                                                                ? sessionUser.displayName[0]
                                                                                : sessionUser.firstName[0]
                                                                        ) : null}
                                                                    </Avatar>

                                                                    {!existingTicketData.isCommentSubmitInProgress ? (
                                                                        <TextField
                                                                            id="new-comment-add-textbox"
                                                                            label="Add a Comment"
                                                                            fullWidth
                                                                            multiline
                                                                            minRows={2}
                                                                            maxRows={10}
                                                                            value={existingTicketData.newComment}
                                                                            onChange={(e) => {
                                                                                dispatch({
                                                                                    type: 'ticketManagement/updateFieldValues',
                                                                                    payload: {
                                                                                        state: 'existingTicket',
                                                                                        field: 'newComment',
                                                                                        value: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment
                                                                                        position="end"
                                                                                        onClick={() =>
                                                                                            dispatch(
                                                                                                addCommentToTicket({
                                                                                                    ticketId: existingTicketData._id,
                                                                                                    commentString: existingTicketData.newComment,
                                                                                                })
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <IconButton>
                                                                                            <SendRoundedIcon />
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Skeleton
                                                                            variant="rect"
                                                                            sx={{ boarderRadius: `10px` }}
                                                                            width="100%"
                                                                            height="60px"
                                                                            component="div"
                                                                        />
                                                                    )}
                                                                </Stack>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {
                                                                    existingTicketData?.comments
                                                                        .slice()
                                                                        // .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                                                        .map((comment, index) => (
                                                                            <Card
                                                                                key={`card-${index}`}
                                                                                sx={{
                                                                                    margin: 0.5,
                                                                                    backgroundColor: 'lightgray', // Customize the background color
                                                                                    border: '1px solid #ccc', // Add a border
                                                                                    borderRadius: '4px', // Add some border radius
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#d9d9d9', // Change background color on hover
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <CardHeader
                                                                                    avatar={
                                                                                        <Avatar
                                                                                            alt="Commented User"
                                                                                            src={comment?.user?.photoURL}
                                                                                            sx={{
                                                                                                marginRight: '8px',
                                                                                                marginTop: '4px',
                                                                                                backgroundColor: comment?.user?.photoURL
                                                                                                    ? 'transparent'
                                                                                                    : comment?.user?.displayName
                                                                                                        ? stringToColor(comment.user.displayName)
                                                                                                        : stringToColor(`${comment?.user?.firstName} ${comment?.user?.lastName}`),
                                                                                            }}
                                                                                        >
                                                                                            {!comment?.user?.photoURL && (comment?.user?.displayName || comment?.user?.firstName) ? (
                                                                                                comment?.user?.displayName
                                                                                                    ? comment?.user?.displayName[0]
                                                                                                    : comment?.user?.firstName[0]
                                                                                            ) : null}
                                                                                        </Avatar>
                                                                                    }
                                                                                    title={comment?.user?.displayName ?? `${comment?.user?.firstName} ${comment?.user?.lastName}`}
                                                                                    subheader={formatDate(comment.createdAt)} // Format the date and time
                                                                                />
                                                                                <CardContent sx={{ paddingTop: '0' }}> {/* Reduce the top padding */}
                                                                                    <Typography variant="body1">
                                                                                        {comment.comment}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        ))}
                                                            </Grid>

                                                        </>
                                                    )}

                                                    {toggleCommentsAndActivities === 'activities' && (
                                                        <>
                                                            {/* Activities Section */}
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    {existingTicketData.activities?.length ? (
                                                                        existingTicketData.activities.map((log, index) => (
                                                                            <Paper
                                                                                key={`activity-${index}`}
                                                                                sx={{
                                                                                    p: 2,
                                                                                    m: 1,
                                                                                    backgroundColor: 'lightgray', // Customize the background color
                                                                                    border: '1px solid #ccc', // Add a border
                                                                                    borderRadius: '4px', // Add some border radius
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#d9d9d9', // Change background color on hover
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    {new Date(log.createdAt).toLocaleString()} {/* Format the date and time */}
                                                                                </Typography>
                                                                                <Typography variant="body1">{log.activityString}</Typography>
                                                                            </Paper>
                                                                        ))
                                                                    ) : (
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            No activities to display.
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }

                                {
                                    existingTicketData.isLoading && (
                                        <TicketModalSkeleton />
                                    )
                                }
                            </Grid>
                            <DeclineDialog open={declineModaleOpen} handleClose={setDeclineModalOpen} handleDecline={handleAdminApproval} />
                        </div>
                    </DialogContent>
                    <Divider />
                </Paper>
            </div>
        </Dialog >
    );
};

export default TicketViewOnlyMode;
