import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SelectInput from '../../components/commons/SelectInput';
import TextInput from '../../components/commons/TextInput';
import { Grid, FormHelperText, Stack } from '@mui/material';
import { roleOptionsForCustomerAdmin, allUserRoles, roleOptionsForVendorAdmin, authProviders, authProvidersForAutoComplete } from '../../constants/commonConstants';
import { isValidEmail } from '../../utilities/commonUtils';
import { getCompaniesList } from './Service';
import Autocomplete from '../../components/commons/Autocomplete';

function PendingApprovalUsersFilter({ isActive }) {
    const dispatch = useDispatch();
    const sessionUserType = useSelector((state) => state.userData.data.userType);
    const filterValues = useSelector((state) => state.userManagement.pendingApprovalUsersFilter);
    const filterErrorFlag = useSelector((state) => state.userManagement.pendingApprovalUsersFilterErrorFlag);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [companiesList, setCompaniesList] = useState([]);

    const handleValueChange = (name, value) => {
        if (inputError(name, value)) return;
        dispatch({ type: 'userManagement/handlePendingApprovalUsersFilterValueChange', payload: { name, value } });
    }

    const inputError = (name, value) => {
        if (name === 'email' && !isValidEmail(value)) setIsEmailValid(true)
        else setIsEmailValid(false)
        return false;
    }

    useEffect(() => {
        getCompaniesList()
            .then((result) => {
                if (result?.data?.length) {
                    let tempArr = [];
                    result.data.forEach(item => {
                        tempArr.push({ value: item._id, label: item.companyName });
                    });
                    setCompaniesList(tempArr)
                }
            })
    }, [])


    return (
        <div>
            <Stack sx={{ margin: 0, marginBottom: '5px' }}>
                {filterErrorFlag ? (
                    <FormHelperText error>
                        {filterErrorFlag}
                    </FormHelperText>
                ) : <FormHelperText>
                </FormHelperText>}
            </Stack>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={4}>

                        <Grid item xs={isMobile ? 12 : 6}>
                            <TextInput
                                label="Email"
                                name={'email'}
                                error={isEmailValid}
                                value={filterValues.email}
                                onChange={(e) => handleValueChange('email', e.target.value)}
                            />
                        </Grid>

                        {sessionUserType === "Admin" && (
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Autocomplete
                                    label="Company"
                                    name='company'
                                    value={filterValues.company}
                                    onChange={(e) => handleValueChange('company', e.target.value)}
                                    options={companiesList}
                                />
                            </Grid>
                        )}

                        <Grid item xs={isMobile ? 12 : 6}>
                            <Autocomplete
                                label="Login Method"
                                name='authProvider'
                                value={filterValues.authProvider}
                                onChange={(e) => handleValueChange('authProvider', e.target.value)}
                                options={authProvidersForAutoComplete}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default PendingApprovalUsersFilter;
