import { Grid, Skeleton } from '@mui/material';
import React from 'react';

const LoadingTemplate = () => {
    return (

        <Grid container spacing={2}>
            {[...Array(10)].map((item, index) => (
                <Grid item xs={6} key={index}>
                    <Skeleton variant="rect" height={56} sx={{ boarderRadius: '8px' }} />
                </Grid>
            ))}
        </Grid>

    );
}

export default LoadingTemplate;
