import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import service from './service';



const initialState = {
    create: {
        country: "",
        area: "",
        minRange: "",
        maxRange: "",
        image: "",
    },
    franchises: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMessage: '',
    errorMessages: [],
    isCreateOrEditModalOpen: false,
    modalMode: 'create', // accepted enums  'create' || 'update'
    selectedFranchise: null,
    currentFranchise: null,
    isAdminCompanyExist: false
}





const franchiseSlice = createSlice({
    name: 'franchiseMangement',
    initialState,
    reducers: {
        updateCurrentFranchise: (state, action) => {
            state.currentFranchise = action.payload.franchise

            state.isAdminCompanyExist = action.payload.isSuperAdminExist

        },
        updateFranchiseFieldValues: (state, actions) => {
            state.create[actions.payload.key] = actions.payload.value
        },
        openModal: (state) => {
            state.isCreateOrEditModalOpen = true
        },
        closeModal: (state) => {
            state.isCreateOrEditModalOpen = false
            state.selectedFranchise = null;
            state.modalMode = 'create';
            state.create = initialState.create
        },
        saveAllFranchises: (state, actions) => {
            state.franchises = actions.payload
        },

        openEditModal: (state, action) => {
            state.isCreateOrEditModalOpen = true
            state.modalMode = 'update'
            state.selectedFranchise = action.payload;
        },

        updateIsActive: (state, action) => {
            state.selectedFranchise.isActive = action.payload.isActive
        },

        updateFranchiseDataSuccess: (state, action) => {
            const updatedFranchise = action.payload;
            // Find the index of the franchise to update
            const index = state.franchises.findIndex(franchise => franchise._id === updatedFranchise._id);
            if (index !== -1) {
                // Update the franchise at the found index
                state.franchises[index] = updatedFranchise;
            }
        }
    }
})


export const {
    updateFranchiseFieldValues,
    openModal,
    closeModal,
    saveAllFranchises,
    openEditModal,
    updateIsActive,
    updateFranchiseDataSuccess,
    updateCurrentFranchise
} = franchiseSlice.actions
export default franchiseSlice.reducer