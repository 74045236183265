import React, { useEffect, useState } from 'react';
import CustomDialogueMedium from '../../components/commons/CustomDialogueMedium';
import { Typography, TextField, Grid, MenuItem, Switch, Stack, Avatar, InputAdornment, IconButton, Card, CardHeader, CardContent, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    OPEN_EXPORT_ASSET_MODAL,
    UPDATE_FIELDS_EXPORT_ASSETS,
    UPDATE_LOADING_EXPORT_ASSETS,
    SET_SELECTED_EXPORT_ASSET,
    UPDATE_SELECTED_EXPORT_ASSET,
    updateExportAsset,
    UPDATE_ERROR_FIELDS_EXPORT_ASSETS,
    getExportAssetThunk,
    addRemarksToExportAsset,
    UPDATE_REMARK_COMMENT_DATA,
} from '../../app/ExportAsset/ExportAssetSlice';
import FileUploader from '../../components/FileUploader/FileUploader';
import { printerTypes, tonerLevel, reduceQunatityOptions } from '../../constants/commonConstants';
import LoadingTemplate from './LoadingTemplate';
import { SaveButton } from "../../components/commons/Buttons";
import { toast } from 'react-toastify';
import { validateExportAssetChange } from './exportAssetValidation';
import { stringToColor } from '../../utilities/commonUtils';
import { SendRounded } from '@mui/icons-material';
import { format } from 'date-fns';
import { getGlobalCompanyList } from '../../app/Company/service';

const assetTypes = ['laptop', 'printer', 'toner'];
const healthStatusOptions = ['Good Condition', 'Maintenance Required',];
const colorOptions = ['Black', 'Cyan', 'Magenta', 'Yellow'];


const UpdateExportAsset = () => {
    const exportAssetSession = useSelector((state) => state.exportAssetMangement);
    const sessionUser = useSelector((state) => state.userData.data)
    const selectedExportAssetData = useSelector(
        (state) => state.exportAssetMangement.selectedExportAsset
    );
    const [companyList, setCompanyList] = useState([]);
    const [searchCompany, setSearchCompany] = useState('');

    const remarkSession = useSelector((state) => state.exportAssetMangement.newRemarks)
    const formErrors = useSelector(state => state.exportAssetMangement.errors);
    function formatDate(dateTimeString) {
        return format(new Date(dateTimeString), 'dd/MM/yyyy hh:mm a'); // Format the date and time
    }


    const dispatch = useDispatch();
    const handleCreateDialogClose = () => {
        dispatch(OPEN_EXPORT_ASSET_MODAL(false));
        dispatch(SET_SELECTED_EXPORT_ASSET(null));
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        let error = validateExportAssetChange(value, name, selectedExportAssetData);

        if (name.startsWith('tonerDetails')) {
            const keyField = name.split('.').pop(); // Extract the color from the name
            if (keyField === 'updateQuantityByDigit') {
                dispatch(UPDATE_SELECTED_EXPORT_ASSET({
                    tonerDetails: {
                        ...selectedExportAssetData.tonerDetails,
                        updateQuantityByDigit: value,
                        updateQuantityByPercent: null,
                    },
                }));
                dispatch(UPDATE_ERROR_FIELDS_EXPORT_ASSETS({
                    tonerDetails: {
                        [keyField]: error
                    },
                }))
            } else if (keyField === 'updateQuantityByPercent') {
                dispatch(UPDATE_SELECTED_EXPORT_ASSET({
                    tonerDetails: {
                        ...selectedExportAssetData.tonerDetails,
                        updateQuantityByDigit: null,
                        updateQuantityByPercent: value,
                    },
                }));
                dispatch(UPDATE_ERROR_FIELDS_EXPORT_ASSETS({
                    tonerDetails: {

                        [keyField]: error
                    },
                }))
            } else {
                dispatch(UPDATE_SELECTED_EXPORT_ASSET({
                    tonerDetails: {
                        ...selectedExportAssetData.tonerDetails,
                        [keyField]: value
                    },
                }));
                dispatch(UPDATE_ERROR_FIELDS_EXPORT_ASSETS({
                    tonerDetails: {
                        [keyField]: error
                    },
                }))
            }
        } else if (name.startsWith('printerDetails.tonerLevel.')) {
            const tonerColor = name.split('.').pop(); // Extract the color from the name
            dispatch(UPDATE_SELECTED_EXPORT_ASSET({
                printerDetails: {
                    ...selectedExportAssetData.printerDetails,
                    tonerLevel: {
                        ...selectedExportAssetData.printerDetails.tonerLevel,
                        [tonerColor]: value,
                    },
                },
            }));
        } else {
            dispatch(UPDATE_SELECTED_EXPORT_ASSET({
                [name]: value,
            }));
        }
    };

    function saveUploadedFilesInRedux(fileData) {
        dispatch(UPDATE_SELECTED_EXPORT_ASSET({
            ...selectedExportAssetData,
            attachments: [...selectedExportAssetData.attachments, fileData],
        }));
    }

    function removeUploadedFileFromRedux(fileName) {
        if (fileName) {
            dispatch(UPDATE_SELECTED_EXPORT_ASSET({
                attachments: selectedExportAssetData.attachments?.filter(item => item.filename !== fileName),
            }));
        }
    }

    const handleUpdateSave = () => {
        const hasErrors = Object.values(formErrors).some(errorObj => {
            return Object.values(errorObj).some(errorMessage => !!errorMessage);
        });
        if (hasErrors) {
            dispatch(UPDATE_LOADING_EXPORT_ASSETS(false));
            Object.entries(formErrors).forEach(([section, errorObj]) => {
                Object.entries(errorObj).forEach(([fieldName, errorMessage]) => {
                    if (errorMessage) {
                        toast.error(` ${errorMessage}`, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                            style: { width: '400px' },
                        });
                    }
                });
            });
            return;
        }
        if (selectedExportAssetData.assetType === 'toner') {
            if (
                selectedExportAssetData.tonerDetails.updateQuantityBy &&
                (
                    (selectedExportAssetData.tonerDetails.updateQuantityBy === "byDigit" && !selectedExportAssetData.tonerDetails.updateQuantityByDigit) ||
                    (selectedExportAssetData.tonerDetails.updateQuantityBy === "byPercent" && !selectedExportAssetData.tonerDetails.updateQuantityByPercent)
                )
            ) {
                toast.error(`Please fill the quantity reduction details.`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    style: { width: '400px' },
                });
                return;
            }
        }


        // dispatch(UPDATE_LOADING_EXPORT_ASSETS(true));
        dispatch(updateExportAsset(selectedExportAssetData))
            .then((result) => {
                if (!result.error) {
                    dispatch(getExportAssetThunk(exportAssetSession.filter))
                    dispatch(OPEN_EXPORT_ASSET_MODAL(false));
                    dispatch(SET_SELECTED_EXPORT_ASSET(null));
                }
            }).catch(err => {

            })
    }
    useEffect(() => {
        const getCompanyList = async () => {
            const response = await getGlobalCompanyList();
           
            setCompanyList(response.data.companies.map(company => ({
                label: company.companyName,
                value: company._id,
            })));
        };
        getCompanyList();
    }, [exportAssetSession.isOpen,]);


    const footerButtons = (
        <>
            <SaveButton disabled={false} onClick={handleUpdateSave} />
        </>
    );

    return (
        <CustomDialogueMedium
            open={exportAssetSession.isOpen === "update"}
            onClose={() => {
                handleCreateDialogClose();
            }}
            headerText={"Update Export Asset"}
            footerButtons={footerButtons}
        >
            {exportAssetSession.isLoading && <LoadingTemplate />}
            {!exportAssetSession.isLoading && selectedExportAssetData && (
                <Grid container spacing={2}>
                    {selectedExportAssetData.assetType !== 'toner' && <Grid item xs={12} sm={12}>
                        <Typography variant='h6' color={'error'} >MOVE TO SOLD</Typography>
                        <Switch
                            checked={selectedExportAssetData.assetStatus === 'sold'}
                            onChange={(event) => {
                                const { checked } = event.target;
                                dispatch(UPDATE_SELECTED_EXPORT_ASSET({
                                    assetStatus: checked ? 'sold' : 'at warehouse',
                                }));
                            }}
                            inputProps={{ 'aria-label': 'toggle move to sold' }}
                        />
                        {selectedExportAssetData.assetStatus === 'sold' && (
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    options={companyList}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Sold Company"
                                            variant="outlined"
                                        />
                                    )}
                                    value={companyList.find(option => option.value === selectedExportAssetData?.soldCompany) || null}
                                    onChange={(event, newValue) => {
                                        // Update the state or form value with the selected company's ID
                                        // Handle case when newValue is null (user clears the selection)
                                        handleChange({ target: { name: 'soldCompany', value: newValue?.value || '' } });
                                    }}
                                    onInputChange={(event, newValue) => {
                                   
                                        setSearchCompany(newValue);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                />
                            </Grid>
                        )}

                    </Grid>}

                    {selectedExportAssetData.assetType !== 'toner' && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Machine Code"
                                    variant="outlined"
                                    name="machineCode"
                                    value={selectedExportAssetData.machineCode}
                                    disabled
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={4}>
                        <TextField
                            select
                            fullWidth
                            label="Asset Type"
                            variant="outlined"
                            name="assetType"
                            value={selectedExportAssetData.assetType}
                            disabled

                        >
                            {assetTypes.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {selectedExportAssetData.assetType !== "toner" && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Serial Number"
                                    variant="outlined"
                                    name="serialNumber"
                                    value={selectedExportAssetData.serialNumber}
                                    disabled
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            label="Machine Name"
                            variant="outlined"
                            name="machineName"
                            value={selectedExportAssetData.machineName}
                            onChange={handleChange}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            label="Machine Model"
                            variant="outlined"
                            name="machineModel"
                            value={selectedExportAssetData.machineModel}
                            disabled

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            label="Cost Price"
                            variant="outlined"
                            name="costPrice"
                            value={selectedExportAssetData.costPrice}
                            onChange={handleChange}
                            

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            label="Selling Price"
                            variant="outlined"
                            name="sellingPrice"
                            value={selectedExportAssetData.sellingPrice}
                            onChange={handleChange}
                           
                        />
                    </Grid>
                    {selectedExportAssetData.assetType !== 'toner' && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Health Status"
                                    variant="outlined"
                                    name="healthStatus"
                                    value={selectedExportAssetData.healthStatus}
                                    onChange={handleChange}

                                >
                                    {healthStatusOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Origin"
                            variant="outlined"
                            name="origin"
                            value={selectedExportAssetData.origin}
                            disabled

                        />
                    </Grid>
                    {selectedExportAssetData.assetType === 'printer' && (
                        <>
                            <Grid item xs={12}>
                                <hr />
                                <Typography>Printer Details</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Printer Type"
                                    variant="outlined"
                                    name="printerType"
                                    value={selectedExportAssetData.printerDetails.printerType}
                                    disabled
                                >
                                    {printerTypes.map((option) => (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} />
                            {(
                                <>
                                    {selectedExportAssetData.printerDetails.printerType && <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            label={`Black Toner Level`}
                                            variant="outlined"
                                            name={`printerDetails.tonerLevel.black`}
                                            value={selectedExportAssetData.printerDetails.tonerLevel.black}
                                            onChange={handleChange}
                                        >
                                            {tonerLevel.map((level) => (
                                                <MenuItem key={level.key} value={level.key}>
                                                    {level.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}

                                    {selectedExportAssetData.printerDetails.printerType === 'color' && <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            label={`Cyan Toner Level`}
                                            variant="outlined"
                                            name={`printerDetails.tonerLevel.cyan`}
                                            value={selectedExportAssetData.printerDetails.tonerLevel.cyan || ''}
                                            onChange={handleChange}
                                        >
                                            {tonerLevel.map((level) => (
                                                <MenuItem key={level.key} value={level.key}>
                                                    {level.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}

                                    {selectedExportAssetData.printerDetails.printerType === 'color' && <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            select
                                            label={`Magenta Toner Level`}
                                            variant="outlined"
                                            name={`printerDetails.tonerLevel.magenta`}
                                            value={selectedExportAssetData.printerDetails.tonerLevel.magenta}
                                            onChange={handleChange}
                                        >
                                            {tonerLevel.map((level) => (
                                                <MenuItem key={level.key} value={level.key}>
                                                    {level.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}
                                    {selectedExportAssetData.printerDetails.printerType === 'color' && <Grid item xs={12} sm={6} md={3}>

                                        <TextField
                                            fullWidth
                                            select
                                            label={`Yellow Toner Level`}
                                            variant="outlined"
                                            name={`printerDetails.tonerLevel.yellow`}
                                            value={selectedExportAssetData.printerDetails.tonerLevel.yellow}
                                            onChange={handleChange}
                                        >
                                            {tonerLevel.map((level) => (
                                                <MenuItem key={level.key} value={level.key}>
                                                    {level.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>}


                                </>
                            )}
                            <Grid xs={12}>
                                <hr />
                            </Grid>
                        </>
                    )}
                    {selectedExportAssetData.assetType === 'toner' && (
                        <>
                            <Grid item xs={12}>
                                <hr />
                                <Typography variant='h6' >Toner Details</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    fullWidth
                                    select
                                    label={`Toner Color`}
                                    variant="outlined"
                                    name={`tonerDetails.color`}
                                    value={selectedExportAssetData.tonerDetails.tonerColor}
                                    disabled
                                >
                                    {colorOptions.map((level) => (
                                        <MenuItem key={level} value={level}>
                                            {level}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    fullWidth
                                    label={`Quanity`}
                                    variant="outlined"
                                    name={`tonerDetails.quantity`}
                                    value={selectedExportAssetData.tonerDetails.quantity}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    fullWidth
                                    label={`Toner Type`}
                                    variant="outlined"
                                    name={`tonerDetails.tonerType`}
                                    value={selectedExportAssetData.tonerDetails.tonerType}
                                    disabled
                                />
                            </Grid>


                        </>
                    )}

                    {selectedExportAssetData.assetType !== 'toner' && <Grid xs={12} sm={6} sx={{ paddingX: '2rem' }}>
                        <Typography>Required Parts</Typography>
                        <Switch
                            checked={selectedExportAssetData.isPartsRequired}
                            onChange={(event) => {
                                const { checked } = event.target;
                                dispatch(UPDATE_SELECTED_EXPORT_ASSET({
                                    isPartsRequired: checked,
                                }));
                            }}
                            inputProps={{ 'aria-label': 'toggle required parts' }}
                        />
                    </Grid>}
                    {selectedExportAssetData.isPartsRequired && selectedExportAssetData.assetType !== 'toner' && <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Parts Description"
                            multiline
                            rows={2}
                            variant="outlined"
                            name="partsDescription"
                            value={selectedExportAssetData.partsDescription}
                            onChange={handleChange}

                        />
                    </Grid>}
                    <Grid item xs={12}>
                        <FileUploader
                            onFileUploadSuccess={saveUploadedFilesInRedux}
                            onDeleteFile={removeUploadedFileFromRedux}
                            destinationParentFolder="exportAsset"
                            destinationSubFolder="attachments"
                            savedFiles={selectedExportAssetData.attachments}
                            disabled={false}
                            setAdminOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={12}>
                                <Stack direction={'row'} spacing={1.5} sx={{ marginTop: '10px' }}>
                                    <Avatar
                                        alt="User Avatar"
                                        src={sessionUser.photoURL}
                                        style={{
                                            marginRight: '8px',
                                            marginTop: '4px',
                                            backgroundColor: sessionUser.photoURL
                                                ? 'transparent'
                                                : sessionUser.displayName
                                                    ? stringToColor(sessionUser.displayName)
                                                    : stringToColor(`${sessionUser.firstName} ${sessionUser.lastName}`),
                                        }}
                                    >
                                        {!sessionUser.photoURL && (sessionUser.displayName || sessionUser.firstName) ? (
                                            sessionUser.displayName
                                                ? sessionUser.displayName[0]
                                                : sessionUser.firstName[0]
                                        ) : null}
                                    </Avatar>


                                    <TextField
                                        id="new-remarks-add-textbox"
                                        label="Add an Remark"
                                        fullWidth
                                        multiline
                                        minRows={2}
                                        disabled={remarkSession.isLoading}
                                        maxRows={10}
                                        value={remarkSession.comment}
                                        onChange={(e) => {
                                            // dispatch({
                                            //     type: 'exportAssetMangement/updateFieldValues',
                                            //     payload: {
                                            //         state: 'newRemarks',
                                            //         field: 'comment',
                                            //         value: e.target.value,
                                            //     },
                                            // });
                                            dispatch(UPDATE_REMARK_COMMENT_DATA(e.target.value));
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() =>
                                                        dispatch(
                                                            addRemarksToExportAsset({
                                                                data: {
                                                                    comment: remarkSession.comment
                                                                },
                                                                exportId: selectedExportAssetData._id
                                                            })
                                                        )
                                                    }
                                                >
                                                    <IconButton>
                                                        <SendRounded />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    selectedExportAssetData?.remarks
                                        ?.slice()
                                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                        .map((comment, index) => (
                                            <Card
                                                key={`card-${index}`}
                                                sx={{
                                                    margin: 0.5,
                                                    backgroundColor: 'lightgray', // Customize the background color
                                                    border: '1px solid #ccc', // Add a border
                                                    borderRadius: '4px', // Add some border radius
                                                    '&:hover': {
                                                        backgroundColor: '#d9d9d9', // Change background color on hover
                                                    },
                                                }}
                                            >
                                                <CardHeader
                                                    avatar={
                                                        <Avatar
                                                            alt="Commented User"
                                                            src={comment?.user?.photoURL}
                                                            sx={{
                                                                marginRight: '8px',
                                                                marginTop: '4px',
                                                                backgroundColor: comment?.user?.photoURL
                                                                    ? 'transparent'
                                                                    : comment?.user?.displayName
                                                                        ? stringToColor(comment.user.displayName)
                                                                        : stringToColor(`${comment?.user?.firstName} ${comment?.user?.lastName}`),
                                                            }}
                                                        >
                                                            {!comment?.user?.photoURL && (comment?.user?.displayName || comment?.user?.firstName) ? (
                                                                comment?.user?.displayName
                                                                    ? comment?.user?.displayName[0]
                                                                    : comment?.user?.firstName[0]
                                                            ) : null}
                                                        </Avatar>
                                                    }
                                                    title={comment?.user?.displayName ?? `${comment?.user?.firstName} ${comment?.user?.lastName}`}
                                                    subheader={formatDate(comment.createdAt)} // Format the date and time
                                                />
                                                <CardContent sx={{ paddingTop: '0' }}> {/* Reduce the top padding */}
                                                    <Typography variant="body1">
                                                        {comment.comment}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </CustomDialogueMedium>
    );
};

export default UpdateExportAsset;
