import React from 'react';
import { Button, ThemeProvider, createTheme } from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';

const theme = createTheme();

const borderRadius = '4px';

// Button styles for different contexts
const baseButton = {
    minWidth: 120,
    color: '#fff',
    borderRadius: borderRadius,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s, box-shadow 0.3s',
}

const buttonStyles = {
    cancel: {
        ...baseButton,
        backgroundColor: '#B0B0B0',
        '&:hover': {
            backgroundColor: '#A0A0A0',
            boxShadow: '0px 6px 12px #B0B0B0',
        },
    },
    close: {
        ...baseButton,
        backgroundColor: '#FF7F7F',
        '&:hover': {
            backgroundColor: '#FF5F5F',
            boxShadow: '0px 6px 12px #FF7F7F',
        },
    },
    save: {
        ...baseButton,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
            boxShadow: `0px 6px 12px ${green[500]}`,
        },
        '&:disabled': {
            backgroundColor: '#B0B0B0',
            color: '#fff',
            cursor: 'not-allowed',
        },
    },
    update: {
        ...baseButton,
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
            boxShadow: `0px 6px 12px ${blue[500]}`,
        },
    },
    delete: {
        ...baseButton,
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
            boxShadow: `0px 6px 12px ${red[500]}`,
        },
    },
    search: {
        ...baseButton,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0px 6px 12px ${theme.palette.primary.light}`,
        },
    },
    export: {
        ...baseButton,
        backgroundColor: 'rgba(76, 175, 80, 0.9)', // Green color for the background with alpha value of 0.9
        color: '#fff', // White color for the text
        '&:hover': {
            backgroundColor: 'rgba(56, 142, 60, 1)', // Darker green color for the hover background with alpha value of 1
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)', // Shadow effect on hover
        },
    },
    create: {
        minWidth: 120,
        color: '#fff',
        borderRadius: '4px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        textTransform: 'none',
        transition: 'background-color 0.3s, box-shadow 0.3s',
        fontWeight: 600,
        backgroundColor: '#244fac',
        '&:hover': {
            backgroundColor: '#365fd4',
            color: '#fff',
            boxShadow: `0px 6px 12px ${'#213555'}`,
        },
    },
    headerCreateBtn: {
        minWidth: 120,
        color: '#44546f',
        borderRadius: '4px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        textTransform: 'none',
        transition: 'background-color 0.3s, box-shadow 0.3s',
        fontWeight: 600,
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#244fac',
            color: '#fff',
            boxShadow: `0px 6px 12px ${'#213555'}`,
        },
    },
    revert: {
        ...baseButton,
        minWidth: 120,
        borderRadius: '4px',
        textTransform: 'none',
        transition: 'background-color 0.3s, box-shadow 0.3s',
        fontWeight: 600,
        backgroundColor: '#FFA500', // Orange color
        '&:hover': {
            backgroundColor: '#FF8C00', // Darker orange color on hover
            boxShadow: '0px 6px 12px rgba(255, 165, 0, 0.5)', // Shadow effect on hover
        },
    }
    // Add more styles for other contexts here
};



export const CancelButton = (props) => (
    <Button
        startIcon={<CancelIcon />}
        sx={buttonStyles.cancel}
        {...props}
    >
        Cancel
    </Button>
);

export const CloseButton = (props) => (
    <Button
        startIcon={<CloseIcon />}
        sx={buttonStyles.close}
        {...props}
    >
        Close
    </Button>
);

export const SaveButton = (props) => (
    <Button
        startIcon={<SaveIcon />}
        sx={buttonStyles.save}
        {...props}
    >
        {props.text ?? "Save"}
    </Button>
);

export const UpdateButton = (props) => (
    <Button
        startIcon={<UpdateIcon />}
        sx={buttonStyles.update}
        {...props}
    >
        Update
    </Button>
);

export const DeleteButton = (props) => (
    <Button
        startIcon={<DeleteIcon />}
        sx={buttonStyles.delete}
        {...props}
    >
        {props.text ?? "Delete"}
    </Button>
);

export const SearchButton = (props) => (
    <Button
        startIcon={<SearchIcon />}
        sx={buttonStyles.search}
        {...props}
    >
        Search
    </Button>
);

export const ExportButton = (props) => (
    <Button
        startIcon={< CloudDownloadIcon />}
        sx={buttonStyles.export}
        {...props}
    >
        Export
    </Button>
);

export const ResetButton = (props) => (
    <Button
        startIcon={<RestartAltIcon />}
        sx={buttonStyles.cancel}
        {...props}
    >
        Reset
    </Button>
);

export const CreateButton = (props) => (
    <Button
        endIcon={<AddIcon />}
        sx={buttonStyles.create}
        {...props}
    >
        {props.text ?? "Create"}
    </Button>
);

export const HeaderCreateBtn = (props) => (
    <Button
        endIcon={<AddIcon />}
        sx={buttonStyles.headerCreateBtn}
        {...props}
    >
        {props.text ?? "Create"}
    </Button>
);



export const RevertButton = (props) => (
    <Button
        startIcon={<ReplayRoundedIcon />}
        sx={buttonStyles.revert}
        {...props}
    >
        {props.text ?? "Revert"}
    </Button>
);

export const ImportButton = (props) => (
    <Button
        startIcon={<FileDownloadIcon />}
        sx={buttonStyles.create}
        {...props}
    >
        {props.text ?? "Import"}
    </Button>
);

export const EditButton = (props) => (
    <Button
        startIcon={<EditIcon />}
        sx={buttonStyles.update}
        {...props}
    >
        {props.text ?? "Edit"}
    </Button>
);

// Add more buttons for other contexts here
const MuiButtons = () => {
    return null;
};

export default MuiButtons;
