import { Typography, Paper, Tooltip, Grid, Box, Tab, useMediaQuery, TextField, MenuItem, Collapse, Chip, Button, IconButton } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CLEAR_FILTER_EXPORT_ASSET, OPEN_EXPORT_ASSET_MODAL, UPDATE_FILTER_EXPORT_ASSET, UPDATE_VIEW_CONTROLLER_EXPORT_ASSET, createExportAssetDownloadable, getExportAssetThunk } from '../../app/ExportAsset/ExportAssetSlice';

import { ArrowDownward, ArrowUpward, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { CreateButton, ResetButton } from '../../components/commons/Buttons';


import TextInput from '../../components/commons/TextInput';

import GetAppIcon from '@mui/icons-material/GetApp';
import { styled } from "@mui/material/styles";
import { toast } from 'react-toastify';

import { generateAndDownloadCsv, generateAndDownloadCsvForPrinterAndLaptop, generateAndDownloadPdf, generateAndDownloadPdfForPrinterAndLaptop, generateAndDownloadPdfForTable } from '../../utilities/downloadable';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListAltIcon from '@mui/icons-material/ListAlt';


const colorOptions = ['Black', 'Cyan', 'Magenta', 'Yellow'];


const healthStatusOptions = ['Good Condition', 'Maintenance Required',];

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
}));
const FilterExportAsset = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const exportAssetSession = useSelector(state => state.exportAssetMangement.filter);
    const exportAssetViewController = useSelector(state => state.exportAssetMangement.viewController);
    const isLoading = useSelector((state) => state.exportAssetMangement.isLoading);
    const fieldOptions = useSelector(state => state.exportAssetMangement.options);



    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // You need to set this value based on your application's logic
    const [isFilterComponentExpanded, setIsFilterComponentExpanded] = useState(false);
    const currentTab = useSelector(state => state.exportAssetMangement.filter.currentTab);
    const handleTabChange = (event, newValue) => {
        dispatch(UPDATE_FILTER_EXPORT_ASSET({ key: "currentTab", value: newValue }));
        // setCurrentTab(newValue);
    };

    const handleFilterChange = (e) => {
        dispatch(UPDATE_FILTER_EXPORT_ASSET({ key: e.target.name, value: e.target.value }));
    };
    const handleCreateDialogOpen = () => {
        dispatch(OPEN_EXPORT_ASSET_MODAL("create"))
        // setShowCreateDialog(true);
    };


    const handleExportCsv = () => {

        const exportAssetCsv = true;
        dispatch(createExportAssetDownloadable({ exportAssetSession, exportAssetCsv })).then((result) => {
            
            if (!result.error) {
                if (currentTab === 'warehouseToner') {
                    const exportAssetCsvDataForToner = result.payload.exportAssetCsvDataForToner;
                    generateAndDownloadCsv(exportAssetCsvDataForToner)
                } else if (currentTab === 'warehouseMachine' || currentTab === 'sold') {
                    const exportAssetCsvDataForPrinterAndLaptop = result.payload.csvDataForPrinterAndLaptop;
                    const totalQuantity = result.payload.totalQuantity;
                    generateAndDownloadCsvForPrinterAndLaptop(exportAssetCsvDataForPrinterAndLaptop, totalQuantity , exportAssetSession);
                }

                toast.success("Csv file downloaded successfully");
            } else {
                toast.error("No data found to download");
            }
        }).catch((err) => {
            toast.error(err.error.message);
        })
    };

    const handleExportPDF = () => {
        const exportAssetPdf = true;
        dispatch(createExportAssetDownloadable({ exportAssetSession, exportAssetPdf })).then((result) => {
            if (result.error) {
                toast.error("No data found to download");
                return;
            }

            if (currentTab === 'warehouseToner') {
                const exportAssetPdfDataForToner = result.payload.exportAssetCsvDataForToner;

                if (!exportAssetPdfDataForToner.length) {
                    toast.error("No data available to download");
                    return;
                }

                generateAndDownloadPdf(exportAssetPdfDataForToner);
                toast.success("Pdf file downloaded successfully");
            } else if (currentTab === 'warehouseMachine' || currentTab === 'sold') {
                const exportAssetPdfDataForPrinterAndLaptop = result.payload.csvDataForPrinterAndLaptop;

                if (typeof exportAssetPdfDataForPrinterAndLaptop === 'object' && Object.keys(exportAssetPdfDataForPrinterAndLaptop).length === 0) {
                    toast.error("No data available to download");
                    return;
                }
                const totalQuantity = result.payload.totalQuantity;

                generateAndDownloadPdfForPrinterAndLaptop(exportAssetPdfDataForPrinterAndLaptop, totalQuantity , exportAssetSession);
                toast.success("Pdf file downloaded successfully");
            }

        }).catch((err) => {
            toast.error(err.error.message);

        })
    };

    const handleTablePDF = () => {
        if (currentTab === "warehouseMachine" || currentTab === "sold") {
            dispatch(createExportAssetDownloadable({exportAssetSession} )).then((result) => {
                if (!result.error) {
                    const exportAssetPdfDataForTable = result.payload.pdfTableData;
                    if(exportAssetPdfDataForTable.length === 0){
                        toast.error("No data available to download");
                        return;
                    }
                    generateAndDownloadPdfForTable(exportAssetPdfDataForTable , exportAssetSession );
                    toast.success("Pdf file downloaded successfully");
                }else{
                    toast.error("No data found to download");
                }
            })
        }
    }
          

    const getCommonAssetType = () => {
        let assetTypesToReturn;
        if (exportAssetSession.currentTab === 'warehouseToner') {
            assetTypesToReturn = [{ name: 'Toner', val: 'toner' }]; // Copy the array to avoid modifying the original
        } else {
            assetTypesToReturn = [{ name: 'Default', val: "" }, { name: 'Laptop', val: 'laptop' }, { name: 'Printer', val: "printer" }]
        }
        return assetTypesToReturn;
    };

    return (
        <>
            <Box sx={{ marginY: "1rem" }}>
                <Grid container spacing={2} justifyContent="start">
                    <Grid item xs={12} md={6} lg={3}>
                        <TextInput
                            label="Brand Name"
                            name={"brandName"}
                            value={exportAssetSession.brandName || ""}
                            onChange={handleFilterChange}
                            fullWidth
                            select
                        >
                            {fieldOptions?.brand.map((brand) => (
                                <MenuItem key={brand.name} value={brand.name}>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </TextInput>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextInput
                            label="Machine Model"
                            name={"machineModel"}
                            value={exportAssetSession.machineModel || ""}
                            onChange={handleFilterChange}
                            fullWidth
                            select
                        >
                            {exportAssetSession.brandName && fieldOptions?.model[exportAssetSession.brandName]?.map((model) => (
                                <MenuItem key={model.name} value={model.name}>
                                    {model.name}
                                </MenuItem>
                            ))}
                        </TextInput>
                    </Grid>


                    <Grid item xs={12} md={6} lg={3}>
                        <TextInput
                            label="Asset Type"
                            name={"assetType"}
                            value={(exportAssetSession.currentTab === 'warehouseToner' ? "toner" : exportAssetSession.assetType) || ""}
                            onChange={handleFilterChange}
                            disabled={exportAssetSession.currentTab === 'warehouseToner'}
                            fullWidth
                            select
                        >
                            {getCommonAssetType()
                                .map((option) => (<MenuItem key={option.name} value={option.val}>
                                    {option.name}
                                </MenuItem>
                                ))}
                        </TextInput>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <TextInput
                            label="Machine Code"
                            name={"machineCode"}
                            value={exportAssetSession.machineCode || ""}
                            onChange={handleFilterChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Collapse in={isFilterComponentExpanded}>
                            <Grid container spacing={2}>

                                {exportAssetSession.currentTab !== 'warehouseToner' && <Grid item xs={12} md={6} lg={3}>
                                    <TextInput
                                        select
                                        label="Health Status"
                                        name="healthStatus"
                                        value={exportAssetSession.healthStatus || ""}
                                        onChange={handleFilterChange}
                                        fullWidth
                                    >
                                        {healthStatusOptions.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextInput>
                                </Grid>}

                                <Grid item xs={12} md={6} lg={3}>
                                    <TextInput
                                        label="Origin"
                                        name="origin"
                                        value={exportAssetSession.origin || ""}
                                        onChange={handleFilterChange}
                                        fullWidth
                                        select
                                    >
                                        {fieldOptions?.origin.map((brand) => (
                                            <MenuItem key={brand.name} value={brand.name}>
                                                {brand.name}
                                            </MenuItem>
                                        ))}
                                    </TextInput>
                                </Grid>
                                {exportAssetSession.currentTab !== 'warehouseToner' && <Grid item xs={12} md={6} lg={3}>
                                    <TextInput
                                        select
                                        label="Parts Required"
                                        name="isPartsRequired"
                                        value={exportAssetSession.isPartsRequired || ""}
                                        onChange={handleFilterChange}
                                        fullWidth
                                    >
                                        {[{ name: "Default", val: "" }, { name: "Yes", val: "Yes" }, { name: "No", val: "No" }].map((option) => (
                                            <MenuItem key={option.name} value={option.val}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextInput>
                                </Grid>}
                                {exportAssetSession.currentTab === 'warehouseToner' && <>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextInput
                                            label="Color"
                                            name="color"
                                            value={exportAssetSession?.color}
                                            onChange={handleFilterChange}
                                            fullWidth
                                            select
                                        >
                                            {colorOptions.map((level) => (
                                                <MenuItem key={level} value={level}>
                                                    {level}
                                                </MenuItem>
                                            ))}
                                        </TextInput>
                                    </Grid>
                                </>}
                            </Grid>
                        </Collapse>
                    </Grid>
                </Grid>
            </Box>
            <Box 
            
            style={{ gap: "1rem", display: "flex", justifyContent: "flex-end", alignItems: "center",flexWrap:"wrap",maxWidth:"100%" }}>
                <Chip
                    label={exportAssetViewController.showTable ? "Show Counter" : "Show Table"}
                    variant={!exportAssetViewController.showTable ? 'filled' : 'outlined'}
                    color={!exportAssetViewController.showTable ? 'primary' : undefined}
                    sx={{ borderRadius: '8px', fontSize: '11px', cursor: 'pointer' }}
                    onClick={() => {
                        dispatch(UPDATE_VIEW_CONTROLLER_EXPORT_ASSET("showTable"))
                    }}
                />

                <Tooltip title="Table PDF" style={{ height: "2.2rem" }}>
                    <StyledIconButton
                        onClick={handleTablePDF}
                    >
                        <ListAltIcon />
                    </StyledIconButton>
                </Tooltip>

                <Tooltip title="Export to PDF" style={{ height: "2.2rem" }}>
                    <StyledIconButton
                        onClick={handleExportPDF}
                    >
                        <PictureAsPdfIcon />
                    </StyledIconButton>
                </Tooltip>

                <Tooltip title="Export to CSV" style={{ height: "2.2rem" }}>
                    <StyledIconButton
                        onClick={handleExportCsv}
                    >
                        <GetAppIcon />
                    </StyledIconButton>
                </Tooltip>

                <CreateButton onClick={handleCreateDialogOpen}>
                    Create Export Asset
                </CreateButton>
                <ResetButton
                    tex={'Search'}
                    disabled={isLoading}
                    onClick={() => {
                        dispatch(CLEAR_FILTER_EXPORT_ASSET())
                    }}
                />

                <IconButton
                    onClick={() => {
                        setIsFilterComponentExpanded(prev => !prev)
                    }}
                    sx={{
                        transition: 'background-color 0.3s, color 0.3s',
                        '&:hover': {
                            color: '#fff',
                            backgroundColor: '#81d4fa',
                        },
                    }}
                >
                    {
                        isFilterComponentExpanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />
                    }
                </IconButton>




            </Box>
            <Box sx={{ minWidth: "20rem", width: isMobile ? '100%' : '70%', display: 'flex', flexDirection: 'column', marginY: "1rem" }}>
                <TabContext value={exportAssetSession.currentTab}>
                    <Paper
                        elevation={1}
                        sx={{
                            borderColor: 'divider',
                            zIndex: 99,
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            transition: 'box-shadow 0.3s',
                            '&:hover': {
                                boxShadow: '3px 15px 15px rgba(0, 0, 0, 0.4)',
                                cursor: 'pointer',
                                elevation: 5,
                            },
                        }}
                    >
                        <TabList
                            onChange={handleTabChange}
                            aria-label="CompanyManagement Tabs"
                            variant={isMobile ? 'scrollable' : 'fullWidth'}
                            allowScrollButtonsMobile={isMobile ? true : false}
                            orientation={'horizontal'}
                            sx={{
                                '& .MuiTab-root': {
                                    padding: '0 16px',
                                    minHeight: '48px',
                                    maxHeight: '48px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:not(:last-child)': {
                                        borderRight: '1px solid #ccc', // Apply border to all but the last child
                                    },
                                },
                                '& .Mui-selected': {
                                    borderBottom: 'solid 5px #97C3F0',
                                    backgroundColor: '#f5f5f5',
                                },
                            }}
                        >
                            <Tab
                                value="warehouseMachine"
                                sx={{ minWidth: "8rem" }}
                                label={
                                    <div id='ware' style={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="All Active Companies" arrow placement="left">
                                            <Typography variant="subtitle2" fontWeight={600} fontSize={14} >
                                                Machine
                                            </Typography>
                                        </Tooltip>

                                    </div>
                                }
                            />
                            {/* <Tab
                                value="warehouseToner"
                                sx={{ minWidth: "8rem" }}
                                label={
                                    <div id='ware' style={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="Ware House Toner" arrow placement="left">
                                            <Typography variant="subtitle2" fontWeight={600} fontSize={14} >
                                                Toner
                                            </Typography>
                                        </Tooltip>

                                    </div>
                                }
                            /> */}

                            <Tab
                                sx={{ minWidth: "8rem" }}
                                label={
                                    <div id='sold' style={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="Sold Asset" arrow placement="left">
                                            <Typography variant="subtitle2" fontWeight={600} fontSize={14} >
                                                Sold Asset
                                            </Typography>
                                        </Tooltip>

                                    </div>
                                }
                                value="sold"
                            />
                        </TabList>
                    </Paper>
                </TabContext>
            </Box>

        </>
    );
}

export default FilterExportAsset;
