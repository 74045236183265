import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startOfMonth, endOfMonth, startOfYear, subDays, startOfDay, endOfDay, subMonths, startOfWeek } from 'date-fns';
import { Grid, Paper, Typography, Chip, Card, CardContent, Divider, Box } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getTicketsByQuery } from '../../app/Ticket/TicketManagement'
import { Line, Bar, Doughnut } from 'react-chartjs-2'
import service from './service'
import 'chartjs-adapter-moment';
import { CategoryScale, LinearScale, Chart, PointElement, LineElement, BarElement, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Tooltip, Legend);


function Dashboard() {
    const sessionUser = useSelector((state) => state.userData.data)
    const dispatch = useDispatch();
    const [chartData, setChartData] = useState({})
    const [getTicketTotalsIn10DaysFlag, setgetTicketTotalsIn10DaysFlag] = useState(false)
    const [statusCountData, setStatusCountData] = useState({})
    const [statusCountDataFlag, setStatusCountDataFlag] = useState(false)
    const [activeTicketPrioritiesCount, setActiveTicketPrioritiesCount] = useState({});
    const [activeTicketPrioritiesCountFlag, setActiveTicketPrioritiesCountFlag] = useState(false);
    const [ticketCountPerUser, setTicketCountPerUser] = useState([]);
    const [activeUserStatusState, setActiveUserStatusState] = useState('This Month')
    const [activeCompanyStatusState, setActiveCompanyTicketStatus] = useState('This Month');
    const [ticketCountPerCompany, setTicketCountPerCompany] = useState([]);
    const [ticketCountPerCompanyFlag, setTicketCountPerCompanyFlag] = useState(false);
    const [userStatusQueryDates, setUserStatusQueryDates] = useState({ startDate: '', endDate: '' });
    const [companyStatusQueryDates, setCompanyStatusQueryDates] = useState({ startDate: '', endDate: '' })

    const getStatusStyle = (status) => {
        switch (status) {
            case 'open':
                return { label: 'Open', color: '#ff8a80' }; // Light Red
            case 'todo':
                return { label: 'To Do', color: '#7986cb' }; // Light Indigo
            case 'inProgress':
                return { label: 'In Progress', color: '#a5d6a7' }; // Light Green
            case 'escalated':
                return { label: 'Escalated', color: '#ffcc80' }; // Light Amber
            case 'done':
                return { label: 'Done', color: '#90caf9' }; // Light Blue
            case 'hold':
                return { label: 'On Hold', color: '#bdbdbd' }; // Light Grey
            case 'discarded':
                return { label: 'Discarded', color: '#bdbdbd' }; // Light Grey
            case 'reqSuperAdminApproval':
                return { label: 'Requires Super Admin Approval', color: '#82b1ff' }; // Light Blue
            case 'reqCustomerAdminApproval':
                return { label: 'Requires Customer Admin Approval', color: '#8c9eff' }; // Light Blue
            case 'customerAdminApproved':
                return { label: 'Customer Admin Approved', color: '#64dd17' }; // Light Green
            case 'customerAdminDeclined':
                return { label: 'Customer Admin Declined', color: '#e57373' }; // Light Red
            case 'superAdminApproved':
                return { label: 'Super Admin Approved', color: '#64dd17' }; // Light Green
            case 'superAdminDeclined':
                return { label: 'Super Admin Declined', color: '#e57373' }; // Light Red
            case 'reOpen':
                return { label: 'Reopened', color: '#ff8a80' }; // Light Red
            default:
                return { label: 'Open', color: '#ff8a80' }; // Light Red
        }
    };



    const getTicketTotalsIn10Days = async () => {
        try {
            const response = await service.getTicketTotalsIn10Days()
            
            // console.log(response.data)
            const data = response?.data

            const labels = data.map(item => item._id)
            const counts = data.map(item => item.count)

            setChartData({
                labels: labels,
                datasets: [
                    {
                        label: 'Ticket Totals in 10 Days',
                        data: counts,
                        fill: false,
                        backgroundColor: 'rgb(75, 192, 192)',
                        borderColor: 'rgba(75, 192, 192, 0.2)',
                    },
                ],
            })
            setgetTicketTotalsIn10DaysFlag(true);
        } catch (error) {
            console.error("An error occurred while getting ticket totals:", error)
        }
    }

    const getTicketStatusCount = async () => {
        const response = await service.getTicketStatusCount();
        const labels = response.data.map(item => item._id);
        const data = response.data.map(item => item.count);

        const backgroundColors = [
            'rgba(255, 99, 132, 0.2)', // red
            'rgba(54, 162, 235, 0.2)', // blue
            'rgba(255, 206, 86, 0.2)', // yellow
            'rgba(75, 192, 192, 0.2)', // green
            'rgba(153, 102, 255, 0.2)', // purple
            'rgba(255, 159, 64, 0.2)', // orange
            'rgba(255, 105, 180, 0.2)', // pink
            'rgba(240, 230, 140, 0.2)', // khaki
            'rgba(147, 112, 219, 0.2)', // medium purple
            'rgba(100, 149, 237, 0.2)', // corn flower blue
            'rgba(102, 205, 170, 0.2)', // medium aqua marine
            'rgba(70, 130, 180, 0.2)' // steel blue
        ];

        const borderColors = backgroundColors.map(color => color.replace('0.2', '1'));

        setStatusCountData({
            labels,
            datasets: [{
                label: 'Tickets By Status',
                data,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1,
            }]
        });
        setStatusCountDataFlag(true)
    }


    const getActiveTicketPrioritiesCount = async () => {
        const response = await service.activeTicketPrioritiesCount();
        const labels = response.data.map(item => item._id);
        const data = response.data.map(item => item.count);

        setActiveTicketPrioritiesCount({
            labels,
            datasets: [{
                label: 'Tickets By Priority',
                data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)', // red
                    'rgba(54, 162, 235, 0.2)', // blue
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)', // red
                    'rgba(54, 162, 235, 1)', // blue
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            }]
        });
        setActiveTicketPrioritiesCountFlag(true);
    }

    const getTicketCountsPerUser = async (fromDate, toDate) => {
        const response = await service.getTicketCountsPerUser(fromDate, toDate);
        setTicketCountPerUser(response?.data ?? [])
    }

    const getTicketCountsPerCompany = async (fromDate, toDate) => {
        const response = await service.getTicketCountsPerCompany(fromDate, toDate);
        setTicketCountPerCompany(response?.data ?? [])
    }

    useEffect(() => {
        getTicketTotalsIn10Days();
        getTicketStatusCount();
        getActiveTicketPrioritiesCount()
        getTicketCountsPerUser();
        getTicketCountsPerCompany(startOfMonth(new Date()), new Date());
        getTicketCountsPerUser(startOfMonth(new Date()), new Date());
        setUserStatusQueryDates({startDate: startOfMonth(new Date()), endDate: new Date()});
        setCompanyStatusQueryDates({startDate: startOfMonth(new Date()), endDate: new Date()});
    }, [sessionUser.franchise])

    const handleGetCompanyStatusQuery = (label, startDate, endDate) => {
        getTicketCountsPerCompany(startDate, endDate);
        setActiveCompanyTicketStatus(label);
        setCompanyStatusQueryDates({ startDate, endDate });
    };

    const handleUserChipClick = (label, startDate, endDate) => {
        getTicketCountsPerUser(startDate, endDate);
        setActiveUserStatusState(label);
        setUserStatusQueryDates({ startDate, endDate })
    };

    const handleUserClick = (user) => {
        if (sessionUser.userType === 'Customer') {
            return;
        }
        dispatch({ type: 'ticketManagement/resetState', payload: { field: 'ticketQuickSearch' } })
        
        const updateFieldValues = (field, value) => ({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'ticketQuickSearch',
                field,
                value,
            },
        });

        dispatch(updateFieldValues('loadingFlag', true));
        dispatch(updateFieldValues('createdAtFrom', userStatusQueryDates.startDate));
        dispatch(updateFieldValues('createdAtTo', userStatusQueryDates.endDate));
        dispatch(updateFieldValues('assignedUsers', [user]));
        dispatch(updateFieldValues('isModalOpen', true));

        const queryData = {
            assignedUsers: [{ _id: user._id }],
            createdAtFrom: userStatusQueryDates.startDate,
            createdAtTo: userStatusQueryDates.endDate
        };
        dispatch(getTicketsByQuery({ data: queryData, page: 1 }));
    }

    const handleCompanyClick = (companyId, companyName) => {
        if (sessionUser.userType === 'Customer') {
            return;
        }
        dispatch({ type: 'ticketManagement/resetState', payload: { field: 'ticketQuickSearch' } })
        const updateFieldValues = (field, value) => ({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'ticketQuickSearch',
                field,
                value,
            },
        });

        dispatch(updateFieldValues('loadingFlag', true));
        dispatch(updateFieldValues('createdAtFrom', companyStatusQueryDates.startDate));
        dispatch(updateFieldValues('createdAtTo', companyStatusQueryDates.endDate));
        dispatch(updateFieldValues('company', {value: companyId, label: companyName}));
        dispatch(updateFieldValues('isModalOpen', true));

        const queryData = {
            company: { value: companyId, label: companyName },
            createdAtFrom: companyStatusQueryDates.startDate,
            createdAtTo: companyStatusQueryDates.endDate
        };
        dispatch(getTicketsByQuery({ data: queryData, page: 1 }));
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} sx={{ padding: '5px' }}>
                        <Paper elevation={4} sx={{ borderRadius: '8px', padding: '10px' }}>
                            <Typography variant={'h6'}>Tickets Created Per Day (Last 10 Days)</Typography>
                            {getTicketTotalsIn10DaysFlag &&
                                <div style={{ height: '300px' }}>
                                    <Line data={chartData} options={{
                                        scales: {
                                            x: {
                                                type: 'category',
                                            }
                                        },
                                        plugins: {
                                            title: {
                                                display: true,
                                                text: 'Tickets Created Per Day (Last 10 Days)'
                                            },
                                            tooltip: {
                                                enabled: true,
                                                callbacks: {
                                                    label: function (context) {
                                                        return 'Count: ' + context.parsed.y;
                                                    }
                                                }
                                            }
                                        },
                                        elements: {
                                            line: {
                                                tension: 0.4,
                                                borderColor: '#007BFF',
                                                borderWidth: 2,
                                            },
                                            point: {
                                                radius: 2,
                                            }
                                        },
                                        layout: {
                                            padding: {
                                                bottom: 20
                                            }
                                        },
                                        responsive: true,
                                        maintainAspectRatio: false,
                                    }} />
                                </div>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ padding: '5px' }}>
                        <Paper elevation={4} sx={{ borderRadius: '8px', padding: '10px' }}>
                            <Typography variant={'h6'}>Tickets By Status</Typography>
                            {statusCountDataFlag &&
                                <div style={{ height: '300px' }}>
                                    <Bar data={statusCountData} options={{ responsive: true, maintainAspectRatio: false }} />
                                </div>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ padding: '5px' }}>
                        <Paper elevation={4} sx={{ borderRadius: '8px', padding: '10px' }}>
                            <Typography variant={'h6'}>Active Ticket Priorities</Typography>
                            {activeTicketPrioritiesCountFlag &&
                                <div style={{ height: '300px' }}>
                                    <Doughnut data={activeTicketPrioritiesCount} options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {
                                                display: true,
                                                position: 'bottom',
                                            }
                                        }
                                    }} />
                                </div>
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ padding: '5px', marginTop: '10px' }}>
                <Grid container spacing={2}>
                    <Paper elevation={4} sx={{ width: '100%', padding: '5px' }}>
                        <Box sx={{ marginLeft: '20px', marginBottom: '10px' }}>
                            <Typography variant={'h6'}>Tickets Per Assigned Users</Typography>
                        </Box>
                        <Box sx={{ paddingLeft: '5px', marginBottom: '10px' }}>
                            <Chip
                                label="Today"
                                onClick={() => handleUserChipClick('Today', startOfDay(new Date()), endOfDay(new Date()))}
                                sx={{ margin: '5px', backgroundColor: activeUserStatusState === 'Today' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="Yesterday"
                                onClick={() => handleUserChipClick('Yesterday', startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1)))}
                                sx={{ margin: '5px', backgroundColor: activeUserStatusState === 'Yesterday' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="This Week"
                                onClick={() => handleUserChipClick('This Week', startOfWeek(new Date()), new Date())}
                                sx={{ margin: '5px', backgroundColor: activeUserStatusState === 'This Week' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="This Month"
                                onClick={() => handleUserChipClick('This Month', startOfMonth(new Date()), new Date())}
                                sx={{ margin: '5px', backgroundColor: activeUserStatusState === 'This Month' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="Last Month"
                                onClick={() => handleUserChipClick('Last Month', startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1)))}
                                sx={{ margin: '5px', backgroundColor: activeUserStatusState === 'Last Month' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="This Year"
                                onClick={() => handleUserChipClick('This Year', startOfYear(new Date()), new Date())}
                                sx={{ margin: '5px', backgroundColor: activeUserStatusState === 'This Year' ? '#8EACCD' : "inherit" }}
                            />
                        </Box>
                        <Box className='custom-scrollbar' sx={{ maxHeight: '500px', minHeight: '500px', overflow: 'auto' }}>
                            {ticketCountPerUser.map((item, index) => (
                                <Card key={index} sx={{ margin: '2px 0', padding: '5px' }}>
                                    <CardContent>
                                        <Chip
                                            label={`${item.user[0]?.displayName} (${item.totalCount})`}
                                            onClick={() => handleUserClick(item.user[0])}
                                            sx={{ margin: '5px' }}
                                        />
                                        <Divider />
                                        {item.statuses.map((status, index) => {
                                            const { label, color } = getStatusStyle(status.status);
                                            return (
                                                <Chip
                                                    key={index}
                                                    label={`${label}: ${status.count}`}
                                                    sx={{ margin: '2px', fontSize: '0.8rem', backgroundColor: color }}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            );
                                        })}
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ padding: '5px', marginTop: '10px' }}>
                <Grid container spacing={2}>
                    <Paper elevation={4} sx={{ width: '100%', padding: '5px' }}>
                        <Box sx={{ marginLeft: '20px', marginBottom: '10px' }}>
                            <Typography variant={'h6'}>Tickets Per Company</Typography>
                        </Box>
                        <Box sx={{ paddingLeft: '5px', marginBottom: '10px' }}>
                            <Chip
                                label="Today"
                                onClick={() => handleGetCompanyStatusQuery('Today', startOfDay(new Date()), endOfDay(new Date()))}
                                sx={{ margin: '5px', backgroundColor: activeCompanyStatusState === 'Today' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="Yesterday"
                                onClick={() => handleGetCompanyStatusQuery('Yesterday', startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1)))}
                                sx={{ margin: '5px', backgroundColor: activeCompanyStatusState === 'Yesterday' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="This Week"
                                onClick={() => handleGetCompanyStatusQuery('This Week', startOfWeek(new Date()), new Date())}
                                sx={{ margin: '5px', backgroundColor: activeCompanyStatusState === 'This Week' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="This Month"
                                onClick={() => handleGetCompanyStatusQuery('This Month', startOfMonth(new Date()), new Date())}
                                sx={{ margin: '5px', backgroundColor: activeCompanyStatusState === 'This Month' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="Last Month"
                                onClick={() => handleGetCompanyStatusQuery('Last Month', startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1)))}
                                sx={{ margin: '5px', backgroundColor: activeCompanyStatusState === 'Last Month' ? '#8EACCD' : "inherit" }}
                            />
                            <Chip
                                label="This Year"
                                onClick={() => handleGetCompanyStatusQuery('This Year', startOfYear(new Date()), new Date())}
                                sx={{ margin: '5px', backgroundColor: activeCompanyStatusState === 'This Year' ? '#8EACCD' : "inherit" }}
                            />
                        </Box>
                        <Box className='custom-scrollbar' sx={{ maxHeight: '500px', minHeight: '500px', overflow: 'auto' }}>
                            {ticketCountPerCompany.map((item, index) => (
                                <Card key={index} sx={{ margin: '2px 0', padding: '5px' }}>
                                    <CardContent>
                                        <Chip
                                            label={`${item.companyName} (${item.totalCount})`}
                                            onClick={() => handleCompanyClick(item.companyId, item.companyName)}
                                            sx={{ margin: '5px' }}
                                        />
                                        <Divider />
                                        {item.statuses.map((status, index) => {
                                            const { label, color } = getStatusStyle(status.status);
                                            return (
                                                <Chip
                                                    key={index}
                                                    label={`${label}: ${status.count}`}
                                                    sx={{ margin: '2px', fontSize: '0.8rem', backgroundColor: color }}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            );
                                        })}
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default Dashboard