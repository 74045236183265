import React from 'react';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBusinessTime, faCrown, faUsersCog, faTruckFast, faHeadset, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import EngineeringIcon from '@mui/icons-material/Engineering';

const roleColors = {
    customerAdmin: '#A5D6A7', // Light Green
    customerUser: '#90CAF9',  // Light Blue
    vendorAdmin: '#FFCCBC',   // Light Peach
    vendorUser: '#CE93D8',    // Light Purple
    superAdmin: '#FFD54F',    // Light Yellow 
    technicalAdmin: '#B2DFDB', // Light Teal
    supportAgent: '#e56b6f', // 
    coreAdmin : '#ffcad4'
};

const roleIcons = {
    coreAdmin: faCrown,
    vendorAdmin: faTruckFast,
    vendorUser: faTruckFast,
    customerAdmin: faBusinessTime,
    customerUser: faUser,
    superAdmin: faUsersCog,
    technicalAdmin: EngineeringIcon,
    technician: faScrewdriverWrench,
    supportAgent: faHeadset,
};

function getUserRoleLabel(role) {
    switch (role) {
        case 'customerAdmin':
            return 'Customer Admin';
        case 'customerUser':
            return 'Customer User';
        case 'vendorAdmin':
            return 'Vendor Admin';
        case 'vendorUser':
            return 'Vendor User';
        case 'superAdmin':
            return 'Super Admin';
        case 'technicalAdmin':
            return 'Technical Admin'
        case 'technician': 
            return 'Technician'
        case 'supportAgent':
            return 'Support Agent'
        case 'coreAdmin':
            return 'Core Admin'
        default:
            return role;
    }
}

function UserRoleChip({ role }) {
    const label = getUserRoleLabel(role);

    return (
        <Chip
            label={label}
            color="default"
            avatar={role !== 'technicalAdmin' ? <FontAwesomeIcon icon={roleIcons[role]} /> : <EngineeringIcon/>}
            style={{
                backgroundColor: roleColors[role] || '#B0BEC5', // Default light gray color
                color: 'black',
                borderRadius: '10px',
                padding: '4px 5px',
                fontSize: '14px',
                // fontWeight: 600,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                textTransform: 'capitalize',
                marginRight: '3px',
            }}
        />
    );
}

export default UserRoleChip;
