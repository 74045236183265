import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { createExportAsset as createExportAssetApi, getExportAssetListFromBackend, getExportAssetList, updateExportAsset as updateExportAssetApi, createBrandModelOrigin, getBrandModelOrigin, getExportAssetForCsv, getExportAssetForCsvOrPdf, postAddRemarksComment } from "./service";
import { toast } from "react-toastify";



const initialState = {
    createOrUpdate: {
        machineCode: '',
        assetType: '',
        machineName: '',
        machineModel: '',
        serialNumber: '',
        printerDetails: {
            type: "",
            tonerLevel: {
                black: "",
                yellow: "",
                cyan: "",
                magenta: ""
            }
        },
        tonerDetails: {
            tonerColor: "",
            quantity: ""
        },
        isPartsRequired: false,
        partsDescription: '',
        costPrice: '',
        sellingPrice: '',
        attachments: [],
        previewURLs: [],
        healthStatus: '',
        origin: ""
    },
    errors: {
        machineCode: '',
        assetType: '',
        machineName: '',
        machineModel: '',
        serialNumber: '',
        costPrice: '',
        sellingPrice: '',
        tonerDetails: {
            quantity: '',
        }
    },
    isOpen: false,
    isLoading: false,
    isError: false,
    exportAssetList: {},
    selectedExportAsset: null,
    filter: {
        currentTab: 'warehouseMachine',
        page: 1,
        count: 0
    },
    assetCounts: {},
    viewController: {
        showTable: true,
    },
    options: {
        allModels: [],
        brand: [],
        model: {},
        origin: [],
    },
    newRemarks: {
        isLoading: false,
        comment: ""
    }
}

export const exportAssetInitialState = initialState;
export const createExportAsset = createAsyncThunk(
    "exportAssetMangement/createExportAsset",
    async (newExportAsset, { rejectWithValue }) => {
        try {
            const response = await createExportAssetApi(newExportAsset)
            return response
        } catch (err) {
            return rejectWithValue(err.message ?? 'An un-expected error occurred while making the request.')
        }
    }
)


export const updateExportAsset = createAsyncThunk(
    "exportAssetMangement/updateExportAsset",
    async (updatedExportAsset, { rejectWithValue }) => {
        try {
            const response = await updateExportAssetApi(updatedExportAsset)
            return response.data
        } catch (err) {
            return rejectWithValue(err.message ?? 'An un-expected error occurred while making the request.')
        }
    }
)

export const getExportAssetThunk = createAsyncThunk(
    "exportAssetMangement/getExportAssetThunk",
    async (query, { rejectWithValue }) => {
        try {
            const response = await getExportAssetList(query)
            return response.data

        } catch (err) {
            return rejectWithValue(err.message ?? 'An un-expected error occurred while making the request.')
        }
    }
)
export const creationBrandModelOrigin = createAsyncThunk(
    "exportAssetMangement/creationBrandModelOrigin",
    async (newExportAsset, { rejectWithValue }) => {
        try {
            const response = await createBrandModelOrigin(newExportAsset)
            return response.data

        } catch (err) {
            return rejectWithValue(err.message ?? 'An un-expected error occurred while making the request.')
        }
    }
)
export const getFieldsOptionData = createAsyncThunk(
    "exportAssetMangement/getFieldsOptionData",
    async (newExportAsset, { rejectWithValue }) => {
        try {
            const response = await getBrandModelOrigin(newExportAsset)
            return response.data

        } catch (err) {
            return rejectWithValue(err.message ?? 'An un-expected error occurred while making the request.')
        }
    }
)

export const createExportAssetDownloadable = createAsyncThunk(
    "exportAssetMangement/createExportAssetCsv",
    async ({ exportAssetSession, exportAssetCsv }, { rejectWithValue }) => {
        try {
            const response = await getExportAssetForCsvOrPdf({ exportAssetSession, exportAssetCsv })
            return response.data
        } catch (err) {
            return rejectWithValue(err.message ?? 'An un-expected error occurred while making the request.')
        }
    }
)

export const addRemarksToExportAsset = createAsyncThunk(
    "exportAssetMangement/addRemarksToExportAsset",
    async ({ data, exportId }, { rejectWithValue }) => {
        try {
            const response = await postAddRemarksComment(data, exportId)
            return response.data
        } catch (err) {
            return rejectWithValue(err.message ?? 'An un-expected error occurred while making the request.')
        }
    }
)


const exportAssetSlice = createSlice({
    name: 'exportAssetMangement',
    initialState,
    reducers: {
        OPEN_EXPORT_ASSET_MODAL: (state, action) => {
            state.isOpen = action.payload
        },
        UPDATE_FIELDS_EXPORT_ASSETS: (state, action) => {
            state.createOrUpdate = { ...state.createOrUpdate, ...action.payload }
        },
        UPDATE_ERROR_FIELDS_EXPORT_ASSETS: (state, action) => {
            state.errors = action.payload
        },
        UPDATE_LOADING_EXPORT_ASSETS: (state, action) => {
            state.isLoading = action.payload
        },
        UPDATE_EXPORT_ASSET_LIST: (state, action) => {
            state.exportAssetList[action.payload.currentTab] = action.payload.list
            state.filter.count = action.payload.count
        },
        SET_SELECTED_EXPORT_ASSET: (state, action) => {
            let isPartsRequired = action.payload?.isPartsRequired === 'Yes' ? true : false
            state.selectedExportAsset = { ...action.payload, isPartsRequired }
        },

        UPDATE_SELECTED_EXPORT_ASSET: (state, action) => {
            state.selectedExportAsset = { ...state.selectedExportAsset, ...action.payload }
        },
        UPDATE_FILTER_EXPORT_ASSET: (state, action) => {
            if (action.payload.key === 'currentTab') {
                state.filter = { page: 1 }
            }
            if (action.payload.key === "brandName") {
                state.filter.machineModel = ""
            }
            state.filter[action.payload.key] = action.payload.value
        },
        CLEAR_FILTER_EXPORT_ASSET: (state, action) => {

            if (state.filter != exportAssetInitialState.filter) {
                state.filter = {
                    ...state.filter,
                    count: 0,
                    machineModel: "",
                    brandName: "",
                    assetType: "",
                    machineCode: "",
                    healthStatus: "",
                    origin: "",
                    isPartsRequired: "",
                }
            }
        },
        UPDATE_VIEW_CONTROLLER_EXPORT_ASSET: (state, action) => {
            state.viewController[action.payload] = !state.viewController[action.payload]
        },
        UPDATE_REMARK_COMMENT_DATA: (state, action) => {
            state.newRemarks.comment = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createExportAsset.pending, (state) => {
                state.isLoading = true;
                state.errors = exportAssetInitialState.errors
            })
            .addCase(createExportAsset.fulfilled, (state, action) => {
                state.exportAssetList['warehouseMachine'] = [action.payload.data.assetData, ...state.exportAssetList['warehouseMachine']]

                state.isLoading = false
                state.errors = exportAssetInitialState.errors
            })
            .addCase(createExportAsset.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
            })

            .addCase(updateExportAsset.pending, (state) => {
                state.isLoading = true;
                state.errors = exportAssetInitialState.errors
            })

            .addCase(updateExportAsset.fulfilled, (state, action) => {
                const { updatedExportAsset } = action.payload;
                toast.success('Export Asset updated successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    style: { width: '400px' },
                });
                // state.exportAssetList[state.filter.currentTab] = state.exportAssetList[state.filter.currentTab].map((asset) => {
                //     if (updatedExportAsset._id === asset._id && updatedExportAsset.assetStatus !=='sold') {
                //         return {...updatedExportAsset, isPartsRequired: updatedExportAsset.isPartsRequired ? "Yes" : "No" , updatedBy: updatedExportAsset.updatedBy.displayName}
                //     }
                //     return asset
                // })
                state.isLoading = false;

            })
            .addCase(updateExportAsset.rejected, (state, action) => {
                const result = action.payload
                state.isLoading = false
                toast.error(result?.error?.message ?? 'Something went wrong at asset update', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    style: { width: '400px' },
                })
                state.isError = true
            })


            .addCase(getExportAssetThunk.pending, (state) => {
                state.isLoading = true;
                state.errors = exportAssetInitialState.errors
            })
            .addCase(getExportAssetThunk.fulfilled, (state, action) => {
                state.isLoading = false
                const { currentTab, count, exportAssets, assetCounts } = action.payload
                let list = exportAssets.map((asset) => {
                    const displayName = asset.updatedBy ? asset.updatedBy.displayName : '';
                    let tonerQuantity = 0
                    let tonerColor
                    if (asset.assetType === "toner") {
                        tonerQuantity = asset.tonerDetails.quantity
                        tonerColor = asset.tonerDetails.tonerColor
                    }
                    return ({ ...asset, isPartsRequired: asset.isPartsRequired ? "Yes" : "No", updatedBy: displayName, tonerQuantity, tonerColor })
                })
                state.exportAssetList[currentTab] = list
                state.filter.count = count
                state.errors = exportAssetInitialState.errors
                state.assetCounts[currentTab] = assetCounts
            })
            .addCase(getExportAssetThunk.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
            })

            .addCase(creationBrandModelOrigin.fulfilled, (state, action) => {
                const { message, error } = action.payload
                if (error) {
                    toast.error(message)
                } else {
                    toast.success(message)
                }
            })

            .addCase(getFieldsOptionData.fulfilled, (state, action) => {
                const { brand, model, origin, allModels } = action.payload
                state.options.brand = brand || []
                state.options.model = model || {}
                state.options.origin = origin || []
                state.options.allModels = allModels || []
            })
            .addCase(addRemarksToExportAsset.pending, (state) => {
                state.newRemarks.isLoading = true;
            })
            .addCase(addRemarksToExportAsset.fulfilled, (state, action) => {
                state.newRemarks.isLoading = false;
                state.newRemarks.comment = ""
                state.selectedExportAsset.remarks = action.payload.result.remarks
                toast.success('Remarks added successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            })
            .addCase(addRemarksToExportAsset.rejected, (state, action) => {
                state.newRemarks.isLoading = false;
                toast.error(action.payload ?? 'Failed to add remarks', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
    }

})


export const {
    OPEN_EXPORT_ASSET_MODAL,
    UPDATE_FIELDS_EXPORT_ASSETS,
    UPDATE_ERROR_FIELDS_EXPORT_ASSETS,
    UPDATE_LOADING_EXPORT_ASSETS,
    UPDATE_EXPORT_ASSET_LIST,
    SET_SELECTED_EXPORT_ASSET,
    UPDATE_FILTER_EXPORT_ASSET,
    UPDATE_SELECTED_EXPORT_ASSET,
    CLEAR_FILTER_EXPORT_ASSET,
    UPDATE_VIEW_CONTROLLER_EXPORT_ASSET,
    UPDATE_REMARK_COMMENT_DATA
} = exportAssetSlice.actions
export default exportAssetSlice.reducer