import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import {
    TextField,
    IconButton,
    InputAdornment,
    Box,
    Tooltip
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/UploadFile';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { styled } from '@mui/material/styles';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { serverTimestamp, addDoc, collection, doc, updateDoc, arrayRemove, getDoc, onSnapshot } from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../../../utilities/auth-config/firebase-config";
import { toast } from 'react-toastify';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import NotificationsIcon from '@mui/icons-material/Notifications';

import { privateApi } from "../../../../api";
import { updateCurrentChatRoom } from '../ChatSlice';
import service from '../ChatSection-3-RightComponent/service';

const Container = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: 'white',
    boxShadow: '0px -1px 5px 0px rgba(0, 0, 0, 0.2)',
    bottom: 0,
    width: 'auto',
    transition: 'box-shadow 0.2s',
    '&:hover': {
        boxShadow: '3px 15px 15px rgba(0, 0, 0, 0.4)',
        cursor: 'pointer',
        borderBottom: 'solid 5px #091B95',
    },
});

const ChatInputController = () => {
    const dispatch = useDispatch();
    const endChatIconButtonRef = useRef(null);
    const selectedChatRoomId = useSelector((state) => state.chats.openedChatId);
    /** GET THE DETAILS OF CURRENTLY OPENED CHAT ROOM */
    const attendedChats = state => state.chats.attendedChats;
    const selectOpenedChat = createSelector(
        attendedChats,
        attendedChats => attendedChats.find(chat => chat.id === selectedChatRoomId)
    );
    const chatSlice = useSelector(state => state.chats)
    const currentChat = useSelector(selectOpenedChat);
    /** *******************ENDS HERE******************** */
    const user = useSelector((state) => state.userData.data);
    const [message, setMessage] = useState('');
    const [ticketInputVisible, setTicketInputVisible] = useState(false);
    const [ticketId, setTicketId] = useState('');
    const [companyTickets, setCompanyTickets] = useState([]);

    const messageDbRef = collection(db, 'messages');
    const storageRef = ref(storage, selectedChatRoomId);

    useEffect(() => {
        const roomDocRef = doc(db, 'rooms', selectedChatRoomId || "NONE");
        const unsubscribe = onSnapshot(roomDocRef, (roomDocSnap) => {
            if (roomDocSnap.exists()) {
                const roomData = { id: roomDocSnap.id, ...roomDocSnap.data() };
                const supportAgentExist = roomData.attendedBy.some(attender => attender.role === 'supportAgent')
              
                dispatch(updateCurrentChatRoom({
                    allowAdminInput: supportAgentExist &&( user.role === 'coreAdmin' || user.role == 'superAdmin'),
                    isSupportAgent: roomData.userType === 'Admin',
                    currentSupportAgent: roomData.attendedBy.find(attender => attender.role === 'supportAgent')
                }))
            } else {
                // console.log('Room document does not exist.');
            }
        });

        return () => unsubscribe();
    }, [selectedChatRoomId]);
    const handleSendMessage = async () => {
        if (!selectedChatRoomId) {
            toast.warning('Please select a chat box to start sending messages.');
            return
        }
        const messageToSend = message;
        setMessage('');

        if (messageToSend.trim() !== '') {
            const messageObj = {
                message: messageToSend,
                photoURL: user.photoURL ?? "",
                roomId: selectedChatRoomId,
                timestamp: serverTimestamp(),
                type: 'text',
                userId: user._id,
                userName: user.displayName ?? `${user.firstName} ${user.lastName}`,
                userType: user.userType,
                seen: null,
                fileURL: '',
            };

            await addDoc(messageDbRef, messageObj)

        }
    };

    const handleFileChange = async (event) => {
        await handleFileUpload(event.target.files[0]);
    };

    const handleFileUpload = async (file) => {
        if (!file) {
            toast.warning('Please select a file to upload.');
            return;
        }

        try {
            const fileRef = ref(storageRef, file.name); // Storage reference
            await uploadBytes(fileRef, file);

            const downloadURL = await getDownloadURL(fileRef);

            // Save the download link in messages collection
            const messageObj = {
                message: '',
                photoURL: user.photoURL ?? "",
                roomId: selectedChatRoomId,
                timestamp: serverTimestamp(),
                type: 'file',
                fileType: file.type ?? "",
                fileName: file.name ?? "",
                userId: user._id,
                userName: user.displayName ?? `${user.firstName} ${user.lastName}`,
                seen: null,
                fileURL: downloadURL,
            };

            await addDoc(messageDbRef, messageObj)
        } catch (error) {
            toast.error('An error occured while trying to upload the file.')
        }
    };

    const handleTicketIconClick = () => {
        setTicketInputVisible(!ticketInputVisible);  
    };

    const handleTicketInputChange = (event) => {
        setTicketId(event.target.value);
    };


        useEffect(() => {
            if(currentChat){
                getTicketsOfCompany();
            }
        }, [currentChat]);
    


    const getTicketsOfCompany = async () => {
        try{
            if(currentChat.company.companyId){
                const response = await service.getTicketsByQuery({ company: currentChat.company.companyId });
                const tickets = response?.data?.tickets;
                if(tickets.length > 0){
                    setCompanyTickets(tickets);
                }else{
                    setCompanyTickets([]);
                }
            }
                
        }catch(err){
            toast.error('An error occured while fetching tickets.');
        }
    };

    const handleSendTicketId = async () => {
        
        if (!ticketId) {
            toast.warning('Please enter a ticket ID.');
            return
        }else if(ticketId.trim() !== ''){
            const ticketIdNumber = Number(ticketId);
            const isTicketExist = companyTickets.some(ticket => ticket.customTicketId === ticketIdNumber);
            if(!isTicketExist){
                toast.warning('Ticket ID does not exist in this company. Please enter a valid ticket ID.');
                setTicketId('');
                setTicketInputVisible(!ticketInputVisible);
                return
            }

            // Save the link in messages collection
            const messageObj = {
                message: `A Ticket link is shared. Please click Here to view the ticket.`,
                linkURL: `${process.env.REACT_APP_APP_URL}tickets/${ticketId}`,
                timestamp: serverTimestamp(),
                seen: false,
                userName: user.displayName ?? `${user.firstName} ${user.lastName}`,
                userId: user._id,
                photoURL:  user.photoURL ?? "",
                type: "link", 
                isUser: false,
                roomId: selectedChatRoomId,
            }
            await addDoc(messageDbRef, messageObj) 
        }
        setTicketInputVisible(!ticketInputVisible);
        setTicketId('');
    }

    const handleChatSessionClose = async (isClosingMessageNeeded) => {
        const roomRef = doc(db, "rooms", selectedChatRoomId);
        const roomSnap = await getDoc(roomRef);
        const adminName = user.displayName
            ? user.displayName
            : `${user.firstName} ${user.lastName}`;

        if (roomSnap.exists()) {
            const roomData = roomSnap.data();
            const attendedByCount = roomData.attendedBy.length; // Assuming attendedBy is an array

            const updates = {
                attendedBy: arrayRemove({ id: user._id, role: user.role, displayName: adminName }),
            };

            if ((attendedByCount < 2)) {
                updates.status = "closed";
                updates.attendedByName = '';
            }
            if (attendedByCount === 2 && adminName === roomData.attendedByName) {
                updates.attendedByName = 'admin';
            }

            await updateDoc(roomRef, updates);
        }

        if (isClosingMessageNeeded) {
            const messageObj = {
                message: 'System Generated: Chat Session with ' + (user.displayName ?? `${user.firstName} ${user.lastName}`) + ' has ended.',
                photoURL: "",
                roomId: selectedChatRoomId,
                timestamp: serverTimestamp(),
                type: 'text',
                userId: 'na',
                userName: `System Generated`,
                userType: 'Admin',
                seen: null,
                fileURL: '',
            };

            try {
                await addDoc(messageDbRef, messageObj);
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        }

        dispatch({ type: 'chats/setOpenedChatId', payload: null });

        endChatIconButtonRef.current.click();
    }

    const handleSendNotification = async () => {
        try {
            debugger
            const response = await privateApi.post('users/sendPushNotify', { tokens: currentChat.userTokens });
            if (response.status === 200) {
                toast.success('Sent a push notification successfully to the user.');
            } else {
                toast.error('An error occurred while sending the push notify, please contact technical support if this issue persists.');
            }
        } catch (error) {
            toast.error('An error occurred while sending the push notify, please contact technical support if this issue persists.');
        }
    };

    return (
        <Container sx={{ position: 'relative' }}>
            {ticketInputVisible && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: -50,
                        left: 0,
                        backgroundColor: 'white',
                        padding: '8px',
                        borderRadius: '4px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        zIndex: 1
                    }}
                >
                    <TextField
                        value={ticketId}
                        onChange={handleTicketInputChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSendTicketId();
                            }
                        }}
                        type="number"
                        label="Enter Ticket ID"
                        variant="outlined"
                        size="small"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                </Box>          
            )}
            <Tooltip title="Send Ticket Link" placement="top-start">
                <IconButton color='primary' disabled={!selectedChatRoomId || chatSlice.currentChatRoom.allowAdminInput} onClick={handleTicketIconClick}>
                    <ConfirmationNumberIcon fontSize='large' />
                </IconButton>
            </Tooltip>
            <TextField
                variant="outlined"
                placeholder="Send a message"
                fullWidth
                multiline
                sx={{ marginRight: '4px' }}
                value={message}
                onChange={(e) => {
                    setMessage(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleSendMessage} color='primary' disabled={!selectedChatRoomId || chatSlice.currentChatRoom.allowAdminInput}>
                                <SendIcon fontSize='large' />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                disabled={!selectedChatRoomId || chatSlice.currentChatRoom.allowAdminInput}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* Use label to trigger file input */}
                <label htmlFor="file-input" style={{ marginRight: '5px', cursor: 'pointer' }} >
                    <UploadIcon fontSize="large" sx={{
                        color: (selectedChatRoomId || chatSlice.currentChatRoom.allowAdminInput) ? '#1976d2' : 'rgba(0, 0, 0, 0.38)',
                        pointerEvents: (selectedChatRoomId || chatSlice.currentChatRoom.allowAdminInput) ? 'auto' : 'none',
                    }} disabled={!selectedChatRoomId || chatSlice.currentChatRoom.allowAdminInput} />
                </label>
                {/* Actual file input, hidden visually */}
                <input
                    accept="image/*, audio/*, video/*, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, text/csv, .zip, application/zip, application/x-zip-compressed, multipart/x-zip"
                    style={{ display: 'none' }}
                    id="file-input"
                    type="file"
                    onChange={handleFileChange}
                    disabled={!selectedChatRoomId || chatSlice.currentChatRoom.allowAdminInput}
                />

                <OverlayTrigger trigger="click" rootClose placement="top" align="center" delay={400} overlay={
                    <Popover id="popover-basic" style={{ width: '300px' }} className="custom-popover popover">
                        <div className="popover-header" style={{ fontSize: '18px', padding: '10px', color: 'black', fontWeight: 500 }}>Close this Chat?</div>
                        <Popover.Body>
                            <div className="popover-content" style={{ fontSize: '16px', padding: '10px' }}>
                                Want to end chat session with{' '}
                                <strong>
                                    {currentChat?.displayName ? currentChat?.displayName : `${currentChat?.firstName} ${currentChat?.lastName}`}
                                </strong>
                                ?
                            </div>
                        </Popover.Body>
                        <div className="popover-footer" style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                            <Button className='mr-3' onClick={() => { handleChatSessionClose(false) }} variant="outline-secondary" style={{ marginRight: '10px' }}>
                                Silently Close
                            </Button>
                            <Button className='ml-2' variant={"outline-danger"} onClick={() => { handleChatSessionClose(true) }}>
                                End Chat
                            </Button>
                        </div>
                    </Popover>
                }>
                    <IconButton color="error" disabled={!selectedChatRoomId} ref={endChatIconButtonRef}>
                        <RemoveCircleOutlineIcon fontSize="large" />
                    </IconButton>
                </OverlayTrigger>

            </Box>

        </Container>
    );
};

export default ChatInputController;
