import React, { useState, useEffect } from 'react'
import { CreateButton } from '../../components/commons/Buttons';
import { useSelector, useDispatch } from 'react-redux';
import CustomDialogueMedium from '../../components/commons/CustomDialogueMedium'
import { Grid, Avatar, TextField, MenuItem, Skeleton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { isValidEmail, isValidPassword, stringToColor } from '../../utilities/commonUtils';
import SelectInput from '../../components/commons/SelectInput';
import {
    userRoles,
    roleOptionsForSuperAdmin,
    roleOptionsForCustomerAdmin,
    roleOptionsForVendorAdmin,
    userTypes,
    authProviders,
    preferredContactOptions,
    roleOptionsForTechnicalAdmin
} from '../../constants/commonConstants';
import TextInput from '../../components/commons/TextInput';
import { Visibility, VisibilityOff, Edit } from "@mui/icons-material";
import Autocomplete from '../../components/commons/Autocomplete';
import { getCompaniesList, getCompanyLocations } from './Service';
import { publicApi } from "../../api";
import { createUserAsync, resetCreateUserData } from '../../app/User/UserManagementSlice';
import { toast } from "react-toastify";
import PhoneInputCustom from '../../components/commons/PhoneInput';


function CreateUser() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const data = useSelector((state) => state.userManagement.createUserData);
    const sessionUser = useSelector((state) => state.userData.data)
    const [roleOptions, setRoleOptions] = useState([]);
    const [formError, setFormError] = useState({
        emailError: { flag: false, msg: '' },
        passwordError: { flag: false, msg: '' },
    });
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [originalCompaniesList, setOriginalCompaniesList] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [companyLocationsList, setCompanyLocationsList] = useState([]);


    const getUserRoles = () => {
        let options;
        if (data.userType === 'Admin') {
            if (sessionUser.role === 'coreAdmin') {
                options = [{ key: 'coreAdmin', name: "Core Admin" }, ...roleOptionsForSuperAdmin]
            } else if (sessionUser.role === 'superAdmin') {
                options = roleOptionsForSuperAdmin
            } else if (sessionUser.role === 'technicalAdmin') {
                options = roleOptionsForTechnicalAdmin
            } else {
                options = []
            }
            // options = sessionUser.role === 'coreAdmin' ?;
        } else if (data.userType === 'Customer') {
            options = roleOptionsForCustomerAdmin;
        } else if (data.userType === 'Vendor') {
            options = roleOptionsForVendorAdmin;
        } else {
            // Default options if none of the above conditions match
            options = [];
        }
        setRoleOptions(options)
    }

    useEffect(() => {
        getUserRoles();
    }, [])

    useEffect(() => {
        getUserRoles();
        const tempCompanyList = originalCompaniesList.filter((item) => {
            switch (data?.userType) {
                case 'Admin':
                    return item.type === 'superAdmin';
                case 'Customer':
                    return item.type === 'customer';
                case 'Vendor':
                    return item.type === 'vendor';
                default:
                    return false;
            }
        });
        setCompaniesList(tempCompanyList);
    }, [data?.userType]);

    const handlePasswordVisibilityClick = () => {
        setPasswordVisible((prevVisible) => !prevVisible);
    };

    useEffect(() => {
        if (data.isCreateModalOpen) {
            getCompaniesList()
                .then((result) => {
                    if (result?.data?.length) {
                        let tempArr = [];
                        result.data.forEach(item => {
                            tempArr.push({ value: item._id, label: item.companyName, type: item.type });
                        });
                        setOriginalCompaniesList(tempArr)
                        setCompaniesList(tempArr)
                    } else if (result.data) {
                        setOriginalCompaniesList([{ value: result.data._id, label: result.data.companyName, type: result?.data?.type }]);
                        setCompaniesList([{ value: result.data._id, label: result.data.companyName, type: result?.data?.type }])
                    }
                });
        }
        return ()=>{
            dispatch(resetCreateUserData())
        }

    }, [data.isCreateModalOpen]);

    const checkUserExistWithEmail = async () => {
        if (isValidEmail(data.email)) {
            const response = await publicApi('POST', 'users/checkUserExistWithEmail', { "email": data.email });
            if (response?.success)
                setFormError({ ...formError, emailError: { flag: response.data?.userExists, msg: response?.data?.message ?? "" } });
        }
    };

    const handleSubmit = () => {
        // validations
        const errors = [];
        if (!data.firstName || !data.lastName) {
            errors.push("First Name or Last Name");
        }
        if (!data.company && data.role !== 'coreAdmin') {
            errors.push("Company.");
        }
        if (!data.contactPhoneNumber && data.role !== 'coreAdmin') {
            errors.push("Contact Phone");
        }
        if (!data.preferredContactMethod && data.role !== 'coreAdmin') {
            errors.push("Preferred Contact");
        }
        if (data.authProvider === "email" && (!data.password || formError.passwordError.flag)) {
            errors.push("Either weak password or password missing");
        }
        if (!data.userType || !data.role) {
            errors.push("User Type or Role is missing");
        }
        if (!data.authProvider) {
            errors.push("Login method");
        }
        if (formError.emailError.flag) {
            errors.push(formError.emailError.msg)
        }
        // display notification for missing items using toast
        if (errors.length > 0) {
            toast.error(`Please resolve below errors: ${errors.join(", ")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                style: { width: '400px' },
            });
        } else {
            dispatch(createUserAsync({
                userData: {
                    ...data,
                    company: data.company?.value,
                }
            }));
        }
    };

    useEffect(() => {
        if (data.isSuccess) {
            toast.success('Success: User has been registered.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            setTimeout(() => {
                dispatch({ type: 'userManagement/resetCreateUserData', payload: {} });
            }, 600);
        }
    }, [data.isSuccess])

    useEffect(() => {
        if (data.isError) {
            toast.error(data.errorMessages, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                style: { width: '400px' },
            });
            dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'isError', value: false, } });
        }
    }, [data.isError])

    useEffect(() => {
        if (data?.company?.value) {
            dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'companyLocation', value: null, } });
            getSelectedCompaniesLocations();
        }
    }, [data?.company?.value]);

    const getSelectedCompaniesLocations = async () => {
        try {
            const locationsList = await getCompanyLocations(data?.company?.value);
            setCompanyLocationsList(locationsList?.data?.map((item) => ({ value: item.id, label: item.locationName })) || []);
        } catch (error) {
            setCompanyLocationsList([]);
            console.error(`Failed to get locations: ${error.message}`);
        }
    }


    const footerButtons = (
        <>
            <CreateButton onClick={handleSubmit} disabled={data.isLoading} />
        </>
    );

    return (
        <div>
            <CustomDialogueMedium
                open={data.isCreateModalOpen}
                onClose={() => { dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'isCreateModalOpen', value: false, }, }); }}
                headerText={"Create User"}
                footerButtons={footerButtons}
            >
                <Grid container spacing={4}>
                    {
                        !data?.isLoading && (
                            <>
                                <Grid item xs={isMobile ? 12 : 6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={isMobile ? 4 : 2} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ borderRadius: '4px', border: 'solid 1px rgba(0, 0, 0, 0.38)', padding: '4px', height: '105%' }}>
                                                <Avatar
                                                    alt="User Avatar"
                                                    src={data?.photoURL} // src attribute will render the avatar from the photoURL if available
                                                    style={{
                                                        backgroundColor: data?.photoURL
                                                            ? 'transparent'
                                                            : data?.displayName
                                                                ? stringToColor(data.displayName)
                                                                : stringToColor(`${data.firstName} ${data.lastName}`),
                                                    }}
                                                >
                                                    {/* Only render initial character if photoURL is not available and displayName or firstName is available */}
                                                    {(!data?.photoURL && (data?.displayName || data?.firstName)) ? (
                                                        data?.displayName
                                                            ? data?.displayName[0]
                                                            : data?.firstName[0]
                                                    ) : null}
                                                </Avatar>
                                            </div>
                                        </Grid>
                                        <Grid item xs={isMobile ? 8 : 10}>
                                            <SelectInput
                                                required={true}
                                                name={'userType'}
                                                label="User Type"
                                                value={data.userType}
                                                options={['Customer', 'Vendor'].includes(sessionUser.userType) ? userTypes.filter(item => item.key === sessionUser.userType) : userTypes.filter(item => {
                                                    if ((sessionUser.role === 'technician' || sessionUser.role === 'supportAgent') && item.name === "Admin") {
                                                        return false
                                                    }
                                                    return true
                                                })}
                                                onChange={(e) => {
                                                    dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'userType', value: e.target.value, }, })
                                                    dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'role', value: "", }, })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 6} >
                                    <SelectInput
                                        required={true}
                                        label="Role"
                                        name='role'
                                        value={data.role}
                                        onChange={(e) => {
                                            dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'role', value: e.target.value, }, })
                                        }}
                                        options={roleOptions}
                                    />
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                                    <TextInput
                                        label="First Name"
                                        name={'firstName'}
                                        value={data.firstName}
                                        required={true}
                                        onChange={(e) => { dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'firstName', value: e.target.value, }, }) }}
                                    />
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 6} >
                                    <TextInput
                                        required
                                        label="Last Name"
                                        value={data.lastName}
                                        name={'lastName'}
                                        onChange={(e) => { dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'lastName', value: e.target.value, }, }) }}
                                    />
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                                    <TextInput
                                        required
                                        label="Email"
                                        value={data.email}
                                        name="email"
                                        type="email"  // This enforces email validation
                                        helperText={formError.emailError.msg}
                                        error={formError.emailError.flag}
                                        onChange={(e) => {
                                            dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'email', value: e.target.value, }, });
                                            checkUserExistWithEmail();
                                            if (!isValidEmail(e.target.value)) setFormError({ ...formError, emailError: { flag: true, msg: 'Invalid email' } });
                                            else setFormError({ ...formError, emailError: { flag: false, msg: '' } });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={isMobile ? 12 : 6} >
                                    <SelectInput
                                        required
                                        name={'authProvider'}
                                        label="Login Method"
                                        value={data.authProvider}
                                        options={authProviders}
                                        onChange={(e) => { dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'authProvider', value: e.target.value, }, }) }}
                                    />
                                </Grid>
                                {
                                    data.authProvider === 'email' && (
                                        <Grid item xs={12} md={6}>
                                            <TextInput
                                                label="Password"
                                                name="password"
                                                value={data.password}
                                                type={passwordVisible ? "text" : "password"}
                                                onChange={(e) => {
                                                    dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'password', value: e.target.value, }, });
                                                    if (!isValidPassword(e.target.value)) setFormError({ ...formError, passwordError: { flag: true, msg: 'Password doesnt meet the requirement' } });
                                                    else setFormError({ ...formError, passwordError: { flag: false, msg: '' } });
                                                }}
                                                required={true}
                                                error={formError.passwordError.flag}
                                                helperText={formError.passwordError.msg}
                                                InputProps={{
                                                    endAdornment: (
                                                        <span
                                                            onClick={handlePasswordVisibilityClick}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            {passwordVisible ? (
                                                                < VisibilityOff color='primary' />
                                                            ) : (
                                                                <Visibility color='primary' />
                                                            )}
                                                        </span>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    )
                                }

                                {!(data.role === "coreAdmin") && <Grid item xs={isMobile ? 12 : 6} >
                                    <Autocomplete
                                        label="Company"
                                        name='company'
                                        value={data.company}
                                        onChange={(e) => { dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'company', value: e.target.value, }, }) }}
                                        options={companiesList}
                                        required={true}
                                        error={(!data.company && !data.userType === 'Admin')}
                                    />
                                </Grid>}

                                {(data.role !== "coreAdmin") && <Grid item xs={isMobile ? 12 : 6} >
                                    <Autocomplete
                                        label="Company Location"
                                        name='companyLocation'
                                        value={data.companyLocation}
                                        onChange={(e) => { dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'companyLocation', value: e.target.value, }, }) }}
                                        options={companyLocationsList}
                                        required={false}
                                    // error={(!data.company && !data.userType === 'Admin')}
                                    />
                                </Grid>}


                                <Grid item xs={isMobile ? 12 : 6} >
                                    <SelectInput
                                        label="Preferred Contact Method"
                                        value={data.preferredContactMethod}
                                        onChange={(e) => { dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'preferredContactMethod', value: e.target.value, }, }) }}
                                        name='preferredContactMethod'
                                        options={preferredContactOptions}
                                    />
                                </Grid>

                                <Grid item xs={isMobile ? 12 : 6}>
                                    <PhoneInputCustom
                                        label="Phone number"
                                        value={data.contactPhoneNumber}
                                        onChange={(value) =>
                                            dispatch({ type: 'userManagement/updateFieldValues', payload: { state: 'createUserData', field: 'contactPhoneNumber', value: value, }, })
                                        }
                                    />
                                </Grid>
                            </>
                        )
                    }
                    {
                        data?.isLoading &&
                        <>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 6}>
                                <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
                            </Grid>
                        </>

                    }
                </Grid>
            </CustomDialogueMedium>
        </div>
    )
}

export default CreateUser
