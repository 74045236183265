import { privateApi } from "../../api";

const createAsset = async (inputData) => {
    return await privateApi.post('assets/createAsset', inputData);
};

const editAsset = async (inputData) => {
    return await privateApi.put(`assets/updateAsset/${inputData?.id}`, inputData);
};

const getAssetsByQuery = async (queryData) => {
    const {
        isActive, page = 1, limit, assetName, assetType, machineCode, model,
        sortField, sortOrder, company, users, nextInvoiceFrom, nextInvoiceTo,
        serviceDueBy, healthStatus, nextServiceFrom, nextServiceTo,
        partsRequired, exportCSV, clientTimeZone, exclusiveSortAndFilter,
        contractType
    } = queryData;

    const queryParameters = [
        `clientTimeZone=${clientTimeZone}`,
        isActive !== undefined && `isActive=${isActive}`,
        assetName && `assetName=${assetName}`,
        assetType && `assetType=${assetType}`,
        machineCode && `machineCode=${machineCode}`,
        model && `model=${model}`,
        company && `company=${company.value}`,
        users && `users=${users.map(item => item._id).join(',')}`,
        serviceDueBy && `serviceDueBy=${serviceDueBy}`,
        healthStatus && `healthStatus=${healthStatus}`,
        nextServiceFrom && `nextServiceFrom=${nextServiceFrom}`,
        nextServiceTo && `nextServiceTo=${nextServiceTo}`,
        nextInvoiceFrom && `nextInvoiceFrom=${nextInvoiceFrom}`,
        nextInvoiceTo && `nextInvoiceTo=${nextInvoiceTo}`,
        partsRequired && `partsRequired=${partsRequired}`,
        exportCSV && `exportCSV=${exportCSV}`,
        exclusiveSortAndFilter && `exclusiveSortAndFilter=${exclusiveSortAndFilter}`,
        sortField && `sortField=${sortField}`,
        sortOrder && `sortOrder=${sortOrder}`,
        contractType && `contractType=${contractType}`,
        `page=${page}`,
        limit && `limit=${limit}`
    ]?.filter(Boolean).join('&');

    return await privateApi.get(`assets/getAssets?${queryParameters}`);
};


const searchAssets = async (queryData) => {
    const {
        company,
        searchString
    } = queryData;

    let queryParameters = [];

    if (company) queryParameters.push(`company=${company}`);
    if (searchString) queryParameters.push(`searchString=${searchString}`);

    let queryString = queryParameters.join('&');

    return await privateApi.get(`assets/searchAssets?${queryString}`);
};

const getAsset = async (id) => {
    return await privateApi.get(`assets/getAsset/${id}`);
}

const getAssetLogs = async (queryData) => {

    const { assetId, page, limit } = queryData;

    return await privateApi.get(`assets/getAssetLogs/?assetId=${assetId}&page=${page}&limit=${limit}`);
}

export default {
    createAsset,
    editAsset,
    getAssetsByQuery,
    getAsset,
    searchAssets,
    getAssetLogs
};