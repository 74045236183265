import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCommentToTicket, addInternalCommentsToTicket, updateTicket, handleTicketApproval } from '../../app/Ticket/TicketManagement'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Divider,
    Paper,
    useMediaQuery,
    useTheme,
    Grid,
    InputLabel,
    TextField,
    Skeleton,
    Stack,
    Chip,
    Avatar,
    InputAdornment,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Box,
    FormHelperText,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { styled } from "@mui/material/styles";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import TicketModalSkeleton from './TicketModalSkeleton';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import '../../index.css'
import { SaveButton } from '../../components/commons/Buttons';
import './ticket.css'
import SelectInput from '../../components/commons/SelectInput';
import MUIDatePicker from '../../components/commons/DatePicker/DatePicker';
import { getCompaniesList, getCompanyLocations } from '../UserMangaement/Service';
import Autocomplete from '../../components/commons/Autocomplete';
import FileUploader from '../../components/FileUploader/FileUploader';
import TicketStatusSelector from '../../components/commons/TicketStatusSelector';
import LabelSelector from '../../components/commons/LabelSelector';
import MultipleUserSelector from '../../components/commons/MultipleUserSelector/MultipleUserSelector';
import TicketPrioritySelector from '../../components/commons/TicketPrioritySelector';
import ProposalDataGrd from './ProposalDataGrid';
import { stringToColor } from '../../utilities/commonUtils';
import { format } from 'date-fns';
import DeclineDialog from './DeclineModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StatusChangesTimeline from './StatusChangesTimeline';
import AssetController from '../../components/commons/AssetController/AssetController';


const StyledAccordion = styled(Accordion)(({ theme }) => ({
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    // margin: '10px 0',
    padding: 0
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '0',
    minHeight: 'auto',
    '&.Mui-expanded': {
        minHeight: 'auto',
    },
    '& > .MuiAccordionSummary-content': {
        // margin: '0.5rem 0', 
        '&.Mui-expanded': {
            // margin: '0.5rem 0',
        },
    },
    borderRadius: '4px 4px 0 0',
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: '#f5f5f5',
    borderRadius: '0 0 4px 4px',
    padding: '1rem',
}));



const ExistingTicketView = () => {
    const sessionUser = useSelector((state) => state.userData.data)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const existingTicketData = useSelector((state) => state.ticketManagement.existingTicket);
    const [toggleCommentsAndActivities, setToggleCommentsAndActivities] = useState('comments')
    const [declineModaleOpen, setDeclineModalOpen] = useState(false);
    const [companyLocationsList, setCompanyLocationsList] = useState([]);

    const dialogContentStyle = {
        overflow: 'hidden',
        maxHeight: isMobile ? '100vh' : '75vh',
        overflowY: 'auto',
    };
    const [companiesList, setCompaniesList] = useState([]);
    const [proposalBreakUps, setProposalBreakUps] = useState([]);

    const [expanded, setExpanded] = useState(false);
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    useEffect(() => {
        getCompaniesList()
            .then((result) => {
                if (result?.data?.length) {
                    let tempArr = [];
                    result.data.forEach(item => {
                        tempArr.push({ value: item._id, label: item.companyName });
                    });
                    setCompaniesList(tempArr)
                } else if (result.data) {
                    setCompaniesList([{ value: result.data._id, label: result.data.companyName }]);
                    dispatch({
                        type: 'ticketManagement/updateFieldValues',
                        payload: {
                            state: 'existingTicket',
                            field: 'company',
                            value: { value: sessionUser.company._id, label: sessionUser.company.companyName },
                        },
                    })
                }
            });
    }, []);

    useEffect(() => {
        if (sessionUser.userType === 'Admin') {
            setToggleCommentsAndActivities('Internal Comments')
        }
        if (sessionUser.userType === 'Customer') {
            setToggleCommentsAndActivities('Customer Comments')
        }
    }, [sessionUser.userType])

    useEffect(() => {
        // If proposal break values are there in the redux state then sync that data with local state
        if (existingTicketData?.proposalBreakUps?.length && proposalBreakUps.length === 0) {
            setProposalBreakUps([...existingTicketData?.proposalBreakUps])
        }
    }, [existingTicketData?.proposalBreakUps?.length])

    function saveUploadedFilesInRedux(fileData) {
        dispatch({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'existingTicket',
                field: 'uploads',
                value: [...existingTicketData.uploads, fileData],
            },
        });
    }

    function removeUploadedFileFromRedux(fileName) {
        if (fileName) {
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'existingTicket',
                    field: 'uploads',
                    value: existingTicketData.uploads?.filter(item => item.filename !== fileName),
                },
            });
        }
    }

    const handleProposalBreakUpsGridDataChange = (newValue) => {
        dispatch({
            type: 'ticketManagement/updateFieldValues',
            payload: {
                state: 'existingTicket',
                field: 'proposalBreakUps',
                value: newValue,
            },
        });
        setProposalBreakUps(newValue)
    }

    /**
     * Dispatch create ticket async thunk function to redux
     */
    const handleTicketUpdateSubmission = async () => {
        dispatch(updateTicket(existingTicketData));
    }

    /**
     * Handle API Request state - Error Handling
     */
    useEffect(() => {
        if (existingTicketData.isErrorInTicketCreate && existingTicketData?.validationErrors) {
            const errorMessages = Object.values(existingTicketData.validationErrors);

            // Format error messages as a list
            const formattedErrors = (
                <ul>
                    {errorMessages.map((errorMessage, index) => (
                        <li key={index}>{errorMessage}</li>
                    ))}
                </ul>
            );

            toast.error(formattedErrors, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
                closeOnClick: false,
                draggable: true,
                style: { width: '600px' },
            });
        }
    }, [existingTicketData?.validationErrors]);

    /**
     * Handle API Request state - Information Message
     */
    useEffect(() => {
        if (existingTicketData?.errorMessage !== "") {
            toast.error(existingTicketData?.errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
        }
    }, [existingTicketData?.errorMessage]);

    function formatDate(dateTimeString) {
        return format(new Date(dateTimeString), 'dd/MM/yyyy hh:mm a'); // Format the date and time
    }


    /**
     * Handle API Request state - Success Message
     */
    useEffect(() => {
        if (existingTicketData?.isSuccessInTicketCreate) {
            toast.success('Ticket has been updated successfully.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'existingTicket',
                    field: 'isSuccessInTicketCreate',
                    value: false,
                },
            });
        }
    }, [existingTicketData?.isSuccessInTicketCreate]);

    const handleAdminApproval = (type, declineReason) => {
        if (type === 'approved') {
            dispatch(handleTicketApproval({
                data: {
                    id: existingTicketData._id,
                    status: ['superAdmin', 'technicalAdmin', 'coreAdmin'].includes(sessionUser.role) ? 'superAdminApproved' : 'customerAdminApproved'
                }
            }));
        } else if (type === 'declined-without-reason') {
            setDeclineModalOpen(true);
        } else if (type === 'decline-with-reason') {
            dispatch(handleTicketApproval({
                data: {
                    id: existingTicketData._id,
                    status: ['superAdmin', 'technicalAdmin', 'coreAdmin'].includes(sessionUser.role) ? 'superAdminDeclined' : 'customerAdminDeclined',
                    declineReason: declineReason
                }
            }));
        }
    }

    useEffect(() => {
        if (existingTicketData?.handleApprovalApiSuccess) {
            toast.success(`Ticket status changed to ${existingTicketData?.status}.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({
                type: 'ticketManagement/updateFieldValues',
                payload: {
                    state: 'existingTicket',
                    field: 'isSuccessInTicketCreate',
                    value: false,
                },
            });
            setDeclineModalOpen(false)

        }
    }, [existingTicketData?.handleApprovalApiSuccess])

    useEffect(() => {
        if (existingTicketData?.company?.value) {
            getSelectedCompaniesLocations();
        }
    }, [existingTicketData?.company?.value]);

    const getSelectedCompaniesLocations = async () => {
        try {
            const locationsList = await getCompanyLocations(existingTicketData.company?.value);
            setCompanyLocationsList(locationsList?.data?.map((item) => ({ value: item.id, label: item.locationName })) || []);
        } catch (error) {
            setCompanyLocationsList([]);
            console.error(`Failed to get locations: ${error.message}`);
        }
    }


    return (
        <Dialog
            open={true}
            fullScreen={isMobile}
            fullWidth
            maxWidth={isMobile ? 'md' : 'lg'}
        >
            <div className="backdrop" />
            <div className="dialog-wrapper">
                <Paper className="dialog-paper">
                    <DialogTitle
                        className={'fixed-header'}
                        sx={{
                            backgroundColor: '#244fac',
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'sticky',
                            top: 0
                        }}
                    >
                        {existingTicketData.isLoading ? (
                            <Skeleton variant="text" width={150} /> // Adjust the width as needed
                        ) : (
                            <>
                                <strong>{`Edit Ticket: ${existingTicketData.customTicketId ?? ""}`}</strong>
                            </>
                        )}
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                dispatch({ type: 'ticketManagement/handleTicketModals', payload: { modalType: 'existingTicketModalOpen' } });
                                dispatch({ type: 'ticketManagement/resetState', payload: { field: 'existingTicket' } })
                            }}
                            aria-label="close"
                            sx={{
                                transition: 'background-color 0.3s, color 0.3s',
                                '&:hover': {
                                    color: 'grey',
                                    backgroundColor: '#fff',
                                },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    {/* <Divider /> */}
                    <DialogContent className="dialog-content custom-scrollbar" style={dialogContentStyle}>
                        <div>
                            <Grid container spacing={2}>
                                {
                                    !existingTicketData.isLoading && (
                                        <>
                                            {/* Helper Text Notifiying Edit Priviliage */}
                                            {sessionUser.role !== 'superAdmin' && (
                                                <Grid item xs={12}>
                                                    <FormHelperText>
                                                        ** It appears that you have limited access to modify this ticket. Editing and deleting privileges are reserved for Super Admin Users.
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                            {/* TICKET HEADING */}
                                            <Grid item xs={12}>
                                                <InputLabel sx={{ marginTop: '10px', }} htmlFor="ticketHead">Ticket Heading</InputLabel>
                                                <div
                                                    className={(!existingTicketData.ticketHead || existingTicketData.ticketHead === "") ? 'ticket-head-div ticket-head-no-input-error' : "ticket-head-div"}
                                                    name="ticketHead"
                                                    contentEditable={(sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin')}
                                                    aria-required
                                                    required={true}
                                                    onFocus={(e) => {
                                                        const div = e.target;

                                                        // Create a range and set it to the end of the content
                                                        const range = document.createRange();
                                                        range.selectNodeContents(div);
                                                        range.collapse(false); // Set the cursor at the end

                                                        // Select the range to set the cursor position
                                                        const selection = window.getSelection();
                                                        selection.removeAllRanges();
                                                        selection.addRange(range);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            document.execCommand('insertHTML', false, '<br>');
                                                            e.target.blur(); // Blur the contentEditable div
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        const div = e.target;
                                                        const text = div.textContent;

                                                        // Update your state or dispatch action here when the input is blurred
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'ticketHead',
                                                                value: text,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    {existingTicketData.ticketHead}
                                                </div>
                                            </Grid>

                                            {/* TICKET DESCRIPTION */}
                                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                                <InputLabel htmlFor="ticketDescription">Ticket Description</InputLabel>
                                                <div
                                                    className="ticket-description-div"
                                                    name="ticketHead"
                                                    contentEditable={sessionUser.userType === 'Admin'}
                                                    aria-required
                                                    required={true}
                                                    onFocus={(e) => {
                                                        const div = e.target;

                                                        // Create a range and set it to the end of the content
                                                        const range = document.createRange();
                                                        range.selectNodeContents(div);
                                                        range.collapse(false); // Set the cursor at the end

                                                        // Select the range to set the cursor position
                                                        const selection = window.getSelection();
                                                        selection.removeAllRanges();
                                                        selection.addRange(range);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            document.execCommand('insertHTML', false, '<br>');
                                                            e.target.blur(); // Blur the contentEditable div
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        const div = e.target;
                                                        const text = div.textContent;

                                                        // Update your state or dispatch action here when the input is blurred
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'ticketDescription',
                                                                value: text,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    {existingTicketData.ticketDescription}
                                                </div>
                                            </Grid>

                                            {/* COMPANY */}
                                            <Grid item xs={isMobile ? 12 : 6} >
                                                <Autocomplete
                                                    label="Company"
                                                    name='company'
                                                    value={existingTicketData.company}
                                                    options={[existingTicketData.company]}
                                                    onChange={(e) => { }}
                                                    required={true}
                                                    error={!existingTicketData.company}
                                                // disabled={sessionUser.role !== 'superAdmin'}
                                                />
                                            </Grid>

                                            {/* COMPANY LOCATION */}
                                            {((sessionUser.role === 'coreAdmin' || sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin') || (existingTicketData.companyLocation && existingTicketData.companyLocation !== "")) &&
                                                <Grid item xs={isMobile ? 12 : 6}>
                                                    <Autocomplete
                                                        label="Company Location"
                                                        name='companyLocation'
                                                        value={existingTicketData.companyLocation}
                                                        onChange={(e) => {
                                                            dispatch({
                                                                type: 'ticketManagement/updateFieldValues',
                                                                payload: {
                                                                    state: 'existingTicket',
                                                                    field: 'companyLocation',
                                                                    value: e.target.value,
                                                                },
                                                            });
                                                        }}
                                                        options={companyLocationsList}
                                                        required={false}
                                                    // error={(!data.company && !data.userType === 'Admin')}
                                                    />
                                                </Grid>
                                            }

                                            <Grid item xs={isMobile ? 12 : 6}>
                                                <LabelSelector
                                                    value={existingTicketData.labels}
                                                    onChange={(newValue) => {
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'labels',
                                                                value: newValue,
                                                            },
                                                        });
                                                    }}
                                                    disableFlag={(sessionUser.role !== 'superAdmin' && sessionUser.role !== 'technicalAdmin' && sessionUser.role !== 'technician' && sessionUser.role !== 'coreAdmin' && sessionUser.role !== 'supportAgent')}
                                                />
                                            </Grid>

                                            {/* TICKET STATUS */}
                                            <Grid item xs={isMobile ? 12 : 6}>
                                                <TicketStatusSelector
                                                    name={'status'}
                                                    label="Status"
                                                    value={existingTicketData.status}
                                                    onChange={(e) => {
                                                        if (sessionUser.userType === 'Admin') {
                                                            dispatch({
                                                                type: 'ticketManagement/updateFieldValues',
                                                                payload: {
                                                                    state: 'existingTicket',
                                                                    field: 'status',
                                                                    value: e.target.value,
                                                                },
                                                            });
                                                        } else {
                                                            return
                                                        }
                                                    }}
                                                    disableFlag={((sessionUser.role !== 'superAdmin' && sessionUser.role !== 'technicalAdmin' && sessionUser.role !== 'technician' && sessionUser.role !== 'coreAdmin' && sessionUser.role !== 'supportAgent') || existingTicketData.approvalRequireFlag)}
                                                />
                                                {
                                                    existingTicketData.approvalRequireFlag && (
                                                        <FormHelperText>
                                                            ** This ticket requires Admin review and approval.
                                                        </FormHelperText>
                                                    )
                                                }
                                            </Grid>

                                            {/* APPROVAL REASON TEXT INPUT */}
                                            {((existingTicketData.status === 'reqCustomerAdminApproval' && ['coreAdmin', 'superAdmin', 'customerAdmin', 'technicalAdmin', 'technician', 'supportAgent'].includes(sessionUser.role)) || (existingTicketData.status === 'reqSuperAdminApproval' && ['coreAdmin', 'superAdmin', 'technicalAdmin', 'technician', 'supportAgent'].includes(sessionUser.role))) && (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="admins-approval-reason"
                                                        label="Approval Reason"
                                                        fullWidth
                                                        multiline
                                                        minRows={2}
                                                        maxRows={4}
                                                        required
                                                        value={existingTicketData.approvalReason}
                                                        onChange={(e) => {
                                                            if (sessionUser.userType === 'Admin') {
                                                                dispatch({
                                                                    type: 'ticketManagement/updateFieldValues',
                                                                    payload: {
                                                                        state: 'existingTicket',
                                                                        field: 'approvalReason',
                                                                        value: e.target.value,
                                                                    },
                                                                });
                                                            } else {
                                                                return;
                                                            }
                                                        }}
                                                        error={((existingTicketData.status === 'reqCustomerAdminApproval') || (existingTicketData.status === 'reqSuperAdminApproval')) && !existingTicketData.approvalReason}
                                                    />
                                                </Grid>
                                            )}

                                            {/* DUE DATE */}
                                            <Grid item xs={isMobile ? 12 : 6} style={{ display: 'flex', alignItems: 'center' }}>
                                                <MUIDatePicker
                                                    label="Due Date"
                                                    value={isNaN(Date.parse(existingTicketData.dueDate)) ? null : new Date(existingTicketData.dueDate)}
                                                    onChange={(e) => {
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'dueDate',
                                                                value: e,
                                                            },
                                                        });
                                                    }}
                                                    onClear={(() => {
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'dueDate',
                                                                value: null,
                                                            },
                                                        });
                                                    })}
                                                    disableFlag={(sessionUser.role !== 'coreAdmin' && sessionUser.role !== 'superAdmin' && sessionUser.role !== 'technicalAdmin' && sessionUser.role !== 'technician')}
                                                    minDate={Date.now()}
                                                    timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                                />
                                            </Grid>

                                            {/* TICKET PRIORITY */}
                                            <Grid item xs={isMobile ? 12 : 6} >
                                                <TicketPrioritySelector
                                                    name={'priority'}
                                                    required={true}
                                                    label="Priority"
                                                    value={existingTicketData.priority}
                                                    disableFlag={(sessionUser.role !== 'coreAdmin' && sessionUser.role !== 'superAdmin' && sessionUser.role !== 'technicalAdmin' && sessionUser.role !== 'technician')}
                                                    onChange={(e) => {
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'priority',
                                                                value: e.target.value,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </Grid>


                                            {/* PAYMENT STATUS */}
                                            {
                                                (sessionUser.role === 'coreAdmin' || sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin' || sessionUser.role === 'technician' || sessionUser.role === 'supportAgent') && (
                                                    <>
                                                        <Grid item xs={isMobile ? 12 : 6}>
                                                            <SelectInput
                                                                label={'Payment Status'}
                                                                value={existingTicketData.paymentStatus}
                                                                required={true}
                                                                onChange={(e) => {
                                                                    dispatch({
                                                                        type: 'ticketManagement/updateFieldValues',
                                                                        payload: {
                                                                            state: 'existingTicket',
                                                                            field: 'paymentStatus',
                                                                            value: e.target.value,
                                                                        },
                                                                    });
                                                                }}
                                                                options={[{ key: 'required', name: 'Payment Required' }, { key: 'notRequired', name: 'Payment Not Required' }, { key: 'paid', name: 'Paid' }, { key: 'paymentPending', name: 'Payment Pending' }]}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            }

                                            <Grid item xs={isMobile ? 12 : 6}>
                                                <AssetController
                                                    onChange={(newValue) => {
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'assets',
                                                                value: newValue,
                                                            },
                                                        });
                                                    }}
                                                    selectedAssets={existingTicketData.assets}
                                                    company={existingTicketData?.company?.value}
                                                    location={existingTicketData?.companyLocation?.value || ""}
                                                />
                                            </Grid>
                                            {existingTicketData.assets.length > 0 && <Grid xs={12} container spacing={1} sx={{ marginTop: '10px', padding: "2rem" }}>
                                                {existingTicketData.assets?.map(asset =>
                                                    <Grid item key={asset.value}>
                                                        <Chip
                                                            label={`${asset.label} - ${asset.contractType}`}
                                                            sx={{ borderRadius: '8px', marginRight: '2px', fontWeight: 500, fontSize: "1.1rem", textTransform: "uppercase" }}
                                                            size={isMobile ? "small" : "medium"}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>}


                                            {/* ASSIGNED USERS */}
                                            <Grid item xs={12}>
                                                <MultipleUserSelector
                                                    label='Assigned Technicians'
                                                    value={existingTicketData.assignedUsers}
                                                    disableFlag={(sessionUser.role !== 'coreAdmin' && sessionUser.role !== 'superAdmin' && sessionUser.role !== 'technicalAdmin' && sessionUser.role !== 'technician' && sessionUser.role !== 'supportAgent')}
                                                    required={true}
                                                    error={!existingTicketData.assignedUsers?.length}
                                                    onChange={(newValue) => {
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'assignedUsers',
                                                                value: newValue,
                                                            },
                                                        });
                                                    }}
                                                    companyId={existingTicketData?.company?.value ?? ''}
                                                />
                                            </Grid>

                                            {/* REPORTED USERS */}
                                            <Grid item xs={12}>
                                                <MultipleUserSelector
                                                    label={'Reported Users'}
                                                    value={existingTicketData.reportedUsers}
                                                    disableFlag={(sessionUser.role !== 'coreAdmin' && sessionUser.role !== 'superAdmin' && sessionUser.role !== 'technicalAdmin' && sessionUser.role !== 'technician' && sessionUser.role !== 'technician' && sessionUser.role !== 'supportAgent')}
                                                    required={true}
                                                    error={!existingTicketData.reportedUsers?.length}
                                                    onChange={(newValue) => {
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'reportedUsers',
                                                                value: newValue,
                                                            },
                                                        });
                                                    }}
                                                    companyId={existingTicketData?.company?.value ?? ''}
                                                />
                                            </Grid>

                                            {/* FILE UPLOADS || ATTACHMENTS */}
                                            <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                                                <FileUploader
                                                    onFileUploadSuccess={saveUploadedFilesInRedux}
                                                    onDeleteFile={removeUploadedFileFromRedux}
                                                    destinationParentFolder='tickets'
                                                    destinationSubFolder={existingTicketData._id}
                                                    savedFiles={existingTicketData.uploads}
                                                    disabled={false}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    marginBottom: '20px',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    justifyContent: { xs: 'flex-start', md: 'center' },
                                                }}
                                            >
                                                <Chip
                                                    label={`Created At: ${new Date(existingTicketData.createdAt).toLocaleString()}`}
                                                    color="primary"
                                                    sx={{ marginRight: '10px', marginBottom: '10px' }}
                                                />
                                                <Chip
                                                    label={`Created By: ${existingTicketData.createdBy?.displayName}`}
                                                    avatar={<Avatar>{existingTicketData.createdBy?.displayName?.charAt(0)}</Avatar>}
                                                    sx={{ marginRight: '10px', marginBottom: '10px' }}
                                                />
                                                <Chip
                                                    label={`Updated At: ${new Date(existingTicketData?.updatedAt).toLocaleString()}`}
                                                    color="secondary"
                                                    sx={{ marginRight: '10px', marginBottom: '10px' }}
                                                />
                                                <Chip
                                                    label={`Updated By: ${existingTicketData.updatedBy?.displayName}`}
                                                    avatar={<Avatar>{existingTicketData.updatedBy?.displayName?.charAt(0)}</Avatar>}
                                                    sx={{ marginBottom: '10px' }}
                                                />
                                            </Grid>

                                            {/* STATUS CHANGES TIMELINE */}
                                            <Grid item xs={12}>
                                                <StyledAccordion
                                                    expanded={expanded}
                                                    onChange={handleAccordionChange}
                                                >
                                                    <StyledAccordionSummary
                                                        expandIcon={<ExpandMoreIcon style={{ marginRight: '15px', color: '#fff' }} />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Box display="flex" justifyContent="center" width="100%">
                                                            <Typography variant={'h6'}>Status Timeline</Typography>
                                                        </Box>
                                                    </StyledAccordionSummary>
                                                    <StyledAccordionDetails>
                                                        {
                                                            existingTicketData?._id && expanded &&
                                                            <StatusChangesTimeline ticketId={existingTicketData?._id} isExpanded={expanded} />
                                                        }
                                                    </StyledAccordionDetails>
                                                </StyledAccordion>
                                            </Grid>

                                            {/* COMMENTS & ACTIVITIES SECTION */}
                                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                                                <Grid container spacing={0.5}>
                                                    <Grid item xs={isMobile ? 12 : 6}>
                                                        {/* CHIPS TO SWITCH B/W COMMENTS & ACTIVITIES */}
                                                        <Stack direction="row" spacing={1} >
                                                            {   // Render activities only the current session user is a superAdmin
                                                                (sessionUser.userType === 'Admin') && (
                                                                    <Chip label="Internal Comments" sx={{ borderRadius: '7px', backgroundColor: toggleCommentsAndActivities === 'Internal Comments' ? '#8EACCD' : '', fontWeight: 600 }} onClick={() => setToggleCommentsAndActivities('Internal Comments')} />
                                                                )
                                                            }
                                                            <Chip label="Customer Comments" sx={{ borderRadius: '7px', backgroundColor: toggleCommentsAndActivities === 'Customer Comments' ? '#8EACCD' : '', fontWeight: 600 }} onClick={() => setToggleCommentsAndActivities('Customer Comments')} />
                                                            {   // Render activities only the current session user is a superAdmin
                                                                (sessionUser.role === 'coreAdmin' || sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin' || sessionUser.role === 'technician') && (
                                                                    <Chip label="Activities" sx={{ borderRadius: '7px', backgroundColor: toggleCommentsAndActivities === 'activities' ? '#8EACCD' : '', fontWeight: 600 }} onClick={() => setToggleCommentsAndActivities('activities')} />
                                                                )
                                                            }
                                                        </Stack>
                                                    </Grid>

                                                    {toggleCommentsAndActivities === 'Internal Comments' && (
                                                        <>
                                                            {/* ADD COMMENT BOX */}
                                                            <Grid item xs={12}>
                                                                <Stack direction={'row'} spacing={1.5} sx={{ marginTop: '10px' }}>
                                                                    <Avatar
                                                                        alt="User Avatar"
                                                                        src={sessionUser.photoURL}
                                                                        style={{
                                                                            marginRight: '8px',
                                                                            marginTop: '4px',
                                                                            backgroundColor: sessionUser.photoURL
                                                                                ? 'transparent'
                                                                                : sessionUser.displayName
                                                                                    ? stringToColor(sessionUser.displayName)
                                                                                    : stringToColor(`${sessionUser.firstName} ${sessionUser.lastName}`),
                                                                        }}
                                                                    >
                                                                        {!sessionUser.photoURL && (sessionUser.displayName || sessionUser.firstName) ? (
                                                                            sessionUser.displayName
                                                                                ? sessionUser.displayName[0]
                                                                                : sessionUser.firstName[0]
                                                                        ) : null}
                                                                    </Avatar>

                                                                    {!existingTicketData.isCommentSubmitInProgress ? (
                                                                        <TextField
                                                                            id="new-comment-add-textbox"
                                                                            label="Add an Internal Comment"
                                                                            fullWidth
                                                                            multiline
                                                                            minRows={2}
                                                                            maxRows={10}
                                                                            value={existingTicketData.newComment}
                                                                            onChange={(e) => {
                                                                                dispatch({
                                                                                    type: 'ticketManagement/updateFieldValues',
                                                                                    payload: {
                                                                                        state: 'existingTicket',
                                                                                        field: 'newComment',
                                                                                        value: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment
                                                                                        position="end"
                                                                                        onClick={() =>
                                                                                            dispatch(
                                                                                                addInternalCommentsToTicket({
                                                                                                    ticketId: existingTicketData._id,
                                                                                                    commentString: existingTicketData.newComment,
                                                                                                })
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <IconButton>
                                                                                            <SendRoundedIcon />
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Skeleton
                                                                            variant="rect"
                                                                            sx={{ boarderRadius: `10px` }}
                                                                            width="100%"
                                                                            height="60px"
                                                                            component="div"
                                                                        />
                                                                    )}
                                                                </Stack>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {
                                                                    existingTicketData?.internalComments
                                                                        .slice()
                                                                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                                                        .map((comment, index) => (
                                                                            <Card
                                                                                key={`card-${index}`}
                                                                                sx={{
                                                                                    margin: 0.5,
                                                                                    backgroundColor: 'lightgray', // Customize the background color
                                                                                    border: '1px solid #ccc', // Add a border
                                                                                    borderRadius: '4px', // Add some border radius
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#d9d9d9', // Change background color on hover
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <CardHeader
                                                                                    avatar={
                                                                                        <Avatar
                                                                                            alt="Commented User"
                                                                                            src={comment?.user?.photoURL}
                                                                                            sx={{
                                                                                                marginRight: '8px',
                                                                                                marginTop: '4px',
                                                                                                backgroundColor: comment?.user?.photoURL
                                                                                                    ? 'transparent'
                                                                                                    : comment?.user?.displayName
                                                                                                        ? stringToColor(comment.user.displayName)
                                                                                                        : stringToColor(`${comment?.user?.firstName} ${comment?.user?.lastName}`),
                                                                                            }}
                                                                                        >
                                                                                            {!comment?.user?.photoURL && (comment?.user?.displayName || comment?.user?.firstName) ? (
                                                                                                comment?.user?.displayName
                                                                                                    ? comment?.user?.displayName[0]
                                                                                                    : comment?.user?.firstName[0]
                                                                                            ) : null}
                                                                                        </Avatar>
                                                                                    }
                                                                                    title={comment?.user?.displayName ?? `${comment?.user?.firstName} ${comment?.user?.lastName}`}
                                                                                    subheader={formatDate(comment.createdAt)} // Format the date and time
                                                                                />
                                                                                <CardContent sx={{ paddingTop: '0' }}> {/* Reduce the top padding */}
                                                                                    <Typography variant="body1">
                                                                                        {comment.comment}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        ))}
                                                            </Grid>

                                                        </>
                                                    )}


                                                    {toggleCommentsAndActivities === 'Customer Comments' && (
                                                        <>
                                                            {/* ADD COMMENT BOX */}
                                                            <Grid item xs={12}>
                                                                <Stack direction={'row'} spacing={1.5} sx={{ marginTop: '10px' }}>
                                                                    <Avatar
                                                                        alt="User Avatar"
                                                                        src={sessionUser.photoURL}
                                                                        style={{
                                                                            marginRight: '8px',
                                                                            marginTop: '4px',
                                                                            backgroundColor: sessionUser.photoURL
                                                                                ? 'transparent'
                                                                                : sessionUser.displayName
                                                                                    ? stringToColor(sessionUser.displayName)
                                                                                    : stringToColor(`${sessionUser.firstName} ${sessionUser.lastName}`),
                                                                        }}
                                                                    >
                                                                        {!sessionUser.photoURL && (sessionUser.displayName || sessionUser.firstName) ? (
                                                                            sessionUser.displayName
                                                                                ? sessionUser.displayName[0]
                                                                                : sessionUser.firstName[0]
                                                                        ) : null}
                                                                    </Avatar>

                                                                    {!existingTicketData.isCommentSubmitInProgress ? (
                                                                        <TextField
                                                                            id="new-comment-add-textbox"
                                                                            label="Add a Comment"
                                                                            fullWidth
                                                                            multiline
                                                                            minRows={2}
                                                                            maxRows={10}
                                                                            value={existingTicketData.newComment}
                                                                            onChange={(e) => {
                                                                                dispatch({
                                                                                    type: 'ticketManagement/updateFieldValues',
                                                                                    payload: {
                                                                                        state: 'existingTicket',
                                                                                        field: 'newComment',
                                                                                        value: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment
                                                                                        position="end"
                                                                                        onClick={() =>
                                                                                            dispatch(
                                                                                                addCommentToTicket({
                                                                                                    ticketId: existingTicketData._id,
                                                                                                    commentString: existingTicketData.newComment,
                                                                                                })
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <IconButton>
                                                                                            <SendRoundedIcon />
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Skeleton
                                                                            variant="rect"
                                                                            sx={{ boarderRadius: `10px` }}
                                                                            width="100%"
                                                                            height="60px"
                                                                            component="div"
                                                                        />
                                                                    )}
                                                                </Stack>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {
                                                                    existingTicketData?.comments
                                                                        .slice()
                                                                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                                                        .map((comment, index) => (
                                                                            <Card
                                                                                key={`card-${index}`}
                                                                                sx={{
                                                                                    margin: 0.5,
                                                                                    backgroundColor: 'lightgray', // Customize the background color
                                                                                    border: '1px solid #ccc', // Add a border
                                                                                    borderRadius: '4px', // Add some border radius
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#d9d9d9', // Change background color on hover
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <CardHeader
                                                                                    avatar={
                                                                                        <Avatar
                                                                                            alt="Commented User"
                                                                                            src={comment?.user?.photoURL}
                                                                                            sx={{
                                                                                                marginRight: '8px',
                                                                                                marginTop: '4px',
                                                                                                backgroundColor: comment?.user?.photoURL
                                                                                                    ? 'transparent'
                                                                                                    : comment?.user?.displayName
                                                                                                        ? stringToColor(comment.user.displayName)
                                                                                                        : stringToColor(`${comment?.user?.firstName} ${comment?.user?.lastName}`),
                                                                                            }}
                                                                                        >
                                                                                            {!comment?.user?.photoURL && (comment?.user?.displayName || comment?.user?.firstName) ? (
                                                                                                comment?.user?.displayName
                                                                                                    ? comment?.user?.displayName[0]
                                                                                                    : comment?.user?.firstName[0]
                                                                                            ) : null}
                                                                                        </Avatar>
                                                                                    }
                                                                                    title={comment?.user?.displayName ?? `${comment?.user?.firstName} ${comment?.user?.lastName}`}
                                                                                    subheader={formatDate(comment.createdAt)} // Format the date and time
                                                                                />
                                                                                <CardContent sx={{ paddingTop: '0' }}> {/* Reduce the top padding */}
                                                                                    <Typography variant="body1">
                                                                                        {comment.comment}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        ))}
                                                            </Grid>

                                                        </>
                                                    )}

                                                    {toggleCommentsAndActivities === 'activities' && (
                                                        <>
                                                            {/* Activities Section */}
                                                            <Grid item xs={12}>
                                                                <Box>
                                                                    {existingTicketData.activities?.length ? (
                                                                        existingTicketData.activities.map((log, index) => (
                                                                            <Paper
                                                                                key={`activity-${index}`}
                                                                                sx={{
                                                                                    p: 2,
                                                                                    m: 1,
                                                                                    backgroundColor: 'lightgray', // Customize the background color
                                                                                    border: '1px solid #ccc', // Add a border
                                                                                    borderRadius: '4px', // Add some border radius
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#d9d9d9', // Change background color on hover
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    {new Date(log.createdAt).toLocaleString()} {/* Format the date and time */}
                                                                                </Typography>
                                                                                <Typography variant="body1">{log.activityString}</Typography>
                                                                            </Paper>
                                                                        ))
                                                                    ) : (
                                                                        <Typography variant="body2" color="textSecondary">
                                                                            No activities to display.
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }

                                {
                                    existingTicketData.isLoading && (
                                        <TicketModalSkeleton />
                                    )
                                }
                            </Grid>
                            <DeclineDialog open={declineModaleOpen} handleClose={setDeclineModalOpen} handleDecline={handleAdminApproval} />
                        </div>
                    </DialogContent>
                    <Divider />


                    <DialogActions
                        className={isMobile ? 'fixed-footer' : ''}
                        sx={{
                            backgroundColor: '#f4f4f4',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            position: 'sticky',
                            bottom: 0
                        }}
                    >
                        <Box sx={{ marginRight: '30px', display: 'flex', alignItems: 'center' }}>
                            {!existingTicketData.approvalRequireFlag && sessionUser.userType === 'Admin' &&
                                <SaveButton
                                    onClick={handleTicketUpdateSubmission}
                                    disabled={existingTicketData.approvalRequireFlag || existingTicketData.isLoading}
                                />
                            }
                            {/* APPROVAL BUTTON */}
                            {existingTicketData.approvalRequireFlag &&
                                ((existingTicketData.status === 'reqCustomerAdminApproval' && (sessionUser.role === 'customerAdmin' || sessionUser.userType === 'Admin'))
                                    || existingTicketData.status === 'reqSuperAdminApproval' && (sessionUser.role === 'coreAdmin' || sessionUser.role === 'superAdmin' || sessionUser.role === 'technicalAdmin')) && (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {
                                            existingTicketData.approvalRequireFlag && ['coreAdmin', 'superAdmin'].includes(sessionUser.role) && (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    disabled={existingTicketData.isLoading}
                                                    onClick={() => {
                                                        dispatch({
                                                            type: 'ticketManagement/updateFieldValues',
                                                            payload: {
                                                                state: 'existingTicket',
                                                                field: 'approvalRequireFlag',
                                                                value: false,
                                                            },
                                                        });
                                                    }}
                                                    sx={{ marginRight: '10px' }}
                                                >
                                                    Revert Approval Request
                                                </Button>
                                            )
                                        }
                                        {
                                            existingTicketData.approvalRequireFlag &&
                                            ((['coreAdmin', 'superAdmin'].includes(sessionUser.role) && existingTicketData.status === 'reqSuperAdminApproval') ||
                                                (['customerAdmin'].includes(sessionUser.role) && existingTicketData.status === 'reqCustomerAdminApproval')) &&
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    disabled={existingTicketData.isLoading}
                                                    onClick={() => { handleAdminApproval('declined-without-reason') }}
                                                    sx={{ marginRight: '10px' }}
                                                >
                                                    Decline
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={existingTicketData.isLoading}
                                                    onClick={() => { handleAdminApproval('approved') }}
                                                >
                                                    Approve
                                                </Button>
                                            </>
                                        }
                                    </Box>
                                )}
                        </Box>
                    </DialogActions>


                </Paper>
            </div>
        </Dialog >
    );
};

export default ExistingTicketView;