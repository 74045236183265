import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    data: {
        _id: null,
        userType: "",
        customUserId: "",
        role: "",
        authProvider: "",
        displayName: "",
        firstName: "",
        lastName: "",
        contactPhoneNumber: "",
        email: "",
        preferredContactMethod: "",
        photoURL: "",
        company: {},
        permissions: [],
        createdAt: "",
        isTokenVerified: false,
        franchise:{
            country:"",
            area:""
        }
    },
    setting: {
        notificationSound: true,
        showCustomerChatWindow: false,
        isInChatSupportMode: false
    },
    /*****************************************************/
    /***********CUSTOMER CHAT STATES**********************/
    chatStates: {
        lastMessages: []
    }
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateUserFromAuth: (state, action) => {
            const user = action.payload;
            state.data = { ...state.data, isTokenVerified: true, ...user };
        },
        setShowCustomerChatWindow: (state, action) => {
            state.setting.showCustomerChatWindow = !state.setting.showCustomerChatWindow
        },
        // Accepts the name of the state, the field, and the value to handle value change in tickets add or edit modal
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },
        resetUserState: (state) => initialState,
        updateChangeFranchise:(state , actions) => {
            if(state.data.role === 'coreAdmin'){
                state.data.franchise = actions.payload
            }
        }
    }
});

export const { updateUserFromAuth, setShowCustomerChatWindow , updateChangeFranchise } = userSlice.actions;
export default userSlice.reducer