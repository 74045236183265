// Libraries and modules
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Components from external libraries
import { Grid, Card, CardContent, Avatar, IconButton, TextField, MenuItem, Skeleton, Divider, InputLabel, Typography, Box, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";

// Your own components
import { CreateButton, DeleteButton, ResetButton, RevertButton } from '../../components/commons/Buttons';
import CustomDialogueMedium from '../../components/commons/CustomDialogueMedium';
import SelectInput from '../../components/commons/SelectInput';
import TextInput from '../../components/commons/TextInput';
import Autocomplete from '../../components/commons/Autocomplete';
import PhoneInputCustom from '../../components/commons/PhoneInput';
import Android12SwitchComponent from '../../components/commons/Switch';

// Utilities, constants, and other stuff
import { generateCompanyCode, isValidEmail, isValidPassword, stringToColor } from '../../utilities/commonUtils';
import { companyTypes } from '../../constants/commonConstants';
import { companyCreateOrEditValidation } from './CompanyInputValidations';
import { createCompany, editCompany, getCompany } from '../../app/Company/CompanySlice';

// CSS files
import "./Company.css";
import CustomDialogueSmall from '../../components/commons/CustomDialogueSmall';
import { superAdminCompanyExist } from '../../app/Ticket/Service';
import { updateCurrentFranchise } from '../../app/Franchise/FranchiseSlice';
import { getAsset } from '../../app/Asset/AssetSlice';


function CreateCompany() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const data = useSelector((state) => state.companyManagement.createOrEditData);
    const isModalOpen = useSelector((state) => state.companyManagement.isCreateOrEditModalOpen);
    const modalMode = useSelector((state) => state.companyManagement.modalMode);
    const isError = useSelector((state) => state.companyManagement.isError);
    const isLoading = useSelector((state) => state.companyManagement.isLoading);
    const errorMessage = useSelector((state) => state.companyManagement.errorMessage);
    const isSuccess = useSelector((state) => state.companyManagement.isSuccess);
    const franchiseManagement = useSelector((state) => state.franchiseMangement);
    const [originalEditItem, setOriginalEditItem] = useState(null);

    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [itemToDeleteAfterConfirm, setItemToDeleteAfterConfirm] = useState(null);
    const sessionUser = useSelector((state) => state.userData.data)
    const [superCompanyExist, setSuperAdminCompanyExist] = useState(false)
    const [formError, setFormError] = useState({
        companyName: false,
        trnNumber: false,
        companyEmail: false,
        address: {
            streetLine1: false,
            city: false,
            postalCode: false,
            stateProvinceRegion: false,
            country: false,
        },
        locationInput: {
            locationName: false,
            address: {
                streetLine1: false,
                city: false,
                postalCode: false,
                stateProvinceRegion: false,
                country: false,
            }
        },
    });

    useEffect(() => {
        if (data?.address?.streetLine1 && (!data?.address?.city || !data?.address?.country)) {
            setFormError(prevState => ({
                ...prevState,
                address: {
                    ...prevState.address,
                    city: !data?.address?.city,
                    country: !data?.address?.country
                }
            }));
        } else if ((data?.address?.city || data?.address?.country) && !data?.address?.streetLine1) {
            setFormError(prevState => ({
                ...prevState,
                address: {
                    ...prevState.address,
                    streetLine1: true
                }
            }));
        } else {
            setFormError(prevState => ({
                ...prevState,
                address: {
                    ...prevState.address,
                    streetLine1: false,
                    city: false,
                    country: false
                }
            }));
        }
    }, [data.address]);

    useEffect(() => {
        if (data?.locationInput?.address?.streetLine1 && (!data?.locationInput?.address?.city || !data?.locationInput?.address?.country)) {
            setFormError(prevState => ({
                ...prevState,
                locationInput: {
                    ...prevState.locationInput,
                    address: {
                        ...prevState.locationInput.address,
                        city: !data?.locationInput?.address?.city,
                        country: !data?.locationInput?.address?.country
                    }
                }
            }));
        } else if ((data?.locationInput?.address?.city || data?.locationInput?.address?.country) && !data?.locationInput?.address?.streetLine1) {
            setFormError(prevState => ({
                ...prevState,
                locationInput: {
                    ...prevState.locationInput,
                    address: {
                        ...prevState.locationInput.address,
                        streetLine1: true
                    }
                }
            }));
        } else if (data?.locationInput?.address?.streetLine1 && data?.locationInput?.address?.city && data?.locationInput?.address?.country && !data?.locationInput?.locationName) {
            setFormError(prevState => ({
                ...prevState,
                locationInput: {
                    ...prevState.locationInput,
                    locationName: true
                }
            }));
        } else {
            setFormError(prevState => ({
                ...prevState,
                locationInput: {
                    ...prevState.locationInput,
                    address: {
                        ...prevState.locationInput.address,
                        streetLine1: false,
                        city: false,
                        country: false
                    },
                    locationName: false
                }
            }));
        }
    }, [data.locationInput.address, data.locationInput.locationName]);

    useEffect(() => {
        if (isSuccess) {
            toast.success(modalMode === 'create' ? 'Company created!' : 'Changes saved!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'isSuccess', value: false, } });
        }
        if (isError) {
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'isError', value: false, } });
        }
    }, [isSuccess, isError])

    function addOrEditLocation() {
        if (!data?.locationInput?.locationName || !data?.locationInput?.address?.streetLine1
            || !data?.locationInput?.address?.city || !data?.locationInput?.address?.country
        ) {
            toast.warning('Please fill all the mandatory fields.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            return;
        }

        const duplicateFlag = data.locations.find((item) => item.locationName === data.locationInput.locationName);
        if (duplicateFlag) {
            toast.error('Location already exists with the given location name.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            return;
        }

        dispatch({
            type: 'companyManagement/updateFieldValues',
            payload: {
                state: 'createOrEditData',
                field: 'locations',
                value: [...data.locations, { id: data?.locationInput?.id ?? `New-${uuidv4()}`, locationName: data?.locationInput?.locationName, address: data?.locationInput?.address }],
            }
        });

        dispatch({
            type: 'companyManagement/updateFieldValues',
            payload: {
                state: 'createOrEditData',
                field: 'locationInput',
                value: {
                    locationName: '',
                    address: {
                        streetLine1: '',
                        city: '',
                        postalCode: '',
                        stateProvinceRegion: '',
                        country: '',
                    }
                },
            }
        });
    }


    const handleLocationEditBtnClick = (id) => {
        const itemToEdit = data?.locations?.find(item => item.id === id);
        dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locationInput', value: { id: itemToEdit.id, locationName: itemToEdit.locationName, address: { streetLine1: itemToEdit?.address?.streetLine1, city: itemToEdit?.address?.city, postalCode: itemToEdit?.address?.postalCode, stateProvinceRegion: itemToEdit?.address?.stateProvinceRegion, country: itemToEdit?.address?.country } }, }, })
        dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locations', value: [...data.locations?.filter(item => item?.id !== id)], } });
        setOriginalEditItem(itemToEdit);
        toast.info('Location Added for edit.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            style: { width: '400px' },
        });
    }

    const handleLocationDelete = (id) => {
        dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locations', value: [...data.locations?.filter(item => item?.id !== id)], } });
        toast.info('Location has been removed from the list.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            style: { width: '400px' },
        });
        if (!id.startsWith('New')) {
            dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locationsDeleted', value: data?.locationsDeleted?.length ? [...data.locationsDeleted, id] : [id] } });
        }
        setItemToDeleteAfterConfirm(null);
        setDeleteConfirmModal(false);
    }

    function checkEmptyValues(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object') {
                if (!checkEmptyValues(obj[key])) {
                    return false;
                }
            } else if (obj[key] !== '') {
                return false;
            }
        }
        return true;
    }

    const handleSubmission = () => {
        // Validations
        const errors = companyCreateOrEditValidation(data);
        if (errors.length > 0) {
            toast.error(
                <div>
                    {errors.map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    style: { width: '400px' },
                }
            );
            return;
        }
        if (!checkEmptyValues(data.locationInput)) {
            toast.error('Unsaved location data detected. Please save or clear it before proceeding.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: '400px' },
            });
            return;
        }
        if (modalMode === 'create') {
            dispatch(createCompany(data));
        } else {
            dispatch(editCompany(data));
        }
    }

    const handleModalClose = () => {
        dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'mainState', field: 'isCreateOrEditModalOpen', value: false, } });
        if (modalMode === 'update') {
            // When user closes the modal of an edit view then reset the edit data
            dispatch({ type: 'companyManagement/resetStateField', payload: { state: 'mainState', field: 'createOrEditData' } });
            dispatch({ type: 'companyManagement/resetStateField', payload: { state: 'mainState', field: 'modalMode' } });
        }
    }

    const revertEditLocationToOriginalState = () => {
        if (!originalEditItem?.id) {
            return;
        }
        dispatch({
            type: 'companyManagement/updateFieldValues',
            payload: {
                state: 'createOrEditData',
                field: 'locations',
                value: [...data.locations, originalEditItem],
            }
        });

        dispatch({
            type: 'companyManagement/updateFieldValues',
            payload: {
                state: 'createOrEditData',
                field: 'locationInput',
                value: {
                    locationName: '',
                    address: {
                        streetLine1: '',
                        city: '',
                        postalCode: '',
                        stateProvinceRegion: '',
                        country: '',
                    }
                },
            }
        });
        setOriginalEditItem(null);
        toast.info('Location reverted back to the list.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            style: { width: '400px' },
        });
    }

    const handleStatusChange = () => {
        dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'isActive', value: data.isActive ? false : true, } })
    };


    const footerButtons = (
        <>
            <ResetButton
                style={{ margingRight: '5px' }}
                onClick={() => {
                    if (modalMode === 'create') dispatch({ type: 'companyManagement/resetStateField', payload: { state: 'mainState', field: 'createOrEditData' } });
                    else dispatch(getCompany(data._id));
                }}
            />
            <CreateButton text={modalMode === 'update' ? "Save" : "Create"} onClick={() => { handleSubmission() }} disabled={isLoading} />
        </>
    );

    return (
        <div>
            <CustomDialogueMedium
                open={isModalOpen}
                onClose={() => { handleModalClose() }}
                headerText={modalMode !== 'update' ? "Create Company" : `Edit Company Details`}
                footerButtons={footerButtons}
                isLarge={!isMobile}
            >
                {
                    !isLoading &&
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Basic Details</Typography>
                            <Divider sx={{ height: '2px' }} />
                        </Grid>
                        {/* Company Id */}
                        <Grid item xs={4}>
                            <TextInput
                                label="Company Id"
                                name={'companyId'}
                                value={data.id}
                                disabled={true}
                            />

                        </Grid>
                        {/* Company Code */}
                        <Grid item xs={4}>
                            <TextInput
                                label="Company Code"
                                name={'companyCode'}
                                value={data.id === '' ? generateCompanyCode(data.companyName) : data.companyCode}
                                disabled={true}
                            />
                        </Grid>

                        {/* Company Type */}
                        <Grid item xs={4}>
                            <SelectInput
                                required={true}
                                name={'type'}
                                label="Company Type"
                                value={data.type}
                                options={
                                    ((modalMode === "update" && data.type === "superAdmin") || !franchiseManagement.isAdminCompanyExist) ?
                                        [{ key: 'superAdmin', name: 'superAdmin' }] :
                                        [...companyTypes]
                                }
                                onChange={(e) => {
                                    dispatch({
                                        type: 'companyManagement/updateFieldValues',
                                        payload: {
                                            state: 'createOrEditData',
                                            field: 'type',
                                            value: e.target.value,
                                        },
                                    })
                                }}
                            />
                        </Grid>

                        {/* Company Name */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <TextInput
                                label="Company Name"
                                name={'companyName'}
                                value={data.companyName}
                                onChange={(e) => {
                                    dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'companyName', value: e.target.value, }, })
                                    setFormError({ ...formError, companyName: e.target.value === '' })
                                }}
                                error={formError.companyName}
                                required={true}
                            />
                        </Grid>

                        {/* TRN Number */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <TextInput
                                label="TRN / Tax No."
                                name={'trnNumber'}
                                value={data.trnNumber}
                                onChange={(e) => {
                                    dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'trnNumber', value: e.target.value, }, })
                                    setFormError({ ...formError, trnNumber: (e.target.value?.length > 1 && e.target.value?.length < 10) })
                                }}
                                error={formError.trnNumber}

                                type={'number'}
                                maxLength={10}
                            />
                        </Grid>

                        <Grid item xs={isMobile ? 12 : 6}>
                            <PhoneInputCustom
                                label="Phone number"
                                value={data.companyPhone}
                                onChange={(value) => dispatch({
                                    type: 'companyManagement/updateFieldValues',
                                    payload: {
                                        state: 'createOrEditData',
                                        field: 'companyPhone',
                                        value: value,
                                    },
                                })}
                            />
                        </Grid>

                        {/* Company Email */}
                        <Grid item xs={isMobile ? 12 : 6}>
                            <TextInput
                                label="Email"
                                name={'companyEmail'}
                                value={data.companyEmail}
                                onChange={(e) => {
                                    dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'companyEmail', value: e.target.value, }, })
                                    setFormError({ ...formError, companyEmail: (e.target.value?.length >= 1 && !isValidEmail(e.target.value)) })
                                }}
                                error={formError.companyEmail}
                                required={false}
                            />
                        </Grid>

                        {/* Company Status */}

                        {modalMode === 'update' &&
                            <Grid container direction={'row'} item alignItems={'center'}>
                                <Android12SwitchComponent
                                    value={data.isActive}
                                    onChange={handleStatusChange} />
                                {data.isActive ?
                                    (<Typography>Active</Typography>) : (<Typography>Inactive</Typography>)}
                            </Grid>
                        }



                        {/* Company Address */}
                        <Grid item xs={12}>
                            <Typography variant="h6">Address Details</Typography>
                            <Divider sx={{ height: '2px' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {/* Street line 1 */}
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Street"
                                        name={'street'}
                                        value={data?.address?.streetLine1}
                                        onChange={(e) => {
                                            dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'address', value: { ...data?.address, streetLine1: e.target.value }, }, })
                                        }}
                                        error={formError.address.streetLine1}
                                        required={true}
                                    />
                                </Grid>

                                {/* City */}
                                <Grid item xs={isMobile ? 12 : 6}>
                                    <TextInput
                                        label="City"
                                        name={'city'}
                                        value={data?.address?.city}
                                        onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'address', value: { ...data?.address, city: e.target.value }, }, }) }}
                                        required={true}
                                        error={formError.address.city}
                                    />
                                </Grid>

                                {/* Province / Region */}
                                <Grid item xs={6}>
                                    <TextInput
                                        label="State / Province / Region"
                                        name={'stateProvinceRegion'}
                                        value={data?.address?.stateProvinceRegion}
                                        onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'address', value: { ...data?.address, stateProvinceRegion: e.target.value }, }, }) }}
                                        required={false}
                                    />
                                </Grid>


                                {/* Country */}
                                <Grid item xs={6}>
                                    <TextInput
                                        label="Country"
                                        name={'country'}
                                        value={data?.address?.country}
                                        onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'address', value: { ...data?.address, country: e.target.value }, }, }) }}
                                        required={true}
                                        error={formError.address.country}
                                    />
                                </Grid>

                                {/* Postal Code */}
                                <Grid item xs={6}>
                                    <TextInput
                                        label="Postal Code"
                                        name={'postalCode'}
                                        value={data?.address?.postalCode}
                                        onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'address', value: { ...data?.address, postalCode: e.target.value }, }, }) }}
                                        required={false}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {data?.locations?.map((location, index) => (
                                            <Grid item xs={isMobile ? 12 : 4} key={index}>
                                                <Card className="location-card">
                                                    <CardContent>
                                                        <Typography variant="h6">{location.locationName}</Typography>
                                                        <Typography variant="body2">Street: {location.address?.streetLine1}</Typography>
                                                        <Typography variant="body2">City: {location.address?.city}</Typography>
                                                        <Typography variant="body2">Postal Code: {location.address?.postalCode}</Typography>
                                                        <Typography variant="body2">State/Province/Region: {location.address?.stateProvinceRegion}</Typography>
                                                        <Typography variant="body2">Country: {location.address?.country}</Typography>
                                                    </CardContent>
                                                    <IconButton style={{ position: 'absolute', top: '8px', right: '8px' }} onClick={() => { handleLocationEditBtnClick(location.id) }}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        style={{ position: 'absolute', top: '8px', right: '48px' }}
                                                        onClick={() => {
                                                            setItemToDeleteAfterConfirm(location.id);
                                                            setDeleteConfirmModal(true);
                                                        }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant="h6">Additional Locations Input</Typography>
                                    <Divider sx={{ height: '2px' }} />
                                </Grid>

                                <Grid item xs={12} sx={{ padding: '5px' }}>
                                    <Grid container spacing={2} sx={{ padding: '15px', border: '1px solid #DEF1FF', borderRadius: '8px' }}>
                                        {/* Location name */}
                                        <Grid item xs={12}>
                                            <TextInput
                                                label="Location Name"
                                                name={'locationName'}
                                                value={data?.locationInput?.locationName}
                                                onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locationInput', value: { ...data.locationInput, locationName: e.target.value }, }, }) }}
                                                required={true}
                                                error={formError?.locationInput?.locationName}
                                            />
                                        </Grid>

                                        {/* Street line 1 */}
                                        <Grid item xs={12}>
                                            <TextInput
                                                label="Street"
                                                name={'street'}
                                                value={data?.locationInput?.address?.streetLine1}
                                                onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locationInput', value: { ...data.locationInput, address: { ...data.locationInput?.address, streetLine1: e.target.value } }, }, }) }}
                                                required={true}
                                                error={formError.locationInput.address.streetLine1}
                                            />
                                        </Grid>

                                        {/* City */}
                                        <Grid item xs={6}>
                                            <TextInput
                                                label="City"
                                                name={'city'}
                                                value={data?.locationInput?.address?.city}
                                                onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locationInput', value: { ...data.locationInput, address: { ...data.locationInput?.address, city: e.target.value } }, }, }) }}
                                                required={true}
                                                error={formError.locationInput.address.city}
                                            />
                                        </Grid>

                                        {/* Province / Region */}
                                        <Grid item xs={6}>
                                            <TextInput
                                                label="State / Province / Region"
                                                name={'stateProvinceRegion'}
                                                value={data?.locationInput?.address?.stateProvinceRegion}
                                                onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locationInput', value: { ...data.locationInput, address: { ...data.locationInput?.address, stateProvinceRegion: e.target.value } }, }, }) }}
                                                required={true}
                                            />
                                        </Grid>

                                        {/* Country */}
                                        <Grid item xs={6}>
                                            <TextInput
                                                label="Country"
                                                name={'country'}
                                                value={data?.locationInput?.address?.country}
                                                onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locationInput', value: { ...data.locationInput, address: { ...data.locationInput?.address, country: e.target.value } }, }, }) }}
                                                required={true}
                                                error={formError.locationInput.address.country}
                                            />
                                        </Grid>

                                        {/* Postal Code */}
                                        <Grid item xs={6}>
                                            <TextInput
                                                label="Postal Code"
                                                name={'postalCode'}
                                                value={data?.locationInput?.address?.postalCode}
                                                onChange={(e) => { dispatch({ type: 'companyManagement/updateFieldValues', payload: { state: 'createOrEditData', field: 'locationInput', value: { ...data.locationInput, address: { ...data.locationInput?.address, postalCode: e.target.value } }, }, }) }}
                                                required={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left' }}>
                                            {
                                                data?.locationInput?.id &&
                                                <RevertButton style={{ marginRight: '5px' }} text={"Revert"} onClick={() => { revertEditLocationToOriginalState() }} disabled={isLoading} />
                                            }
                                            <CreateButton text={data?.locationInput?.id ? "Save Edit" : "Add Location"} onClick={() => { addOrEditLocation() }} disabled={isLoading} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        {modalMode === 'update' && <>
                            {data.assets?.filter(val => val.status === "In").length > 0 && <Grid item xs={12}>
                                <Typography variant="h6">Asset In Company</Typography>
                                <Divider sx={{ height: '2px' }} />
                            </Grid>}

                            <Grid item display={"flex"} gap={"1rem"} flexWrap={"wrap"} xs={12}>
                                {data.assets?.filter(val => val.status === "In").map((assets) => (
                                    <Button onClick={()=> dispatch(getAsset(assets.assetId._id))} variant='outlined' >
                                        <Typography >  {assets.assetId.machineCode}  / {assets.assetId.assetName}</Typography>
                                    </Button>
                                ))}
                            </Grid>
                           {data.assets?.filter(val => val.status === "Out").length > 0 && <Grid item xs={12}>
                                <Typography variant="h6">Asset Removed From Company</Typography>
                                <Divider sx={{ height: '2px' }} />
                            </Grid>}
                            <Grid item display={"flex"} xs={12}>
                            {data.assets?.filter(val => val.status === "Out").map((assets) => (
                                    <Button onClick={()=> dispatch(getAsset(assets.assetId._id))} variant='outlined' display={"grid"} >
                                        <Typography >  {assets.assetId.machineCode}  / {assets.assetId.assetName}</Typography>

                                    </Button>
                            ))}
                            </Grid>
                        </>}
                    </Grid>
                }
                {
                    isLoading &&
                    <Grid container spacing={2}>
                        {[...Array(20)].map((item, index) => (
                            <Grid item xs={6} key={index}>
                                <Skeleton variant="rect" height={56} sx={{ boarderRadius: '8px' }} />
                            </Grid>
                        ))}
                    </Grid>
                }

                <CustomDialogueSmall
                    open={deleteConfirmModal}
                    onClose={() => {
                        setDeleteConfirmModal(false);
                        setItemToDeleteAfterConfirm(null);
                    }}
                    headerText={'Are you sure you want to delete this location?'}
                    footerButtons={
                        <>
                            <DeleteButton
                                onClick={() => {
                                    handleLocationDelete(itemToDeleteAfterConfirm);
                                }} />
                        </>
                    }
                />
            </CustomDialogueMedium>
        </div>
    )
}

export default CreateCompany;
