export const validateExportAsset = (formData) => {
  let errors = {};
  let hasError = false;

  if(formData.assetType !== "toner"){
    
   
     // healthStatus
    if (formData.healthStatus.trim() === "") {
      errors.healthStatus = "Health Status is required";
      hasError = true;
    }

    if (formData.serialNumber.trim() === "") {
      errors.serialNumber = "Serial Number is required";
      hasError = true;
    }
  }

  


  if (formData.assetType.trim() === "") {
    errors.assetType = "Asset Type is required";
    hasError = true;
  }
 
  if (formData.origin.trim() === "") {
    errors.origin = "Origin is required";
    hasError = true;
  }

  if (formData.machineName.trim() === "") {
    errors.machineName = "Machine Name is required";
    hasError = true;
  }

  if (formData.machineModel.trim() === "") {
    errors.machineModel = "Machine Model is required";
    hasError = true;
  }

 

  if (
    formData.costPrice.trim() === "" ||
    isNaN(parseFloat(formData.costPrice))
  ) {
    errors.costPrice = "Cost Price must be a number";
    hasError = true;
  }

  if (formData.isPartsRequired && formData.partsDescription.trim() === "") {
    errors.partsDescription = "Parts Description need to be filled";
    hasError = true;
  }

  if (
    formData.sellingPrice.trim() === "" ||
    isNaN(parseFloat(formData.sellingPrice))
  ) {
    errors.sellingPrice = "Selling Price must be a number";
    hasError = true;
  }

  if (formData.assetType === "printer") {
    if (!formData.printerDetails.type) {
      errors.isTonerLevelNotFilled = "Printer type is required";
      hasError = true;
    }
    if (formData.printerDetails.type === "color") {
      let isAllTonerLevelsFilled = true;
      Object.keys(formData.printerDetails.tonerLevel).forEach(function (
        key,
        index
      ) {

        isAllTonerLevelsFilled = isAllTonerLevelsFilled
          ? !!formData.printerDetails.tonerLevel[key]
          : false;
      });
      if (!isAllTonerLevelsFilled) {
        errors.isTonerLevelNotFilled = "All toner level should be filled";
        hasError = true;
      }
    } else if (formData.printerDetails.type === "black&White" && !formData.printerDetails.tonerLevel.black) {
      errors.isTonerLevelNotFilled = "Black toner level should be filled";
      hasError = true;
    }
  }

  return { hasError, errors };
};

export const validateExportAssetChange = (value, name , selectedExportAssetData) => {
  if (name === "assetType" && value.trim() === "") {
    return "Asset Type is required";
  } else if (name === "machineName" && value.trim() === "") {
    return "Machine Name is required";
  } else if (name === "machineModel" && value.trim() === "") {
    return "Machine Model is required";
  } else if (name === "serialNumber" && value.trim() === "") {
    return "Serial Number is required";
  } else if (
    name === "costPrice" &&
    (value.trim() === "" || isNaN(parseFloat(value)))
  ) {
    return "Cost Price must be a number";
  } else if (
    name === "sellingPrice" &&
    (value.trim() === "" || isNaN(parseFloat(value)))
  ) {
    return "Selling Price must be a number";
  } else if (name === "tonerDetails.quantity" && (!/^\d+(\.\d+)?$/.test(value)))  {
    return "Quantity must be a number";
  }else if(name === 'tonerDetails.updateQuantityByDigit') {
    if (!/^\d+(\.\d+)?$/.test(value) && value.trim() !== "") {
      return "Quantity must be a number";
    }
    const updateQuantityByDigits = parseFloat(value);
    const selectedQuantity = selectedExportAssetData.tonerDetails.quantity;
    if (updateQuantityByDigits > selectedQuantity) {
      return "Quantity cannot be greater than actual quantity";
    }
  } else if (name === 'tonerDetails.updateQuantityByPercent') {
    if (!/^\d+(\.\d+)?$/.test(value) && value.trim() !== "") {
        return "Percentage must be a number";
    }
    const updateQuantityByPercent = parseFloat(value);
    if (updateQuantityByPercent <= 0 || updateQuantityByPercent > 100) {
        return "Percentage must be between 1 and 100";
    }
  }
};
