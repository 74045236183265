import React, { useState, useEffect } from 'react';

const IdleTimer = ({ timeout, onIdle }) => {
    const [idle, setIdle] = useState(false);

    useEffect(() => {
        let idleTimer;

        const resetTimer = () => {
            clearTimeout(idleTimer);
            setIdle(false);
            idleTimer = setTimeout(onIdle, timeout * 60000);
        };

        const handleEvent = () => {
            resetTimer();
        };

        // Add event listeners for user activity
        document.addEventListener('mousemove', handleEvent);
        document.addEventListener('keydown', handleEvent);
        document.addEventListener('touchstart', handleEvent);

        // Start the idle timer initially
        resetTimer();

        // Clean up event listeners on component unmount
        return () => {
            clearTimeout(idleTimer);
            document.removeEventListener('mousemove', handleEvent);
            document.removeEventListener('keydown', handleEvent);
            document.removeEventListener('touchstart', handleEvent);
        };
    }, [timeout, onIdle]);

    return <></>; // You can render your UI based on idle status
};

export default IdleTimer;