import { privateApi } from "../../api"

const deleteFile = async (data) => {
    return await privateApi.post('files/delete', data)
}

const downloadFileProxyApi = async (fileId) => {
    return await privateApi.get(`files/downloadFileProxyApi/${fileId}`)
}

export default {
    deleteFile, downloadFileProxyApi
}