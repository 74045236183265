import { Skeleton, Grid } from '@mui/material'
import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const UserEditorSkeleton = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Grid item xs={12}>
                <Skeleton variant="text" width={200} height={30} />
                <Skeleton variant="text" width={300} height={20} />
                <Skeleton variant="text" width={300} height={20} />
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Skeleton variant="rectangular" width={100} height={36} />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="rectangular" width={100} height={36} />
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Skeleton variant="rectangular" width="100%" height={36} />
                    <Skeleton variant="rectangular" width="100%" height={36} />

                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Skeleton variant="rectangular" width="100%" height={36} />
                    <Skeleton variant="rectangular" width="100%" height={36} />

                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6} >
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                    <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6} >
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                    <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6} >
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                    <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6} >
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                    <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6} >
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                </Grid>
                <br />
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container spacing={4}>
                    <Grid item xs={isMobile ? 12 : 6} sx={{ marginBottom: isMobile ? '10px' : undefined }}>
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6} >
                        <Skeleton variant="rectangular" width="100%" height={36} />
                    </Grid>
                </Grid>
                <br />
            </Grid>
        </>
    )
}

export default UserEditorSkeleton
