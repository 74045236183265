import React, { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import service from './service';
import { Avatar, Chip, Tooltip, Typography, Box } from '@mui/material';
import { stringToColor } from '../../../utilities/commonUtils';

export default function SingleUserSelector({ value = {}, onChange, disabled, required, error, label, companyId, disableFlag, onlySelectedCompanyUser }) {
    const [options, setOptions] = useState([]);

    const memoizedCompanyId = useMemo(() => companyId, [companyId]);

    async function getUserList(companyId) {
        const res = await service.getUsersListForMultiSelect(companyId ?? "", onlySelectedCompanyUser);
        if (res.success) {
            setOptions(res.data);
        }
    }

    useEffect(() => {
        getUserList(memoizedCompanyId);
    }, [memoizedCompanyId]);

    const renderOption = (props, option, { selected }) => (
        <li {...props}>
            <Tooltip
                title={
                    <Box>
                        <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                            {option.company.companyName}
                        </Typography>
                        {
                            option?.companyLocation?.locationName ?
                                <>
                                    <Typography variant="body2" sx={{ marginLeft: 1, fontStyle: 'italic' }}>
                                        Company Location: {option?.companyLocation?.locationName}
                                    </Typography>
                                </>
                                : null
                        }
                        <Typography variant="body2" sx={{ marginLeft: 1, fontStyle: 'italic' }}>
                            User Type: {option.userType}
                        </Typography>
                        <Typography variant="body2" sx={{ marginLeft: 1, fontStyle: 'italic' }}>
                            Role: {option.role}
                        </Typography>
                    </Box>
                }
                arrow
                placement="right"
            >
                <Chip
                    avatar={
                        <Avatar
                            alt="User Avatar"
                            src={option.photoURL || ''}
                            sx={{
                                backgroundColor: option.photoURL ? 'transparent' : stringToColor(option.displayName || `${option.firstName} ${option.lastName}`),
                                color: 'white',
                                marginRight: '10px',
                            }}
                        >
                            {!option.photoURL && (option.displayName || option.firstName) ? (
                                option.displayName
                                    ? option.displayName[0]
                                    : option.firstName[0]
                            ) : null}
                        </Avatar>
                    }
                    label={
                        (option.displayName || `${option.firstName} ${option.lastName}`)
                        + (option?.companyLocation?.locationName ? ` (${option.companyLocation.locationName})` : '')
                    }
                />
            </Tooltip>
        </li>
    );

    return (
        <div>
            <Autocomplete
                id="checkboxes-tags-demo"
                options={options}
                getOptionLabel={(option) => option.displayName || `${option.firstName} ${option.lastName}`}
                groupBy={(option) => option.company?.companyName}
                value={value}
                onChange={(event, newValue) => {
                    if (!disableFlag) {
                        onChange(newValue);
                    } else {
                        return
                    }
                }}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderOption={renderOption}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label ?? "Assigned Users"}
                        placeholder={label ?? "Assigned Users"}
                        required={required}
                        error={error}
                    />
                )}
            />
        </div>
    );
}