import React, { useState, useEffect } from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from "@mui/material";



import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';

const TicketPrioritySelector = ({
    label,
    value,
    onChange,
    helperText,
    error,
    required,
    name,
    defaultValue,
    disabled,
    disableFlag
}) => {
    const [options, setOptions] = useState([
        { key: "high", name: "High", backgroundColor: "#FF9900", icon: <PriorityHighIcon /> },
        { key: "medium", name: "Medium", backgroundColor: "#F5F5DC", icon: <DensityMediumIcon /> }, // Yellow
        { key: "low", name: "Low", backgroundColor: "#071952", icon: <LowPriorityIcon /> }, // Bluish
    ]);

    useEffect(() => {
        setOptions((prevState) => {
            if (disableFlag) {
                return prevState.filter((item) => item.key === value);
            } else {
                return prevState;
            }
        });
    }, [disableFlag, value]);


    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel
                required={required ?? false}
                sx={{
                    color: disabled ? "rgba(0, 0, 0, 0.38)" : undefined,
                }}
                htmlFor={name}
                shrink={value ? true : undefined} // Shrink the label when a value is selected
            >
                {label}
            </InputLabel>
            <Select
                value={value}
                name={name}
                onChange={onChange}
                disabled={disabled ?? false}
                label={label}
                error={error ?? false}
                defaultValue={defaultValue ?? ""}
                inputProps={{
                    id: name,
                }}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={option.key}
                        value={option.key}
                        sx={{
                            borderRadius: '3px',
                            padding: '10px',
                            fontWeight: 'medium',
                            fontSize: 'larger',
                            backgroundColor: "transparent",
                            "&:hover": {
                                backgroundColor: option.backgroundColor,
                                color: option.backgroundColor === "#071952" ? "white" : "black",
                            },
                            marginTop: index === 0 ? undefined : 0,
                            marginBottom: index === options.length - 1 ? undefined : 0,
                            display: "flex",
                            alignItems: "center", // Center icon and text vertically
                        }}
                    >
                        {/* Include the icon and text in a flex container */}
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                        }}>
                            <div style={{ marginRight: '10px' }}>
                                {option.icon}
                            </div>
                            {option.name}
                        </div>
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default TicketPrioritySelector;
