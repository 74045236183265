import { Grid } from '@mui/material';
import React from 'react';
import ListItAmcIssues from './ListItAmcIssues';
import FilterItAmcIssue from './filterItAmcIssue';
import EditItAmcIssue from './EditItAmcIssue';

const ItAmcManagement = () => {
    return (
        <>
            <FilterItAmcIssue />
            <div style={{paddingTop:".5rem"}} />
            <ListItAmcIssues />
            <EditItAmcIssue />
        </>
    );
}

export default ItAmcManagement;
