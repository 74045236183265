import { privateApi } from "../../../api";


const createCustomFieldValues = async (data) => {
    return await privateApi.post('customFields/createCustomFields', data);
}

const updateCustomFieldValues = async (data) => {
    return await privateApi.put(`customFields/editCustomFields/${data.id}`, data);
}

const deleteCustomFieldValues = async (data) => {
    return await privateApi.delete(`customFields/deleteCustomFields/${data.id}`);
}

const getCustomFields = async (queryData) => {
    const { label, inputType, page = 1, limit } = queryData;

    let queryParameters = [];
    if (label) queryParameters.push(`label=${label}`);
    if (inputType) queryParameters.push(`label=${label}`);
    
    if (page) queryParameters.push(`page=${page}`);
    if (limit) queryParameters.push(`limit=${limit}`);

    let queryString = queryParameters.join('&');

    return await privateApi.get(`customFields/getCustomFields?${queryString}`);
};


export default {
    createCustomFieldValues,
    updateCustomFieldValues,
    getCustomFields,
    deleteCustomFieldValues
};