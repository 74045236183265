import React, { useRef, useState, useEffect } from 'react';
import { Typography, Paper, Tooltip, } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Badge from '@mui/material/Badge';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ActiveUsersTable from './ActiveUsersTable';
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import UserEditor from './UserEditor';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomDialogueMedium from '../../components/commons/CustomDialogueMedium';
import { ResetButton, SearchButton } from '../../components/commons/Buttons';
import ActiveUserFilter from './ActiveUserFilter';
import CustomPopover from '../../components/commons/CustomPopover';
import PendingApprovalUsersTable from './PendingApprovalUsersTable';
import InActiveUsersTable from './InActiveUsersTable';
import PendingApprovalUsersFilter from './PendingApprovalUsersFilter';

function UserManagement() {
    const activeUsersTableRef = useRef();
    const pendingApprovalTabRef = useRef();
    const dispatch = useDispatch()
    const sessionUserId = useSelector((state) => state.userData.data._id);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint as needed
    const [dialogOpen, setDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTab, setCurrentTab] = useState('activeUsers');
    const [userBeingEdited, setUserBeingEdited] = useState(null);
    const [filterCountForBadge, setFilterCountForBadge] = useState(0);
    const [previousTab, setPreviousTab] = useState('');
    const filterValues = useSelector((state) => state.userManagement[currentTab === "activeUsers" ? 'activeUsersFilter' : 'pendingApprovalUsersFilter']);

    const handleTabChange = (event, newValue = 'activeUsers') => {
        if (['activeUsers', 'pendingApproval', 'inactive'].includes(currentTab)) setPreviousTab(currentTab);
        setCurrentTab(newValue);
    };

     const handleUserEditBtnClick = (user) => {
        if (sessionUserId === user._id) {
            toast.error('You are not allowed to edit your own Profile from User management, Please use your profile edit option.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 8000,
                style: { width: '400px' }, 
            });
            return;
        }
        if (userBeingEdited?._id && userBeingEdited._id !== user._id) {
            toast.warning('A record is already open for edit.', {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            setUserBeingEdited({ ...user, companyLocation: user?.companyLocation?._id ? { value: user?.companyLocation?._id, label: user.companyLocation?.locationName } : null });
            handleTabChange(undefined, user._id);
        }
    };

    const handleEditUserTabClose = () => {
        if (currentTab !== userBeingEdited._id) return;
        handleTabChange(undefined, previousTab);
        setTimeout(() => {
            setUserBeingEdited(null);
        }, 100);
    };


    const handleDialogOpen = (event) => {
        if (!isMobile) setAnchorEl(event.currentTarget);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const triggerFilterSearch = () => {
        if (activeUsersTableRef.current) {
            activeUsersTableRef.current.triggerSearchFromParent();
        }
    };

    const triggerFilterSearchForPendingApproval = () => {
        if (pendingApprovalTabRef.current) {
            pendingApprovalTabRef.current.triggerSearchFromParent();
        }
    };

    const footerButtons = (
        <>
            <ResetButton onClick={() => { dispatch({ type: currentTab === "activeUsers" ? 'userManagement/resetActiveUsersFilterData' : 'userManagement/resetPendingApprovalUsersFilterData' }) }} />
            <SearchButton onClick={currentTab === "activeUsers" ? triggerFilterSearch : triggerFilterSearchForPendingApproval} />
        </>
    );

    // When Filter value changes update the filter badge with the number of filter query being selected
    useEffect(() => {
        setFilterCountForBadge(Object.entries(filterValues).filter(([key, value]) => value && value !== "").length);
    }, [filterValues]);

    // Clear the filter state from redux up on return from the user management page
    useEffect(() => {
        return () => {
            dispatch({ type: 'userManagement/resetActiveUsersFilterData' });
            dispatch({ type: 'userManagement/resetPendingApprovalUsersFilterData' })
        }
    }, []);

    return (
        <div>
            <TabContext value={currentTab}>
                <Box sx={{ width: isMobile ? '100%' : '65%', typography: 'body1', display: 'flex', flexDirection: 'column' }}>
                    <Paper
                        elevation={1}
                        sx={{
                            borderColor: 'divider',
                            zIndex: 99,
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            transition: 'box-shadow 0.3s',
                            '&:hover': {
                                boxShadow: '3px 15px 15px rgba(0, 0, 0, 0.4)',
                                cursor: 'pointer',
                                elevation: 5,
                            },
                        }}
                    >
                        <TabList
                            onChange={handleTabChange}
                            aria-label="Chat mode tabs"
                            variant={isMobile ? 'scrollable' : 'fullWidth'}
                            allowScrollButtonsMobile={isMobile ? true : false}
                            orientation={'horizontal'}
                            sx={{
                                '& .MuiTab-root': {
                                    padding: '0 16px',
                                    minHeight: '48px', 
                                    maxHeight: '48px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:not(:last-child)': {
                                        borderRight: '1px solid #ccc', // Apply border to all but the last child
                                    },
                                },
                                '& .Mui-selected': {
                                    borderBottom: 'solid 5px #97C3F0',
                                    backgroundColor: '#f5f5f5',
                                },
                            }}
                        >
                            <Tab
                                value="activeUsers"
                                label={
                                    <div id='activeUsers' style={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="All Active Users" arrow placement="left">
                                            <Typography variant="subtitle2" fontWeight={600} fontSize={14}>
                                                Active Users
                                            </Typography>
                                        </Tooltip>
                                        {currentTab === 'activeUsers' && (
                                            <Tooltip title="Filter" arrow placement="top">
                                                <span style={{ marginLeft: "8px", cursor: "pointer" }} onClick={handleDialogOpen}>
                                                    <Badge badgeContent={filterCountForBadge} color="primary">
                                                        <FilterListIcon color={'primary'} fontSize="small" />
                                                    </Badge>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </div>
                                }
                            />

                            <Tab
                                value="pendingApproval"
                                label={
                                    <div id='pendingApproval' style={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="Requests to Join" arrow placement="left">
                                            <Typography variant="subtitle2" fontWeight={600} fontSize={14}>
                                                Pending Approval
                                            </Typography>
                                        </Tooltip>
                                        {currentTab === 'pendingApproval' && (
                                            <Tooltip title="Filter" arrow placement="top">
                                                <span style={{ marginLeft: "8px", cursor: "pointer" }} onClick={handleDialogOpen}>
                                                    <Badge badgeContent={filterCountForBadge} color="primary">
                                                        <FilterListIcon color={'primary'} fontSize="small" />
                                                    </Badge>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </div>
                                }
                            />

                            <Tab
                                label={
                                    <Tooltip title="Inactive Users" arrow placement="right">
                                        <Typography id='inactive' variant="subtitle2" fontWeight={600} fontSize={14}>
                                            Inactive Users
                                        </Typography>
                                    </Tooltip>
                                }
                                value="inactive"
                            />

                            {
                                userBeingEdited?._id && (
                                    <Tab
                                        label={
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title={userBeingEdited.displayName || `${userBeingEdited.firstName} ${userBeingEdited.lastName}`} arrow placement="top">
                                                    <Typography variant="subtitle2" fontWeight={600} fontSize={14}>
                                                        {userBeingEdited.displayName || `${userBeingEdited.firstName} ${userBeingEdited.lastName}`}
                                                    </Typography>
                                                </Tooltip>
                                                <Tooltip title="Close Tab" arrow placement="top">
                                                    <span style={{ marginLeft: "8px", cursor: "pointer" }} onClick={() => handleEditUserTabClose()}>
                                                        <CloseIcon fontSize="small" />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        }
                                        value={userBeingEdited?._id}
                                    />
                                )
                            }
                        </TabList>
                    </Paper>
                </Box>

                <TabPanel value="activeUsers">
                    <div className='chat-user-list'>
                        <ActiveUsersTable
                            ref={activeUsersTableRef}
                            handleUserEditBtnClick={handleUserEditBtnClick}
                            closeFilterModal={setDialogOpen}
                            isFilterModalOpen={dialogOpen}
                        />
                    </div>
                </TabPanel>

                <TabPanel value="pendingApproval">
                    <div className='chat-user-list'>
                        <PendingApprovalUsersTable
                            ref={pendingApprovalTabRef}
                            handleUserEditBtnClick={handleUserEditBtnClick}
                            closeFilterModal={setDialogOpen}
                            isFilterModalOpen={dialogOpen}
                        />
                    </div>
                </TabPanel>

                <TabPanel value="inactive">
                    <InActiveUsersTable
                        ref={activeUsersTableRef}
                        handleUserEditBtnClick={handleUserEditBtnClick}
                        closeFilterModal={setDialogOpen}
                        isFilterModalOpen={dialogOpen}
                    />
                </TabPanel>

                {userBeingEdited?._id && (
                    <TabPanel value={userBeingEdited?._id}>
                        <UserEditor userBeingEdited={userBeingEdited} setUserBeingEdited={setUserBeingEdited} />
                    </TabPanel>
                )}
            </TabContext>

            {isMobile && (
                <CustomDialogueMedium
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    headerText={currentTab === "activeUsers" ? "Filter Active Users" : "Filter Requests"}
                    footerButtons={footerButtons}
                >
                    {currentTab === "activeUsers" && (
                        <ActiveUserFilter />
                    )}
                    {currentTab === "pendingApproval" && (
                        <PendingApprovalUsersFilter />
                    )}
                </CustomDialogueMedium>
            )}
            {!isMobile && (
                <CustomPopover
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    anchorEl={anchorEl}
                    headerText={currentTab === "activeUsers" ? "Filter Active Users" : "Filter Requests"}
                    footerButtons={footerButtons}
                >
                    <div style={{ width: '650px' }}>
                        {currentTab === "activeUsers" && (
                            <ActiveUserFilter />
                        )}
                        {currentTab === "pendingApproval" && (
                            <PendingApprovalUsersFilter />
                        )}
                    </div>
                </CustomPopover>
            )}
        </div>
    )
}

export default UserManagement;
