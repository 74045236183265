import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  Button,
  Skeleton,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { publicApi } from "../api";
import {
  signInWithGoogle,
  signInWithMicrosoft,
} from "../utilities/auth-config/firebase-config";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAuth, deleteUser } from "firebase/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

const PageContainer = styled(Box)({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "linear-gradient(45deg, #f3e5f5, #e1bee7, #ce93d8)",
  position: "relative",
  overflow: "hidden",
});

const LoginContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "80%",
  height: "auto",
  maxWidth: "1000px",
  borderRadius: "20px",
  backgroundColor: "#fff",
  boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    width: "95%",
    height: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "95%",
    height: "auto",
  },
}));

const LoginSection = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "40px",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
    height: "auto",
  },
}));

const ImageSection = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const LoginButton = styled(Button)({
  marginTop: "20px",
  backgroundColor: "#6c3169",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#551651",
  },
});

const SocialButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  margin: theme.spacing(1),
  color: "#000",
  border: "1px solid #6c3169",
  "&:hover": {
    backgroundColor: "#d3c1d2",
    border: "1px solid #551651",
  },
}));

const CurvedShape = styled(Box)({
  position: "absolute",
  width: "300px",
  height: "300px",
  borderRadius: "50%",
  background: "rgba(255, 255, 255, 0.1)",
});

const commonTextFieldStyles = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "black",
    },
  },
};

const CustomerLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const authorizedUser = localStorage.getItem("_id");
    if (authorizedUser) {
      toast.warning("You are already logged in.");
      navigate("/");
    }
    const isSessionOut = localStorage.getItem("isSessionOut");
    if (isSessionOut) {
      toast.warn("Session expired, please re-login.");
      localStorage.removeItem("isSessionOut");
    }
  }, []);

  const handleValueChange = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;

    setLoginInput({ ...loginInput, [name]: value });
  };

  const handleLoginSubmit = async (method) => {
    setIsLoading(true);
    let authObj;
    if (method === "Google") {
      authObj = await signInWithGoogle();
    } else if (method === "Microsoft") {
      authObj = await signInWithMicrosoft();
    } else if (method === "email") {
      if (!loginInput.email || !loginInput.password) {
        toast.error("Email or password is missing.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });
        setIsLoading(false);
        return;
      }

      const loginData = await publicApi("POST", "users/loginUsers", {
        method: method,
        email: loginInput.email,
        password: loginInput.password,
      });
      if (loginData?.data?.data?._id) {
        localStorage.setItem("_id", loginData.data.data._id);
        dispatch({
          type: "user/updateUserFromAuth",
          payload: loginData.data.data,
        });
        setTimeout(() => {
          const lastVisitedPage = location?.state?.from;
          navigate(lastVisitedPage ?? "/");
        }, 200);
        return;
      } else if (loginData?.error?.message === "User not found") {
        toast.error("Not registered with us, please continue to register.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });

        setIsLoading(false);
        return;
      } else {
        toast.warning(
          loginData?.error?.message ?? `An unexpected error occurred.`,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000,
            style: { width: "400px" },
          }
        );
        setIsLoading(false);
      }
      return;
    }
    const firebaseError = "auth/account-exists-with-different-credential";
    if (authObj?.code && authObj?.code === firebaseError) {
      toast.error(
        "Account associated to this email address is registered using a different login provider.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        }
      );
      setIsLoading(false);
      return;
    }

    if (method !== "email" && (authObj?.uid || authObj?.localId)) {
      const loginResponse = await publicApi("POST", "users/loginUsers", {
        method: method,
        authObj: authObj,
      });
      if (loginResponse.data?.data?._id) {
        localStorage.setItem("_id", loginResponse.data?.data?._id);
        dispatch({
          type: "user/updateUserFromAuth",
          payload: loginResponse.data?.data,
        });
        setTimeout(() => {
          navigate("/");
        }, 800);
        return;
      } else if (loginResponse.error?.message === "User not found") {
        toast.error("Not registered with us, please continue to register.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          style: { width: "400px" },
        });
        // Delete Firebase authorized user
        const auth = getAuth();
        await deleteUser(auth.currentUser);
        setTimeout(() => {
          navigate("/signup");
        }, 1800);
        setIsLoading(false);
        return;
      } else {
        toast.error(
          loginResponse.error?.message ?? `An unexpected error occurred.`,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 8000,
            style: { width: "400px" },
          }
        );
        setIsLoading(false);
        return;
      }
    }
  };

  const redirectToLogin = () => {
    navigate("/signup");
  };

  const redirectToRegister = () => {
    navigate("/register");
  };

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleLoginSubmit("email");
    }
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <PageContainer>
      <CurvedShape style={{ top: "-150px", left: "-150px" }} />
      <CurvedShape style={{ bottom: "-100px", right: "-100px" }} />
      <LoginContainer>
        <LoginSection>
          <Box sx={{ mb: 2 }}>
            <img
              src={"/fixmyofficeHeroLogo.png"}
              alt="Logo"
              style={{ maxWidth: "250px", height: "auto", marginLeft: "-20px" }}
            />
          </Box>
          <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
            Welcome back! Please enter your details.
          </Typography>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            name="email"
            sx={commonTextFieldStyles}
            onChange={handleValueChange}
            value={loginInput.email}
          />
          <TextField
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            sx={commonTextFieldStyles}
            onChange={handleValueChange}
            value={loginInput.password}
            name="password"
            onKeyDown={handleKeyPress}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoginButton
            fullWidth
            variant="contained"
            sx={{ height: 48 }}
            onClick={() => {
              handleLoginSubmit("email");
            }}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <Typography variant="button">Log in</Typography>
            )}
          </LoginButton>
          <Typography variant="body2" align="center" sx={{ mt: 2, mb: 2 }}>
            Or continue with
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <SocialButton
                fullWidth
                variant="outlined"
                startIcon={
                  <img
                    src="/googleIcon.png"
                    alt="Google"
                    style={{ width: 24, height: 24 }}
                  />
                }
                onClick={() => handleLoginSubmit("Google")}
              >
                Google
              </SocialButton>
            </Grid>
            <Grid item xs={6}>
              <SocialButton
                fullWidth
                variant="outlined"
                startIcon={
                  <img
                    src="/microsoftIcon.png"
                    alt="Microsoft"
                    style={{ width: 24, height: 24 }}
                  />
                }
                onClick={() => handleLoginSubmit("Microsoft")}
              >
                Microsoft
              </SocialButton>
            </Grid>
          </Grid>
          <Typography variant="body2" align="center">
            Don't have an account?{" "}
            <Typography
              component="span"
              color="#612c5f"
              sx={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={redirectToRegister}
            >
              {" "}
              Register
            </Typography>
          </Typography>
        </LoginSection>
        <ImageSection>
          <img
            src={"/LoginSideImage.svg"}
            alt="Login"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </ImageSection>
      </LoginContainer>
    </PageContainer>
  );
};

export default CustomerLogin;
