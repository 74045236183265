import React, { useState, useEffect } from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from "@mui/material";

import ToDoIcon from "@mui/icons-material/CheckCircle";
import InProgressIcon from "@mui/icons-material/HourglassTop";
import EscalatedIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import HoldIcon from "@mui/icons-material/Pause";
import DiscardedIcon from "@mui/icons-material/Delete";
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


const TicketStatusSelector = ({
    label,
    value,
    onChange,
    helperText,
    error,
    required,
    name,
    defaultValue,
    disabled,
    ticketState,  // new or existing
    disableFlag
}) => {
    const [options, setOptions] = useState([
        { key: "todo", name: "To Do", backgroundColor: "#F5F5DC", icon: <ToDoIcon /> }, // Yellow
        { key: "inProgress", name: "In Progress", backgroundColor: "#071952", icon: <InProgressIcon /> }, // Bluish
        { key: "paymentPending", name: "PAYMENT-PENDING", backgroundColor: "#FF9900", icon: <EscalatedIcon /> }, // Orange
        { key: "done", name: "Done", backgroundColor: "#00AA00", icon: <DoneIcon /> }, // Dark green
        { key: "hold", name: "Hold", backgroundColor: "#E4F1FF", icon: <HoldIcon /> }, // Gray
        { key: "discarded", name: "Discarded", backgroundColor: "#F1EFEF", icon: <DiscardedIcon /> }, // Dark gray
        { key: "reqSuperAdminApproval", name: "Require Super Admin Approval", backgroundColor: "#F5F5DC", icon: <DoneAllIcon /> },
        { key: "reqCustomerAdminApproval", name: "Require Customer Admin Approval", backgroundColor: "#F5F5DC", icon: <DomainVerificationIcon /> },
    ]);

    const additionalOptions = [
        { key: "customerAdminApproved", name: "Customer Admin Approved", backgroundColor: "#00AA00", icon: <CheckCircleIcon /> }, // Green
        { key: "customerAdminDeclined", name: "Customer Admin Declined", backgroundColor: "#FF9900", icon: <CancelIcon /> }, // Red
        { key: "superAdminApproved", name: "Super Admin Approved", backgroundColor: "#00AA00", icon: <CheckCircleIcon /> }, // Green
        { key: "superAdminDeclined", name: "Super Admin Declined", backgroundColor: "#FF9900", icon: <CancelIcon /> } // Red
    ];


    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {
        setFilteredOptions((prevState) => {
            return disableFlag
                ? options.filter((option) => option.key === value)
                : options;
        })
    }, [disableFlag]);

    useEffect(() => {
        const hasAdditionalOption = additionalOptions.some((addOption) => addOption.key === value);
        if (hasAdditionalOption) {
            setFilteredOptions((prevState) => {
                const newOptions = [...prevState];
                const additionalOption = additionalOptions.find((addOption) => addOption.key === value);
                if (!newOptions.some((option) => option.key === additionalOption.key)) {
                    newOptions.push(additionalOption);
                }
                return newOptions;
            });
        }
    }, []);

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel
                required={required ?? false}
                sx={{
                    color: disabled ? "rgba(0, 0, 0, 0.38)" : undefined,
                }}
                htmlFor={name}
                shrink={value ? true : undefined} // Shrink the label when a value is selected
            >
                {label}
            </InputLabel>
            <Select
                value={value}
                name={name}
                onChange={onChange}
                disabled={disabled ?? false}
                label={label}
                error={error ?? false}
                defaultValue={defaultValue ?? ""}
                inputProps={{
                    id: name,
                }}
            >
                {filteredOptions.map((option, index) => (
                    <MenuItem
                        key={option.key}
                        value={option.key}
                        sx={{
                            borderRadius: '3px',
                            padding: '10px',
                            fontWeight: 'medium',
                            fontSize: 'larger',
                            backgroundColor: "transparent",
                            "&:hover": {
                                backgroundColor: option.backgroundColor,
                                color: option.backgroundColor === "#071952" ? "white" : "black",
                            },
                            marginTop: index === 0 ? undefined : 0,
                            marginBottom: index === options.length - 1 ? undefined : 0,
                            display: "flex",
                            alignItems: "center", // Center icon and text vertically
                        }}
                        disabled={ticketState === 'new' && option.key !== "open" && option.key !== "todo" && option.key !== "reqCustomerAdminApproval" && option.key !== "reqSuperAdminApproval"}                    >
                        {/* Include the icon and text in a flex container */}
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                        }}>
                            <div style={{ marginRight: '10px' }}>
                                {option.icon}
                            </div>
                            {option.name}
                        </div>
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default TicketStatusSelector;
